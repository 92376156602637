export const GET_COMMODITY_SUCCESS = (data) => ({
    type: 'GET_COMMODITY_SUCCESS',
    payload: data
})
export const GET_COMMODITY_FAIL = (data) => ({
    type: 'GET_COMMODITY_FAIL',
    payload: data
})
export const GET_COMMODITY = (data) => ({
    type: 'GET_COMMODITY',
    payload: data
})
export const RESET_COMMODITY_SCREEN = () => ({
    type: 'RESET_COMMODITY_SCREEN'
})