/* eslint-disable */
import { call, put, select } from "redux-saga/effects"
import axios from "axios"
import {
  CALL_QUIZ_API_FAIL,
  CALL_QUIZ_API_SUCCESS,
  GET_PROGRESS
} from "../actions/quiz.actions"
import { APIResponseParser } from "../../helpers/APIResponseParser.helpers"
import { addHeaders } from "../../helpers/Saga.helpers"
import { baseUrl } from "../../data/misc.data"
import { tokenSelector } from "../selectors/selectors"

 const QuizAPI = async (payload, token) => {
  try {
    const result =
      payload.payload.method === "get"
        ? await axios.get(`${baseUrl}/v1/quiz/${payload.payload.quizId}`, {
          headers: addHeaders(token)
        })
        : await axios.post(`${baseUrl}/v1/quiz/${payload.payload.quizId}`,
          payload,
          {
            headers: addHeaders(token)
          }
        )
    return APIResponseParser(result)
  } catch (error) {
    console.log(error)
    throw error;
  }

}

function* quizSaga(action) {
  try {
    const token = yield select(tokenSelector)
    const data = yield call(QuizAPI, action, token)

    yield put(CALL_QUIZ_API_SUCCESS(data))
    //  yield put(GET_PROGRESS(data))
  } catch (e) {
    yield put(CALL_QUIZ_API_FAIL(e))
  }
}
export default quizSaga
