/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import 'swiper/swiper.min.css'

const SalesTIps = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="sales-tips"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1 style={{
                        color:'var(--sales-tips) !important'
                    }}>Sales Tips for Parts Counter Reps</h1> 
                    <p style={{"marginTop":"0px"}}>Tips to help you sell the right parts to your customers</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                        <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Mates &amp; Relates" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Parts Marketing News" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Customer Purchase Drivers" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Value Over Price" onClick={() =>sliderNavClicked(4)}>4</a></li>
                    </ol>
                </nav>

                <div className="flex-row swiper" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1' style={{color:"black"}}>
                        <h3>Mates &amp; Relates</h3>
                        <hr/>
                        <div className="flex-row" style={{"width":"100%","alignItems":"center"}}>
                            <div className="flex-item-50">
                                <p>Your main role is making sure your customer has all the parts they need to get their repair completed, then placing and managing their orders.</p>
                                <p>Checking for mates & relates is not “up-selling”, it’s supporting your customer by making
                                    sure they don’t have to come all the way back for a $5 part to complete a large repair.
                                    Customers appreciate when you’ve got them covered on everything they need.
                                </p>
                            </div>
                            <div className="flex-item-50 callout">
                            Good customer service is giving them
                                what they
                                ask for. Great customer service
                                is giving them what they didn't know to ask for.</div>
                        </div>
                        <hr/>
                        <h3>How to get up to speed on Mates &amp; Relates</h3>
                        <div className="flex-row" style={{"width":"100%","alignItems":"center","marginBottom":"50px"}}>
                            <div className="flex-item-50" style={{"order":"2"}}>
                                <img src={require("../../images/sbom-slide-8.jpg")} style={{"width":"100%"}} />
                            </div>
                            <div className="flex-item-50" style={{"order":"1"}}>
                            <p>There are important related parts for just about any major part your customer orders.  You can view potential mates &amp; relates in <a href="/mydna/part-lookup-sbom#slide8"
                                    target="_blank">S-BOM</a> as you're viewing a specific part in the parts list.</p>
                            </div>
                        </div>
                        <hr/>
                        <h3>Parts U</h3>
                        <p>Learn many top Mates &amp; Relates parts for each commodity on Parts U:</p>
                        <div className="flex-row" style={{"marginBottom":"50px"}}>
                            <a className="commodity-link" href="/dashboard/commodities/batteries/mates-and-relates"
                                target="_blank">
                                <img src={require("../../images/batteries-icon.png")} />
                                Batteries</a>
                            <a className="commodity-link" href="/dashboard/commodities/filters/mates-and-relates"
                                target="_blank">
                                <img src={require("../../images/filters-icon.png")}/>
                                Filters</a>
                            <a className="commodity-link" href="/dashboard/commodities/hydraulics/mates-and-relates"
                                target="_blank">
                                <img src={require("../../images/hydraulics-icon.png")}/>
                                Hydraulics</a>
                            <a className="commodity-link" href="/dashboard/commodities/lubricants/mates-and-relates"
                                target="_blank">
                                <img src={require("../../images/lubricants-icon.png")}/>
                                Lubricants</a>
                            <a className="commodity-link" href="/dashboard/commodities/reman/mates-and-relates"
                                target="_blank">
                                <img src={require("../../images/reman-icon.png")}/>
                                Reman</a>
                            <a className="commodity-link" href="/dashboard/commodities/undercarriage/mates-and-relates"
                                target="_blank">
                                <img src={require("../../images/undercarriage-icon.png")}/>
                                Undercarriage</a>
                            <a className="commodity-link" href="/dashboard/commodities/sr-drive-system/mates-and-relates"
                                target="_blank">
                                <img src={require("../../images/sr-drive-system-icon.png")}/>
                                SR Drive System</a>
                        </div>
                        <hr/>
                        <h3 style={{"marginTop":"1.25rem"}}>Mates &amp; Relates Cards</h3>
                        <p>View the Mates &amp; Relates and parts counter pro-level information for the top 10 most frequently ordered parts:</p>
                        <div className="flex-row" style={{"width":"100%"}}>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide1">
                                <h5>Alternator</h5>
                                <img src={require("../../images/alternator-mr-card-back.jpeg")} alt="alternator mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide2">
                                <h5>Batteries</h5>
                                <img src={require("../../images/battery-mr-card-front.jpeg")} alt="batteries mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide3">
                                <h5>Cabin Air Filter</h5>
                                <img src={require("../../images/cabin-air-filters-mr-card-back.jpeg")} alt="cabin air filter mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide4">
                                <h5>Engine Belt</h5>
                                <img src={require("../../images/engine-belt-mr-card-front.jpeg")} alt="engine belt mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide5">
                                <h5>Engine Oil Filter</h5>
                                <img src={require("../../images/engine-oil-filters-mr-card-back.jpeg")} alt="engine oil filter mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide6">
                                <h5>Hydraulic Filter</h5>
                                <img src={require("../../images/hydraulic-filter-mr-card-front.jpeg")} alt="hydraulic filter mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide7">
                                <h5>Hydraulic Hose</h5>
                                <img src={require("../../images/hydraulic-hose-mr-card-back.jpeg")} alt="hydraulic hose mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide8">
                                <h5>UC Link Assembly</h5>
                                <img src={require("../../images/link-assembly-mr-card-front.jpeg")} alt="UC link assembly belt mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide9">
                                <h5>Starter</h5>
                                <img src={require("../../images/starter-mr-card-back.jpeg")} alt="starter belt mates & relates card"/>
                            </a>
                            <a className="mates-card" href="sales-mates-and-relates-tips#slide10">
                                <h5>Turbocharger</h5>
                                <img src={require("../../images/turbocharger-mr-card-front.jpeg")} alt="turbocharger belt mates & relates card"/>
                            </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                        <h3>Parts Marketing News</h3>
                        <hr />
                        <p>There is a wealth of helpful information available in <a href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/PMN.aspx" target="_blank">Parts Marketing News</a>, such as: </p>
                        <ul>
                        <li>The <a
                                    href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/PMN/PMN%20400-68.1%20UC%20Component%20Compatability.pdf"
                                    target="_blank">Undercarriage component compatibility chart</a> - quickly see which
                                parts are interchangeable across Conventional, GC, PLUS and PLUS-fit UCs.</li>
                            <li><a href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/PMN-PARTnership.aspx"
                                    target="_blank">Partnership Bulletins</a> with the latest updates and announcements
                                that directly impact your role (tip: sort by issue date).</li>
                            <li><a href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/PMN-Training.aspx"
                                    target="_blank">Training announcements and opportunities</a></li>
                            <li>The latest updates, news and changes for any commodity can be easily accessed by
                                clicking its tile on the <a
                                    href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/PMN.aspx"
                                    target="_blank">PMN homepage</a>.</li>
                        </ul>
                        <p>
                        <span class="bold">ProTip: </span>Sign up to receive new Parts Marketing News bulletins so you're always have the latest information for your dealership and your customers:
                        </p>
                        <img src={require('../../images/parts-marketing-news-slide.jpg')} alt="step 2" />
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                        <h3>What's Driving Your Customer's Purchase?</h3>  
                        <img src={require('../../images/time-cost-quality-card.jpg')} alt="step 3"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                        <h3>Value Over Price</h3>
                        <hr/>
                        <p>Komatsu owners have chosen to invest in a high-quality machine. Why would they want to compromise their investment by installing a cheaper, low-quality part during maintenance or repairs? Help them protect their investment.</p>
                        <img src={require('../../images/value-over-price-card.jpeg')} alt="step 4"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default SalesTIps
