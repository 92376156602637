/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"

const TrackMachines = () => {
  return (
    <>
      <div className="page " style={{ paddingTop: "20vh" }}>
        <div
          style={{
            position: "absolute",
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <div>
              <h1 style={{ fontWeight: "900", fontSize: "3.5rem" }}>
                <b id="tut-1" style={{ color: "white" }}>
                  Parts University
                </b>
              </h1>
            </div>
          </center>
        </div>
        <Row className="flex-wrap">
        <Col
            id="tut-4"
            className="machine-col mb-sm-5 mb-xs-5 ant-col-lg-7 ant-col-xl-7 position-relative"
            align="bottom"
            sm={24}
            md={24}
            lg={12}
            xl={12}>
            <Link
              className="link d-flex flex-column justify-content-center align-items-center machine"
              to="/dashboard/forestry">
              <img
                src={`https://cdn.komatsupartsu.com/new-Forestry_Harvester_901XCxparent.png`}
                alt=""
              />
            </Link>
            <h2 className="text-dark-blue machine-name machine-landing-titles">
              <b>Forestry</b>
            </h2>
          </Col>
          <Col
            id="tut-2"
            className="machine-col mb-sm-5 mb-xs-5 ant-col-lg-8 ant-col-xl-8 position-relative"
            align="bottom"
            sm={24}
            md={24}
            lg={12}
            xl={12}>
            <Link
              className="link d-flex flex-column justify-content-center align-items-center machine"
              to="/dashboard/construction">
              <img
                src={`https://cdn.komatsupartsu.com/new-PC210LC-11_hiRes_xparent.png`}
                alt=""
              />
            </Link>
            <h2 className="text-dark-blue machine-name machine-landing-titles">
              <b>Construction</b>
            </h2>
          </Col>
           <Col
            id="tut-3"
            className="machine-col mb-sm-5 mb-xs-5 ant-col-lg-9 ant-col-xl-9 position-relative"
            align="bottom"
            sm={24}
            md={24}
            lg={8}
            xl={8}>
            <Link
              className="link d-flex flex-column justify-content-center align-items-center machine"
              to="/dashboard/mining">
              <img
                src={`https://cdn.komatsupartsu.com/mining-electric-drive-truck.png`}
                alt=""
              />
            </Link>
            <h2 className="text-dark-blue machine-name machine-landing-titles">
              <b>Mining</b>
            </h2>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default TrackMachines
