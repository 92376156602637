export const CALL_QUIZ_API_SUCCESS = (data) => ({
  type: "CALL_QUIZ_API_SUCCESS",
  payload: data
})
export const CALL_QUIZ_API_FAIL = (data) => ({
  type: "CALL_QUIZ_API_FAIL",
  payload: data
})
export const CALL_QUIZ_API = (data) => ({
  type: "CALL_QUIZ_API",
  payload: data
})
export const RESET_QUIZ = () => ({
  type: "RESET_QUIZ"
})
export const RESET_QUIZ_SCREEN = () => ({
  type: "RESET_QUIZ_SCREEN"
})
export const IS_QUESTION_CORRECT = (payload) => ({
  type: "IS_QUESTION_CORRECT",
  payload
})
export const SUBMIT_QUIZ = (payload) => ({
  type: "SUBMIT_QUIZ",
  payload: {
    ...payload,
    method: "post"
  }
})
export const GET_PROGRESS = (payload) => ({
  type: "GET_PROGRESS",
  payload: {
    ...payload,
    method: "get"
  }
})
export const GET_USER_PROGRESS_SUCCESS = (payload) => ({
  type: "GET_USER_PROGRESS_SUCCESS",
  payload
})
