/* eslint-disable */
import React, { useState, useEffect } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { Row, Col, Modal, message } from "antd"

import { useSelector, useDispatch } from "react-redux"
import { quizSelector } from "../../../store/selectors/selectors"
import { useHistory } from "react-router-dom"
import Drop from "../../dnd/drop.dnd"
import DDrop from "../../dnd/drop-2.dnd"
import { reorder } from "../../../helpers/Utils.helpers"
import SuccessModal from "../other/success.modal"
import { useURL } from "../../hooks/url.hooks"
import {
  IS_QUESTION_CORRECT,
  SUBMIT_QUIZ
} from "../../../store/actions/quiz.actions"

const WordMatchQuizAlt = ({ ez_id, index, totalQuestions }) => {
  const history = useHistory()
  const quiz = useSelector(quizSelector(ez_id))
  const q = useSelector((state) => state.Quiz)
  const url = useURL()
  const quiz_active = url.query.get("quiz_active")
  const quiz_prompt = url.query.get("quiz_prompt")
  const dispatch = useDispatch()

  const quizId = url.query.get("qid")
  const initialstate = {
    provided_answers: quiz.provided_answers.filter(
      (item) => item.type === "word" || item.type === "image_answer"
    ),

    proposed_answers: [],

    provided_2_answers: quiz.provided_answers.filter(
      (item) => item.type !== "word" && item.type !== "image_answer"
    )
  }

  const nextQuestion = () => {
    if (quiz_active !== null && index + 1 !== totalQuestions) {
      history.push(
        `${window.location.pathname}?quiz_prompt=${quiz_prompt}&sub_quiz=${
          q.sub_quizes[index + 1].ez_id
        }&quiz_active=true`
      )
    } else if (quiz_active !== null && index + 1 === totalQuestions) {
      history.push(`${window.location.pathname}?quiz_active=false`)
    } else {
      history.push(
        index + 1 !== totalQuestions
          ? `/dashboard/quiz/${q.sub_quizes[index + 1].ez_id}?qid=${quizId}`
          : "/dashboard/machines"
      )
    }
  }

  const [state, setState] = useState(initialstate)

  // const [lastMove, setLastMove] = useState([])

  const moveItem = (source, destination) => {
    if (source.droppableId !== destination.droppableId) {
      // setLastMove([source, destination])

      setState({
        ...state,
        [source.droppableId]: state[source.droppableId].filter(
          (item, index) => index !== source.index
        ),
        [destination.droppableId]: state[destination.droppableId].concat(
          state[source.droppableId][source.index]
        )
      })
    } else {
      // REORDERS CURRENT LIST
      setState({
        ...state,
        [source.droppableId]: reorder(
          state[source.droppableId],
          source.index,
          destination.index
        )
      })
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    onAnswer(result.source, result.destination)
  }

  const onAnswer = (source = { droppableId: "" }, destination) => {
    if (
      typeof source.index !== "undefined" &&
      source.droppableId === "provided_answers"
    ) {
      const Qitem = state[source.droppableId][source.index]

      let correct = false
      for (let i = 0; i < quiz.correct_answers.length; i++) {
        const answer = quiz.correct_answers[i]
        if (
          answer[0] === parseInt(destination.droppableId) &&
          answer[1] === Qitem.keyIndex
        ) {
          correct = true
        }
      }

      if (correct) {
        message.success({
          content: "Correct!"
        })
        moveItem(source, destination)
      } else {
        message.error({
          content: "Incorrect! Please try again..."
        })
      }
    }
  }

  useEffect(() => {
    state.provided_2_answers.map((prov_answer) => {
      setState((oldstate) => ({
        ...oldstate,
        [prov_answer.keyIndex]: []
      }))
    })
    return () => {}
  }, [])

  useEffect(() => {
    const drops = state.provided_2_answers
    let correct = 0
    let answerLengths = {}
    for (let i = 0; i < quiz.correct_answers.length; i++) {
      const answer = quiz.correct_answers[i]
      if (typeof answerLengths[answer[0]] !== "undefined") {
        answerLengths[answer[0]] = answerLengths[answer[0]] + 1
      } else {
        answerLengths[answer[0]] = 1
      }
    }

    for (let i = 0; i < drops.length; i++) {
      const drop = drops[i]
      const keyId = drop.keyIndex.toString()
      if (typeof state[keyId] !== "undefined") {
        if (state[keyId].length === answerLengths[keyId]) {
          correct += 1
        }
      }
    }

    if (
      state.provided_2_answers.length === correct ||
      state.provided_answers.length === 0
    ) {
      dispatch(IS_QUESTION_CORRECT(true))
      dispatch(SUBMIT_QUIZ(quiz))
      if (index + 1 === totalQuestions) {
        dispatch(SUBMIT_QUIZ(q))
      }
      Modal.success({
        className: "quiz-contain",
        content: (
          <SuccessModal
            lastQuestion={index + 1 === totalQuestions}
            onOk={nextQuestion}
          />
        ),
        icon: null
      })
    }
  }, [state.provided_answers])

  return (
    <div>
      {index + 1}/{totalQuestions}
      <div className="py-3">
        <h5>
          Quiz:{" "}
          <strong>
            {quiz.question_prompt || "Match the word with the image"}
          </strong>
        </h5>
      </div>
      <div className="my-4">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Row justify="space-around" align="middle">
            <Col span={8}>
              <Drop
                items={state.provided_answers.sort(() => Math.random() - 0.5)}
                droppableId="provided_answers"
              />
            </Col>

            <Col span={12}>
              {state.provided_2_answers.map((prov_answer) => {
                return (
                  <div key={prov_answer.keyIndex} style={{ marginBottom: 20 }}>
                    <Row align="middle">
                      <Col span={12}>
                        <div
                          className={` rounded ${
                            typeof state[prov_answer.keyIndex] !==
                              "undefined" &&
                            state[prov_answer.keyIndex]?.length > 0
                              ? "green-haze"
                              : "shadow-sm"
                          }`}>
                          <DDrop
                            onAnswer={onAnswer}
                            style={{ padding: 0, minHeight: 100 }}
                            items={
                              typeof state[prov_answer.keyIndex] !== "undefined"
                                ? state[prov_answer.keyIndex]
                                : []
                            }
                            droppableId={prov_answer.keyIndex.toString()}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div>
                          {prov_answer.type === "image" && (
                            <center>
                              <div
                                style={{
                                  backgroundImage: `url(${prov_answer.data})`
                                }}
                                className="wordmatch-quiz-image"
                              />
                            </center>
                          )}
                          {prov_answer.type === "imagetitle" && (
                            <center>
                              <div
                                style={{
                                  backgroundImage: `url(${prov_answer.data[0]})`,
                                  height: 200,
                                  width: 200
                                }}
                                className="wordmatch-quiz-image"
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: prov_answer.data[1]
                                }}
                              />
                            </center>
                          )}

                          {prov_answer.type === "sentence" && (
                            <div className="p-4">
                              <p style={{ fontSize: 14 }}>{prov_answer.data}</p>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </Col>
          </Row>
        </DragDropContext>
      </div>
    </div>
  )
}

export default WordMatchQuizAlt
