/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import axios from "axios"

//document.documentElement.setAttribute("data-theme", "mydna");
const MachineGETAPI = async (payload, token) => {
    const result = await axios.get(`${baseUrl}/v1/machine/${payload.ez_id}`, {
      headers: addHeaders(token)
    })
    return APIResponseParser(result)
  }

const Main = ({
    dataTheme = "",
}) => {
  return (
    <>
      <div className="myDnaPage page" data-theme="mydna"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header">
                <div className="page-title">
                    <h1>Parts Counter Rep: MyDNA</h1>
                    <p >How to use the tools you need to get the job done</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
          <section className="flex-row">   
              <a className="portal part-lookup-portal" href="mydna/part-lookup" data-theme="lookup" style={{backgroundColor: "var(--blue-mydna)"}}>
                  <p>How to Look up a Part</p>
              </a>
              <a className="portal place-order-portal" href="mydna/place-an-order" data-theme="place-order"  style={{backgroundColor: "var(--blue-mydna)"}}>
                  <p>How to Place an Order</p>
              </a>
              <a className="portal manage-order-portal" href="mydna/manage-an-order" data-theme="manage-order" style={{backgroundColor: "var(--blue-mydna)"}}>
                  <p>How to Manage an Order</p>
              </a>
              <a className="portal communicate-portal" href="mydna/communicate" data-theme="communicate" style={{backgroundColor: "var(--blue-mydna)"}}>
                  <p>How to Communicate</p>
              </a>
              <a className="portal sales-tips-portal" href="mydna/sales-tips" data-theme="sales-tips" style={{backgroundColor: "var(--blue-mydna)"}}>
                  <p>Sales Tips</p>
              </a>
              <a className="portal pro-tips-portal" href="mydna/parts-counter-pro-tips" data-theme="pro-tips" style={{backgroundColor: "var(--blue-mydna)"}}>
                  <p>Productivity &amp; ProTips</p>
              </a>
          </section>
          <div className="divider"></div>
          <div className="flex-row" style={{marginBottom: "5rem"}}>
              <h2 style={{
              width: "100%", 
              textAlign: "center",
              marginTop:0,
              color: "var(--blue-mydna) !important",
              fontWeight:"bold"
            }}>Level Up</h2>
              <a className="portal-small"
                  href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/Training%20Resources.aspx"
                  target="_blank">
                  <p>PCP Training Programs</p>
              </a>
              <a className="portal-small" href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/PMN.aspx"
                  target="_blank">
                  <p>Parts &amp; Service News</p>
              </a>
              <a className="portal-small"
                  href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/PMN/Parts%20Counter%20Challenge%202022-2023.pdf"
                  target="_blank" title="current PCC information">
                  <p>Parts Counter Challenge</p>
              </a>
              <a className="portal-small" href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/PPL.aspx"
                  target="_blank" title="all PPLs on Parts Marketing Home">
                  <p>PPLs</p>
              </a>
              <a className="portal-small" href="/mydna/parts-managers" title="all PPLs on Parts Marketing Home">
                  <p>Just for Parts Managers</p>
              </a>
          </div>
          <div className="divider"></div>
        <div className="flex-row" style={{"marginBottom":"5rem","justifyContent":"space-between"}}>
            <h2 style={{"width":"100%","textAlign":"center","marginTop":"0", fontWeight:"bold", marginBottom:"40px"}}>Resources &amp; Support</h2>
            <div className="flex-item-50 content-box" style={{"padding":"0","justifyContent":"center"}}>
                <h3>Resources and Materials</h3>
                <a className="commodity-link" href="/dashboard/commodities/resources/batteries/" target="_blank">
                                <img src={require("../../images/batteries-icon.png")}/>
                                Batteries</a>
                            <a className="commodity-link" href="/dashboard/commodities/resources/filters/" target="_blank">
                                <img src={require("../../images/filters-icon.png")} />
                                Filters</a>
                            <a className="commodity-link" href="/dashboard/commodities/resources/hydraulics/" target="_blank">
                                <img src={require("../../images/hydraulics-icon.png")} />
                                Hydraulics</a>
                            <a className="commodity-link" href="/dashboard/commodities/resources/lubricants/" target="_blank">
                                <img src={require("../../images/lubricants-icon.png")} />
                                Lubricants</a>
                            <a className="commodity-link" href="/dashboard/commodities/resources/reman/" target="_blank">
                                <img src={require("../../images/reman-icon.png")} />
                                Reman</a>
                            <a className="commodity-link" href="/dashboard/commodities/resources/undercarriage/" target="_blank">
                                <img src={require("../../images/undercarriage-icon.png")} />
                                Undercarriage</a>
                            <a className="commodity-link" href="/dashboard/commodities/resources/sr-drive-system/" target="_blank">
                                <img src={require("../../images/sr-drive-system-icon.png")} />
                                SR Drive System</a>
                    </div>
                    <div className="flex-item-50 content-box" style={{"padding":"0","justifyContent":"center"}}>
                        <h3>Product Support Contacts</h3>
                        <a className="commodity-link" href="/dashboard/commodities/support/batteries/"
                        target="_blank">
                        <img src={require("../../images/batteries-icon.png")} />
                        Batteries</a>
                    <a className="commodity-link" href="/dashboard/commodities/support/filters/"
                        target="_blank">
                        <img src={require("../../images/filters-icon.png")} />
                        Filters</a>
                    <a className="commodity-link" href="/dashboard/commodities/support/hydraulics/"
                        target="_blank">
                        <img src={require("../../images/hydraulics-icon.png")} />
                        Hydraulics</a>
                    <a className="commodity-link" href="/dashboard/commodities/support/lubricants/"
                        target="_blank">
                        <img src={require("../../images/lubricants-icon.png")} />
                        Lubricants</a>
                    <a className="commodity-link" href="/dashboard/commodities/support/reman/"
                        target="_blank">
                        <img src={require("../../images/reman-icon.png")} />
                        Reman</a>
                    <a className="commodity-link" href="/dashboard/commodities/support/undercarriage/"
                        target="_blank">
                        <img src={require("../../images/undercarriage-icon.png")} />
                        Undercarriage</a>
                    <a className="commodity-link" href="/dashboard/commodities/support/sr-drive-system/"
                        target="_blank">
                        <img src={require("../../images/sr-drive-system-icon.png")} />
                        SR Drive System</a>
                    </div>
                </div>
        </main>
      </div>
    </>
  )
}

export default Main
