import React from "react"
import { Row, Col } from "antd"

const imageMap = (data) => {
  return (
    <Col span={8}>
      <div className="pointer-cursor d-flex justify-content-end align-items-center flex-column pb-5">
        <img alt="?" src={data.image} height={data.height} width={data.width} />
        <br />
        <b className="lubricants-color">{data.title}</b>
        <i>
          <div style={{ fontSize: 14 }}>{data.caption}</div>
        </i>
      </div>
    </Col>
  )
}

const LubricantsSizes = ({ images = [], title = "" }) => {
  return (
    <div className="page">
      <div className="d-flex justify-content-center px-5 p-2 pt-5">
        <h1>
          <b>{title}</b>
        </h1>
      </div>
      <Row gutter={32} align="bottom" justify="center">
        {images.map(imageMap)}
      </Row>
    </div>
  )
}

export default LubricantsSizes
