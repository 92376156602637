import React, { useState}  from "react"
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css'

const SlidesSection = ({ slides }) => {
    const [activeSlide, setActiveSlide] = useState(1)
    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    const slideNavigation = slides.map((slide, index) =>
        <li key={'nav'+index}>
            <a  href={"#slide" + (index +1)} 
                className={`step-title ${activeSlide === (index + 1)  ? 'active' : ''}`}
                data-text={slide.title} 
                onClick={() =>sliderNavClicked(index+1)}>
                {index+1}
            </a>
        </li>
    );
    const slideItems = slides.map((slide, index) =>
        <SwiperSlide id={'slide'+(index+1)} data-hash={'slide'+(index+1)} key={index}>
            <h3>{slide.title}</h3>
            <hr/>
            {slide.description}
            <img src={slide.image}  alt={'step '+(index+1)}/>
        </SwiperSlide>
    );

    return (
    <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
        <nav style={{paddingBottom:"20px"}}>
            <ol className="navigation">
                {slides.length > 0 && slideNavigation}
            </ol>
        </nav>

        <div className="flex-row" style={{color:"black"}}>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
            >
                {slides.length > 0 && slideItems}
            </Swiper>
        </div> 
    </section>
    )
}
export default SlidesSection
