import { Button, Modal } from "antd"
import React, { memo } from "react"
import { useEffect } from "react"
import { useState } from "react"
import Lottie from "react-lottie"
// import Welldone from "../../../assets/lotties/fireworks.json"

const SuccessModal = ({ lastQuestion, onOk = () => {}, game = false }) => {
  const [data, setdata] = useState(null)
  const onPress = () => {
    Modal.destroyAll()
    onOk()
    setdata(null)
  }
  useEffect(() => {
    setTimeout(() => {
      const Welldone = require("../../../assets/lotties/fireworks.json")
      setdata(Welldone)
    }, 3000)
  }, [])
  return (
    <div>
      {data !== null && (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: JSON.parse(JSON.stringify(data)),
            isStopped: data !== null,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
          height={150}
          width={150}
        />
      )}
      {lastQuestion && (
        <center>
          <h3>Great Job! You've successfully completed this quiz.</h3>
        </center>
      )}
      {!lastQuestion && (
        <center>
          <h3>Great Job! You correctly answered the question.</h3>
        </center>
      )}
      <div className="float-right">
        <Button type="primary" className="mr-3" onClick={onPress}>
          {lastQuestion ? "Done" : "Next"}
        </Button>

        {game && (
          <Button
            type="primary"
            onClick={() => {
              window.location.reload()
            }}>
            Retake
          </Button>
        )}
      </div>
    </div>
  )
}
export default memo(SuccessModal)
