/* eslint-disable */
import React, { useEffect } from "react"
import { Row, Col } from "antd"
import { useParrot } from "../hooks/parrot.hooks"
// import TestYourKnowledge from "../common/icons/test-your-knowledge"
// import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
// import { commoditySafetyLinks } from "../../helpers/quiz.helpers"

const FiltersSafety = () => {
  // const setModalToInactive = () => {
  //   let currentUrlParams = new URLSearchParams(window.location.search)
  //   currentUrlParams.set("quiz_active", "false")
  //   window.location.href =
  //     window.location.pathname + "?" + currentUrlParams.toString()
  // }
  const { setParrot } = useParrot()
  useEffect(() => {
    setParrot({
      dialog:
        "<b>Safety Tip:</b> Do not sit on or lean against stacked material. Always keep your safety and your team’s safety your top priority. You are important to us.",
      display: "TEACHER",
      mode: 1,
      next_bird_actions: []
    })
  }, [])
  return (
    <div className={`page filters-icon-safety-bg vh-80`}>
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="filters-color">
          <b>Filters</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <p>
            When inspecting or removing a filter from a machine, be sure to
            follow the procedures found in the shop manual and use appropriate
            PPE.
          </p>
        </Col>
      </Row>
      {/* <TestYourKnowledge link={commoditySafetyLinks.filters} />
      <QuizPrompt onExitEarly={setModalToInactive} /> */}
    </div>
  )
}

export default FiltersSafety
