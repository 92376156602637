const initialState = {
  dialog: "",
  display: "",
  mode: 0,
  next_bird_actions: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "UPDATE_LOCAL_VERSION":
      return initialState
    case "SET_PARROT":
      return { ...state, ...payload }
    case "RESET_PARROT":
      return initialState
    default:
      return state
  }
}
