import React from "react"
import { Row, Col } from "antd"
import { useParams, Link } from "react-router-dom"
import { useCommodityScreen } from "../hooks/screen.hooks"

const CommodityTechnical = () => {
  let { commodity_ez_id } = useParams()
  const { commodity } = useCommodityScreen(commodity_ez_id)

  return (
    <div className="page pt-5" style={{ minHeight: "84vh" }}>
      <div className="mb-5 pb-5">
        <center>
          <strong>
            <h1 className={`${commodity_ez_id.toLowerCase()}-color capitalize-title`}>
              <b>{commodity.name}</b>
            </h1>
          </strong>
          <div style={{ fontSize: 24, fontWeight: 600, fontFamily: "Roboto" }}>
            <b>Technical Information</b>
          </div>
        </center>
      </div>

      <Row>
        <Col span={2} />
        <Col span={20}>
          <div className="d-flex justify-content-between align-items-center flex-row">
            <Link
              to={`/dashboard/commodities/${commodity_ez_id.toLowerCase()}/how-it-works`}>
              <div className="d-flex justify-content-center align-items-center flex-column pointer-cursor">
                <img
                  className="object-fill mb-3"
                  height={200}
                  width={250}
                  src={require("../../assets/images/icons/how-it-works-icon@2x.png")}
                  alt=""
                />
                <h6 className="k-blue">How It Works</h6>
              </div>
            </Link>
            <Link
              to={`/dashboard/commodities/${commodity_ez_id.toLowerCase()}/key-terms`}>
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img
                  className="object-fill mb-3"
                  height={200}
                  width={200}
                  src={require("../../assets/images/icons/key-terms-icon@3x.png")}
                  alt=""
                />
                <h6 className="k-blue">Key Terms</h6>
              </div>
            </Link>
            <Link
              to={`/dashboard/commodities/${commodity_ez_id.toLowerCase()}/safety`}>
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img
                  className="object-fill mb-3"
                  height={200}
                  width={250}
                  src={require("../../assets/images/icons/safety-icon@3x.png")}
                  alt=""
                />
                <h6 className="k-blue">Safety</h6>
              </div>
            </Link>
            <Link
              to={`/dashboard/commodities/${commodity_ez_id.toLowerCase()}/mates-and-relates`}>
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img
                  className="object-fill mb-3"
                  height={200}
                  width={250}
                  src={require("../../assets/images/icons/mates-and-relates-icon-200px@3x.png")}
                  alt=""
                />
                <h6 className="k-blue">Mates & Relates</h6>
              </div>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CommodityTechnical
