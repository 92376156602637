import React, { useState } from "react"
import { Col, Input, Row, Select } from "antd"
import validator from "validator"
import Qty from "js-quantities"

const { Option } = Select

const Conversions = ({ units = "distance", initialUnit }) => {
  const [val, setVal] = useState(10)
  const [measures, setMeasures] = useState({
    root: initialUnit
  })
  const changeVal = (e) => {
    e.preventDefault()
    if (validator.isNumeric) {
      setVal(parseInt(e.target.value === "" ? 0 : e.target.value))
    }
  }
  const changeMeasure = (value) => {
    setMeasures({
      ...measures,
      root: value
    })
  }

  return (
    <div className="custom-p-5">
      Conversion
      {console.log(Qty.getUnits())}
      <Row>
        <Col>
          <Input onChange={changeVal} value={val} />
        </Col>
        <Col>
          <Select
            defaultValue={initialUnit}
            className="ml-3"
            style={{ width: 120 }}
            onChange={changeMeasure}>
            {units === "distance" && (
              <>
                <Option value="m">Meter</Option>
                <Option value="km">Kilometer</Option>
                <Option value="cm">Centimeter</Option>
                <Option value="mm">Milimeter</Option>
                <Option value="mi">Mile</Option>
                <Option value="yrd">Yard</Option>
                <Option value="ft">Foot</Option>
                <Option value="in">Inch</Option>
              </>
            )}
            {units === "volume" && (
              <>
                <Option value="liter">Liters</Option>
                <Option value="gallon">Gallons</Option>
                <Option value="pint">Pints</Option>
                <Option value="cup">Cups</Option>
                <Option value="quart">Quarts</Option>
                <Option value="fluid-ounce">Ounces</Option>
              </>
            )}
            {units === "temperature" && (
              <>
                <Option value="fahrenheit">Fahrenheit</Option>
                <Option value="celsius">Celsius</Option>
                <Option value="kelvin">Kelvin</Option>
                <Option value="rankine">Rankine</Option>
              </>
            )}
            {units === "pressure" && (
              <>
                <Option value="bar">Barometric</Option>
                <Option value="pascal">Pascal</Option>
                <Option value="atm">Atmospheric</Option>
                <Option value="torr">Torr</Option>
                <Option value="psi">Pressure Per Square Inch</Option>
              </>
            )}
          </Select>
        </Col>
      </Row>
      <div className="py-3">
        {units === "distance" && (
          <>
            <div>
              <b>Kilometers:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("km").scalar}
            </div>
            <div>
              <b>Centimeters:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("cm").scalar}
            </div>
            <div>
              <b>Milimeters:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("mm").scalar}
            </div>

            <div>
              <b>Miles:</b> {Qty(`${val} ${measures.root}`).to("mi").scalar}
            </div>
            <div>
              <b>Feet:</b> {Qty(`${val} ${measures.root}`).to("ft").scalar}
            </div>
            <div>
              <b>Inch:</b> {Qty(`${val} ${measures.root}`).to("in").scalar}
            </div>
          </>
        )}
        {units === "volume" && (
          <>
            <div>
              <b>Liters:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("liter").scalar.toFixed(2)}
            </div>
            <div>
              <b>Gallons:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("gallon").scalar.toFixed(2)}
            </div>
            <div>
              <b>Pints:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("pint").scalar.toFixed(2)}
            </div>

            <div>
              <b>Cups:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("cup").scalar.toFixed(2)}
            </div>
            <div>
              <b>Quarts:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("quart").scalar.toFixed(2)}
            </div>
            <div>
              <b>Ounces:</b>{" "}
              {Qty(`${val} ${measures.root}`)
                .to("fluid-ounce")
                .scalar.toFixed(2)}
            </div>
          </>
        )}
        {units === "temperature" && (
          <>
            <div>
              <b>Celsius:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("celsius").scalar.toFixed(2)}
            </div>
            <div>
              <b>Fahrenheit:</b>{" "}
              {Qty(`${val} ${measures.root}`)
                .to("fahrenheit")
                .scalar.toFixed(2)}
            </div>
          </>
        )}
        {units === "pressure" && (
          <>
            <div>
              <b>Barometric:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("bar").scalar.toFixed(2)}
            </div>
            <div>
              <b>Pascal:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("pascal").scalar.toFixed(2)}
            </div>
            <div>
              <b>Atmospheric:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("atm").scalar.toFixed(2)}
            </div>

            <div>
              <b>Torr:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("torr").scalar.toFixed(2)}
            </div>
            <div>
              <b>Pressure Per Square Inch:</b>{" "}
              {Qty(`${val} ${measures.root}`).to("psi").scalar.toFixed(2)}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Conversions
