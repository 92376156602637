/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import 'swiper/swiper.min.css'

const ManageCancelledOrders = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="manage-order"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                <h1>Cancel an Order</h1>
                    <p style={{"marginTop":"0px"}}>How to cancel an order after it's already been placed</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>

                <div className="flex-row content-box" style={{color:"black"}}>
                <h3>If you need to cancel an order after it has already been placed, contact your assigned customer care rep
            directly.</h3>
        <h3>If you have already received the part, you will need to submit a return request.</h3>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default ManageCancelledOrders
