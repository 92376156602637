import React, { Component } from 'react'
import { Row, Col } from 'antd'
export default class AuthLayout extends Component {
  render() {
    const { children } = this.props
    return (
      <div className='layout-container'>
        <Row>
          <Col span={12}>
            <div className='custom-p-5'>{children}</div>
          </Col>
        </Row>
      </div>
    )
  }
}
