export const GET_CONTENT_SUCCESS = (data) => ({
	type: 'GET_CONTENT_SUCCESS',
	payload: data
})
export const GET_CONTENT_FAIL = (data) => ({
	type: 'GET_CONTENT_FAIL',
	payload: data
})
export const GET_CONTENT = (data) => ({
	type: 'GET_CONTENT',
	payload: data
})
export const RESET_CONTENT_SCREEN = () => ({
	type: 'RESET_CONTENT_SCREEN'
})
