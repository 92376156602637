/* eslint-disable */
import React, { useState, useEffect } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { Row, Col, message, Modal } from "antd"

import { useSelector, useDispatch } from "react-redux"
import { quizSelector } from "../../../store/selectors/selectors"
import { useHistory } from "react-router-dom"

import HotDrop from "../../dnd/hotdrop.dnd"
import HotspotDrop from "../../dnd/hotspot.dnd"
import { reorder } from "../../../helpers/Utils.helpers"
import SuccessModal from "../other/success.modal"
import { useURL } from "../../hooks/url.hooks"
import {
  IS_QUESTION_CORRECT,
  SUBMIT_QUIZ
} from "../../../store/actions/quiz.actions"

const HotSpotQuiz = ({ ez_id, index, totalQuestions }) => {
  const quiz = useSelector(quizSelector(ez_id))
  const url = useURL()
  const dispatch = useDispatch()

  const quiz_prompt = url.query.get("quiz_prompt")
  const quiz_active = url.query.get("quiz_active")
  const quizId = url.query.get("qid")

  const initialstate = {
    provided_answers: quiz.provided_answers.filter(
      (item) => item.type !== "droparea"
    ),
    correct_answers: quiz.correct_answers,
    provided_2_answers: quiz.provided_answers.filter(
      (item) => item.type !== "word"
    )
  }
  quiz.provided_answers
    .filter((item) => item.type !== "word")
    .map((item) => {
      initialstate[item.keyIndex] = []
    })

  const [state, setState] = useState(initialstate)

  const history = useHistory()

  const q = useSelector((state) => state.Quiz)

  const nextQuestion = () => {
    if (quiz_active !== null && index + 1 !== totalQuestions) {
      history.push(
        `${window.location.pathname}?quiz_prompt=${quiz_prompt}&sub_quiz=${
          q.sub_quizes[index + 1].ez_id
        }&quiz_active=true`
      )
    } else if (quiz_active !== null && index + 1 === totalQuestions) {
      history.push(`${window.location.pathname}?quiz_active=false`)
    } else {
      history.push(
        index + 1 !== totalQuestions
          ? `/dashboard/quiz/${q.sub_quizes[index + 1].ez_id}?qid=${quizId}`
          : "/dashboard/machines"
      )
    }
  }

  const initDrops = (dropIndex) => {
    setState({
      ...state,
      [dropIndex]: []
    })
  }

  const moveItem = (source, destination) => {
    // console.log(source)
    if (source.droppableId !== destination.droppableId) {
      // TRAVERSES LISTS
      const correct = checkAnswer(destination.droppableId, source.index)
      if (correct) {
        setState({
          ...state,
          [source.droppableId]: state[source.droppableId].filter(
            (item, index) => index !== source.index
          ),
          [destination.droppableId]: state[destination.droppableId].concat(
            state[source.droppableId][source.index]
          )
        })
      }
    } else {
      // REORDERS CURRENT LIST
      setState({
        ...state,
        [source.droppableId]: reorder(
          state[source.droppableId],
          source.index,
          destination.index
        )
      })
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    // console.log(result.source, result.destination)
    moveItem(result.source, result.destination)
  }

  const checkAnswer = (dropid, sourceindex) => {
    let correctAnswer = false

    quiz.correct_answers.forEach((correct) => {
      if (
        correct[0] === state.provided_answers[sourceindex].keyIndex &&
        correct[1] === dropid
      ) {
        correctAnswer = true
        return
      }
    })
    if (correctAnswer) {
      message.success("Correct!")
    } else {
      message.error("Incorrect, please try again.")
    }
    return correctAnswer
  }

  const onAnswer = () => {
    // console.log(index, totalQuestions)
    if (state.provided_answers.length === 0) {
      dispatch(IS_QUESTION_CORRECT(true))
      dispatch(SUBMIT_QUIZ(quiz))
      if (index + 1 === totalQuestions) {
        dispatch(SUBMIT_QUIZ(q))
      }
      Modal.success({
        className: "quiz-contain",
        content: (
          <SuccessModal
            lastQuestion={index + 1 === totalQuestions}
            onOk={nextQuestion}
          />
        ),

        icon: null
      })
    }
  }

  useEffect(onAnswer, [state.provided_answers])

  return (
    <div>
      {index + 1}/{totalQuestions}
      <div className="py-3">
        <h5>
          Quiz: <strong>Drag each component to its correct location</strong>
        </h5>
      </div>
      <div className="my-4">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Row gutter={32} align="middle" wrap="false">
            <Col span={6} justify="center" align="left">
              <HotDrop
                mode="hotspot"
                items={state.provided_answers}
                droppableId="provided_answers"
              />
            </Col>
            <Col span={18} style={{ transform: "scale(1.4)" }}>
              <div
                className="hotspot-drop"
                style={{
                  backgroundImage: `url(${quiz.question_prompt})`
                }}>
                {state.provided_2_answers.map((drop) => {
                  return (
                    <HotspotDrop
                      onLayout={() => {
                        initDrops(drop.keyIndex)
                      }}
                      key={drop.keyIndex}
                      items={
                        typeof state[drop.keyIndex] !== "undefined"
                          ? state[drop.keyIndex]
                          : []
                      }
                      droppableId={drop.keyIndex}
                      positioning={drop.data}
                    />
                  )
                })}
              </div>
            </Col>
          </Row>
        </DragDropContext>
      </div>
    </div>
  )
}

export default HotSpotQuiz
