export const addHeaders = (token) => {
  return {
    Authorization: `Bearer ${token}`
  }
}
export const addTokenToPayload = (action) => {
  return {
    ...action.payload,
    token: action.token
  }
}
