/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

//document.documentElement.setAttribute("data-theme", "mydna");

const PartManager = () => {
  return (
    <>
      <div className="myDnaPage parts-managers page" data-theme="parts-managers"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header">
                <div className="page-title">
                    <h1>How Adults Learn</h1>
                    <p>Proven methods you can use to teach new skills to your PCRs</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
            <div className="content-box" style={{paddingTop:"20px"}}>
                <ul className="breadcrumb">
                    <li><a href="parts-managers">Just for Managers</a></li>
                    <li>How Adults Learn</li>
                </ul>
                <h2 style={{margin:"0"}}>Training Methods &amp; Tips</h2>
                <p>Adults learn best by doing, so Parts U and MyDNA were designed in alignment with proven adult learning
                    priciples to maximize their retention.</p>
                <div className="highlight-box">
                    <h4>We know that adult learners:</h4>
                    <ul>
                        <li>Thrive in independent training scenarios</li>
                        <li>Learn through experience</li>
                        <li>Learn best when they understand there will be immediate value</li>
                    </ul>
                    <h4>Parts U and MyDNA support these aspects of adult learning by:</h4>
                    <ul>
                        <li>Allowing your PCRs to complete training in a self-directed setting</li>
                        <li>Providing engaging interaction with new, real-world information</li>
                        <li>Ensuring immediate improvement in job performance</li>
                    </ul>
                </div>
                <p>Below are introductions to two proven methods that can help you effortlessly tailor your day-to-day
                    training to both new and existing PCRs to help cross-share skills and knowledge and level up your team
                    more quickly.</p>
                <section className=" flex-row">
                    <div className="sodoto">
                        <h3>SODOTO</h3>
                        <a href="sodoto">
                            <div className="mgr-portal"></div>
                        </a>
                        <p>A proven method for efficiently sharing knowledge across your team</p>
                    </div>
                    <div className="learning-styles">
                        <h3>Learning Styles</h3>
                        <a href="learning-styles">
                            <div className="mgr-portal"></div>
                        </a>
                        <p>How to deliver new information so it's understood and remembered</p>
                    </div>
                </section>
            </div>
        </main>
      </div>
    </>
  )
}

export default PartManager
