import { useLocation } from 'react-router-dom'
export const useURL = () => {
	const location = useLocation()
	return {
		...location,
		query: new URLSearchParams(location.search),
	}
}

export const useQuery = () => {
	const location = useLocation()

	return new URLSearchParams(location.search)
}
