import React from "react"
import { Rate, Input, Button, Divider, message } from "antd"
import { useState } from "react"
import axios from "axios"
import { baseUrl, headers } from "../../data/misc.data"
import { useSelector } from "react-redux"

const { TextArea } = Input
const FeedbackForm = ({ onComplete = () => {} }) => {
  const token = useSelector((state) => state.User.accessToken)
  const [state, setstate] = useState({
    feedback: "",
    rating: 0
  })

  const dismissModal = () => {
    axios.post(`${baseUrl}/v1/feedback`, state, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    })
    onComplete()
    message.success({ content: "We appreciate your feedback" })
  }

  const setFeedback = (e) => {
    setstate({
      ...state,
      feedback: e.target.value
    })
  }

  const setRating = (value) => {
    setstate({
      ...state,
      rating: value
    })
  }

  return (
    <div>
      <h3>Help us help you.</h3>
      <p>
        Your feedback is important to us. See a problem? See something you like?
        Let us know!
      </p>
      <Rate onChange={setRating} />
      <TextArea onChange={setFeedback} rows={4} />
      <Divider />
      <Button onClick={dismissModal} type="primary">
        Send us feedback
      </Button>
    </div>
  )
}

export default FeedbackForm
