/* eslint-disable */
import React, { useEffect } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import Lottie from "react-lottie"
import Ripple from "../../assets/lotties/ripple"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: Ripple,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}
const HotspotDrop = ({
  items,
  droppableId,
  positioning,
  onLayout = () => {}
}) => {
  useEffect(() => {
    onLayout()
  }, [])
  const reduceItem = (item, provided) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="d-flex align-items-center justify-content-center"
        style={{
          ...provided.draggableProps.style,
          userSelect: "none",
          zIndex: 100,
          padding: 0,
          margin: "0",
          color: "#140a9a",
          fontSize: 10
        }}>
        <div
          // className="shadow"
          style={{
            backgroundColor: "white",
            borderRadius: 15,
            marginRight: 5
          }}>
          <Lottie
            options={lottieOptions}
            height={30}
            width={30}
            isStopped={false}
            isPaused={false}
          />
        </div>
        <b>{item.data.toUpperCase()}</b>
        {provided.placeholder}
      </div>
    )
  }

  return (
    <Droppable type="group" droppableId={droppableId}>
      {(provided_) => (
        <div
          ref={provided_.innerRef}
          {...provided_.droppableProps}
          className=" d-flex justify-content-center align-items-center shadow-lg border reman-border"
          style={{
            height: positioning.height,
            width: positioning.width,
            position: "absolute",
            top: `${positioning.y}%`,
            left: `${positioning.x}%`,
            transform: `rotate(${positioning.rotation_degrees}deg)`
          }}>
          {items.map((item, index) => (
            <Draggable
              key={item.keyIndex.toString()}
              draggableId={item.keyIndex.toString()}
              index={index}>
              {(provided) => reduceItem(item, provided)}
            </Draggable>
          ))}
          {provided_.placeholder}
        </div>
      )}
    </Droppable>
  )
}
export default HotspotDrop
