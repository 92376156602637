/* eslint-disable */
import React, { useEffect } from "react"
import { Row, Col } from "antd"
import { useParrot } from "../hooks/parrot.hooks"

const LubricantsSafety = () => {
  const { setParrot } = useParrot()
  useEffect(() => {
    setParrot({
      dialog:
        "<b>Safety Tip:</b>  Treat aisles the same way you would a busy street. Look both ways and always stay alert when crossing or going around corners. Your personal safety and the safety of those around you matters.",
      display: "TEACHER",
      mode: 1,
      next_bird_actions: []
    })
  }, [])
  return (
    <div className="page lubricants-terms-bg vh-80">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="lubricants-color">
          <b>Lubricants</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <p>
            Reference product-specific Safety Data Sheets (SDS) for safety and
            handling information. Available at Parts Marketing Home.
          </p>
        </Col>
      </Row>
      {/* <TestYourKnowledge link={commoditySafetyLinks.lubricants} />
      <QuizPrompt onExitEarly={setModalToInactive} /> */}
    </div>
  )
}

export default LubricantsSafety
