const initialState = {
  loading: {
    global: false,
    MachineLearning: true,
    commodityLearning: true,
    auth: true
  },

  progress: 0,
  completed_quizes: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_USER_PROGRESS_SUCCESS":
      return {
        ...state,
        progress: payload.percent,
        completed_quizes: payload.completed_quizes
      }
    case "GET_MACHINE":
      return {
        ...state,
        loading: {
          ...state.loading,
          MachineLearning: true
        }
      }
    case "GET_MACHINE_SUCCESS":
    case "GET_MACHINE_FAIL":
      return {
        ...state,
        loading: {
          ...state.loading,
          MachineLearning: false
        }
      }
    case "GET_COMMODITY":
      return {
        ...state,
        loading: {
          ...state.loading,
          CommodityLearning: true
        }
      }
    case "GET_COMMODITY_SUCCESS":
    case "GET_COMMODITY_FAIL":
      return {
        ...state,
        loading: {
          ...state.loading,
          CommodityLearning: false
        }
      }
    case "LOGIN":
      return {
        ...state,
        loading: {
          ...state.loading,
          global: true,
          auth: true
        }
      }
    case "LOGIN_SUCCESS":
    case "LOGIN_FAIL":
    case "LOGOUT":
      return {
        ...state,
        loading: {
          ...state.loading,
          global: false,
          auth: false
        }
      }
    default:
      return state
  }
}
