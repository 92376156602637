const initialState = {
  _id: "",
  ez_id: "",
  name: "",
  alloted_time: 0,
  quiz_type: 0,
  rules: [],
  sub_quizes: [],
  provided_answers: [],
  correct_answers: [],
  points: 0,
  question_prompt: "",
  submitted_answers: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "CALL_QUIZ_API_SUCCESS":
      return { ...payload, submitted_answers: [] }
    case "SAVE_QUIZ_QUESTION":
      return { ...payload, submitted_answers: [] }
    case "RESET_QUIZ":
      return initialState
    default:
      return state
  }
}
