// const location = new URLSearchParams(window.location.search)
export const commodityLandingQuizLinks = {
  batteries: `?quiz_prompt=batteries_landing_compilation&quiz_active=true`,
  filters: `?quiz_prompt=filters_landing_compilation&quiz_active=true`,
  lubricants: `?quiz_prompt=lubricants_landing_compilation&quiz_active=true`,
  hydraulics: `?quiz_prompt=hydraulics_landing_compilation&quiz_active=true`,
  undercarriage: `?quiz_prompt=undercarriage_landing_compilation&quiz_active=true`,
  reman: `?quiz_prompt=reman_landing_compilation&quiz_active=true`,
  "sr-drive-system": `?quiz_prompt=srdrive_landing_compilation&quiz_active=true`
}

export const machineLandingLinks = {
  excavator:
    "?scene=machine&quiz_prompt=excavator_compilation_0&quiz_active=true",
  dozer: "?scene=machine&quiz_prompt=dozer_compilation_0&quiz_active=true",
  "wheel-loader":
    "?scene=machine&quiz_prompt=wheel_compilation_0&quiz_active=true",
  "haul-truck":
    "?scene=machine&quiz_prompt=haul_compilation_0&quiz_active=true",
  "final-machines":
    "?scene=machine&quiz_prompt=top_level_reg&quiz_active=true",
  "forestry-excavator":
    "?scene=machine&quiz_prompt=ex_forest_0&quiz_active=true",
  "wheeled-harvester":
    "?scene=machine&quiz_prompt=harvester_compilation_0&quiz_active=true",
  "feller-buncher":
    "?scene=machine&quiz_prompt=feller_compilation_0&quiz_active=true",
  forwarders:
    "?scene=machine&quiz_prompt=forwarder_compilation_0&quiz_active=true",
  "final-forestry":
    "?scene=machine&quiz_prompt=top_level_comp&quiz_active=true",
  commodities:
    "?quiz_prompt=top_level_forestry_comp&quiz_active=true&scene=commodities",
  "hybrid-wheel-loader":
  "?scene=machine&quiz_prompt=hybrid_wheel_loader_compilation_0&quiz_active=true",
  "electric-drive-truck":
  "?scene=machine&quiz_prompt=electric_drive_truck_compilation_0&quiz_active=true",
  drill:
  "?scene=machine&quiz_prompt=drill_compilation_0&quiz_active=true",
  shovel:
  "?scene=machine&quiz_prompt=shovel_compilation_0&quiz_active=true",
  "final-mining":
    "?scene=machine&quiz_prompt=top_level_mining&quiz_active=true",
}

export const commodityProductOfferingLinks = {
  batteries: `?quiz_prompt=batteries_product_offering_compilation&quiz_active=true`,
  filters: `?quiz_prompt=filters_product_offering_compilation&quiz_active=true`,
  lubricants: `?quiz_prompt=lubricants_product_offering_compilation&quiz_active=true`,
  hydraulics: `?quiz_prompt=hydraulics_product_offering_compilation&quiz_active=true`,
  undercarriage: `?quiz_prompt=undercarriage_product_offering_compilation&quiz_active=true`,
  reman: `?quiz_prompt=reman_product_offering_compilation&quiz_active=true`,
  "sr-drive-system": `?quiz_prompt=srdrive_product_offering_compilation&quiz_active=true`
}

export const commodityHowItWorksLinks = {
  batteries: `?quiz_prompt=batteries_hiw_compilation&quiz_active=true`,
  filters: `?quiz_prompt=filters_hiw_compilation&quiz_active=true`,
  lubricants: `?quiz_prompt=lubricants_hiw_compilation&quiz_active=true`,
  hydraulics: `?quiz_prompt=hydraulics_hiw_compilation&quiz_active=true`,
  undercarriage: `?quiz_prompt=undercarriage_hiw_compilation&quiz_active=true`,
  reman: `?quiz_prompt=reman_how_it_works&quiz_active=true`,
  "srdrivesystem": `?quiz_prompt=srdrive_hiw_compilation&quiz_active=true`
}

export const valuePropLinks = {
  batteries: `?quiz_prompt=batteries_twine_compilation&quiz_active=true`,
  filters: `?quiz_prompt=filters_twine_compilation&quiz_active=true`,
  lubricants: `?quiz_prompt=lubricants_twine_compilation&quiz_active=true`,
  hydraulics: `?quiz_prompt=hydraulics_twine_compilation&quiz_active=true`,
  undercarriage: `?quiz_prompt=undercarriage_twine_compilation&quiz_active=true`,
  reman: `?quiz_prompt=reman_twine_compilation&quiz_active=true`
}

export const commodityKeyTermsLinks = {
  batteries: `?quiz_prompt=batteries_kt_compilation&quiz_active=true`,
  filters: `?quiz_prompt=filters_kt_compilation&quiz_active=true`,
  lubricants: `?quiz_prompt=lubricants_kt_compilation&quiz_active=true`,
  hydraulics: `?quiz_prompt=hydraulics_kt_compilation&quiz_active=true`,
  undercarriage: `?quiz_prompt=undercarriage_kt_compilation&quiz_active=true`,
  reman: `?quiz_prompt=reman_kt_compilation&quiz_active=true`,
  "sr-drive-system": `?quiz_prompt=srdrive_kt_compilation&quiz_active=true`
}

export const commoditySafetyLinks = {
  batteries: `?quiz_prompt=batteries_safety_compilation&quiz_active=true`,
  // filters: `?quiz_prompt=filters_safety_compilation&quiz_active=true`,
  // lubricants: `?quiz_prompt=lubricants_safety_compilation&quiz_active=true`,
  hydraulics: `?quiz_prompt=hydraulics_safety_compilation&quiz_active=true`
  // undercarriage: `?quiz_prompt=undercarriage_safety_compilation&quiz_active=true`,
  // reman: `?quiz_prompt=reman_safety_compilation&quiz_active=true`
}

export const commodityMatesLinks = {
  batteries: `?quiz_prompt=batteries_mates_compilation&quiz_active=true`,
  filters: `?quiz_prompt=filters_mates_compilation&quiz_active=true`,
  lubricants: `?quiz_prompt=lubricants_mates_compilation&quiz_active=true`,
  hydraulics: `?quiz_prompt=hydraulics_mates_compilation&quiz_active=true`,
  undercarriage: `?quiz_prompt=undercarriage_mates_compilation&quiz_active=true`,
  reman: `?quiz_prompt=reman_mates_compilation&quiz_active=true`,
  "sr-drive-system": `?quiz_prompt=srdrive_mates_compilation&quiz_active=true`
}

export const commoditySupportLinks = {
  batteries: `?quiz_prompt=batteries_support_compilation&quiz_active=true`,
  filters: `?quiz_prompt=filters_support_compilation&quiz_active=true`,
  lubricants: `?quiz_prompt=lubricants_support_compilation&quiz_active=true`,
  hydraulics: `?quiz_prompt=hydraulics_support_compilation&quiz_active=true`,
  undercarriage: `?quiz_prompt=undercarriage_support_compilation&quiz_active=true`,
  reman: `?quiz_prompt=reman_support_compilation&quiz_active=true`,
  "sr-drive-system": `?quiz_prompt=srdrive_support_compilation&quiz_active=true`
}
