export const GET_MACHINE_SUCCESS = (data) => ({
    type: 'GET_MACHINE_SUCCESS',
    payload: data
})
export const GET_MACHINE_FAIL = (data) => ({
    type: 'GET_MACHINE_FAIL',
    payload: data
})
export const GET_MACHINE = (data) => ({
    type: 'GET_MACHINE',
    payload: data
})
export const RESET_MACHINE_SCREEN = () => ({
    type: 'RESET_MACHINE_SCREEN'
})