/* eslint-disable */
import React, { useEffect } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import Lottie from "react-lottie"
import Correct from "../../assets/lotties/correct.json"
import Incorrect from "../../assets/lotties/incorrect.json"
import Fade from "react-reveal/Fade"

const lottieOptions = {
  loop: true,
  autoplay: true,

  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}
const Drop = ({
  items,
  droppableId,
  isCorrect,

  style = {},
  itemStyle = {},
  onAnswer = () => {}
}) => {
  const reduceItem = (item, provided) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={
          "wordmatch-item d-flex align-items-center justify-content-center"
        }
        style={{
          ...provided.draggableProps.style,
          userSelect: "none",
          padding: 0,
          margin: "0"
        }}>
        {item.type === "image" && (
          <div>
            <center>
              <div
                style={{ backgroundImage: `url(${item.data})` }}
                className="wordmatch-quiz-image"
              />
            </center>
            {provided.placeholder}
          </div>
        )}
        {item.type === "word" && (
          <center>
            <div className="wordmatch-quiz-text">
              <h4 style={itemStyle}>{item.data}</h4>
            </div>
            {provided.placeholder}
          </center>
        )}
        {item.type === "image_answer" && (
          <div>
            <center>
              <div
                style={{
                  backgroundImage: `url(${item.data[1]})`,
                  height: 50,
                  width: 50
                }}
                className="wordmatch-quiz-image"
              />
              <h4>{item.data[0]}</h4>
            </center>
            {provided.placeholder}
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (items.length > 0) {
      onAnswer(isCorrect)
    }
  }, [items])

  return (
    <div style={{ position: "relative" }}>
      {isCorrect && typeof isCorrect !== "undefined" && items.length === 1 && (
        <div style={{ position: "absolute", left: -100, top: 0 }}>
          <Fade right>
            <center>Correct!</center>
            <Lottie
              options={{ ...lottieOptions, animationData: Correct }}
              height={60}
              width={60}
              isStopped={false}
              isPaused={false}
            />
          </Fade>
        </div>
      )}

      <Droppable type="group" droppableId={droppableId}>
        {(provided_) => (
          <div
            ref={provided_.innerRef}
            {...provided_.droppableProps}
            className={"rounded-lg p-1 "}
            style={style}>
            {items.map((item, index) => (
              <Draggable
                key={item.keyIndex.toString()}
                draggableId={item.keyIndex.toString()}
                index={index}>
                {(provided) => reduceItem(item, provided)}
              </Draggable>
            ))}
            {provided_.placeholder}
          </div>
        )}
      </Droppable>

      {!isCorrect && typeof isCorrect !== "undefined" && items.length === 1 && (
        <div style={{ position: "absolute", left: -100, top: 0 }}>
          <Fade right>
            <Lottie
              options={{ ...lottieOptions, animationData: Incorrect }}
              height={60}
              width={60}
              isStopped={false}
              isPaused={false}
            />
            <center>Incorrect...</center>
          </Fade>
        </div>
      )}
    </div>
  )
}
export default Drop
