import React from "react"
import { Route } from "react-router-dom"

import { useDispatch } from "react-redux"
import { LOGIN } from "../../store/actions/login.actions"

import { authProvider } from "../../configs/msal.config"
import { useEffect } from "react"

const ProtectedRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const dispatch = useDispatch()

  const accessTOKEN = async () => {
    try {
      const msalToken = await authProvider.getAccessToken()
      if (msalToken.accessToken.length > 0) {
        dispatch(LOGIN(msalToken.accessToken))
      }
    } catch (error) {
      // console.log(error)
    }
  }
  const login = () => {
    accessTOKEN()
  }
  useEffect(login, [])
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default ProtectedRoute
