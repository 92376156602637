/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
//document.documentElement.setAttribute("data-theme", "mydna");

const CheckPartAvailability = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="place-order"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1>Check Availability of a Part</h1>
                    <p style={{"marginTop":"0px"}}>How to lookup part availability in KPP before placing an order</p>
                </div>
                <a className="platform-link" href="https://na.parts.komatsu/Home/MainPage" target="_blank">Follow along in KPP <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                    <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Customize your dashboard" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Availability" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Stock &amp; Price Availability" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Enter part number" onClick={() =>sliderNavClicked(4)}>4</a></li>
                        <li><a href="#slide5" className={"step-title " + (activeSlide === 5 ? 'active' : '')} data-text="IC Code?" onClick={() =>sliderNavClicked(5)}>5</a></li>
                        <li><a href="#slide6" className={"step-title " + (activeSlide === 6 ? 'active' : '')} data-text="Follow the chain" onClick={() =>sliderNavClicked(6)}>6</a></li>
                        <li><a href="#slide7" className={"step-title " + (activeSlide === 7 ? 'active' : '')} data-text="Check lead time" onClick={() =>sliderNavClicked(7)}>7</a></li>
                        <li><a href="#slide8" className={"step-title " + (activeSlide === 8 ? 'active' : '')} data-text="Lead time details" onClick={() =>sliderNavClicked(8)}>8</a></li>
                        <li><a href="#slide9" className={"step-title " + (activeSlide === 9 ? 'active' : '')} data-text="part availability details" onClick={() =>sliderNavClicked(9)}>9</a></li>
                    </ol>
                </nav>

                <div className="flex-row" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1'>
                            <h3>Customize your dashboard menu</h3>
                          <hr/>
                          <p>Before we get started: Your KPP dashboard menu may be in a different order than the
                            one you see in these
                            examples. You can re-order your menu to fit your workflow at anytime by clicking your name
                            in the top right corner, then click "Re-order Menus". You can then hide/show and drag to
                            re-order.</p>
                          <img src={require('../../images/availability-slide-1.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                        <h3>Go to Availability</h3>
                        <hr/>
                        <p>Click "Availablity" in your left-hand menu.</p>
                        <img src={require('../../images/availability-slide-2.jpg')} alt="step 2"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                        <h3>Check Price &amp; Availability</h3>
                        <hr/>
                        <p>Click Stock &amp; Price Availability in the Availability sub-menu. Then type in your
                            Distributor code in the first box, or select from the dropdown list. Next, do the same for
                            your Branch code in the second box.</p>
                          <img src={require('../../images/availability-slide-3.jpg')} alt="step 3"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                        <h3>Enter the part number</h3>
                        <hr/>
                        <p>Type your part number(s) in the Part Number fields in the first column. You must
                            click the Search button at the top right to get a result.</p>
                          <img src={require('../../images/availability-slide-4.jpg')} alt="step 4"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide5' data-hash='slide5'>
                        <h3>IC Code?</h3>
                        <hr/>
                        <p>If you have an IC Code in your search results, this means the part number has
                            changed. Click Chain/Reman/New to follow the "chain" and find the new, most current part
                            number.</p>
                            <a href="/mydna/part-lookup-protips#slide5" target="_blank">View the list of IC Code definitions <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
                          <img src={require('../../images/availability-slide-5.jpg')} alt="step 5"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide6' data-hash='slide6'>
                        <h3>IC Code: follow the chain</h3>
                        <hr/>
                        <p>After you click Chain/Reman/New, you'll be shown the chain of changes for the part
                            number. Follow it down until you get to the part number that does not show a "Next Part" and
                            does not have an IC Code. That is the current part number you should use. Click the "+" to
                            the left of the new/current part number to view details such as lead time, etc.</p>
                          <img src={require('../../images/availability-slide-6.jpg')} alt="step 6"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide7' data-hash='slide7'>
                        <h3>Check the lead time</h3>
                        <hr/>
                        <ol>
                            <li>Look to see if there is KAC stock available.</li>
                            <li>Click the "+" to the left of the part number to open the details pane.</li>
                            <li>Check the Standard Lead Time. <strong>The date provide here
                                is
                                the <em>estimated</em> date your customer will receive the part if you do nothing more than place a standard
                                order.</strong> <a href="/mydna/communicate-with-customers">Click here</a> for more information about how to obtain a more accurate lead time.</li>
                            <li>There are
                                ways to expedite this if your customer needs it much sooner. Contact your assigned rep for available options to expedite the order.</li>
                        </ol>
                          <img src={require('../../images/availability-slide-7.jpg')} alt="step 7"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide8' data-hash='slide8'>
                        <h3>Dig into the lead time details</h3>
                        <hr/>
                        <p>When a part has a long lead time, there is also sometimes a Short Supply Indicator
                            link with additional information. Clicking the KAC Stock number link can give you even more
                            insight into the reason for the lead time.</p>
                        <p class="bold">Look through all of these details to determine your best course of action for your customer.</p>
                          <img src={require('../../images/availability-slide-8.jpg')} alt="step 8"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide9' data-hash='slide9'>
                        <h3>Learn more about important part availability details</h3>
                        <hr/>
                        <a href="/mydna/part-availability-details">
                            <p>Click here to advance to next section to learn the important information contained
                                within the part detail pane.</p>
                        </a>
                          <img src={require('../../images/availability-slide-9.jpg')} alt="step 9"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default CheckPartAvailability
