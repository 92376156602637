const initialState = {
  firstTime: true,
  complete_parrot_dialog: [],
  visited_pages: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "UPDATE_LOCAL_VERSION":
      return initialState
    case "LOGIN_SUCCESS":
      return { ...state, firstTime: false }
    case "SET_PARROT":
      if (
        !state.complete_parrot_dialog.includes(payload.dialog) &&
        typeof payload.dialog !== "undefined"
      ) {
        return {
          ...state,
          complete_parrot_dialog:
            payload.dialog.length > 0
              ? state.complete_parrot_dialog.concat(payload.dialog)
              : state.complete_parrot_dialog
        }
      } else {
        return state
      }
    case "MARK_PAGE":
      if (!state.visited_pages.includes(payload)) {
        return {
          ...state,
          visited_pages: state.visited_pages.concat(payload)
        }
      } else {
        return state
      }
    default:
      return state
  }
}
