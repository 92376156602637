import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App.jsx"
import * as serviceWorker from "./serviceWorker"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

Sentry.init({
  dsn:
    "https://d3aa2e4dcbf743fab1e14777a2d898bd@o478855.ingest.sentry.io/5524170",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
