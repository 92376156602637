import React from "react"
import { Row, Col, Carousel, Divider } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import { commodityHowItWorksLinks } from "../../helpers/quiz.helpers"

import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import { Link } from "react-router-dom"

const images = [
  {
    src: require("../../assets/images/REMAN-SLIDE-0.png"),
    description: (
      <div>
        <b>
          <h3 className="reman-color">1. Core Acquisition & Inspection</h3>
        </b>
        <ul>
          <li>Core is received and inspected.</li>
        </ul>
      </div>
    )
  },
  {
    src: require("../../assets/images/DSC_3930.png"),
    description: (
      <div>
        <b>
          <h3 className="reman-color">2. Tear Down & Reclamation</h3>
        </b>
        <b>Tear Down:</b>
        <ul>
          <li>Core is carefully disassembled by experienced technicians</li>
          <li>
            Component parts are thoroughly inspected for thermal distortion,
            thread damage and crack detection
          </li>
        </ul>
        <b>Parts Reclamation:</b>
        <ul>
          <li>
            Unique salvage techniques are used to reclaim parts for the
            remanufacturing process: resurfacing, sleeving, machining and
            balancing.
          </li>
        </ul>
      </div>
    )
  },
  {
    src: require("../../assets/images/REMAN-SLIDE-2.png"),
    description: (
      <div>
        <b>
          <h3 className="reman-color">3. Cleaning & Painting</h3>
        </b>
        <b>Cleaning:</b>
        <ul>
          <li>
            Non-destructive cleaning is done with pressure washing, ultrasonic,
            tumbling and baking
          </li>
          <li>
            EPA approved disposal of all paint, cleaning chemicals, wastewater
            and solids resulting from the cleaning process
          </li>
        </ul>
        <b>Painting:</b>
        <ul>
          <li>
            Application of corrosion-resistant sealers, powder coatings,
            varnishes and finish paints
          </li>
        </ul>
      </div>
    )
  },
  {
    src: require("../../assets/images/REMAN-SLIDE-3.png"),
    description: (
      <div>
        <b>
          <h3 className="reman-color">4. Reassembly</h3>
        </b>
        <ul>
          <li>
            Replacement of bearings, seals, o-rings and other selected parts by
            experienced, reputable technicians using state-of-the-art
            remanufacturing techniques.
          </li>
          <li>
            Rigid adherance to strict documented standards, ensuring consistent
            quality.
          </li>
        </ul>
      </div>
    )
  },
  {
    src: require("../../assets/images/REMAN-SLIDE-4.png"),
    description: (
      <div>
        <b>
          <h3 className="reman-color">5. Testing</h3>
        </b>
        <ul>
          <li>
            Dynamic testing is completed for performance under load, leak
            detection, bearing integrity and noise.
          </li>
          <li>
            Completed using industry-leading state-of-the-art testing equipment
            under process and quality audits.
          </li>
          <li>
            Components tested to precise reassembly standards to exacting specs,
            torques and orientations
          </li>
        </ul>
      </div>
    )
  },
  {
    src: require("../../assets/images/REMAN-SLIDE-5.png"),
    description: (
      <div>
        <b>
          <h3 className="reman-color">6. Pack & Ship</h3>
        </b>
        <ul>
          <li>
            Customer-ready Reman components are carefully packed in protective
            packaging for safe shipment.
          </li>
          <li>
            Components are marked with Product I.D.'s and serial numbers and
            packages are bar-coded for clear identification and traceability.
          </li>
        </ul>
      </div>
    )
  }
]

const RemanHowItWorks = () => {
  const setModalToInactive = () => {}
  return (
    <div className="page pb-5">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="reman-color">
          <b>Reman</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <img
              className="image-contain mr-5"
              height={350}
              width={350}
              src={require("../../assets/images/how-it-works-reman-1.png")}
              alt="gif needed"
            />
            <div>
              <p className="reman-color">
                <b>Reman =</b>
              </p>
              <p className="reman-color">
                <b>Engineering Excellence</b>
              </p>
              <p className="reman-color">
                <b>Like-new Performance</b>
              </p>
              <p className="reman-color">
                <b>Half the cost of new</b>
              </p>
              <p className="reman-color">
                <b>Reliability</b>
              </p>
            </div>
          </div>
          <div className="py-4">
            <p>
              <b>
                There are two categories of Reman: Major Components and Minor
                Components:
              </b>
            </p>
            <p>
              Major Components includes things such as the Engine, Transmission,
              Torque Flow, Torque Converter, Piston Pump and Motor, Axles, Final
              Drives, and Differentials. These Major Components represent the
              majority of the customer's machine Owning & Operating Cost (O&O).
              Also referred to as "Majors"
            </p>
            <p>
              Minor Components includes things such as the Water Pump,
              Turbocharger, Starters, Alternators, Brake Components, Travel and
              Swing Motors and more. These are small repair/normal wear-and-tear
              items and are replaced at smaller hours intervals. Also referred
              to as "Minors"
            </p>
            <p>
              At the heart of Reman is the Core Return program. The Core is the
              worn component to be returned to go through the Reman process. It
              is the Essential Raw Material of the Reman process.
            </p>
          </div>
          <Link to="/dashboard/commodities/reman/core-return">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                className="image-contain mr-5"
                height={250}
                width={525}
                src={require("../../assets/images/core-return-remanned-starter-labeled.png")}
                alt="gif needed"
              />
              <img
                className="image-contain mr-5"
                height={150}
                width={150}
                src={require("../../assets/images/how-it-works-reman-1.png")}
                alt="gif needed"
              />
              <p className="reman-color">
                <b>Learn About Core Return</b>
              </p>
            </div>
          </Link>
          <Divider />
          <h3 className="k-blue">
            <b>The Reman Process</b>
          </h3>
          <div className="d-flex flex-row align-items-center">
            <img
              className="image-contain mr-5"
              height={475}
              width={475}
              src={require("../../assets/images/Reman_Animation_System.gif")}
              alt="gif needed"
            />
            <div>
              <b>
                Reman: to completely disassemble, clean, inspect and restore to
                factory specifications.
              </b>
              <p>
                Komatsu uses salvage engineering technology to create "like-new"
                remanufactured components at a fraction of the cost of the
                equivalent new components, resulting in the reduced owning &
                operating cost (O&O) of a machine. Komatsu Reman components come
                with the best Warranty in the industry (1 year/unlimited hours)
                and the Reman Quality Assurance (4 Years/10,000 Hours
                *prorated).
              </p>
            </div>
          </div>

          <Divider />
          <div className="py-3">
            <h3 className="k-blue">
              <b>Step by Step:</b>
            </h3>
          </div>
        </Col>
      </Row>

      <center>
        <div style={{ width: "80%" }}>
          <Carousel autoplay={false}>
            {images.map((image) => {
              return (
                <div className="d-flex flex-row " key={image.description}>
                  <div style={{ width: "40%" }}>
                    <img
                      style={{ objectFit: "contain", marginRight: 20 }}
                      src={image.src}
                      width="100%"
                      alt=""
                    />
                  </div>
                  <div className="p-4 " style={{ textAlign: "left" }}>
                    <p>{image.description}</p>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </center>

      <TestYourKnowledge link={commodityHowItWorksLinks.reman} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default RemanHowItWorks
