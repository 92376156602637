import React, { useState, useEffect } from "react"
import Ripple from "../../assets/lotties/ripple"
import Lottie from "react-lottie"
import { Popover } from "antd"
import Fade from "react-reveal/Fade"
import { Modal } from "antd"
import { useParams } from "react-router-dom"
import { machineLandingLinks } from "../../helpers/quiz.helpers"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: Ripple,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}

const PopupLearning = ({ machine }) => {
  let { machine_ez_id } = useParams()

  const [popups, setPopups] = useState({})
  const recordProgress = (popup) => {
    setPopups({
      ...popups,
      [popup.title]: popup
    })
  }
  const onPopupChange = () => {
    let popupsLength = Object.keys(popups).length
    if (popupsLength === machine.popups.length && machine.popups.length > 0) {
      setTimeout(() => {
        Modal.confirm({
          content:
            "You have hovered over all the popups, ready to test your knowledge?",
          onOk: () => {
            window.location.href =
              machineLandingLinks[machine_ez_id.toLowerCase()]
          },
          okText: "Yes, let's do it.",
          onCancel: () => {},
          cancelText: "Not yet",
          icon: null
        })
      }, 2000)
    }
  }
  useEffect(onPopupChange, [Object.keys(popups).length])
  return (
    <div style={{ overflow: "show" }}>
      {machine.popups.map((popup) => (
        <div key={popup.x + popup.y} onMouseOver={() => recordProgress(popup)}>
          <Popover
            placement={popup.position}
            title={<b>{popup.title}</b>}
            content={
              <div style={{ width: 200 }}>
                <p>{popup.text}</p>
              </div>
            }>
            <div
              style={{
                position: "absolute",
                left: `${popup.x}%`,
                bottom: `${popup.y}%`
              }}>
              <Fade bottom>
                <div
                  className="shadow"
                  style={{
                    backgroundColor: "white",
                    borderRadius: 25
                  }}>
                  <Lottie
                    options={lottieOptions}
                    height={50}
                    width={50}
                    isStopped={false}
                    isPaused={false}
                  />
                </div>
              </Fade>
            </div>
          </Popover>
        </div>
      ))}
    </div>
  )
}

export default PopupLearning
