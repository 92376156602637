import { call, put, select } from "redux-saga/effects"
import axios from "axios"
// import { headers } from '../../data/misc.data'
// import { message } from 'antd'
import {
  GET_COMMODITY_FAIL,
  GET_COMMODITY_SUCCESS
} from "../actions/commodity.actions"
import { APIResponseParser } from "../../helpers/APIResponseParser.helpers"
import { addHeaders } from "../../helpers/Saga.helpers"
import { baseUrl } from "../../data/misc.data"
import { tokenSelector } from "../selectors/selectors"
const CommodityGETAPI = async (payload, token) => {
  const result = await axios.get(`${baseUrl}/v1/commodity/${payload.ez_id}`, {
    headers: addHeaders(token)
  })
  return APIResponseParser(result)
}

function* CommodityGETSaga(action) {
  try {
    const token = yield select(tokenSelector)
    const data = yield call(CommodityGETAPI, action.payload, token)
    yield put(GET_COMMODITY_SUCCESS(data))
  } catch (e) {
    yield put(GET_COMMODITY_FAIL(e))
  }
}
export default CommodityGETSaga
