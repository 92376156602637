import { call, put, select } from "redux-saga/effects"
import axios from "axios"
// import { headers } from '../../data/misc.data'
// import { message } from 'antd'
import {
  GET_CONTENT_FAIL,
  GET_CONTENT_SUCCESS
} from "../actions/content.actions"
import { APIResponseParser } from "../../helpers/APIResponseParser.helpers"
import { addHeaders } from "../../helpers/Saga.helpers"
import { baseUrl } from "../../data/misc.data"
import { tokenSelector } from "../selectors/selectors"
const ContentAPI = async (payload, token) => {
  const result = await axios.get(
    `${baseUrl}/v1/content/${payload.ez_id}${payload.suffix}`,
    {
      headers: addHeaders(token)
    }
  )
  return APIResponseParser(result)
}

function* ContentAPISaga(action) {
  try {
    const token = yield select(tokenSelector)

    const data = yield call(ContentAPI, action.payload, token)
    yield put(GET_CONTENT_SUCCESS(data))
  } catch (e) {
    yield put(GET_CONTENT_FAIL(e))
  }
}
export default ContentAPISaga
