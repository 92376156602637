/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

const Communicate = () => {
  return (
    <>
      <div className="parent-page page" data-theme="communicate"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1 style={{
                        color:'var(--communicate) !important'
                    }}>Communication</h1>
                    <p style={{"marginTop":"0px"}}>How to communicate effectively with Ripley and your customers</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
            <div className="flex-row">
                <a className="portal portal-50 communicate-portal" href="communicate-who-what">
                    <p>Who/What/How</p>
                    <p className="note">Who to contact for what, and how to do it</p>
                </a>
                <a href="communicate-with-ripley" className="portal portal-50 communicate-portal">
                    <p>Communicating with Ripley</p>
                    <p className="note">How to communicate with Ripley in KPP</p>
                </a>
                <a href="communicate-with-customers" className="portal portal-50 communicate-portal">
                    <p>Communicating with Customers</p>
                    <p className="note">How to talk to your customer about shipping, identifying the correct parts and more</p>
                </a>
            </div>
        </main>
      </div>
    </>
  )
}

export default Communicate
