import React, { useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { Row, Col, Modal, message } from "antd"

import { useSelector, useDispatch } from "react-redux"
import { quizSelector } from "../../../store/selectors/selectors"
import { useHistory } from "react-router-dom"
import DDrop from "../../dnd/drop-2.dnd"
import Drop from "../../dnd/drop.dnd"
import { reorder } from "../../../helpers/Utils.helpers"
import SuccessModal from "../other/success.modal"
import { useURL } from "../../hooks/url.hooks"
import {
  IS_QUESTION_CORRECT,
  SUBMIT_QUIZ
} from "../../../store/actions/quiz.actions"

const WordMatchQuizNext = ({ ez_id, index, totalQuestions, imge }) => {
  const history = useHistory()
  const quiz = useSelector(quizSelector(ez_id))
  const q = useSelector((state) => state.Quiz)
  const url = useURL()
  const quiz_active = url.query.get("quiz_active")
  const quiz_prompt = url.query.get("quiz_prompt")
  const dispatch = useDispatch()

  const quizId = url.query.get("qid")

  const initialstate = {
    provided_answers: quiz.provided_answers,
    proposed_answers: []
  }

  const nextQuestion = () => {
    if (quiz_active !== null && index + 1 !== totalQuestions) {
      history.push(
        `${window.location.pathname}?quiz_prompt=${quiz_prompt}&sub_quiz=${
          q.sub_quizes[index + 1].ez_id
        }&quiz_active=true`
      )
    } else if (quiz_active !== null && index + 1 === totalQuestions) {
      history.push(`${window.location.pathname}?quiz_active=false`)
    } else {
      history.push(
        index + 1 !== totalQuestions
          ? `/dashboard/quiz/${q.sub_quizes[index + 1].ez_id}?qid=${quizId}`
          : "/dashboard/machines"
      )
    }
  }

  const [state, setState] = useState(initialstate)

  const moveItem = (source, destination) => {
    if (source.droppableId !== destination.droppableId) {
      setState({
        ...state,
        [source.droppableId]: state[source.droppableId].filter(
          (item, index) => index !== source.index
        ),
        [destination.droppableId]: state[destination.droppableId].concat(
          state[source.droppableId][source.index]
        )
      })
    } else {
      // REORDERS CURRENT LIST
      setState({
        ...state,
        [source.droppableId]: reorder(
          state[source.droppableId],
          source.index,
          destination.index
        )
      })
    }
    checkAnswer(source, destination)
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    moveItem(result.source, result.destination)
  }

  const checkAnswer = (source) => {
    const item = state[source.droppableId][source.index]

    if (quiz.correct_answers.includes(item.keyIndex)) {
      message.success("Correct!")

      if (state.proposed_answers.length === quiz.correct_answers.length - 1) {
        dispatch(IS_QUESTION_CORRECT(true))

        dispatch(SUBMIT_QUIZ(quiz))
        if (index + 1 === totalQuestions) {
          dispatch(SUBMIT_QUIZ(q))
        }
        Modal.success({
          className: "quiz-contain",
          content: (
            <SuccessModal
              lastQuestion={index + 1 === totalQuestions}
              onOk={nextQuestion}
            />
          ),
          icon: null
        })
      }
    } else {
      message.error("Incorrect, please try again")
      setState({
        ...state,
        provided_answers: state.provided_answers.concat(
          state.proposed_answers.filter((i) => i.keyIndex === item.keyIndex)
        )
      })
    }
  }

  return (
    <div>
      {index + 1}/{totalQuestions}
      <div className="py-3">
        <h5>
          Quiz:{" "}
          <strong>
            {quiz.question_prompt ||
              "Drag all the correct questions you should ask your customer about into the speech bubble."}
          </strong>
        </h5>
      </div>
      <div className="my-4">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Row gutter={32} justify="space-around" align="middle">
            <Col span={8}>
              <Drop
                itemStyle={{ fontSize: 16 }}
                items={state.provided_answers.sort(() => Math.random() - 0.5)}
                droppableId="provided_answers"
              />
            </Col>
            <Col span={8}>
              <img style={{ position: "absolute" }} src={imge} alt="" />
              <DDrop
                itemStyle={{ fontSize: 12 }}
                style={{ height: 500 }}
                // isCorrect={isCorrect}
                items={state.proposed_answers}
                droppableId="proposed_answers"
              />
            </Col>
          </Row>
        </DragDropContext>
      </div>
    </div>
  )
}

export default WordMatchQuizNext
