/* eslint-disable */
import React from "react"
// import { AnimatedRoute } from 'react-router-transition'
import { Route } from "react-router-dom"

// import { mapSubPageStyles, maintheme } from '../styles/maintheme.styles'
import dashboardRoutesData from "../data/dashboard.routes.data"
// import { Modal } from "antd"
// import { useSelector, useDispatch } from "react-redux"
// import PartsParrot from "../components/parrot/parts.parrot"

const Dashboard = () => {
  const RenderRoutes = ({ component: Component, ...route }) => (
    <Route
      render={(props) => <Component {...route.extraProps} {...props} />}
      exact={route.exact}
      path={route.path}
    />
  )
  return (
    <>
      {/* <PartsParrot /> */}
      {dashboardRoutesData.map(RenderRoutes)}
    </>
  )
}

export default Dashboard
