import React, { useState, useEffect } from "react"

import { Row, Col } from "antd"

import { useCommodityScreen } from "../hooks/screen.hooks"
import { Spin, Popover } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import CommodityBox from "../commodities/commodity-box.landing"

import { useParams } from "react-router-dom"
import { FrostedGlass } from "../common/frosted/glass.frosted"
import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import { commodityLandingQuizLinks } from "../../helpers/quiz.helpers"
import { useParrot } from "../hooks/parrot.hooks"

const CommoditiesLearning = () => {
  const [contentData, setContentData] = useState({ header: "", content: "" })
  const [currentOpened, setOpened] = useState(0)
  let { commodity_ez_id } = useParams()
  const commodityState = useCommodityScreen(commodity_ez_id)
  const { commodity } = commodityState
  const { setParrot } = useParrot()

  const updateRightContent = (content) => {
    setContentData(content)
    setOpened(content.index)
  }
  const setModalToInactive = () => {}

  const [state, setState] = useState({ size: 0 })
  const resize = () => {
    setState({ size: window.innerWidth })
  }

  const onMount = () => {
    setParrot({
      dialog:
        "Welcome to your first main Commodity page! Hover or click on each of the boxes on the shelf to learn all about this commodity. Every box is full of useful information that will help you really excel at the Parts Counter. We've assigned each commodity its own color to help you know where you are at all times.",
      display: "TALKING-ALT",
      mode: 1
    })
    window.addEventListener("resize", resize)
    resize()
    return () => {
      window.removeEventListener("resize", resize)
    }
  }

  const setBirdDialog = () => {
    setParrot({
      dialog:
        "FYI: The quiz head at the bottom right of this page will test your knowledge on what's inside the What it Does, Warranty and Questions to Ask boxes. Feel like knocking out a quiz? Go ahead and learn what's in each of those boxes then click the quiz head when you're ready!",
      display: "TALKING-ALT",
      mode: 1
    })
  }

  useEffect(onMount, [])

  useEffect(() => {
    setContentData({ header: "", content: "" })
    setOpened(0)
    // return () => {
    //   dispatch({ type: "RESET_COMMODITY_SCREEN" })
    // }
  }, [commodity_ez_id])

  return (
    <div className="page commodity-bg">
      <Spin
        spinning={commodityState.loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
        <center>
          <h1 className={`${commodity_ez_id.toLowerCase()}-color capitalize-title`}>
            <b>{commodity.name}</b>
          </h1>
        </center>

        {contentData.content.length > 0 && (
          <div
            className="display-linebreak"
            style={{
              width: state.size > 1200 ? "30%" : "90%",
              position: "absolute",
              left: state.size > 1200 ? "50%" : "5%"
            }}>
            <FrostedGlass
              className={`${contentData.header
                .split(" ")
                .join("-")
                .toLowerCase()}-border`}
              shade="light"
              containerStyle={{ backgroundColor: "white" }}>
              <h4>
                <div
                  className={`${contentData.header
                    .split(" ")
                    .join("-")
                    .toLowerCase()}-color`}>
                  <b>{contentData.header}</b>
                </div>
              </h4>

              <div
                className="caption"
                dangerouslySetInnerHTML={{ __html: contentData.content }}
              />
            </FrostedGlass>
          </div>
        )}

        <Row
          align="top"
          justify="center"
          style={{ marginTop: !(state.size > 1200) && 200 }}>
          <Col
            style={{ height: "100vh" }}
            className="link d-flex flex-column justify-content-center align-items-center position-relative"
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}>
            <div
              className="commodity-learning-shelf"
              style={{
                backgroundImage: `url(${require("../../assets/images/Current/Shelves_nolabels.png")})`
              }}>
              <div onMouseOver={setBirdDialog}>
                <CommodityBox
                  labelColor="#00a7e1"
                  currentOpened={currentOpened}
                  onClick={updateRightContent}
                  header="Definition"
                  content={commodity.definition}
                  index={1}
                  label="What it does"
                  top={-20}
                  left={60}
                  clickImage={require("../../assets/images/Topic_Icons2_What_Box.png")}
                />
              </div>
              <Link
                // className='commodity-shelf-item'
                style={{ position: "absolute" }}
                to={`/dashboard/commodities/offering/${commodity.ez_id}`}>
                <CommodityBox
                  labelColor="#78be43"
                  currentOpened={currentOpened}
                  onClick={updateRightContent}
                  label="Product Offering"
                  header="Product Offering"
                  content={commodity.product_offering}
                  isLink
                  index={2}
                  top={-20}
                  left={200}
                  clickImage={require("../../assets/images/Topic_Icons2_TypesBox.png")}
                />
              </Link>
              <Link to={`/dashboard/commodities/technical/${commodity.ez_id}`}>
                <CommodityBox
                  labelColor="#f37021"
                  currentOpened={currentOpened}
                  label="Technical Info"
                  isLink
                  top={-20}
                  left={340}
                  clickImage={require("../../assets/images/Topic_Icons2_TechnicalBox.png")}
                />
              </Link>
              <Link to={`/dashboard/commodities/value/${commodity.ez_id}`}>
                <CommodityBox
                  labelColor="#ffd65a"
                  currentOpened={currentOpened}
                  label="Value"
                  isLink
                  top={145}
                  left={60}
                  clickImage={require("../../assets/images/Topic_Icons2_VAlueBox.png")}
                />
              </Link>
              <Link to={`/dashboard/commodities/resources/${commodity.ez_id}`}>
                <CommodityBox
                  labelColor="#853694"
                  currentOpened={currentOpened}
                  label="Resources"
                  isLink
                  top={145}
                  left={200}
                  clickImage={require("../../assets/images/Topic_Icons2_ResourcesBox.png")}
                />
              </Link>
              <Link to={`/dashboard/commodities/support/${commodity.ez_id}`}>
                <CommodityBox
                  labelColor="#d81f27"
                  currentOpened={currentOpened}
                  label="Product Support"
                  isLink
                  top={145}
                  left={340}
                  clickImage={require("../../assets/images/Topic_Icons2_PeopleBox.png")}
                />
              </Link>
              <CommodityBox
                labelColor="#140a9a"
                currentOpened={currentOpened}
                onClick={updateRightContent}
                label="Warranty"
                header="Warranty"
                content={commodity.warranty}
                index={3}
                top={320}
                left={125}
                clickImage={require("../../assets/images/Topic_Icons2_WarrantyBox.png")}
              />
              <CommodityBox
                labelColor="#017960"
                currentOpened={currentOpened}
                onClick={updateRightContent}
                label="Questions to ask"
                header="Questions to ask"
                content={commodity.questions_to_ask}
                index={4}
                top={320}
                left={275}
                clickImage={require("../../assets/images/Topic_Icons2_QuestionsBox.png")}
              />
            </div>
          </Col>
          <Col
            className="link d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100vh" }}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}>
            {state.size > 1200 && (
              <img src={commodity.thumbnail} style={{ width: "75%" }} alt="" />
            )}

            {typeof commodity.aka !== "undefined" &&
              typeof commodity.aka !== "string" && (
                <center>
                  {Array.isArray(commodity.aka) && (
                    <Popover
                      className="pointer-cursor"
                      placement="topRight"
                      content={
                        <div style={{ fontSize: 10, width: 300 }}>
                          <h5>{commodity.aka.join(", ")}</h5>
                        </div>
                      }
                      title={
                        <h3>
                          {`${commodity_ez_id} are also sometimes
                      called`}
                        </h3>
                      }>
                      <img
                        height={20}
                        width={20}
                        src={require("../../assets/images/icons/location-icon.png")}
                        alt=""
                      />
                      <b className="k-blue">Also Known As</b>
                      <br />
                      <br />
                      <br />
                    </Popover>
                  )}
                  {!Array.isArray(commodity.aka) &&
                    typeof commodity === "object" && (
                      <Popover
                        className="pointer-cursor"
                        overlayStyle={{ width: 400 }}
                        placement="topRight"
                        content={Object.entries(commodity.aka).map((item) => (
                          <div
                            className="d-flex flex-row justify-content-start mt-2"
                            key={item}>
                            <div
                              className="reman-color text-right"
                              style={{ width: "50%" }}>
                              <b> {item[0]} </b>
                            </div>
                            <div
                              style={{
                                width: "50%",
                                fontSize: 14,
                                marginLeft: 10
                              }}>
                              {item[1]}
                            </div>
                          </div>
                        ))}>
                        <div className="mt-5 d-flex align-items-baseline">
                          <img
                            height={20}
                            width={20}
                            src={require("../../assets/images/icons/location-icon.png")}
                            alt=""
                          />
                          <div>
                            <h4>
                              <b className="k-blue ml-2">Also Known As</b>
                            </h4>
                          </div>
                        </div>
                      </Popover>
                    )}
                </center>
              )}
          </Col>
        </Row>
      </Spin>
      <TestYourKnowledge
        link={commodityLandingQuizLinks[commodity_ez_id.toLowerCase()]}
      />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default CommoditiesLearning
