/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
//document.documentElement.setAttribute("data-theme", "mydna");

const PlaceAndOrderProcess = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="place-order"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1>How to Place an Order</h1>
                    <p style={{"marginTop":"0px"}}>How to enter a new parts order in KPP</p>
                </div>
                <a className="platform-link" href="https://na.parts.komatsu/Home/MainPage" target="_blank">Follow along in KPP <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                    <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Go to Sales Order in KPP" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Fill in the required fields" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Select Order Type" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Transportation method" onClick={() =>sliderNavClicked(4)}>4</a></li>
                        <li><a href="#slide5" className={"step-title " + (activeSlide === 5 ? 'active' : '')} data-text="Freight Account Number" onClick={() =>sliderNavClicked(5)}>5</a></li>
                        <li><a href="#slide6" className={"step-title " + (activeSlide === 6 ? 'active' : '')} data-text="Unit Down Orders" onClick={() =>sliderNavClicked(6)}>6</a></li>
                        <li><a href="#slide7" className={"step-title " + (activeSlide === 7 ? 'active' : '')} data-text="Shipping Information" onClick={() =>sliderNavClicked(7)}>7</a></li>
                        <li><a href="#slide8" className={"step-title " + (activeSlide === 8 ? 'active' : '')} data-text="Submit initial order" onClick={() =>sliderNavClicked(8)}>8</a></li>
                        <li><a href="#slide9" className={"step-title " + (activeSlide === 9 ? 'active' : '')} data-text="Enter part numbers" onClick={() =>sliderNavClicked(9)}>9</a></li>
                        <li><a href="#slide10" className={"step-title " + (activeSlide === 10 ? 'active' : '')} data-text="Verify order data" onClick={() =>sliderNavClicked(10)}>10</a></li>
                        <li><a href="#slide11" className={"step-title " + (activeSlide === 11 ? 'active' : '')} data-text="Submit your order" onClick={() =>sliderNavClicked(11)}>11</a></li>
                        <li><a href="#slide12" className={"step-title " + (activeSlide === 12 ? 'active' : '')} data-text="Submitted Order Details" onClick={() =>sliderNavClicked(12)}>12</a></li>
                    </ol>
                </nav>

                <div className="flex-row" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1'>
                        <h3>Step 1: Click Sales Order from your KPP dashboard</h3>
                        <hr/>
                        <p>Click Sales Order in the menu on the left. Then click Sales Order Creation in the
                            submenu provided.
                        </p>
                          <img src={require('../../images/sales-order-slide-1.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                        <h3>Step 2: Fill in all required fields</h3>
                        <hr/>
                        <p>Enter your Dealer and Branch Codes. You must include a Distributor PO Number. Also
                            enter your Customer's PO number if you have one.</p>
                        <img src={require('../../images/sales-order-slide-2.jpg')} alt="step 2"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                        <h3>Step 3: Select Order Type</h3>
                        <hr/>
                        <p style={{margin: "0"}}>The Order Type you select determines the priority your order will have at Ripley:</p>
                        <ol style={{color:"black"}}>
                            <li><strong>Firm Future Order:</strong> highest priority; ships fastest. This order type is
                                often used
                                for
                                rebuilds on customer machines and ships in a minimum of 90 days.</li>

                            <li><strong>Unit Down:</strong> These orders take priority over and are filled and shipped
                                before Emergency
                                and Stock
                                Orders.</li>
                            <li><strong>Emergency Order:</strong> Third in priorty for fulfillment by Ripley.</li>
                            <li><strong>Stock Order:</strong> This is the lowest priority, will be fulfilled after all
                                other orders and
                                ships
                                free. These are orders for your warehouse.</li>
                            <li><strong>Inspection Order:</strong> Use this order type if
                                you know you have the right part number, but it is not the right part. This lets Ripley
                                know
                                they need to check their bin and make sure their inventory is correct.</li>
                        </ol>
                          <img src={require('../../images/sales-order-slide-3.jpg')} alt="step 3"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                        <h3>Step 4: Transportation Method</h3>
                        <hr/>
                        <p>Transportation is autofilled for Stock Orders. You can change the transportation
                            method for emergency orders, then you will need to choose the correct carrier code.</p>
                        <p><strong>ProTip:</strong> There is a large FedEx hub near Ripley. If it's late in the day and
                            you need it
                            overnighted, use FedEx.</p>
                          <img src={require('../../images/sales-order-slide-4.jpg')} alt="step 4"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide5' data-hash='slide5'>
                        <h3>Step 5: Freight Account Number</h3>
                        <hr/>
                        <p>You can enter your dealership's freight account number here. The system will not
                            accept customer account numbers.</p>
                          <img src={require('../../images/sales-order-slide-5.jpg')} alt="step 5"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide6' data-hash='slide6'>
                        <h3>Step 6: Unit Down Orders</h3>
                        <hr/>
                        <p>Selecting Unit Down order type will move the order up in the priority chain and
                            expedite receiving parts when your customer's
                            machine is down and they need it back up and running as quickly as possible. Selecting Unit
                            Down will require that
                            you complete additional details on the order form.</p>
                          <img src={require('../../images/sales-order-slide-6.jpg')} alt="step 6"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide7' data-hash='slide7'>
                        <h3>Step 7: Shipping Information</h3>
                        <hr/>
                        <p>Enter shipping details if needed for emergency or unit down orders.</p>
                          <img src={require('../../images/sales-order-slide-7.jpg')} alt="step 7"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide8' data-hash='slide8'>
                        <h3>Step 8: Submit initial order information</h3>
                        <hr/>
                        <p>Double check all of your information. Click submit on the bottom right, then click
                            "OK" on the Header
                            submitted succesfully dialogue box to continue on to enter
                            your part numbers.</p>
                          <img src={require('../../images/sales-order-slide-8.jpg')} alt="step 8"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide9' data-hash='slide9'>
                        <h3>Step 9: Enter part numbers</h3>
                        <hr/>
                        <p>Enter your part numbers on the next screen. Verify the order type and your
                            dealership information, and triple check all part numbers and quantities.</p>
                          <img src={require('../../images/sales-order-slide-9.jpg')} alt="step 9"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide10' data-hash='slide10'>
                      <h3>Step 10: Verify order data</h3>
                        <hr/>
                        <p>Click the Preview button on the bottom right to verify your order data. Click OK on
                            the dialogue box.</p>
                        <p><strong>NOTE:</strong> Clicking Preview does not make it a live order (seeing a
                            sales order number on this screen does not mean you have a live order). You must click
                            Submit to finalize the order and make it live with Ripley.</p>
                          <img src={require('../../images/sales-order-slide-10.jpg')} alt="step 10"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide11' data-hash='slide11'>
                      <h3>Step 11: Submit your order</h3>
                        <hr/>
                        <p>Click the Submit button on the bottom left to submit your order and make it live with Ripley.</p>
                          <img src={require('../../images/sales-order-slide-11.jpg')} alt="step 11"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide12' data-hash='slide12'>
                      <h3>Step 12: Check submitted order details</h3>
                        <hr/>
                        <p>Check your Allocated and Back Ordered parts on the resulting Sales Order Detail
                            Screen. Allocated Qty are the parts in Ripley's inventory that are assigned to your order.
                            If you see numbers in the Back Ordered columns, your parts are on back order and you can
                            click on the number links to view the details. If your customer needs the part(s) more
                            quickly, you can call your assigned rep to explore available options.</p>
                          <img src={require('../../images/sales-order-slide-12.jpg')} alt="step 12"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default PlaceAndOrderProcess
