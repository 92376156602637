import React from "react"
import { Layout, Row, Col } from "antd"
import '../styles/mydna.components.css';
const { Footer } = Layout
const FooterNav = () => {
  return (
    <Footer className="mydna">
        <Row justify="space-around">
          <Col>
            <div>
            <h4>Learn Commodities on Parts U</h4>
              <ul className="footer-menu">
                  <li><a href="/dashboard/machines" target="_blank">Parts U Home</a></li>
                  <li><a href="/dashboard/commodities/learn/batteries"
                          target="_blank">Batteries</a></li>
                  <li><a href="/dashboard/commodities/learn/filters" target="_blank">Filters</a>
                  </li>
                  <li><a href="/dashboard/commodities/learn/hydraulics"
                          target="_blank">Hydraulics</a></li>
                  <li><a href="/dashboard/commodities/learn/lubricants"
                          target="_blank">Lubricants</a></li>
                  <li><a href="/dashboard/commodities/learn/reman" target="_blank">Reman</a></li>
                  <li><a href="/dashboard/commodities/learn/undercarriage"
                          target="_blank">Undercarriage</a></li>
                  <li><a href="/dashboard/commodities/learn/sr-drive-system" target="_blank">SR
                          Drive System</a></li>
              </ul>
              </div>
            </Col>
            <Col>
            <div>
              <h4>Quick Links</h4>
              <ul className="footer-menu">
                  <li><a href="https://na.parts.komatsu/Home/MainPage" target="_blank" rel="noopener noreferrer">KPP</a></li>
                  <li><a href="https://sbom.komatsuamerica.com/InService/delivery/" target="_blank" rel="noopener noreferrer">S-BOM</a></li>
                  <li><a href="https://mykomatsu.komatsu/" target="_blank" rel="noopener noreferrer">My Komatsu</a></li>
                  <li><a href="https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/Home.aspx" target="_blank" rel="noopener noreferrer">Parts Marketing Home</a></li>
                  <li><a href="https://portal.bizapp.komatsu/" target="_blank" rel="noopener noreferrer">Komatsu Global Portal</a></li>
              </ul>
              </div>
            </Col>  
            <Col>
            <div>
              <h4>Product Support Contacts</h4>
              <ul className="footer-menu">
                  <li><a href="/dashboard/commodities/support/batteries"
                          target="_blank">Batteries</a></li>
                  <li><a href="/dashboard/commodities/support/filters"
                          target="_blank">Filters</a></li>
                  <li><a href="/dashboard/commodities/support/hydraulics"
                          target="_blank">Hydraulics</a></li>
                  <li><a href="/dashboard/commodities/support/lubricants"
                          target="_blank">Lubricants</a></li>
                  <li><a href="/dashboard/commodities/support/reman" target="_blank">Reman</a>
                  </li>
                  <li><a href="/dashboard/commodities/support/undercarriage"
                          target="_blank">Undercarriage</a></li>
                  <li><a href="/dashboard/commodities/support/sr-drive-system" target="_blank">SR
                          Drive System</a></li>
                  <li><a href="/dashboard/commodities/support/forestry"
                          target="_blank">Forestry</a></li>
              </ul>
              </div>
            </Col>    
        </Row>
    </Footer>
  )
}

export default FooterNav
