export const LOGIN_SUCCESS = (data) => ({
    type: 'LOGIN_SUCCESS',
    payload: data
})
export const LOGIN_FAIL = (data) => ({
    type: 'LOGIN_FAIL',
    payload: data
})
export const LOGIN = (data) => ({
    type: 'LOGIN',
    payload: data
})
export const LOGOUT = () => ({
    type: 'LOGOUT'
})