import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  GET_MACHINE,
  RESET_MACHINE_SCREEN
} from "../../store/actions/machine.actions"
import {
  GET_COMMODITY,
  RESET_COMMODITY_SCREEN
} from "../../store/actions/commodity.actions"

export const useMachineScreen = (machine_ez_id, init = true) => {
  const machine = useSelector((state) => state.MachineLearningScreen)
  const loading = useSelector((state) => state.Ui.loading.MachineLearning)
  const dispatch = useDispatch()

  const getMachine = () => {
    dispatch(
      GET_MACHINE({
        ez_id: machine_ez_id
      })
    )
  }

  const mount = () => {
    if (init) {
      getMachine()
    }
    return () => {
      dispatch(RESET_MACHINE_SCREEN())
    }
  }

  useEffect(mount, [machine_ez_id])

  return {
    machine,
    getMachine,
    loading
  }
}

export const useCommodityScreen = (commodity_ez_id) => {
  const commodity = useSelector((state) => state.CommodityLearningScreen)
  const loading = useSelector((state) => state.Ui.loading.CommodityLearning)
  const dispatch = useDispatch()

  const getCommodity = () => {
    dispatch(
      GET_COMMODITY({
        ez_id: commodity_ez_id
      })
    )
  }

  const mount = () => {
    getCommodity()
    return () => {
      dispatch(RESET_COMMODITY_SCREEN())
    }
  }

  useEffect(mount, [commodity_ez_id])

  return {
    commodity,
    getCommodity,
    loading
  }
}
