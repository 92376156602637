import React from "react"
import { Progress } from "antd"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { GET_PROGRESS } from "../../../store/actions/quiz.actions"

const UserProgress = () => {
  const dispatch = useDispatch()
  const progress = useSelector((state) => state.Ui.progress)
  const user = useSelector((state) => state.User.accessToken)
  const getProgress = () => {
    dispatch(GET_PROGRESS())
  }
  useEffect(getProgress, [user])
  return (
    <div className="float-right" style={{ width: "20%" }} id="tut-6">
      <Progress
        trailColor="white"
        strokeColor={{
          "0%": "#FFD65A",
          "100%": "#FFD65A"
        }}
        // status='active'
        percent={Math.round(progress)}
      />
    </div>
  )
}
export default UserProgress
