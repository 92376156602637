export const APIResponseParser = (response) => {
  try {
    if (response.data.success === true) {
      return response.data.data
    } else {
      console.log("api response parser error: ", response.data.data);
      throw response.data.data
    }
  } catch (error) {
    console.log("api response parser error: ", error);
    throw error;
  }
}
export const ErrorHandler = (error) => {
  if (error === "404") {
    window.history.replaceState(null, null, "/404")
    window.location.href = "/404"
  }
}
