const initialState = {
	loading: false,
	_id: '',
	thumbnail: '',
	name: '',
	ez_id: '',
	commodity: '',
	content: [],
	secondary_content: [],
	uc: [],
	third_content: [],
	fourth_content: [],
	fifth_content: []
}
export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'GET_CONTENT':
			return {
				...state,
				loading: true
			}
		case 'RESET_CONTENT_SCREEN':
			return initialState
		case 'GET_CONTENT_SUCCESS':
			return { ...payload, loading: false }
		default:
			return state
	}
}
