import React from "react"
import { Row, Col, Divider } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import { commodityHowItWorksLinks } from "../../helpers/quiz.helpers"

import QuizPrompt from "../quizes/prompts/quizprompt.quiz"

const HydraulicsHowItWorks = () => {
  const setModalToInactive = () => {}
  return (
    <div className="page">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="hydraulics-color">
          <b>Hydraulics</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="py-5">
            <center>
              {/* <strong>
            <h1 className={`${commodity_ez_id.toLowerCase()}-color`}>
              <b>{commodity_ez_id.toUpperCase()}</b>
            </h1>
          </strong> */}
              <div
                style={{ fontSize: 26, fontWeight: 600, fontFamily: "Roboto" }}>
                <b>How it Works</b>
              </div>
            </center>
          </div>
          <p style={{ fontSize: 26 }}>
            <b>Power and Motion</b>
          </p>
          <p>
            The heart of the Hydraulic System is the hydraulic pump. Hydraulics
            provide efficient power generation (reusable energy).
          </p>
          <p>Hydraulics = Fluid Power</p>
          <Divider />
          <div className="d-flex flex-lg-row flex-sm-column mb-4">
            <div>
              <p style={{ fontSize: 26 }}>
                <b>Basic Hydraulic Circuit</b>
              </p>
              <p>
                Heavy equipment utilizes a hydraulic system for power and motion
                control.
              </p>
              <p>
                A pump is used to create the flow of hydraulic fluid in a
                continuous loop. Movement of fluid in a confined space creates
                pressure.
              </p>
              <p>
                Pressure creates a multiplication of force which results in
                powerful and efficient energy transfer.
              </p>
            </div>
            <div>
              <img
                className="image-contain mb-3"
                height={392}
                width={411}
                src={require("../../assets/images/Hydraulics_SWF-Medium.gif")}
                alt="gif needed"
              />
            </div>
          </div>
          <Divider />
          <p style={{ fontSize: 26 }}>
            <b>Multiplication of Force</b>
          </p>
          <div className="d-flex align-items-center flex-lg-row flex-sm-column mb-4">
            <img
              className="image-contain mb-3"
              height={392}
              width={411}
              src={require("../../assets/images/how-it-works-hydraulics-2.gif")}
              alt=""
            />

            <div className="p-4">
              <p>
                Hydraulics allow for multiplication of force, which provides the
                extreme strength and power required for Komatsu equipment to
                operate and dig/lift/push dirt and materials.
              </p>
            </div>
          </div>
          <Divider />
          <p style={{ fontSize: 26 }}>
            <b>A Hydraulic System is a lot like the cardiovascular system</b>
          </p>
          <center>
            <img
              className="image-contain mb-3"
              height={400}
              width={272}
              src={require("../../assets/images/Hydraulics_Body-Cropped.gif")}
              alt=""
            />
          </center>
          <div className="d-flex flex-row justify-content-around">
            <img
              className="image-contain mb-3"
              height={350}
              width={238}
              src={require("../../assets/images/Hydraulics-Motors-Comparison@3x-100.png")}
              alt=""
            />
            <img
              className="image-contain mb-3"
              height={350}
              width={238}
              src={require("../../assets/images/Hydraulics-Cylinders-Comparison@3x-100.png")}
              alt=""
            />
            <img
              className="image-contain mb-3"
              height={350}
              width={238}
              src={require("../../assets/images/Hydraulics-Valves-Comparison@3x-100.png")}
              alt=""
            />
            <img
              className="image-contain mb-3"
              height={350}
              width={238}
              src={require("../../assets/images/Hydraulics-Oil-Comparison@3x-100.png")}
              alt=""
            />
          </div>
          <Divider />
          <p style={{ fontSize: 26 }}>
            <b>Enemies of the Hydraulic System</b>
          </p>
          <br />
          <br />
          <div className="d-flex justify-content-around">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                className="image-contain mb-3"
                height={80}
                width={80}
                src={require("../../assets/images/fire-icon@3x.png")}
                alt=""
              />

              <b>Heat</b>
              <i>
                <p>High-pressure systems generate a lot of heat.</p>
              </i>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                className="image-contain mb-3"
                height={80}
                width={80}
                src={require("../../assets/images/air-icon@3x.png")}
                alt=""
              />

              <b>Air</b>
              <i>
                <p>Aeration and cavitation</p>
              </i>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                className="image-contain mb-3"
                height={80}
                width={80}
                src={require("../../assets/images/dirt-icon@3x.png")}
                alt=""
              />

              <b>Contamination</b>
              <i>
                <p>Dirt, water, metal and foreign materials</p>
              </i>
            </div>
          </div>
        </Col>
      </Row>
      <TestYourKnowledge link={commodityHowItWorksLinks.hydraulics} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default HydraulicsHowItWorks
