import React, { useEffect, useState } from "react"
import { Layout } from "antd"

import HeaderNav from "../components/mydna/nav/header.nav"
import Footer from "../components/mydna/nav/footer.nav"
import { Link, useLocation } from "react-router-dom"
import SiderNav from "../components/common/nav/sider.nav"
import '../components/mydna/styles/mydna.components.css';

const { Content, Header } = Layout

const MyDnaLayout = ({ children }) => {
  let location = useLocation()
  const [state, setState] = useState({ size: 1000 })
  const resize = () => {
    setState({ size: window.innerWidth })
  }
  
  const onMount = () => {
    window.addEventListener("resize", resize)
    resize()
    return () => {
      window.removeEventListener("resize", resize)
    }
  }

  useEffect(onMount, [])

  if (state.size > 800) {
    return (
        <Layout data-theme={'mydna-template'} className="mydna" id="MyDNA" style={{backgroundColor: "transparent !important" }}>          
          {state.size > 775 && location.pathname !== "/" && (
            <Header
              className={`header`}>
              <div className="float-left">
                <Link to="/">
                  <img
                    style={{ width: 300 }}
                    src={require("../assets/images/KOMATSU_LOGO__blue_2021.png")                    }
                    alt=""
                  />
                </Link>
              </div>
              <HeaderNav />
            </Header>
          )}

          <Layout>
            {state.size < 775 && location.pathname !== "/" && <SiderNav />}
            <Content >
              <div style={{width:'inherit'}}>{children}</div>
            </Content>
          </Layout>
          {location.pathname !== "/" && <Footer />}
        </Layout>
    )
  } else {
    return (
      <div
        className="flex-column d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}>
        <h2>For the best experience, this learning site is intended for use on
        laptop screens or larger.</h2>
      </div>
    )
  }
}

export default MyDnaLayout
