import React from "react"
import { Menu, Modal } from "antd"
import { Link, useLocation  } from "react-router-dom"
import '../styles/mydna.components.css';
import UserInfoForm from "../../user-info/user.info"
import { useState,useEffect } from "react"
import { useSelector } from "react-redux"


const HeaderNav = ({ mode = "horizontal" }) => {
  let location = useLocation()
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView(true);
        window.scrollTo({top: -10});
    });
  });
  const user = useSelector((state) => state.User)
  const [state, setstate] = useState({
      modal: false,
      content: "tools"
  })

  useEffect(() => {
      const userDataModal = JSON.parse(localStorage.getItem('userDataModal'));
      if ((userDataModal === false) && (userDataModal !== null) ) {
        userInformation();
      }
    }, [user]);

    const userInformation = () => {
      setstate({
        modal: true,
        content: "userInfo",
      })
    }
    const close = () => {
      setstate({
        modal: false,
        content: ""
      })
    }

  return (
    <>
        <Menu
          className="mydnaMenu menu"
          mode={mode}
          defaultSelectedKeys={["1"]}
          style={{
            lineHeight: "20px",
            float: "right",
            backgroundColor: "transparent",
          }}>
          <Menu.Item key="1" id="mydnaHomeLink" >
            <Link to="/mydna" className={(location.pathname === '/mydna') ? "active" : ""} data-theme="mydna">
              <b>MyDNA Home</b>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/mydna/part-lookup" className={location.pathname.includes("/mydna/part-lookup") ? "active" : ""} data-theme="lookup">
              <b>Lookup a Part</b>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/mydna/place-an-order" className={(["/mydna/place-an-order", "/mydna/part-availability-details","/mydna/check-part-availability","/mydna/place-an-order-process"].includes(location.pathname))? "active" : ""} data-theme="place-order">
              <b>Place an Order</b>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/mydna/manage-an-order" className={location.pathname.includes("/mydna/manage-") ? "active" : ""}  data-theme="manage-order">
              <b>Manage an Order</b>
            </Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to="/mydna/communicate" className={location.pathname.includes("/mydna/communicate") ? "active" : ""} data-theme="communicate">
              <b>Communicate</b>
            </Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/mydna/sales-tips" className={location.pathname.includes("/mydna/sales-") ? "active" : ""}  data-theme="sales-tips">
              <b>Sales Tips</b>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/mydna/parts-counter-pro-tips" className={location.pathname.includes("/mydna/parts-counter-pro-tips") ? "active" : ""} data-theme="pro-tips">
              <b>Pro Tips</b>
            </Link>
          </Menu.Item>
        </Menu>
        <Modal
            visible={state.modal}
            onOk={close} 
            onCancel={close}
            footer={null}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
            okText="Close">
            {state.content === "userInfo" && (
                <UserInfoForm
                    onComplete={() => {
                    localStorage.setItem('userDataModal', true);
                    setstate({
                        modal: false,
                        content: ""
                    })
                    }}
                />
            )}        
        </Modal>
    </>
  )
}

export default HeaderNav
