import { createStore, combineReducers, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import storage from "redux-persist/lib/storage"
import ReduxThunk from "redux-thunk"
import { createLogger } from "redux-logger"
import middleware from "../middleware/middleware"
import rootsaga from "../sagas/root.sagas"
import createSagaMiddleware from "redux-saga"
import User from "../reducers/user.reducers"
import Ui from "../reducers/ui.reducers"
import Quiz from "../reducers/quiz.reducers"
import MachineLearningScreen from "../reducers/machine-learning-screen.reducers"
import CommodityLearningScreen from "../reducers/commodity-learning-screen.reducers"
import Content from "../reducers/content-screen.reducers"
import TutorialState from "../reducers/tutorial-reducers"
import UiPersist from "../reducers/ui-persistant.reducers"
import Parrot from "../reducers/parrot.reducers"
import local from "../reducers/control.reducers"

const persistConfig = {
  version: 3,
  key: "KPARTSU",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    "Ui",
    "MachineLearningScreen",
    "CommodityLearningScreen",
    "Quiz",
    "Content",
    // "TutorialState",
    "Parrot"

    // "UiPersist"
  ],
  timeout: null
}
const loggerOptions = {}

export default () => {
  const allreducers = combineReducers({
    User,
    Ui,
    MachineLearningScreen,
    CommodityLearningScreen,
    Quiz,
    Content,
    TutorialState,
    UiPersist,
    Parrot,
    local
  })
  const sagaMiddleware = createSagaMiddleware()
  const logger = createLogger(loggerOptions)
  const persistedReducer = persistReducer(persistConfig, allreducers)

  const store = createStore(
    persistedReducer,
    applyMiddleware(ReduxThunk, middleware, logger, sagaMiddleware)
  )
  sagaMiddleware.run(rootsaga)

  const persistor = persistStore(store)
  return {
    store,
    persistor
  }
}
