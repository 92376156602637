import React, { useEffect } from "react"
import Parallax from "parallax-js"
import { useURL } from "../../hooks/url.hooks"

const GraphicalParallax = ({ subtype = "" }) => {
  const url = useURL()
  const sceneType = url.query.get("scene")

  useEffect(() => {
    if (subtype !== "") {
      const scene = document.getElementById("scene")
      new Parallax(scene, {
        relativeInput: true
      })
    }
  }, [subtype])
  return (
    <>
      {subtype === "" && (
        <div
          id="scene"
          // className="parallax-forestry"
          style={{
            backgroundImage: `url(${require("../../../images/home-page-background.jpg")})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        />
      )}
      {/* home-page-background.jpg */}
      {subtype === "Forestry" && (
        <div
          id="scene"
          className="parallax-forestry"
          style={{
            backgroundColor:
              (sceneType === "machine" || sceneType === "commodities") &&
              "#FFD65A"
          }}>
          <div
            data-depth="0.02"
            className="parallax-bg"
            style={{
              backgroundImage:
                sceneType !== "machine" && sceneType !== "commodities"
                  ? `url(${require("../../../images/Forestry-BG-Back-Layer-Four.png")})`
                  : `url(${require("../../../images/Forestry-Yellow-BG-Back-Layer-Four@2x.png")})`
            }}
          />
          <div
            data-depth="0.05"
            className="parallax-bg"
            style={{
              backgroundImage:
                sceneType !== "machine" && sceneType !== "commodities"
                  ? `url(${require("../../../images/Forestry-BG-3rd-Layer-Three.png")})`
                  : `url(${require("../../../images/Forestry-Yellow-BG-3rd-Layer-Three@2x.png")})`
            }}
          />
          <div
            data-depth="0.1"
            className="parallax-bg"
            style={{
              backgroundImage:
                sceneType !== "machine" && sceneType !== "commodities"
                  ? `url(${require("../../../images/Forestry-BG-2nd-Layer-Two.png")})`
                  : `url(${require("../../../images/Forestry-Yellow-BG-2nd-Layer-Two@2x.png")})`
            }}
          />
          <div
            data-depth="0.2"
            className="parallax-bg"
            style={{
              backgroundImage:
                sceneType !== "machine" && sceneType !== "commodities"
                  ? `url(${require("../../../images/Forestry-BG-Front-Layer-One.png")})`
                  : `url(${require("../../../images/Forestry-Yellow-BG-Front-Layer-One@2x.png")})`
            }}
          />
        </div>
      )}

      {subtype === "Wheeled" && (
        <div id="scene" className="parallax-wheel">
          <div
            data-depth="0.02"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/wheeled-BG-6th-layer-six@2x.png")})`
            }}
          />
          <div
            data-depth="0.05"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/wheeled-BG-5th-layer-five@2x.png")})`
            }}
          />
          <div
            data-depth="0.1"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/wheeled-BG-4th-layer-four@2x.png")})`
            }}
          />
          <div
            data-depth="0.2"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/wheeled-BG-3rd-layer-three@2x.png")})`
            }}
          />

          <div
            data-depth="0.26"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/wheeled-BG-2nd-layer-two@3x.png")})`
            }}
          />
          <div
            data-depth="0.34"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/wheeled-bg-front-layer-one@2x.png")})`
            }}
          />
        </div>
      )}
      {subtype === "Track" && (
        <div id="scene" className="parallax-track">
          <div
            data-depth="0.02"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/track-BG-5th-layer-five@2x.png")})`
            }}
          />
          <div
            data-depth="0.05"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/track-BG-4th-layer-four@2x.png")})`
            }}
          />
          <div
            data-depth="0.1"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/track-BG-3rd-layer-three@2x.png")})`
            }}
          />
          <div
            data-depth="0.2"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/track-BG-2nd-layer-two@2x.png")})`
            }}
          />
          <div
            data-depth="0.3"
            className="parallax-bg "
            style={{
              backgroundImage: `url(${require("../../../images/track-bg-front-layer-one@2x.png")})`
            }}
          />
        </div>
      )}
      {subtype === "Mining" && (
        <div id="scene" className="parallax-track">
          <div
            data-depth="0.02"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/track-BG-5th-layer-five@2x.png")})`
            }}
          />
          <div
            data-depth="0.05"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/track-BG-4th-layer-four@2x.png")})`
            }}
          />
          <div
            data-depth="0.1"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/track-BG-3rd-layer-three@2x.png")})`
            }}
          />
          <div
            data-depth="0.2"
            className="parallax-bg"
            style={{
              backgroundImage: `url(${require("../../../images/track-BG-2nd-layer-two@2x.png")})`
            }}
          />
          <div
            data-depth="0.3"
            className="parallax-bg "
            style={{
              backgroundImage: `url(${require("../../../images/track-bg-front-layer-one@2x.png")})`
            }}
          />
        </div>
      )}
    </>
  )
}
export default GraphicalParallax
