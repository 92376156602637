/* eslint-disable */
import React from "react"
import { Row, Col, Divider } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import { commodityHowItWorksLinks } from "../../helpers/quiz.helpers"

import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
const LubricantsHowItWorks = () => {
  const setModalToInactive = () => {
    let currentUrlParams = new URLSearchParams(window.location.search)
    // currentUrlParams.set('quiz_active', 'false')
  }
  return (
    <div className="page">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="lubricants-color">
          <b>Lubricants</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="py-5">
            <center>
              {/* <strong>
            <h1 className={`${commodity_ez_id.toLowerCase()}-color`}>
              <b>{commodity_ez_id.toUpperCase()}</b>
            </h1>
          </strong> */}
              <div
                style={{ fontSize: 26, fontWeight: 600, fontFamily: "Roboto" }}>
                <b>How it Works</b>
              </div>
            </center>
          </div>
          <Divider />
          <div className="d-flex flex-lg-row align-items-center flex-sm-column mb-4">
            <img
              className="image-contain mr-4"
              height={218}
              width={223}
              src={require("../../assets/images/how-it-works-lubricants-1.png")}
              alt="gif needed"
            />

            <div>
              <p>
                Oil provides lubrication, cooling, sealing, component protection
                and contamination control (moves contaminations to the filter).
              </p>
              <p>
                The primary purpose of a lubricant/oil is to reduce friction. It
                is also used to perform work (transfer of energy).
              </p>
            </div>
          </div>
          <Divider />
          <div>
            <p style={{ fontSize: 26 }}>
              <b>Oil Types</b>
            </p>
          </div>
          <Row>
            <Col sm={24} md={12}>
              <div className="p-4">
                <center>
                  <p>
                    <b>Conventional</b>
                  </p>
                </center>
                • Mineral oil
                <br />
                • Fluid molecule can vary in size and properties
                <br />• Molecule variation can create more friction and heat
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div className="p-4">
                <center>
                  <p>
                    <b>Synthetic</b>
                  </p>
                </center>
                • Molecularly engineered
                <br />
                • Uniform fluid molecule size
                <br />
                • Less friction between fluid molecules
                <br />• Improved component protection
              </div>
            </Col>
          </Row>
          <center>
            <img
              className="image-contain"
              height={238}
              width={740}
              src={require("../../assets/images/Lubricants_Animation_Types.gif")}
              alt="sythetic vs conventional lubricants animation"
            />
          </center>
          <Divider />
          <p style={{ fontSize: 26 }}>
            <b>Viscosity</b>
          </p>
          <p>
            The measure of a fluid's resistance to flow, and a key
            characteristic of oil.
          </p>
          <center>
            <img
              className="image-contain"
              height={293}
              width={600}
              src={require("../../assets/images/how-it-works-lubricants-4.png")}
              alt="how lubricants work animation"
            />
          </center>
        </Col>
      </Row>
      <TestYourKnowledge link={commodityHowItWorksLinks.lubricants} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default LubricantsHowItWorks
