/* eslint-disable */
export const ObjToArr = (obj) => {
  return Object.keys(obj).map((objKey) => ({
    ...obj[objKey],
    key: objKey
  }))
}
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const isUrl = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ) // fragment locator
  return !!pattern.test(str)
}

export const randomLetterGenerator = (number = 20) => {
  let result = ""
  let characters = "abcdefghijklmnopqrstuvwxyz-"
  // "*"
  let charactersLength = characters.length
  for (let i = 0; i < number; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result.split("")
}
export const binaryNumberGenerator = () => {
  return Math.round(Math.random() * 1)
}
export const randomNumberGenerator = (number) => {
  return Math.round(Math.random() * number)
}
export const findHighestLength = (words) => {
  return words[words.length - 1].length
}

export const createLetterGrid = (quizWords) => {
  let letterGrid = []
  let words = quizWords
    .map((word) => word.data.split(""))
    .sort((a, b) => {
      return a.length - b.length
    })
  let highestLength = findHighestLength(words)
  let rootNumber = highestLength * highestLength

  letterGrid = randomLetterGenerator(rootNumber)
  //INITIALIZE
  // for (let i = 0; i < rootNumber; i++) {
  // 	letterGrid.push('*')
  // }
  // WORD PLACEMENT
  for (let i = 0; i < quizWords.length; i++) {
    const prov = quizWords[i]

    let pos = prov.startingIndex

    for (let ii = 0; ii < prov.data.length; ii++) {
      letterGrid[pos] = prov.data[ii]
      if (prov.direction === 0) {
        pos++
      } else {
        pos += highestLength
      }
    }
  }

  // for (let i = 0; i < words.length; i++) {
  // 	const word = words[i]
  // 	let d = 0
  // 	let pos = 0
  // 	if (i === 0) {
  // 		d = 1
  // 		pos = 5
  // 	} else if (i === 1) {
  // 		d = 1
  // 		pos = 50
  // 	} else if (i === 2) {
  // 		d = 1
  // 		pos = 40
  // 	} else if (i === 3) {
  // 		d = 0
  // 		pos = 42
  // 	} else if (i === 4) {
  // 		d = 1
  // 		pos = 60
  // 	} else if (i === 5) {
  // 		d = 0
  // 		pos = 106
  // 	} else if (i === 6) {
  // 		d = 0
  // 		pos = 157
  // 	} else if (i === 7) {
  // 		d = 0
  // 		pos = 144
  // 	} else if (i === 8) {
  // 		d = 1
  // 		pos = 0
  // 	} else if (i === 9) {
  // 		d = 1
  // 		pos = 0
  // 	}
  // 	for (let ii = 0; ii < word.length; ii++) {
  // 		const letter = word[ii]
  // 		letterGrid[pos] = letter
  // 		if (d === 0) {
  // 			pos++
  // 		} else {
  // 			pos += highestLength
  // 		}
  // 	}
  // }

  return letterGrid
}

export const pointsInBetween = (A, B) => {
  let slope = (a, b) => {
    if (a[0] == b[0]) {
      return null
    }

    return (b[1] - a[1]) / (b[0] - a[0])
  }

  let intercept = (point, slope) => {
    if (slope === null) {
      // vertical line
      return point[0]
    }

    return point[1] - slope * point[0]
  }

  let m
  let b

  let coordinates = []

  if (A[0] <= B[0]) {
    m = slope(A, B)
    b = intercept(A, m)
    for (let x = A[0]; x <= B[0]; x++) {
      let y = m * x + b
      coordinates.push({ x, y })
    }
  } else if (B[0] <= A[0]) {
    m = slope(B, A)
    b = intercept(B, m)
    for (let x = B[0]; x <= A[0]; x++) {
      let y = m * x + b
      coordinates.push({ x, y })
    }
  }

  return coordinates
}

export const sortedWords = (quizWords) => {
  let words = quizWords
    .map((word) => word.data.split(""))
    .sort((a, b) => {
      return a.length - b.length
    })
  return words
}
