/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Modal, Spin } from "antd";
import { useParams, Link } from "react-router-dom";
import { FrostedGlass } from "../common/frosted/glass.frosted";
import QuizPrompt from "../quizes/prompts/quizprompt.quiz";
import TestYourKnowledge from "../common/icons/test-your-knowledge";
import { commodityProductOfferingLinks } from "../../helpers/quiz.helpers";
import { useDispatch, useSelector } from "react-redux";
import { GET_CONTENT } from "../../store/actions/content.actions";
import { LoadingOutlined } from "@ant-design/icons";
import { useCommodityScreen } from "../hooks/screen.hooks"
import { useParrot } from "../hooks/parrot.hooks";

const CommodityOffering = ({
  title = "",
  subtitle,
  secondSubtitle,
  thirdSubtitle,
  fourthSubtitle,
  fifthSubtitle,
  sales_tips = "",
}) => {
  let { commodity_ez_id } = useParams()
  const { commodity } = useCommodityScreen(commodity_ez_id)
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [hoverCount, setHoverCount] = useState(0);
  const contentScreen = useSelector((state) => state.Content);
  const dispatch = useDispatch();
  const setModalToInactive = () => {};
  const toggleModal = () => {
    setModal(!modal);
  };
  const close = () => {
    setModal(false);
  };

  const { setParrot } = useParrot();

  const onHover = () => {
    let totalImageCount = 0;
    totalImageCount += contentScreen?.content.length;
    if (contentScreen?.secondary_content.length) {
      totalImageCount += contentScreen?.secondary_content.length;
    }


    setHoverCount((oldcount) => oldcount + 1);

    if (totalImageCount === hoverCount) {
      setTimeout(() => {
        setParrot({
          dialog: `You've now learned about all the products in ${title.toLowerCase()}. Ready to test your knowledge?`,
          display: "TEACHER",
          mode: 1,
          next_bird_actions: [
            commodityProductOfferingLinks[title.toLowerCase()],
          ],
        });
      }, 2500);
    }
  };

  const imageMap =
    (title, length, showtitle = true, onHovered = () => {}) =>
    (data) => {
      return (
        <Col span={length < 4 ? 24 / length : 8}>
          <div
            onMouseEnter={onHovered}
            className={`pointer-cursor offering-contain offering-${title.replace(/\s+/g, '-').toLowerCase()}-border`}
          >
            <FrostedGlass className="glass-absolute-hover p-1">
              <p>
                <div
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </p>

              <div>
                <img alt="?" className="offering-img" src={data.image} />
                <br />
                {showtitle && (
                  <b className={`${title.toLowerCase()}-color`}>{data.title}</b>
                )}
                <br />
              </div>
            </FrostedGlass>
          </div>
        </Col>
      );
    };

    const imageMapUc =
    (title, length, showtitle = true, onHovered = () => {}) =>
    (data) => {
      return (
        <Col span={length < 4 ? 24 / length : 6}>
          <div
            onMouseEnter={onHovered}
            className={`pointer-cursor uc-offering-modal offering-contain offering-${title.replace(/\s+/g, '-').toLowerCase()}-border`}
          >
            <FrostedGlass className="glass-absolute-hover p-1">
              <p>
                <div
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </p>

              <div>
                <img alt="?" className="offering-img" src={data.image} />
                <br />
                {showtitle && (
                  <b className={`${title.toLowerCase()}-color`}>{data.title}</b>
                )}
                <br />
              </div>
            </FrostedGlass>
          </div>
        </Col>
      );
    };

  useEffect(() => {
    setParrot({
      dialog:
        "This is a Commodity Product Offering page. Hover over each product to learn more about it, then click the ''Sales Tip'' at the bottom. Once you've learned all the products and any available Sales Tips, click the quiz head at the bottom right to test your knowledge!",
      display: "TALKING",
      mode: 1,
    });
  }, []);

  useEffect(() => {
    if (title !== "") {
      dispatch(
        GET_CONTENT({ ez_id: "offering", suffix: `/${title.replace(/\s+/g, '-').toLowerCase()}` })
      );
    }

    return () => {
      dispatch({
        type: "RESET_CONTENT_SCREEN",
      });
    };
  }, [title]);

  useEffect(() => {
    console.log("contentScreen!!!: ", contentScreen);
  }, [contentScreen]);
  
  return (
    <Spin
      spinning={contentScreen.loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <div className="page">
        <Modal
          className="modal-70vw"
          onOk={close}
          onCancel={close}
          // cancelText='Close'
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
          okText="Close"
          visible={modal}
        >
          {modalType === "prompt" && (
            <div>
              <div
                className="d-flex flex-row align-items-center"
                onClick={() => {
                  toggleModal();
                }}
              >
                <img
                  height={57}
                  width={63}
                  src={require("../../assets/images/icons/sales-tip-icon@3x.png")}
                  alt=""
                />
                <h2 className="k-blue  ml-2">
                  <b>Sales Tips</b>
                </h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: sales_tips }}></div>
            </div>
          )}
          {modalType === "list" && (
            <div>
              <h5 className={`${title.toLowerCase()}-color`}>
                A/C Compressors
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Air Compressor{" "}
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Alternator </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Arm Cylinder</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Armature</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Axle </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Boom Cylinder</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Brake Components
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Brakes </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Bucket Cylinder
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Controllers (ECM/TCM/PPM)
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Cylinder Heads</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Differential </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Electronic Components
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>EGR</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Engine </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Final Drive </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Fuel Injection Pump{" "}
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Fuel System Components
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>GE Alternator</h5>
              <h5 className={`${title.toLowerCase()}-color`}>GE Card</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Gear Pump </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Grid Blower</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Hoist Cylinder</h5>
              <h5 className={`${title.toLowerCase()}-color`}>HST Pump</h5>
              <h5 className={`${title.toLowerCase()}-color`}>HST Motor</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Hydraulic Cylinder{" "}
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>iMC Components</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Injector</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Monitor Panel/Power Panel
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Oil Coolers</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Piston Pumps & Motors
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Power Module </h5>
              <h5 className={`${title.toLowerCase()}-color`}>PTO Assembly</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Spindle, Hub & Brake
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Starter </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Steering Components
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Steering Cylinder
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Suspensions</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Swing Bearings</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Swing Machinery
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Torque Converters
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Transmission</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Travel Motor</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Turbocharger </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Water Pump </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Wheel Spindle</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Power Supply Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>CPU Card</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Analog I/O Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Digital I/O Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Digital I/O Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Card</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Power Supply Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Inverter I/O Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Inverter CPU Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Analog I/O Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Analog I/O Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>PSC CPU Card</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                TMC Processor Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Power Supply</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Power Supply</h5>
              <h5 className={`${title.toLowerCase()}-color`}>DID Panel</h5>
              <h5 className={`${title.toLowerCase()}-color`}>Chopper Module</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Inverter Phase Module  
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Inverter Phase Module
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>Gate Driver</h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Inverter/Chopper GTO Phase Module
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Inverter/Chopper GTO Phase Module
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                Fiber Optic I/O Card
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                776/791 Armature
              </h5>
              <h5 className={`${title.toLowerCase()}-color`}>
                788/787 Armature
              </h5>
            </div>
          )}
        </Modal>
        <div className="py-5">
          <center>
            <h1>
              <b className={`${title.replace(/\s+/g, '-').toLowerCase()}-color capitalize-title`}>
                {title.toLowerCase()}
              </b>
            </h1>
            <h4>
              <b>Product Offering</b>
            </h4>
            {title.replace(/\s+/g, '-').toLowerCase() === "sr-drive-system" && (
                  <p>All of these products, in addition to cylinders and radiators, are available through the <Link to="/dashboard/commodities/learn/reman">Reman</Link>/Component Exchange Program.</p>
              )}
               {title.toLowerCase() === "undercarriage" && (
                  <p className={`${title.toLowerCase()}-color pointer-cursor`}>
                   <b><a className={`${title.toLowerCase()}-color pointer-cursor`} href="https://cdn.komatsupartsu.com/UC-Component-Compatibility-Chart.pdf" target="_blank">Click here to view a component compatibility chart</a></b></p>
              )}
            <div
              onClick={() => {
                setModal(true);
                setModalType("list");
              }}
            >
              {title.toLowerCase() === "reman" && (
                <h5 className={`${title.toLowerCase()}-color pointer-cursor`}>
                  <img
                    className={`eyehover image-contain mb-3 mr-3 mt-2`}
                    height={19}
                    width={25}
                    src={require("../../assets/images/eye.png")}
                    alt=""
                  />
                  <b>
                    Click here to view a list of all available Reman components
                  </b>
                </h5>
              )}
            </div>
          </center>
        </div>
        <center>
          <h5 className={`${title.toLowerCase()}-color product-subtitle`}>
            <b>{subtitle}</b>
          </h5>
        </center>
        {contentScreen?.uc.length > 0 && (
          <>
            <Row>
              <Col span={3} />
              <Col span={18}>
                <Row gutter={32}>
                  {contentScreen?.uc.map(
                    imageMapUc(title, contentScreen?.uc.length, false, onHover)
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col span={3} />
          <Col span={18}>
            <Row gutter={32}>
              {contentScreen?.content.length > 0 &&
                contentScreen.content.map(
                  imageMap(
                    title,
                    contentScreen.content.length,
                    true,
                    onHover
                  )
                )}
            </Row>
          </Col>
        </Row>

        {contentScreen?.secondary_content.length > 0 && (
          <>
            <Divider />
            <center>
              <h5 className={`${title.toLowerCase()}-color product-subtitle`}>
                <b>{secondSubtitle}</b>
              </h5>
            </center>
            <Row>
              <Col span={3} />
              <Col span={18}>
                <Row gutter={32}>
                  {contentScreen?.secondary_content.map(
                    imageMap(title, contentScreen?.secondary_content.length, true, onHover)
                  )}
                </Row>
              </Col>
            </Row>
          </>
        ) 
        }

{contentScreen?.third_content.length > 0 && (
          <>
            <Divider />
            <center>
              <h5 className={`${title.toLowerCase()}-color product-subtitle`}>
                <b>{thirdSubtitle}</b>
              </h5>
            </center>
            <Row>
              <Col span={3} />
              <Col span={18}>
                <Row gutter={32}>
                  {contentScreen?.third_content.map(
                    imageMap(title, contentScreen?.third_content.length, true, onHover)
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}

        {contentScreen?.fourth_content.length > 0 && (
          <>
            <Divider />
            <center>
              <h5 className={`${title.toLowerCase()}-color product-subtitle`}>
                <b>{fourthSubtitle}</b>
              </h5>
            </center>
            <Row>
              <Col span={3} />
              <Col span={18}>
                <Row gutter={32}>
                  {contentScreen?.fourth_content.map(
                    imageMap(title, contentScreen?.fourth_content.length, true, onHover)
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}
        {contentScreen?.fifth_content.length > 0  && (
          <>
            <Divider />
            <center>
              <h5 className={`${title.toLowerCase()}-color product-subtitle`}>
                <b>{fifthSubtitle}</b>
              </h5>
            </center>
            <Row>
              <Col span={3} />
              <Col span={18}>
                <Row gutter={32}>
                  {contentScreen?.fifth_content.map(
                    imageMap(title, contentScreen?.fifth_content.length, true, onHover)
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}


        <div className="d-flex justify-content-center align-items-center pb-5 mt-4 pointer-cursor">
          {sales_tips.length > 0 && (
            <div
              className="d-flex flex-row justify-content-center align-items-center"
              onClick={() => {
                toggleModal();
                setModalType("prompt");
              }}
            >
              <img
                height={57}
                width={63}
                src={require("../../assets/images/icons/sales-tip-icon@3x.png")}
                alt=""
              />
              <div className="k-blue  ml-2">
                <b>Sales Tips</b>
              </div>
            </div>
          )}

          <Link
            to={`/dashboard/commodities/${title.replace(/\s+/g, '-').toLowerCase()}/mates-and-relates`}
          >
            <div className="d-flex flex-row justify-content-center align-items-center ml-5 pointer-cursor">
              <img
                height={55}
                width={73}
                src={require("../../assets/images/icons/mates-and-relates-icon@3x.png")}
                alt=""
              />
              <div className="k-blue ml-2">
                <b>Mates & Relates</b>
              </div>
            </div>
          </Link>
        </div>

        <TestYourKnowledge
          link={commodityProductOfferingLinks[title.replace(/\s+/g, '-').toLowerCase()]}
        />
        <QuizPrompt onExitEarly={setModalToInactive} />
      </div>
    </Spin>
  );
};
export default CommodityOffering;
