import React from "react"
import { Row, Col } from "antd"
const RemanCoreReturn = () => {
  return (
    <div className="page">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <center>
          <h1 className="reman-color">
            <b>Reman</b>
          </h1>
          <h4>
            <b>Core Return</b>
          </h4>
        </center>
      </div>
      <Row className="custom-p-5">
        <Col xs={24} xm={24} md={16} lg={16}>
          <div className="p-4">
            <h3>
              <b>Core Return Eligibility Requirements</b>
            </h3>
            <p>
              In order for your customer to receive credit for a returned core,
              their core must be:
            </p>
            <ul>
              <li>
                In off-engine condition (not disassembled and reassembled)
              </li>
              <li>Completely in-tact</li>
              <li>
                Without weather damage (e.g., hasn't been sitting outside 
                behind the shop for an extended period of time)
              </li>
              <li>
                Before your distributor evaluates a core, it is very important
                that you check if it is  associated with a warranty claim
              </li>
              <li>
                If the core is a warranty core, file the warranty claim prior to
                evaluating the core
              </li>
              <li>
                Failure to do this step first prevents the warranty claim being
                tied to the MRA which can result in the warranty claim being
                denied and/or no core credit
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={24} xm={24} md={6} lg={6}>
          <center>
            <img
              src={require("../assets/images/core-return-starter.png")}
              alt=""
            />
            <br />
            <i>Starter Core Return</i>
            <img src={require("../assets/images/Reman-starter.png")} alt="" />
            <br />
            <i>Reman Starter</i>
          </center>
        </Col>
      </Row>
      <Row className="custom-p-5">
        <div className="p-4">
          <h3>
            <b>Core Return Eligibility Requirements</b>
          </h3>
          <p>
            Every Reman component has its own specific Core Return Criteria. If
            a customer is considering, or has already disassembled their core,
            you should always look up that component's specific Core Criteria
            and clearly communicate it with your customer.
          </p>
          <p>
            From the Komatsu Parts Portal, go to Reman - Core Criteria and click
            the Core Criteria button at the top of the page. Find your
            customer's component in the list below and click to open the Core
            Criteria PDF to find the information you and your customer need
          </p>
        </div>
      </Row>

      <center>
        <img
          style={{ width: 600, marginBottom: 20 }}
          src={require("../images/reman-core-return-credit-screenshot.png")}
          alt=""
        />
      </center>
    </div>
  )
}

export default RemanCoreReturn
