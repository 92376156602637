import React from "react"
import { Menu, Avatar, Modal } from "antd"
import { Link } from "react-router-dom"
import { useURL } from "../../hooks/url.hooks"

import { useState,useEffect } from "react"
import Conversions from "../../toolbox/conversions.toolbox"
import FeedbackForm from "../../toolbox/feedback.toolbox"
import SearchToolbox from "../../toolbox/search.toolbox"
import UserInfoForm from "../../user-info/user.info"
import { useSelector } from "react-redux"

const HeaderNav = ({ mode = "horizontal" }) => {
  const user = useSelector((state) => state.User)
  const [state, setstate] = useState({
    modal: false,
    content: "tools"
  })
  
  useEffect(() => {
    const userDataModal = JSON.parse(localStorage.getItem('userDataModal'));
    if ((userDataModal === false) && (userDataModal !== null) ) {
      userInformation();
    }
  }, [user]);

  useURL()
  const openKit = () => {
    setstate({
      modal: true,
      content: "tools"
    })
  }
  const openVolKit = () => {
    setstate({
      modal: true,
      content: "tools-volume"
    })
  }
  const openTempKit = () => {
    setstate({
      modal: true,
      content: "tools-temperature"
    })
  }
  const openPressKit = () => {
    setstate({
      modal: true,
      content: "tools-pressure"
    })
  }

  const close = () => {
    setstate({
      modal: false,
      content: ""
    })
  }
  const feedbackProvider = () => {
    setstate({
      modal: true,
      content: "feedback"
    })
  }
  const openSearch = () => {
    setstate({
      modal: true,
      content: "search"
    })
  }
  const openMyNotes = () => {
    window.open("/dashboard/my-notes")
  }
  const userInformation = () => {
    setstate({
      modal: true,
      content: "userInfo",
    })
  }

  return (
    <>
      <Modal
        visible={state.modal}
        onOk={close}
        onCancel={close}
        footer={null}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
        okText="Close">
        {state.content === "tools" && (
          <Conversions initialUnit="m" units="distance" />
        )}
        {state.content === "tools-volume" && (
          <Conversions initialUnit="liter" units="volume" />
        )}
        {state.content === "tools-temperature" && (
          <Conversions initialUnit="fahrenheit" units="temperature" />
        )}
        {state.content === "tools-pressure" && (
          <Conversions initialUnit="bar" units="pressure" />
        )}

        {state.content === "feedback" && (
          <FeedbackForm
            onComplete={() => {
              setstate({
                modal: false,
                content: ""
              })
            }}
          />
        )}
        {state.content === "search" && <SearchToolbox context="header" />}
        {state.content === "userInfo" && (
          <UserInfoForm
            onComplete={() => {
              localStorage.setItem('userDataModal', true);
              setstate({
                modal: false,
                content: ""
              })
            }}
          />
        )}        
      </Modal>
      <center>
        <Menu
          theme="light"
          mode={mode}
          defaultSelectedKeys={["1"]}
          style={{
            lineHeight: "64px",
            float: "right",
            backgroundColor: "transparent",
            width: "550px",
          }}>
          <Menu.Item key="1">
            <Link to="/dashboard/machines">
              <b>Parts U Home</b>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/dashboard/about">
              <b>About</b>
            </Link>
          </Menu.Item>
          <Menu.SubMenu id="tut-8" key="g1" title={<b>Learn</b>}>
            <Menu.ItemGroup  title={<b>Construction</b>}>
              <Menu.SubMenu title={<Link to="/dashboard/construction">Machines</Link>}>  
                <Menu.Item key="/dashboard/machines/learn/excavator">
                  <Link to="/dashboard/machines/learn/excavator">Excavator</Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/dozer">
                  <Link to="/dashboard/machines/learn/dozer">Dozer</Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/wheel-loader">
                  <Link to="/dashboard/machines/learn/wheel-loader">
                    Wheel Loader
                  </Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/haul-truck">
                  <Link to="/dashboard/machines/learn/haul-truck">
                    Haul Truck
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu
              key="g2"
              title={
                <Link to="/dashboard/commodities">
                  Commodities
                </Link>
              }>
              <Menu.Item key="/dashboard/commodities/learn/batteries">
                <Link to="/dashboard/commodities/learn/batteries">
                  Batteries
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/filters">
                <Link to="/dashboard/commodities/learn/filters">Filters</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/hydraulics">
                <Link to="/dashboard/commodities/learn/hydraulics">
                  Hydraulics
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/lubricants">
                <Link to="/dashboard/commodities/learn/lubricants">
                  Lubricants
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/reman">
                <Link to="/dashboard/commodities/learn/reman">Reman</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/undercarriage">
                <Link to="/dashboard/commodities/learn/undercarriage">
                  Undercarriage
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            </Menu.ItemGroup>

            <Menu.ItemGroup  title={<b>Forestry</b>}>
              <Menu.SubMenu key="g3" title={ <Link to="/dashboard/forestry">Machines</Link>}>  
                <Menu.Item key="/dashboard/machines/learn/feller-buncher">
                  <Link to="/dashboard/machines/learn/feller-buncher">Feller Buncher</Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/forestry-excavator">
                  <Link to="/dashboard/machines/learn/forestry-excavator">Forestry Excavator</Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/forwarders">
                  <Link to="/dashboard/machines/learn/forwarders">
                    Forwarder
                  </Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/wheeled-harvester">
                  <Link to="/dashboard/machines/learn/wheeled-harvester">
                    Wheeled Harvester
                  </Link>
                </Menu.Item>

              </Menu.SubMenu>
              <Menu.SubMenu
              key="g4"
              title={
                <Link to="/dashboard/commodities">
                  Commodities
                </Link>
              }>
              <Menu.Item key="/dashboard/commodities/learn/batteries">
                <Link to="/dashboard/commodities/learn/batteries">
                  Batteries
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/filters">
                <Link to="/dashboard/commodities/learn/filters">Filters</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/hydraulics">
                <Link to="/dashboard/commodities/learn/hydraulics">
                  Hydraulics
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/lubricants">
                <Link to="/dashboard/commodities/learn/lubricants">
                  Lubricants
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/reman">
                <Link to="/dashboard/commodities/learn/reman">Reman</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/undercarriage">
                <Link to="/dashboard/commodities/learn/undercarriage">
                  Undercarriage
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            </Menu.ItemGroup>

            <Menu.ItemGroup  title={<b>Mining</b>}>
              <Menu.SubMenu key="g5" title={<Link to="/dashboard/mining">Machines</Link>}>  
                <Menu.Item key="/dashboard/machines/learn/shovel">
                  <Link to="/dashboard/machines/learn/shovel">Electric Rope Shovel</Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/drill">
                  <Link to="/dashboard/machines/learn/drill">Blasthole Drill</Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/electric-drive-truck">
                  <Link to="/dashboard/machines/learn/electric-drive-truck">Electric Drive Truck</Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/machines/learn/hybrid-wheel-loader">
                  <Link to="/dashboard/machines/learn/hybrid-wheel-loader">Hybrid Wheel Loader</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu
              key="g6"
              title={
                <Link to="/dashboard/commodities">
                  Commodities
                </Link>
              }>
              <Menu.Item key="/dashboard/commodities/learn/batteries">
                <Link to="/dashboard/commodities/learn/batteries">
                  Batteries
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/filters">
                <Link to="/dashboard/commodities/learn/filters">Filters</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/hydraulics">
                <Link to="/dashboard/commodities/learn/hydraulics">
                  Hydraulics
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/lubricants">
                <Link to="/dashboard/commodities/learn/lubricants">
                  Lubricants
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/reman">
                <Link to="/dashboard/commodities/learn/reman">Reman</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/undercarriage">
                <Link to="/dashboard/commodities/learn/undercarriage">
                  Undercarriage
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/sr-drive-system">
                <Link to="/dashboard/commodities/learn/sr-drive-system">
                  SR Drive System
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            </Menu.ItemGroup>
            
          </Menu.SubMenu>
          <Menu.SubMenu id="tut-7" key="4" title={<b>Toolbox</b>}>
            <Menu.SubMenu key="toolbox" title={<b>Unit Conversions</b>}>
              <Menu.Item onClick={openKit}>Distance</Menu.Item>
              <Menu.Item onClick={openVolKit}>Volume</Menu.Item>
              <Menu.Item onClick={openTempKit}>Temperature</Menu.Item>
              <Menu.Item onClick={openPressKit}>Pressure</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item onClick={feedbackProvider}>Send Us Feedback</Menu.Item>
            <Menu.Item onClick={openSearch}>Search</Menu.Item>
            <Menu.Item onClick={openMyNotes}>My Notes</Menu.Item>

            <Menu.SubMenu key="6" title={<b>Resources</b>}>
              <Menu.Item>
                <Link to="/dashboard/commodities/resources/batteries">
                  Batteries
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/resources/filters">
                  Filters
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/resources/hydraulics">
                  Hydraulics
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/resources/lubricants">
                  Lubricants
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/resources/reman">Reman</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/resources/undercarriage">
                  Undercarriage
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/resources/forestry">
                  Forestry
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key="7" title={<b>Product Support</b>}>
              <Menu.Item>
                <Link to="/dashboard/commodities/support/batteries">
                  Batteries
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/support/filters">Filters</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/support/hydraulics">
                  Hydraulics
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/support/lubricants">
                  Lubricants
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/support/reman">Reman</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/support/undercarriage">
                  Undercarriage
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/support/sr-drive-system">
                  SR Drive System
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/support/forestry">
                  Forestry
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key="8" title={<b>Key Terms</b>}>
              <Menu.Item>
                <Link to="/dashboard/commodities/batteries/key-terms">
                  Batteries
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/filters/key-terms">
                  Filters
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/hydraulics/key-terms">
                  Hydraulics
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/lubricants/key-terms">
                  Lubricants
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/reman/key-terms">Reman</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/undercarriage/key-terms">
                  Undercarriage
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/commodities/sr-drive-system/key-terms">
                  SR Drive System
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.SubMenu>

          <Menu.Item key="5" id="tut-5">
            <Link to="/dashboard/user/profile">
              <Avatar src={user.credentials.avatar} size={50}>
                {user.credentials.name.split(" ")[0]}
              </Avatar>
            </Link>
          </Menu.Item>
        </Menu>
      </center>
    </>
  )
}

export default HeaderNav
