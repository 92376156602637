import { useSelector, useDispatch } from "react-redux"

export const useParrot = () => {
  const dispatch = useDispatch()
  const { firstTime, complete_parrot_dialog } = useSelector(
    (state) => state.UiPersist
  )
  const parrot = useSelector((state) => state.Parrot)
  const setParrot = (payload) => {
    if (!complete_parrot_dialog.includes(payload.dialog)) {
      dispatch({
        type: "SET_PARROT",
        payload
      })
    }
  }
  const currentTip = () => {
    let ii = 0
    for (let index = 0; index < complete_parrot_dialog.length; index++) {
      const dia = complete_parrot_dialog[index]

      if (dia.includes("Safety Tip:")) {
        ii++
      }
    }
    return ii
  }

  return {
    firstTime,
    setParrot,
    currentTip,
    ...parrot
  }
}
