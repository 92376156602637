import { all, takeLatest, takeEvery } from "redux-saga/effects"
import LoginSaga from "./login.saga"
import SignupSaga from "./signup.saga"
import MachineGETSaga from "./machine.saga"
import CommodityGETSaga from "./commodity.saga.js"
import quizSaga from "./quiz.saga"
import ContentAPISaga from "./content.saga"
import QuizProgressSaga from "./progress.saga"
import LocalStorageHandler from "./localstorage.saga"

//
//
//
// Root Saga
//
//
//

export default function* rootsaga() {
  yield all([
    takeLatest("LOGIN", LoginSaga),
    takeLatest("SIGNUP", SignupSaga),
    takeEvery("GET_MACHINE", MachineGETSaga),
    takeEvery("GET_COMMODITY", CommodityGETSaga),
    takeLatest("CALL_QUIZ_API", quizSaga),
    takeLatest("GET_CONTENT", ContentAPISaga),
    //takeLatest("SUBMIT_QUIZ", QuizProgressSaga),
    takeLatest(
      ["GET_PROGRESS", "SUBMIT_QUIZ", "LOGIN_SUCCESS"],
      QuizProgressSaga
    ),
    takeEvery("persist/REHYDRATE", LocalStorageHandler)
  ])
}
