export const ProgressTracks = {
  batteries: [
    { ez_id: "batteries_landing_compilation", name: "Batteries Landing Page" },
    {
      ez_id: "batteries_product_offering_compilation",
      name: "Batteries Product Offering"
    },
    { ez_id: "batteries_kt_compilation", name: "Batteries Key Terms" },
    { ez_id: "batteries_safety_compilation", name: "Batteries Safety" },
    { ez_id: "batteries_mates_compilation", name: "Batteries Mates & Relates" },
    {
      ez_id: "batteries_support_compilation",
      name: "Batteries Product Support"
    },
    {
      ez_id: "batteries_twine_compilation",
      name: "Batteries Value Proposition"
    },
    { ez_id: "batteries_hiw_compilation", name: "Batteries How It Works" } // TODO create
  ],
  filters: [
    { ez_id: "filters_landing_compilation", name: "Filters Landing Page" },
    {
      ez_id: "filters_product_offering_compilation",
      name: "Filters Product Offering"
    },
    { ez_id: "filters_kt_compilation", name: "Filters Key Terms" },
    { ez_id: "filters_mates_compilation", name: "Filters Mates & Relates" },
    { ez_id: "filters_support_compilation", name: "Filters Product Support" },
    { ez_id: "filters_hiw_compilation", name: "Filters How It Works" },
    { ez_id: "filters_twine_compilation", name: "Filters Value Proposition" } //TODO create
  ],
  hydraulics: [
    {
      ez_id: "hydraulics_landing_compilation",
      name: "Hydraulics Landing Page"
    },
    {
      ez_id: "hydraulics_product_offering_compilation",
      name: "Hydraulics Product Offering"
    },
    { ez_id: "hydraulics_hiw_compilation", name: "Hydraulics How It Works" },
    { ez_id: "hydraulics_kt_compilation", name: "Hydraulics Key Terms" },
    { ez_id: "hydraulics_safety_compilation", name: "Hydraulics Safety" },
    {
      ez_id: "hydraulics_mates_compilation",
      name: "Hydraulics Mates & Relates"
    },
    {
      ez_id: "hydraulics_support_compilation",
      name: "Hydraulics Product Support"
    },
    {
      ez_id: "hydraulics_twine_compilation",
      name: "Hydraulics Value Proposition"
    }
  ],
  lubricants: [
    {
      ez_id: "lubricants_landing_compilation",
      name: "Lubricants Landing Page"
    },
    {
      ez_id: "lubricants_product_offering_compilation",
      name: "Lubricants Product Offering"
    },
    { ez_id: "lubricants_kt_compilation", name: "Lubricants Key Terms" },
    {
      ez_id: "lubricants_mates_compilation",
      name: "Lubricants Mates & Relates"
    },
    {
      ez_id: "lubricants_support_compilation",
      name: "Lubricants Product Support"
    },

    {
      ez_id: "lubricants_twine_compilation",
      name: "Lubricants Value Proposition"
    },
    { ez_id: "lubricants_hiw_compilation", name: "Lubricants How It Works" }
  ],
  reman: [
    { ez_id: "reman_landing_compilation", name: "Reman Landing Page" },
    {
      ez_id: "reman_product_offering_compilation",
      name: "Reman Product Offering"
    },
    { ez_id: "reman_kt_compilation", name: "Reman Key Terms" },
    { ez_id: "reman_mates_compilation", name: "Reman Mates & Relates" },
    { ez_id: "reman_support_compilation", name: "Reman Product Support" },
    { ez_id: "reman_twine_compilation", name: "Reman Value Proposition" }, //TODO create
    { ez_id: "reman_how_it_works", name: "Reman How It Works" }
  ],

  undercarriage: [
    {
      ez_id: "undercarriage_landing_compilation",
      name: "Undercarriage Landing Page"
    },
    {
      ez_id: "undercarriage_product_offering_compilation",
      name: "Undercarriage Product Offering"
    },
    { ez_id: "undercarriage_kt_compilation", name: "Undercarriage Key Terms" },
    { ez_id: "undercarriage_mates_compilation", name: "Undercarriage Mates & Relates" },
    {
      ez_id: "undercarriage_support_compilation",
      name: "Undercarriage Product Support"
    },
    {
      ez_id: "undercarriage_twine_compilation",
      name: "Undercarriage Value Proposition"
    },
    {
      ez_id: "undercarriage_hiw_compilation",
      name: "Undercarriage How It Works"
    }
  ],
  "sr-drive-system": [
    {
      ez_id: "srdrive_landing_compilation",
      name: "SR Drive System Landing Page"
    },
    {
      ez_id: "srdrive_product_offering_compilation",
      name: "SR Drive System Product Offering"
    },
    { ez_id: "srdrive_kt_compilation", name: "SR Drive System Key Terms" },
    { ez_id: "srdrive_mates_compilation", name: "SR Drive System Mates & Relates" },
    {
      ez_id: "srdrive_support_compilation",
      name: "SR Drive System Product Support"
    },
    {
      ez_id: "srdrive_hiw_compilation",
      name: "SR Drive System How It Works"
    }
  ],
  "Construction Machine Knowledge": [
    { ez_id: "excavator_compilation_0", name: "Learn About Excavator", url:"machines/learn/excavator?scene=machine&quiz_prompt=excavator_compilation_0&quiz_active=true" },
    { ez_id: "dozer_compilation_0", name: "Learn About Dozer", url:"machines/learn/dozer?scene=machine&quiz_prompt=dozer_compilation_0&quiz_active=true" },
    { ez_id: "haul_compilation_0", name: "Learn About Haul Truck", url:"machines/learn/haul-truck?scene=machine&quiz_prompt=haul_compilation_0&quiz_active=true" },
    { ez_id: "wheel_compilation_0", name: "Learn About Wheel Loader", url:"machines/learn/wheel-loader?scene=machine&quiz_prompt=wheel_compilation_0&quiz_active=true" },
    { ez_id: "top_level_reg", name: "Learn About Construction Machines", url:"construction?&quiz_prompt=top_level_reg&quiz_active=true" }
  ],
  "Forestry Machine Knowledge": [
    { ez_id: "harvester_compilation_0", name: "Learn About Harvester", url:"machines/learn/wheeled-harvester?scene=machine&quiz_prompt=harvester_compilation_0&quiz_active=true" },
    { ez_id: "feller_compilation_0", name: "Learn About Feller Buncher", url:"machines/learn/feller-buncher?scene=machine&quiz_prompt=feller_compilation_0&quiz_active=true" },
    { ez_id: "forwarder_compilation_0", name: "Learn About Forwarder", url:"machines/learn/forwarders?scene=machine&quiz_prompt=forwarder_compilation_0&quiz_active=true" },
    { ez_id: "ex_forest_0", name: "Learn About Forestry Excavator", url:"machines/learn/forestry-excavator?scene=machine&quiz_prompt=ex_forest_0&quiz_active=true" },
    { ez_id: "top_level_forestry_comp", name: "Forestry Commodities", url:"forestry?quiz_prompt=top_level_forestry_comp&quiz_active=true" },
    { ez_id: "top_level_comp", name: "Learn About Forestry Machines", url:"forestry?quiz_prompt=top_level_comp&quiz_active=true" }
  ],
  "Mining Machine Knowledge": [
    { ez_id: "hybrid_wheel_loader_compilation_0", name: "Learn About Hybrid Wheel Loader", url:"machines/learn/hybrid-wheel-loader?scene=machine&quiz_prompt=hybrid_wheel_loader_compilation_0&quiz_active=true" },
    { ez_id: "electric_drive_truck_compilation_0", name: "Learn About Electric Drive Truck", url:"machines/learn/electric-drive-truck?scene=machine&quiz_prompt=electric_drive_truck_compilation_0&quiz_active=true" },
    { ez_id: "shovel_compilation_0", name: "Learn About Electric Rope Shovels", url:"machines/learn/shovel?scene=machine&quiz_prompt=shovel_compilation_0&quiz_active=true" },
    { ez_id: "drill_compilation_0", name: "Learn About Blasthole Drills", url:"machines/learn/drill?scene=machine&quiz_prompt=drill_compilation_0&quiz_active=true" },
    { ez_id: "top_level_mining", name: "Learn About Mining Machines", url:"mining?&quiz_prompt=top_level_mining&quiz_active=true" }
  ]
}
