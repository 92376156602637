/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
//document.documentElement.setAttribute("data-theme", "mydna");

const PartLookupKgp = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="lookup"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1 style={{
                        color:'var(--look-up) !important'
                    }}>KGP Part Lookup</h1>
                    <p style={{"marginTop":"0px"}}>How to lookup a part number in KGP</p>
                </div>
                <a className="platform-link" href="https://portal.bizapp.komatsu" target="_blank">Follow along
            in KGP <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                        <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Go to KGP Document Center" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Select search options" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Select specific machine model" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Select the system" onClick={() =>sliderNavClicked(4)}>4</a></li>
                        <li><a href="#slide5" className={"step-title " + (activeSlide === 5 ? 'active' : '')} data-text="Choose the part group" onClick={() =>sliderNavClicked(5)}>5</a></li>
                        <li><a href="#slide6" className={"step-title " + (activeSlide === 6 ? 'active' : '')} data-text="Locate the part" onClick={() =>sliderNavClicked(6)}>6</a></li>
                        <li><a href="#slide7" className={"step-title " + (activeSlide === 7 ? 'active' : '')} data-text="Capture the part number(s)" onClick={() =>sliderNavClicked(7)}>7</a></li>
                    </ol>
                </nav>

                <div className="flex-row" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1'>
                          <h3>Go to KGP &amp; click Document Center</h3>
                          <hr/>
                          <p>In KGP, click Document center in the top menu bar.</p>
                          <img src={require('../../images/kgp-slide-1.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                          <h3>Select Parts Book, Machine Type &amp; Model</h3>
                          <hr/>
                          <p>On the left side of the screen, click Parts book, then select the machine type in the middle followed by the model on the right</p>
                          <img src={require('../../images/kgp-slide-2.jpg')} alt="step 2"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                          <h3>Select the correct machine model &amp; parts book</h3>
                          <hr/>
                          <p>Find the correct model number in the list on the left and select its check box, then locate
                            and click the
                            correct parts book. You will be redirected to CSS.</p>
                        <p><strong>NOTE:</strong> it will look like nothing is happening, but wait 15 to 30 seconds and
                            the parts book will
                            open.</p>
                          <img src={require('../../images/kgp-slide-3.jpg')} alt="step 3"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                          <h3>Select the system for your part</h3>
                          <hr/>
                          <p>Select the system that contains your part from the list.</p>
                          <img src={require('../../images/kgp-slide-4.jpg')} alt="step 4"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide5' data-hash='slide5'>
                          <h3>Choose the correct part group</h3>
                          <hr/>
                          <p>Select the correct part group for the specific part you are looking for from the new list
                            provided on the right.</p>
                          <img src={require('../../images/kgp-slide-5.jpg')} alt="step 5"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide6' data-hash='slide6'>
                            <h3>Locate the part(s) you need from the list</h3>
                            <hr/>
                            <p>Clicking a part in the list on the right will highlight
                            it in the diagram. This can help you verify that you are making a correct part
                            identification, as well as identify needed Mates &amp; Relates. Also, pay attention to
                            any available notes for the part you need.</p>
                            <img src={require('../../images/kgp-slide-6.jpg')} alt="step 6"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide7' data-hash='slide7'>
                            <h3>Capture your part number(s)</h3>
                            <hr/>
                            <p>Carefully make note of the specific part number(s) you need so you have them
                                available when you check availability and place the order. Double check that you've captured
                                them correctly!</p>
                            <img src={require('../../images/kgp-slide-8.jpg')} alt="step 7"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default PartLookupKgp
