/* eslint-disable */
import Machines from "../components/dashboard/machines.dashboard"
import Construction from "../components/dashboard/construction.dashboard"
import Mining from "../components/dashboard/mining.dashboard"
import Forestry from "../components/dashboard/forestry.dashboard"
import MachineType from "../components/dashboard/machine-type.dashboard"
import MachineSubtypes from "../components/dashboard/machines-subtypes.dashboard"
import MachineLearning from "../components/dashboard/machine.learning.dashboard"
import CommoditiesLearning from "../components/dashboard/commodities.learning.dashboard"
import CommoditiesTechnical from "../components/commodities/commodity-technical.commodities"
import CommoditiesValue from "../components/commodities/commodity-value.commodities"
import UserProfile from "../components/dashboard/user-profile.dashboard"
import Commodities from "../components/dashboard/commodities.landing.dashboard"
import CommodityResources from "../components/tools-resources/main.tools-resources"
// import WordMatchQuiz from '../components/quizes/wordmatch/wordmatch.quiz'
import QuizDesignator from "../components/quizes/designator.quiz"
import CommoditiesSupport from "../components/commodities/commodity-support.commodities"
// import CommodityRelates from '../components/commodities/commodity-relates.commodities'
import CommodityOffering from "../components/commodities/commodity-offering"
import CommodityOfferingNew from "../components/commodities/commodity-offering-new"
import LubricantsHowItWorks from "../components/how-it-works/lubricants.how-it-works"
import FiltersHowItWorks from "../components/how-it-works/filters.how-it-works"
import BatteriesHowItWorks from "../components/how-it-works/batteries.how-it-works"
import HydraulicsHowItWorks from "../components/how-it-works/hydraulics.how-it-works"
import RemanHowItWorks from "../components/how-it-works/reman.how-it-works"
import UndercarriageHowItWorks from "../components/how-it-works/undercarriage.how-it-works"
import SrDriveSystemHowItWorks from "../components/how-it-works/sr-drive-system.how-it-works"
import {
  batteriesImagesProps,
  filtersImagesProps,
  remanImagesProps1,
  remanImagesProps2,
  undercarriageImagesProps1,
  undercarriageImagesProps2,
  lubricantsImagesProps1,
  lubricantsGreaseProps,
  lubricantsOilProps,
  hydraulicsImagesProps,
  undercarriageUCProps,
  srDriveSystemImagesProps,
  commoditySalesTips
} from "./extra-props.config"
import LubricantsCommodityOffering from "../components/commodities/lubricants-commodity-offering"
import LubricantsSizes from "../components/commodities/lubricants-sizes.commodities"
import KeyTerms from "../components/commodities/key-terms.commodities"
import BatteriesSafety from "../components/safety/batteries.safety"
import FiltersSafety from "../components/safety/filters.safety"
import HydraulicsSafety from "../components/safety/hydraulics.safety"
import LubricantsSafety from "../components/safety/lubricants.safety"
import RemanSafety from "../components/safety/reman.safety"
import UndercarriageSafety from "../components/safety/undercarriage.safety"
import SrDriveSystemSafety from "../components/safety/sr-drive-system.safety"
import Mates from "../mates/mates"
import AboutKomatsu from "../screens/about"
import RemanCoreReturn from "../components/reman-core-return"
import MyNotes from "../screens/mynotes"
import Xray from "../screens/xray"
// import WordFindLanding from "../components/dashboard/wordfind-landing.dashboard"
// import WordFind from "../components/wordfind/quiz.wordfind"
import MyDna from "../components/mydna/main.mydna"

export default [
  {
    exact: true,
    path: "/dashboard/machines",
    component: Machines,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/construction",
    component: Construction,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/forestry",
    component: Forestry,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/mining",
    component: Mining,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/machines/:machine",
    component: MachineType,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/:group/machines/:machine",
    component: MachineSubtypes,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/machines/learn/:machine_ez_id",
    component: MachineLearning,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/learn/:commodity_ez_id",
    component: CommoditiesLearning
  },
  {
    exact: false,
    path: "/dashboard/commodities/technical/:commodity_ez_id",
    component: CommoditiesTechnical,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/:commodity_ez_id/key-terms",
    component: KeyTerms,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/batteries/safety",
    component: BatteriesSafety,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/filters/safety",
    component: FiltersSafety,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/hydraulics/safety",
    component: HydraulicsSafety,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/lubricants/safety",
    component: LubricantsSafety,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/reman/safety",
    component: RemanSafety,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/undercarriage/safety",
    component: UndercarriageSafety,
    extraProps: {}
  },
  {
  exact: false,
  path: "/dashboard/commodities/sr-drive-system/safety",
  component: SrDriveSystemSafety,
  extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/my-notes",
    component: MyNotes,
    extraProps: {}
  },

  {
    exact: false,
    path: "/dashboard/commodities/:commodity_ez_id/mates-and-relates",
    component: Mates,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/value/:commodity_ez_id",
    component: CommoditiesValue,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/resources/:commodity_ez_id",
    component: CommodityResources,
    extraProps: {}
  },
  // {
  //   exact: false,
  //   path: '/dashboard/commodities/relates/:commodity_ez_id',
  //   component: CommodityRelates,
  //   extraProps: {}
  // },
  {
    exact: false,
    path: "/dashboard/commodities/support/:commodity_ez_id",
    component: CommoditiesSupport,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/commodities/offering/batteries",
    component: CommodityOffering,
    extraProps: {
      title: "BATTERIES",
      images: batteriesImagesProps,
      sales_tips: commoditySalesTips.batteries
    }
  },
  {
    exact: false,
    path: "/dashboard/commodities-new/offering/:commodity",
    component: CommodityOfferingNew,
    extraProps: {
      sales_tips: commoditySalesTips.filters
    }
  },
  {
    exact: false,
    path: "/dashboard/commodities/offering/filters",
    component: CommodityOffering,
    extraProps: {
      title: "FILTERS",
      images: filtersImagesProps,
      sales_tips: commoditySalesTips.filters,
      subtitle: "AIR",
      secondSubtitle: "FUEL",
      thirdSubtitle: "HYDRAULIC",
      fourthSubtitle: "ENGINE",
      fifthSubtitle: "OTHER"
    }
  },
  {
    exact: false,
    path: "/dashboard/commodities/offering/hydraulics",
    component: CommodityOffering,
    extraProps: {
      title: "HYDRAULICS",
      images: hydraulicsImagesProps
    }
  },
  {
    exact: true,
    path: "/dashboard/commodities/offering/lubricants",
    component: LubricantsCommodityOffering,
    extraProps: {
      title: "LUBRICANTS",
      images: lubricantsImagesProps1,
      sales_tips: commoditySalesTips.lubricants
    }
  },
  {
    exact: true,
    path: "/dashboard/commodities/offering/lubricants/sizes/oil",
    component: LubricantsSizes,
    extraProps: {
      images: lubricantsOilProps,
      title: "Available Oil Package Sizes"
    }
  },
  {
    exact: true,
    path: "/dashboard/commodities/offering/lubricants/sizes/grease",
    component: LubricantsSizes,
    extraProps: {
      images: lubricantsGreaseProps,
      title: "Available Grease Sizes"
    }
  },
  {
    exact: false,
    path: "/dashboard/commodities/offering/reman",
    component: CommodityOffering,
    extraProps: {
      title: "REMAN",
      subtitle: "MINOR REMAN COMPONENTS",
      images: remanImagesProps1,
      secondSubtitle: "MAJOR REMAN COMPONENTS",
      secondImages: remanImagesProps2,
      sales_tips: commoditySalesTips.reman
    }
  },
  {
    exact: false,
    path: "/dashboard/commodities/offering/undercarriage",
    component: CommodityOffering,
    extraProps: {
      title: "UNDERCARRIAGE",
      subtitle: "THE 4 UC FAMILIES",
      uc: undercarriageUCProps,
      images: undercarriageImagesProps1,
      secondSubtitle: "UNDERCARRIAGE COMPONENTS",
      secondImages: undercarriageImagesProps2
    }
  },
  {
    exact: false,
    path: "/dashboard/commodities/offering/sr-drive-system",
    component: CommodityOffering,
    extraProps: {
      title: "SR Drive System",
      images: srDriveSystemImagesProps
    }
  },
  {
    exact: false,
    path: "/dashboard/user/profile",
    component: UserProfile,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/commodities",
    component: Commodities,
    extraProps: {}
  },
 // {
 //   exact: false,
 //   path: "/dashboard/quiz/:ez_id?",
 //   component: WordFind,
 //   extraProps: {}
 // },
  {
    exact: true,
    path: "/dashboard/commodities/lubricants/how-it-works",
    component: LubricantsHowItWorks,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/commodities/filters/how-it-works",
    component: FiltersHowItWorks,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/commodities/batteries/how-it-works",
    component: BatteriesHowItWorks,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/commodities/hydraulics/how-it-works",
    component: HydraulicsHowItWorks,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/commodities/reman/how-it-works",
    component: RemanHowItWorks,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/commodities/undercarriage/how-it-works",
    component: UndercarriageHowItWorks,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/commodities/sr-drive-system/how-it-works",
    component: SrDriveSystemHowItWorks,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/about",
    component: AboutKomatsu,
    extraProps: {}
  },
  {
    exact: true,
    path: "/dashboard/commodities/reman/core-return",
    component: RemanCoreReturn,
    extraProps: {}
  },
  {
    exact: false,
    path: "/dashboard/machines/xray/:machine_ez_id",
    component: Xray,
    extraProps: {}
  }
//  {
//    exact: false,
//    path: "/dashboard/wordfinds",
//    component: WordFindLanding,
//    extraProps: {}
//  }
]
