import React from 'react'
import { Link } from 'react-router-dom'

const CommodityCard = ({ link = '', thumbnail = '', title = '' }) => {
  return (
    <center>
      <div className='commodity-card'>
        <Link to={link}>
          <div
            className='commodity-card-img'
            style={{
              backgroundImage: `url(${thumbnail})`
            }}
          />
          <h4>{title}</h4>
        </Link>
      </div>
    </center>
  )
}

export default CommodityCard
