/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import 'swiper/swiper.min.css'

const ManageReturnedOrders = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="manage-order"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                <h1>How to Return a Part</h1>
                    <p style={{"marginTop":"0px"}}>How to return a part after you've received the order</p>
                </div>
                <a className="platform-link" href="https://na.parts.komatsu/Home/MainPage" target="_blank">Follow along in KPP <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
        <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                        <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Part Return Policy" onClick={() =>sliderNavClicked(1)}>1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Click Part Return Requests" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Choose Correct Return Type" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Enter Part Information" onClick={() =>sliderNavClicked(4)}>4</a></li>
                        <li><a href="#slide5" className={"step-title " + (activeSlide === 5 ? 'active' : '')} data-text="Exception Return Entry" onClick={() =>sliderNavClicked(5)}>5</a></li>
                        <li><a href="#slide6" className={"step-title " + (activeSlide === 6 ? 'active' : '')} data-text="Save and Submit" onClick={() =>sliderNavClicked(6)}>6</a></li>
                        <li><a href="#slide7" className={"step-title " + (activeSlide === 7 ? 'active' : '')} data-text="PRA Inquiry ASN Creation" onClick={() =>sliderNavClicked(7)}>7</a></li>
                    </ol>
                </nav>

                <div className="flex-row" style={{color:"black"}}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1'>
                          <h3>Part Return Policy</h3>
                          <hr/>
                          <p>The best thing you can do is to take a moment to read and get familiar with the part return policy in Section G of this <a href="https://cdn.komatsupartsu.com/RIM-Parts-Ordering-and-Return-Policy.pdf" target="_blank">Parts Ordering and Return Policy Letter</a>. It provides all the detailed information you need to successfully manage part returns for your dealership. In these slides, we'll provide the highlights starting with the critical return time-frames below, but we encourage you to spend some time with the Parts Ordering and Return Policy Letter and refresh your base knowledge from time to time.</p>
                          <img src={require('../../images/part-return-slide-1.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                          <h3>Open Part Return Request submenu</h3>
                          <hr/>
                          <p>Click Part Return Requests in your KPP left-hand menu to open the full part return menu.</p>
                          <img src={require('../../images/part-return-slide-2.jpg')} alt="step 2"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                          <h3>Click the correct part return type</h3>
                          <hr/>
                          <p>Choose Ordered in Error, Shipped in Error or Exeption Return Request. Click 'New Request' in the top right corner. If entering an Ordered or Shipped in Error Return, enter your dealership and branch codes, then click the large 'Add' button at the top of the Detail table.</p>
                          <img src={require('../../images/part-return-slide-3.jpg')} alt="step 3"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                          <h3>Enter Part Information</h3>
                          <hr/>
                          <p>Ordered or Shipped in Error: In the pop up box, enter the invoice number and part number for each part you need to return, enter any remaining needed information once parts are populated in table, then click the 'Add' button at the bottom of the popup.</p>
                          <img src={require('../../images/part-return-slide-4.jpg')} alt="step 4"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide5' data-hash='slide5'>
                          <h3>Exception Return Request</h3>
                          <hr/>
                          <p>For an Exception Return Request, click "New Request" at the top right, enter your DB and branch information , then click the small "Add+" in the center bottom of the Detail table, then enter your part information in the table provided. If you need to enter a large number of parts, you can 'Download Template' (CSV file), enter the parts there, then 'Load File' using the provided button. This can sometimes be a quicker way to enter many different parts for return.</p>
                          <img src={require('../../images/part-return-slide-5.jpg')} alt="step 5"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide6' data-hash='slide6'>
                          <h3>Save Your Return Request</h3>
                          <hr/>
                          <p>All return types: Once you've entered all your parts for return, click the save button at the bottom right of the page. NOTE! Your return has not yet been submitted. You must review your return for accuracy on the next screen then click the 'Submit' at the bottom right of the screen to submit your return claim.</p>
                          <img src={require('../../images/part-return-slide-6.jpg')} alt="step 6"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide7' data-hash='slide7'>
                          <h3>Review Your PRA Inquiry Regularly and Create ASNs and ship within 30 days</h3>
                          <hr/>
                          <p>You must create an ASN and physically ship your parts back within 30 days of claim approval. Review your PRA Inquiry daily or weekly to stay on top of your parts returns management. Part Return claims that are turning yellow, orange and red are getting ever closer to expiring. Once expired, you will no loner be able to return the part. Keep an eye on the dates in the Expiry column and get those parts shipped back!</p>
                          <img src={require('../../images/part-return-slide-7.jpg')} alt="step 7"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default ManageReturnedOrders
