import React from 'react'
import { Row, Col } from 'antd'
import TestYourKnowledge from '../common/icons/test-your-knowledge'
import { commodityHowItWorksLinks } from '../../helpers/quiz.helpers'

import QuizPrompt from '../quizes/prompts/quizprompt.quiz'

const FiltersHowItWorks = () => {
  const setModalToInactive = () => {
    let currentUrlParams = new URLSearchParams(window.location.search)
    currentUrlParams.set('quiz_active', 'false')
    window.location.href =
      window.location.pathname + '?' + currentUrlParams.toString()
  }
  return (
    <div className='page'>
      <div className='d-flex justify-content-center align-items-center px-5 p-2 pt-5'>
        <h1 className='filters-color'>
          <b>Filters</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className='py-5'>
            <center>
              {/* <strong>
            <h1 className={`${commodity_ez_id.toLowerCase()}-color`}>
              <b>{commodity_ez_id.toUpperCase()}</b>
            </h1>
          </strong> */}
              <div
                style={{ fontSize: 26, fontWeight: 600, fontFamily: 'Roboto' }}>
                <b>How it Works</b>
              </div>
            </center>
          </div>
          <div className='d-flex flex-lg-row flex-sm-column mb-4 align-items-center'>
            <img
              src={require('../../assets/images/Filters_HowItWorks.gif')}
              alt=''
            />
            <div className='p-4'>
              <p>
                Filters are used to protect various systems and components on
                Komatsu machines. The primary function of a filter is to remove
                contamination , particles, debris, water and other impurities to
                ensure the proper machine function.
              </p>
            </div>
          </div>
          <div className='d-flex flex-lg-row flex-sm-column mb-4 align-items-center'>
            <div className='p-4'>
              <p>
                Filters are designed to be ‘sacrificial’ parts that are used to
                protect major components. Filters are replaced during regular
                maintenance service intervals. A good filter requires the proper
                balance of quality materials, capacity and functionality.
              </p>
            </div>
            <img
              className='image-contain mb-3'
              height={392}
              width={411}
              src={require('../../assets/images/how-it-works-filters-1.png')}
              alt=''
            />
          </div>

          <div className='d-flex flex-lg-row flex-sm-column align-items-center'>
            <img
              className='image-contain mb-3 '
              height={339}
              width={483}
              src={require('../../assets/images/how-it-works-filters-2.png')}
              alt=''
            />
            <div className='p-4'>
              <p>
                <b>Where to find filters on the machine</b>
              </p>

              <p>
                On a typical Komatsu machine, you will find the fuel filters
                mounted on (or near) the engine. The Pre-Fuel Filter (painted
                white) is found first in the fuel flow path, with a water
                separation bowl (this should be checked and drained daily). The
                Main Fuel Filter (painted black) has a higher micron rating to
                collect smaller particles.
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <TestYourKnowledge link={commodityHowItWorksLinks.filters} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default FiltersHowItWorks
