/* eslint-disable */
import { baseUrl } from './misc.data'

export const machineTypes = [
  {
    ez_id: 'excavator',
    thumbnail: `https://cdn.komatsupartsu.com/new-PC210LC-11_hiRes_xparent.png`,
    name: 'Excavator',
    model_number: '',
    machine_type: 'Excavator',
    sub_type: 'Track',
    commodities: [],
    machine_group:'Construction',
    type:'Track',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'42vw',
    height:'auto'
  },
  {
    ez_id: 'dozer',
    thumbnail: `https://cdn.komatsupartsu.com/Komatsu_Dozer_xparent_hiRes.png`,
    name: 'Dozer',
    model_number: '',
    machine_type: 'Dozer',
    sub_type: 'Track',
    commodities: [],
    machine_group:'Construction',
    type:'Track',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'45vw',
    height:'auto'
  },
  {
    ez_id: 'wheel-loader',
    thumbnail: `https://cdn.komatsupartsu.com/Komatsu_WheelLoader_xparent_Aligned.png`,
    name: 'Wheel Loader',
    model_number: '',
    machine_type: 'Wheel Loader',
    sub_type: 'Wheeled',
    commodities: [],
    machine_group:'Construction',
    type:'Wheeled',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'',
    height:''
  },
  {
    ez_id: 'haul-truck',
    thumbnail: `https://cdn.komatsupartsu.com/new-Haul-Truck_xparent_1200px.png`,
    name: 'Haul Truck',
    model_number: '',
    machine_type: 'Haul Truck',
    sub_type: 'Wheeled',
    commodities: [],
    machine_group:'Construction',
    type:'Wheeled',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'',
    height:''
  },
  {
    ez_id: 'wheeled-harvester',
    thumbnail: `https://cdn.komatsupartsu.com/new-Forestry_Harvester_901XCxparent.png`,
    name: 'Wheeled Harvester',
    model_number: '',
    machine_type: 'Wheeled Harvester',
    sub_type: 'Forestry',
    commodities: [],
    machine_group:'Forestry',
    type:'Wheeled',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'80%',
    height:''
  },
  {
    ez_id: 'feller-buncher',
    thumbnail: `https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png`,
    name: 'Feller Buncher',
    model_number: '',
    machine_type: 'Feller Buncher',
    sub_type: 'Forestry',
    commodities: [],
    machine_group:'Forestry',
    type:'Track',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'75vh',
    height:''
  },
  {
    ez_id: 'forwarders',
    thumbnail: `https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png`,
    name: 'Forwarders',
    model_number: '',
    machine_type: 'Forwarders',
    sub_type: 'Forestry',
    commodities: [],
    machine_group:'Forestry',
    type:'Wheeled',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'',
    height:''
  },
  {
    ez_id: 'forestry-excavator',
    thumbnail: `https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png`,
    name: 'Forestry Excavator',
    model_number: '',
    machine_type: 'Excavator',
    sub_type: 'Forestry',
    commodities: [],
    machine_group:'Forestry',
    type:'Track',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'55vh',
    height:'auto'
  },
  {
    ez_id: 'hybrid-wheel-loader',
    thumbnail: `https://cdn.komatsupartsu.com/mining-hybrid-wheel-loader.png`,
    name: 'Hybrid Wheel Loader',
    model_number: '',
    machine_type: 'Hybrid Wheel Loader',
    sub_type: 'Mining',
    commodities: [],
    machine_group:'Mining',
    type:'Wheeled',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'85%',
    height:''
  },
  {
    ez_id: 'electric-drive-truck',
    thumbnail: `https://cdn.komatsupartsu.com/mining-electric-drive-truck.png`,
    name: 'Electric Drive Truck',
    model_number: '',
    machine_type: 'Feller Buncher',
    sub_type: 'Mining',
    commodities: [],
    machine_group:'Mining',
    type:'Wheeled',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true
  },
  {
    ez_id: 'shovel',
    thumbnail: `https://cdn.komatsupartsu.com/mining-shovel.png`,
    name: 'Electric Rope Shovel',
    model_number: '',
    machine_type: 'Shovel',
    sub_type: 'Mining',
    commodities: [],
    machine_group:'Mining',
    type:'Track',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'80%',
    height:''
  },
  {
    ez_id: 'drill',
    thumbnail: `https://cdn.komatsupartsu.com/Drill_ZR77_GPL9955.png`,
    name: 'Blasthole Drill',
    model_number: '',
    machine_type: 'Drill',
    sub_type: 'Mining',
    commodities: [],
    machine_group:'Mining',
    type:'Track',
    files: [],
    media: [],
    popups: [],
    description: '',
    available:true,
    width:'50%',
    height:''
  }
]
