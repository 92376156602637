import { MsalAuthProvider, LoginType } from "react-aad-msal"
import { SetEnv } from "../helpers/SetEnv.helpers"

const msalConfig = {
  auth: {
    clientId: SetEnv(
      "c734c9e8-a956-4edc-a3a4-a93867e90f16",
      "f7f39693-c98b-4532-9f55-a45c69c88a9c"
    ),
    authority: `https://login.microsoftonline.com/${SetEnv(
      "58be8688-6625-4e52-80d8-c17f3a9ae08a",
      "organizations"
    )}`,
    redirectUri: SetEnv(
      `${window.location.origin}/`,
      "http://localhost:3000/"
    ),
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    forceRefresh: false
  }
}

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html"
}

export const authProvider = new MsalAuthProvider(
  msalConfig,
  {
    scopes: ["openid", "profile", "User.Read"]
  },
  options
)
