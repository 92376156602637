import React from "react"
import { useState } from "react"
import { message, Modal, notification, Button } from "antd"
import SuccessModal from "../other/success.modal"
import { useURL } from "../../hooks/url.hooks"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  IS_QUESTION_CORRECT,
  SUBMIT_QUIZ
} from "../../../store/actions/quiz.actions.js"

const LogicQuiz = ({ quiz, index, totalQuestions }) => {
  const [step, setStep] = useState(0)
  const url = useURL()

  const q = useSelector((state) => state.Quiz)
  const history = useHistory()
  const quiz_active = url.query.get("quiz_active")
  const quiz_prompt = url.query.get("quiz_prompt")
  const quizId = url.query.get("qid")
  const dispatch = useDispatch()

  const nextQuestion = () => {
    if (quiz_active !== null && index + 1 !== totalQuestions) {
      history.push(
        `${window.location.pathname}?quiz_prompt=${quiz_prompt}&sub_quiz=${
          q.sub_quizes[index + 1].ez_id
        }&quiz_active=true`
      )
    } else if (quiz_active !== null && index + 1 === totalQuestions) {
      history.push(`${window.location.pathname}?quiz_active=false`)
    } else {
      history.push(
        index + 1 !== totalQuestions
          ? `/dashboard/quiz/${q.sub_quizes[index + 1].ez_id}?qid=${quizId}`
          : "/dashboard/machines"
      )
    }
  }
  const answer = (i) => {
    if (quiz.correct_answers[step][1] === i) {
      dispatch(IS_QUESTION_CORRECT(true))

      message.success("Correct!")
      setStep(step + 1)
      if (step + 1 === quiz.provided_answers.length) {
        dispatch(SUBMIT_QUIZ(quiz))
        if (index + 1 === totalQuestions) {
          dispatch(SUBMIT_QUIZ(q))
        }
        setTimeout(() => {
          Modal.success({
            className: "quiz-contain",
            content: (
              <SuccessModal
                lastQuestion={index + 1 === totalQuestions}
                onOk={nextQuestion}
              />
            ),

            footer: null,
            icon: null
          })
        }, 3000)
      }
    } else {
      dispatch(IS_QUESTION_CORRECT(false))
      notification.error({
        message: "Incorrect",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: quiz.provided_answers[step].data[2]
            }}
          />
        ),
        duration: 3
      })
    }
  }
  return (
    <div>
      {index + 1}/{totalQuestions}
      <div className="py-3">
        <h5>
          Quiz:
          <strong> Select the best answer.</strong>
        </h5>
      </div>
      <div className="p-2">
        <center>
          <h2 className="k-blue">
            <strong>{quiz.question_prompt[step]}</strong>
          </h2>
        </center>
      </div>
      {quiz.provided_answers.map((question, index) => {
        if (step === index) {
          return (
            <div
              className="d-flex justify-content-center  align-items-center"
              key={index}>
              {question.data[1].length === 0 && (
                <Button
                  onClick={() => {
                    answer(0)
                  }}>
                  Next
                </Button>
              )}
              {question.data[1].length > 0 &&
                question.data
                  .filter((qT, index) => index < 2)
                  .map((qText, i) => {
                    return (
                      <div className="ml-2" key={qText}>
                        <div
                          className="p-5 align-items-center justify-content-center shadow pointer-cursor rounded"
                          style={{ width: 400, minHeight: 250 }}
                          onClick={() => {
                            answer(i)
                          }}>
                          <h5>
                            <i>{qText}</i>
                          </h5>
                        </div>
                      </div>
                    )
                  })}
            </div>
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

export default LogicQuiz
