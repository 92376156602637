import React from "react"
import { useState } from "react"
import { Button, Divider, message, Select, Form, Checkbox  } from "antd"
import axios from "axios"
import { baseUrl, headers } from "../../data/misc.data"
import { roles, industriesOptions, dealership } from "../../data/user-data-options.data"
import { useSelector } from "react-redux"

const UserInfoForm = ({ onComplete = () => {} }) => {
  const token = useSelector((state) => state.User.accessToken)
  const [dealerRequired, setDealerRequired] = useState(true)
  const [form] = Form.useForm();


  const onRoleChange = (value) => {
    form.setFieldsValue({
      role: value,
    });
    if(value === 'Komatsu Corporate'){
      setDealerRequired(false)
      form.setFieldsValue({
        dealership: '',
      });
    }else{
      setDealerRequired(true)
    }
  };

  const onDealershipChange = (value) => {
    form.setFieldsValue({
      dealership: value,
    });
  };

  const onCheckboxChange = (checkedValues) => {
    form.setFieldsValue({
      industry: checkedValues,
    });
  };

  const onFinish = (values) => {
    if(values.industry.length === 0){
      message.warning({content: 'Please select at least one industry'});
      return false;
    } else {
      const industries = {
        construction : false,
        mining: false,
        forestry: false,
      };
      if(values.industry.includes("Construction")){
        industries.construction = true
      } else if(values.industry.includes("Mining")){
        industries.mining = true
      } else if(values.industry.includes("Forestry")){
        industries.forestry = true
      }
      values.industry = industries
      axios.post(`${baseUrl}/v1/user/user-data`, values, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      })
      onComplete()
      message.success({ content: "Thank you" })
    }
  };

  return (
    <div>
      <h3>Complete your profile</h3>
      <Divider />
      <Form form={form}  onFinish={onFinish}>
        <Form.Item name="role" label="User Role" rules={[{ required: true, message: 'Please Select your Role!'  }]} extra="Choose the role that best describes what you do at your company">
          <Select
            placeholder="Choose your role"
            allowClear
            options={roles}
            onChange={onRoleChange}
          >
          </Select>
        </Form.Item>
        <Form.Item name="dealership" label="Dealership" hidden={!dealerRequired} rules={[{ required: dealerRequired }]}>
          <Select
            showSearch
            placeholder="Choose your Dealership"
            allowClear
            options={dealership}
            onChange={onDealershipChange}
          >
          </Select>
        </Form.Item>
        <Form.Item label="What industries do you serve? (check all that apply)" labelCol={{span: 24, offset: 0}} name="industry">
          <Checkbox.Group
            options={industriesOptions}
            onChange={onCheckboxChange}
          />
        </Form.Item>
        <Form.Item >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default UserInfoForm
