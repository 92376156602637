/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

//document.documentElement.setAttribute("data-theme", "mydna");

const PlaceOrder = () => {
  return (
    <>
      <div className="place-order page" data-theme="place-order"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div class="page-title">
                    <h1 style={{color:'var(--place-order) !important'}}>Availability Placing Orders</h1>
                    <p style={{"marginTop":"0px"}}>How to check the availability of the parts you need and place orders with Ripley</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
        <div className="flex-row">
              <a className="portal portal-50 place-order-portal" href="check-part-availability">
                  <p>1. Check Part Availability</p>
                  <p className="note"></p>
              </a>
              <a href="place-an-order-process" className="portal portal-50 place-order-portal">
                  <p>2. Place the Order</p>
                  <p className="note"></p>
              </a>
              <a className="portal portal-50 place-order-portal" href="part-availability-details">
                  <p>Important Part Availability Information</p>
                  <p className="note"></p>
              </a>
          </div>
        </main>
      </div>
    </>
  )
}

export default PlaceOrder
