import { call, put, select } from "redux-saga/effects"
import axios from "axios"

import { SIGNUP_SUCCESS, SIGNUP_FAIL } from "../actions/signup.actions"
import { message } from "antd"

import { addHeaders } from "../../helpers/Saga.helpers"

import { tokenSelector } from "../selectors/selectors"

const SignupAPI = async (data, token) => {
  const result = await axios.post(
    "https://exampleapi.com/api/v1/signup",
    data,
    {
      headers: addHeaders(token)
    }
  )
  return result.data
}

function* SignupSaga(action) {
  message.loading("Signing in...")
  const token = yield select(tokenSelector)
  try {
    const data = yield call(SignupAPI, action.payload, token)
    message.destroy()
    yield put(SIGNUP_SUCCESS(data))
  } catch (error) {
    message.destroy()
    yield put(SIGNUP_FAIL(error.data))
    message.error(error.data)
  }
}
export default SignupSaga
