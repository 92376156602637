/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import { commoditySafetyLinks } from "../../helpers/quiz.helpers"
import { useEffect } from "react"
import { useParrot } from "../hooks/parrot.hooks"

const SrDriveSystemSafety = () => {
  const setModalToInactive = () => {}
  const { setParrot } = useParrot()
  useEffect(() => {
    setParrot({
      dialog:
        "<b>Safety Tip:</b> Know where your branch’s first-aid kits and supplies are kept. Your safety and well being matters.",
      display: "TEACHER",
      mode: 1,
      next_bird_actions: []
    })
  }, [])
  return (
    <div className="page vh-80">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="sr-drive-system-color">
          <b>SR Drive System</b>
        </h1>
      </div>
      <center>
        <h1>
          <b>Safety</b>
        </h1>
      </center>
      <br />
      <br />

      <Row>
        <Col span={4} />
        <Col span={18}>
          <Row>
           <Col span={12}>
            <div className="py-5">
                <center>
                  <img
                    className="image-contain "
                    height={153}
                    width={330}
                    src={`https://cdn.komatsupartsu.com/sr-drive-safety-img-1-electrical-hazards.png`}
                    alt=""
                  />
                </center>
              </div>
            </Col>
            <Col span={12}>
              <div className="py-5">

                  <img
                    className="image-contain "
                    height={187}
                    width={270}
                    src={`https://cdn.komatsupartsu.com/sr-drive-safety-img-1-pinch-points.png`}
                    alt=""
                  />

              </div>
            </Col>
          </Row>
          <Row>

            <Col span={20}>
              <p>
                • Wear appropriate safety gear
                <br />
                • This is a high-voltage system
                <br />
                • There are multiple pinch-points throughout the system. Do not work on this system unless you've been trained by Komatsu to make repairs.
                <br />
                • Use the correct components for the application
                <br />
                • Use the proper installation techniques and tools
                <br />
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      {/*<TestYourKnowledge link={commoditySafetyLinks.hydraulics} />
      <QuizPrompt onExitEarly={setModalToInactive} />*/}
    </div>
  )
}

export default SrDriveSystemSafety
