import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useState } from 'react'

const TestYourKnowledge = ({ link = '/dashboard/quiz' }) => {
  let location = useLocation()

  const [redirect, setRedirect] = useState(false)
  const navigate = () => {
    setRedirect(true)
  }
  return (
    <div id='test-your-knowledge' onClick={navigate}>
      {redirect && <Redirect to={location.pathname + link} />}
      <img
        height={40}
        width={40}
        src={require('../../../assets/images/icons/head-cogs-komatsu-blue@3x.png')}
        alt=''
      />
    </div>
  )
}
export default TestYourKnowledge
