/* eslint-disable */
import React, { useState }  from "react"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import 'swiper/swiper.min.css'

const SalesMatesRelatesTips = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="sales-tips"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1>Mates &amp; Relates Top 10</h1> 
                    <p style={{"marginTop":"0px"}}>Mates &amp; Relates and customer support protips for the top 10 ordered parts</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                    <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Alternator" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Battery" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Cabin Air Filter" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Engine Belt" onClick={() =>sliderNavClicked(4)}>4</a></li>
                        <li><a href="#slide5" className={"step-title " + (activeSlide === 5 ? 'active' : '')} data-text="Engine Oil Filter" onClick={() =>sliderNavClicked(5)}>5</a></li>
                        <li><a href="#slide6" className={"step-title " + (activeSlide === 6 ? 'active' : '')} data-text="Hydraulic Filter" onClick={() =>sliderNavClicked(6)}>6</a></li>
                        <li><a href="#slide7" className={"step-title " + (activeSlide === 7 ? 'active' : '')} data-text="Hydraulic Hose" onClick={() =>sliderNavClicked(7)}>7</a></li>
                        <li><a href="#slide8" className={"step-title " + (activeSlide === 8 ? 'active' : '')} data-text="UC Link Assembly" onClick={() =>sliderNavClicked(8)}>8</a></li>
                        <li><a href="#slide9" className={"step-title " + (activeSlide === 9 ? 'active' : '')} data-text="Starter" onClick={() =>sliderNavClicked(9)}>9</a></li>
                        <li><a href="#slide10" className={"step-title " + (activeSlide === 10 ? 'active' : '')} data-text="Turbocharger" onClick={() =>sliderNavClicked(10)}>10</a></li>
                    </ol>
                </nav>

                <div className="flex-row swiper" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1' style={{color:"black"}}>
                      <img src={require("../../images/alternator-mr-card-front.jpeg")} alt="alternator mates & relates flash card front" />
                      <img src={require("../../images/alternator-mr-card-back.jpeg")} alt="alternator mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                      <img src={require('../../images/battery-mr-card-front.jpeg')} alt="battery mates & relates flash card front" />
                        <img src={require('../../images/battery-mr-card-back.jpeg')} alt="battery mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                      <a className="mates-link" href="/dashboard/commodities/filters/mates-and-relates" target="_blank" style={{marginTop:"20px"}}>View more filters mates &amp; relates <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
                        <img src={require('../../images/cabin-air-filters-mr-card-front.jpeg')} alt="engine belt mates & relates flash card front" />
                        <img src={require('../../images/cabin-air-filters-mr-card-back.jpeg')} alt="engine belt mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                      <img src={require('../../images/engine-belt-mr-card-front.jpeg')} alt="hydraulic hose mates & relates flash card front" />
                        <img src={require('../../images/engine-belt-mr-card-back.jpeg')} alt="hydraulic hose mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide5' data-hash='slide5'>
                      <a className="mates-link" href="/dashboard/commodities/filters/mates-and-relates" target="_blank" style={{marginTop:"20px"}}>View more filters mates &amp; relates <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
                        <img src={require('../../images/engine-oil-filters-mr-card-front.jpeg')} alt="hydraulic hose mates & relates flash card front" />
                        <img src={require('../../images/engine-oil-filters-mr-card-back.jpeg')} alt="hydraulic hose mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide6' data-hash='slide6'>
                      <a className="mates-link" href="/dashboard/commodities/filters/mates-and-relates" target="_blank" style={{marginTop:"20px"}}>View more filters mates &amp; relates <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
                        <img src={require('../../images/hydraulic-filter-mr-card-front.jpeg')} alt="engine belt mates & relates flash card front" />
                        <img src={require('../../images/hydraulic-filter-mr-card-back.jpeg')} alt="engine belt mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide7' data-hash='slide7'>
                      <img src={require('../../images/hydraulic-hose-mr-card-front.jpeg')} alt="hydraulic hose mates & relates flash card front" />
                        <img src={require('../../images/hydraulic-hose-mr-card-back.jpeg')} alt="hydraulic hose mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide8' data-hash='slide8'>
                      <img src={require('../../images/link-assembly-mr-card-front.jpeg')} alt="hydraulic hose mates & relates flash card front" />
                        <img src={require('../../images/link-assembly-mr-card-back.jpeg')} alt="hydraulic hose mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide9' data-hash='slide9'>
                      <img src={require('../../images/starter-mr-card-front.jpeg')} alt="hydraulic hose mates & relates flash card front" />
                        <img src={require('../../images/starter-mr-card-back.jpeg')} alt="hydraulic hose mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide10' data-hash='slide10'>
                      <img src={require('../../images/turbocharger-mr-card-front.jpeg')} alt="hydraulic hose mates & relates flash card front" />
                        <img src={require('../../images/turbocharger-mr-card-back.jpeg')} alt="hydraulic hose mates & relates flash card back" />
                      </SwiperSlide>
                      <SwiperSlide id='slide11' data-hash='slide11'>
                      <img src={require('../../images/water-pump-mr-card-front.jpeg')} alt="hydraulic hose mates & relates flash card front" />
                        <img src={require('../../images/water-pump-mr-card-back.jpeg')} alt="hydraulic hose mates & relates flash card back" />
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default SalesMatesRelatesTips
