/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

//document.documentElement.setAttribute("data-theme", "mydna");

const PartLookup = () => {
  return (
    <>
      <div className="parent-page page" data-theme="lookup"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1 style={{
                        color:'var(--look-up) !important'
                    }}>How to Look up Parts</h1>
                    <p style={{"marginTop":"0px"}}>How to look up and verify correct part numbers</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
            <div className="flex-row">
            <a className="portal portal-50 part-lookup-portal" href="part-lookup-sbom">
                <p>How to look up a part in S-BOM</p>
                <p className="note">When you need to look up a part number for a newer, serialized machine</p>
            </a>
            <a href="part-lookup-kgp" className="portal portal-50 part-lookup-portal">
                <p>How to look up a part in KGP</p>
                <p className="note">When you need to look up a part number for either an older or a newer, serialized machine
                </p>
            </a>
            <a href="part-lookup-protips" className="portal portal-50 part-lookup-portal">
                <p>Part Lookup ProTips</p>
                <p className="note">These are must-reads to become a Parts Counter Pro!
                </p>
            </a>
        </div>
        <div className="divider"></div>
        <div className="flex-row" style={{"marginBottom":"5rem"}}>
            <a href="https://na.parts.komatsu/Home/MainPage" className="portal-small" target="_blank">
                <p>KPP > PAL</p>
                <p className="note">A sometimes helpful, alternate way to look up and view all current information on a part.
                </p>
            </a>
            <a href="https://mykomatsu.komatsu/" className="portal-small" target="_blank">
                <p>My Komatsu</p>
                <p className="note">Customers can use My Komatsu to look up part numbers.</p>
            </a>
        </div>
        </main>
      </div>
    </>
  )
}

export default PartLookup
