import React, { useEffect, useState } from "react"
import { Col, Row, Spin } from "antd"
import LearningMenu from "../components/learning/left-menu.learning"
import { useParams } from "react-router-dom"
import { useMachineScreen } from "../components/hooks/screen.hooks"
import { LoadingOutlined } from "@ant-design/icons"
import { useParrot } from "../components/hooks/parrot.hooks"

/* eslint-disable */
const Xray = () => {
  let { machine_ez_id } = useParams()
  const machineState = useMachineScreen(machine_ez_id)
  const { machine } = machineState
  let [commodityString, setCommodity] = useState("")
  const { setParrot } = useParrot()

  const commodityHover = (commodityString) => {
    setCommodity(commodityString)
  }

  useEffect(() => {
    setParrot({
      dialog:
        "This is a page where you can learn about each commodity that you sell to customers all the time. Hover over each commodity in the menu on the left to see each one inside the machine. Then, start learning about one of the commodities by clicking it.",
      display: "TALKING",
      mode: 1
    })
  }, [])

  return (
    <Spin
      spinning={machineState.loading}
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
      }>
      <div>
        <div style={{ position: "relative", zIndex: 100 }} className="mt-2">
          <center>
            <h1 className="text-dark-blue machine-name capitalize-title">
              <b>{machine.name}</b>
            </h1>
          </center>
        </div>
        <Row itemType="flex" align="middle" className="page">
          <Col lg={6}>
            <LearningMenu
              commodityString={commodityString}
              // onCommodityLeave={commodityLeave}
              onCommodityHover={commodityHover}
            />
          </Col>
          <Col md={24} sm={24} xl={18} lg={18}>
            <div className="machine-learning-xray-container">
              <div className="machine-xray-imgs">
                <div>
                  <div
                    className="machine-learning-xray-img opacity-1"
                    style={{
                      backgroundImage: `url(${machine.xray_images.xray_full})`
                    }}
                  />

                  <div
                    className={`machine-learning-xray-commodity-img ${
                      commodityString.length > 0 ? "opacity-1" : "opacity-0"
                    }`}
                    style={{
                      backgroundImage: `url(${machine.xray_images[commodityString]})`
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

export default Xray
