/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Row, Col, Spin, Modal } from "antd"
import { useParams, useHistory } from "react-router-dom"
import { useMachineScreen } from "../hooks/screen.hooks"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import { useDispatch } from "react-redux"
import { LoadingOutlined } from "@ant-design/icons"

import { useURL } from "../hooks/url.hooks"
import MachineSizes from "../machine/sizes.machine"
import BottomActions from "../learning/bottom-actions.learning"
import PopupLearning from "../learning/popups.learning"
import MachineAction from "../machine/action.machine"
import ForestryPopupLearning from "../learning/forestry-popups.learning"
import { FrostedGlass } from "../common/frosted/glass.frosted"
import LearningMenu from "../learning/left-menu.learning"
import { machineLandingLinks } from "../../helpers/quiz.helpers"
import { useParrot } from "../hooks/parrot.hooks"
import { useSelector } from "react-redux"

const MachineLearning = () => {
  const setModalToInactive = () => {}
  let { machine_ez_id } = useParams()
  let [commodityString, setCommodity] = useState("")
  const { setParrot } = useParrot()
  const { visited_pages } = useSelector((state) => state.UiPersist)
  const history = useHistory()
  const url = useURL()
  const dispatch = useDispatch()

  const sceneType = url.query.get("scene")
  const modal_content = url.query.get("modal_content")
  const machineState = useMachineScreen(machine_ez_id)
  const { machine } = machineState

  const hideModal = () => {
    history.push(`${url.pathname}?&scene=machine`)
    // window.location.href = `${url.pathname}?&scene=machine`
  }

  const spanner = () => {
    if (
      sceneType === "commodities" &&
      machineState.machine.sub_type !== "Forestry"
    ) {
      return 18
    } else if (
      sceneType === "commodities" &&
      machineState.machine.sub_type === "Forestry"
    ) {
      return 12
    } else {
      return 18
    }
  }

  const commodityHover = (commodityString) => {
    setCommodity(commodityString)
  }

  useEffect(() => {
    setParrot({
      dialog:
        "This is a main machine page.  There's one for each machine.   Here you'll learn what each machine does and, by clicking the icons at the bottom, you also learn about the major components and the commodities on each machine.",
      display: "TALKING",
      mode: 1
    })

    // if (
    //   Ui.visited_pages.includes("wheel-loader_top_level") &&
    //   Ui.visited_pages.includes("wheel-loader_top_level") &&
    //   Ui.visited_pages.includes("wheel-loader_top_level")
    // ) {
    // }
  }, [])

  useEffect(() => {
    dispatch({
      type: "MARK_PAGE",
      payload: `${machine_ez_id}_top_level`
    })
    if (
      visited_pages.includes("forestry-excavator_top_level") &&
      visited_pages.includes("forwarders_top_level") &&
      visited_pages.includes("feller-buncher_top_level") &&
      visited_pages.includes("wheeled-harvester_top_level")
    ) {
      setParrot({
        dialog:
          "You have visited all the forestry landing pages! Ready to test your knowledge?",
        display: "TALKING",
        mode: 1,
        next_bird_actions: ["?quiz_prompt=top_level_comp&quiz_active=true"]
      })
    }
    if (
      visited_pages.includes("excavator_top_level") &&
      visited_pages.includes("dozer_top_level") &&
      visited_pages.includes("wheel-loader_top_level") &&
      visited_pages.includes("haul-truck_top_level")
    ) {
      setParrot({
        dialog:
          "You have visited all the machine landing pages! Ready to test your knowledge?",
        display: "TALKING",
        mode: 1,
        next_bird_actions: ["?quiz_prompt=top_level_reg&quiz_active=true"]
      })
    }
  }, [machine_ez_id, visited_pages])

  useEffect(() => {
    if (sceneType !== null) {
      dispatch({
        type: "MARK_PAGE",
        payload: `${machine_ez_id}_${sceneType}`
      })
    }
    if (
      visited_pages.includes("wheeled-harvester_commodities") &&
      visited_pages.includes("feller-buncher_commodities") &&
      visited_pages.includes("forwarders_commodities") &&
      visited_pages.includes("forestry-excavator_commodities")
    ) {
      setParrot({
        dialog:
          "You have visited all the forestry commodity pages! Ready to test your knowledge?",
        display: "TALKING",
        mode: 1,
        next_bird_actions: [
          "?quiz_prompt=top_level_forestry_comp&quiz_active=true&scene=commodities"
        ]
      })
    }
  }, [sceneType, visited_pages])

  useEffect(() => {
    if (sceneType === "machine") {
      setParrot({
        dialog:
          "This page helps you quickly learn all about each Komatsu machine type. Hover over all the dots on a machine to learn about its major components and features, then click the icon at the bottom to see the available machine sizes.There's even a video to see it in action. When you are ready to test your knowledge on that machine,  click the quiz head at the bottom right.",
        display: "TALKING",
        mode: 1
      })
    } else if (
      sceneType === "commodities" &&
      machineState.machine.sub_type !== "Forestry"
    ) {
      setParrot({
        dialog:
          "This is a page where you can learn about each commodity that you sell to customers all the time. Hover over each commodity in the menu on the left to see each one inside the machine. Then, start learning about one of the commodities by clicking it.",
        display: "TALKING",
        mode: 1
      })
    } else if (
      sceneType === "commodities" &&
      machineState.machine.sub_type === "Forestry"
    ) {
      setParrot({
        dialog:
          "This is a page where you can learn about each commodity that you sell to your Forestry customers all the time. Hover over all the dots on the machine to learn about each commodity in Forestry machines. Then, click the quiz head at the bottom right to test your knowledge.",
        display: "GUIDE",
        mode: 1
      })
    }
  }, [sceneType])

  return (
    <Spin
      spinning={machineState.loading}
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
      }>
      {sceneType === "commodities" &&
          machineState.machine.sub_type !== "Forestry" && (
        <div style={{ position: "relative", zIndex: 100 }} className="mt-2">
          <center>
            <h1 className="text-dark-blue machine-name capitalize-title">
              <b>{machine.name}</b>
            </h1>
          </center>
        </div>
      )}
      <Modal
        className={modal_content === "machine_sizes" && "my-modal"}
        title={
          modal_content === "machine_sizes" ? (
            <b>Machine Classes</b>
          ) : (
            <b>See it in Action</b>
          )
        }
        visible={modal_content !== null}
        onOk={hideModal}
        onCancel={hideModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
        okText="Close">
        {modal_content === "machine_sizes" && (
          <MachineSizes machine={machine} />
        )}
        {modal_content === "machine_action" && (
          <MachineAction source={machine.media[0].data} />
        )}
      </Modal>

      <Row
        itemType="flex"
        align="bottom"
        className={`machL px-5 
      ${
        sceneType === "commodities" &&
        machineState.machine.sub_type !== "Forestry" &&
        "page"
      }
      `}>
        {sceneType === null && (
          <Col
            md={24}
            sm={24}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 500
            }}>
            <FrostedGlass>
              <div
                className="color-black"
                dangerouslySetInnerHTML={{
                  __html: machine.description
                }}
              />
            </FrostedGlass>
          </Col>
        )}

        {sceneType === "machine" && <Col span={3} />}
        {sceneType === "commodities" &&
          machineState.machine.sub_type === "Forestry" && <Col span={6} />}
        {sceneType === "commodities" &&
          machineState.machine.sub_type !== "Forestry" && (
            <Col lg={6}>
              <LearningMenu
                commodityString={commodityString}
                // onCommodityLeave={commodityLeave}
                onCommodityHover={commodityHover}
              />
            </Col>
          )}

        <Col md={24} sm={24} xl={spanner()} lg={spanner()}>
          <div className="machine-learning-xray-container">
          
          
            <div
              className={`machine-learning-img ${
                sceneType !== "commodities"
                  ? "opacity-1 mlshow"
                  : "opacity-0 mlhide"
              }`}
              style={
                machine_ez_id !== "drill"? {backgroundImage: `url(${machine.thumbnail})`} : {backgroundImage: `url("https://cdn.komatsupartsu.com/mining-drill-lam-image4.png")`}}
              >
              {sceneType === "machine" && <PopupLearning machine={machine} />}
            </div>
          

            <div
              className={`machine-xray-imgs ${
                sceneType === "commodities"
                  ? "opacity-1 mlshow"
                  : "opacity-0 mlhide"
              }`}
              style={
                machineState.machine.sub_type === "Forestry"
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }
                  : {}
              }>
              {machineState.machine.sub_type !== "Forestry" && (
                <div>
                  <div
                    className="machine-learning-xray-img opacity-1"
                    style={{
                      backgroundImage: `url(${machine.xray_images.xray_full})`
                    }}
                  />

                  <div
                    className={`machine-learning-xray-commodity-img ${
                      commodityString.length > 0 ? "opacity-1" : "opacity-0"
                    }`}
                    style={{
                      backgroundImage: `url(${machine.xray_images[commodityString]})`
                    }}
                  />
                </div>
              )}

              {sceneType === "commodities" &&
                machineState.machine.sub_type === "Forestry" && (
                  <div
                    className={`machine-learning-img ${
                      sceneType === "commodities"
                        ? "opacity-1 mlshow"
                        : "opacity-0 mlhide"
                    }`}
                    style={{
                      backgroundImage: `url(${machine.thumbnail})`,
                      backgroundPosition: "center"
                    }}>
                    <ForestryPopupLearning
                      commodityString={commodityString}
                      machine={machine}
                    />
                  </div>
                )}
            </div>
          </div>
        </Col>

        {sceneType === "commodities" &&
          machineState.machine.sub_type === "Forestry" && (
            <div style={{ width: "100%" }}>
              <center>
                <h1 className="text-dark-blue capitalize-title">
                  <b>{machine.name}</b>
                </h1>
              </center>
            </div>
          )}
      </Row>
      <BottomActions machine={machine} />

      {sceneType === "machine" && (
        <TestYourKnowledge
          link={machineLandingLinks[machine_ez_id.toLowerCase()]}
        />
      )}

      {visited_pages.includes("forwarders_top_level") &&
        visited_pages.includes("forestry-excavator_top_level") &&
        visited_pages.includes("feller-buncher_top_level") &&
        visited_pages.includes("wheeled-harvester_top_level") &&
        sceneType === null &&
        machine.sub_type.toLowerCase() === "forestry" && (
          <TestYourKnowledge link="?quiz_prompt=top_level_comp&quiz_active=true" />
        )}

      {visited_pages.includes("wheeled-harvester_commodities") &&
        visited_pages.includes("feller-buncher_commodities") &&
        visited_pages.includes("forwarders_commodities") &&
        visited_pages.includes("forestry-excavator_commodities") &&
        sceneType === "commodities" &&
        machine.sub_type.toLowerCase() === "forestry" && (
          <TestYourKnowledge link="?quiz_prompt=top_level_forestry_comp&quiz_active=true" />
        )}

      {visited_pages.includes("excavator_top_level") &&
        visited_pages.includes("dozer_top_level") &&
        visited_pages.includes("wheel-loader_top_level") &&
        visited_pages.includes("haul-truck_top_level") &&
        machine.sub_type.toLowerCase() !== "forestry" &&
        sceneType === null && (
          <TestYourKnowledge link="?quiz_prompt=top_level_reg&quiz_active=true" />
        )}

      {visited_pages.includes("hybrid-wheel-loader_top_level") &&
        visited_pages.includes("electric-drive-truck_top_level") &&
        visited_pages.includes("shovel_top_level") &&
        visited_pages.includes("drill_top_level") &&
        machine.sub_type.toLowerCase() !== "forestry" &&
        machine.sub_type.toLowerCase() !== "track" &&
        machine.sub_type.toLowerCase() !== "wheeled" &&
        machine.sub_type.toLowerCase() === "mining" &&
        sceneType === null && (
          <TestYourKnowledge link="?quiz_prompt=top_level_mining&quiz_active=true" />
        )}

      <QuizPrompt onExitEarly={setModalToInactive} />
    </Spin>
  )
}

export default MachineLearning
