/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
//document.documentElement.setAttribute("data-theme", "mydna");

const ProTips = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="pro-tips page" data-theme="pro-tips"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1 style={{
                        color:'var(--pro-tips) !important'
                    }}>ProTips for Parts Counter Reps</h1>
                    <p style={{"marginTop":"0px"}}>Tips and tricks to help you be more efficient</p>
                </div>
                <a className="platform-link" href="https://na.parts.komatsu/Home/MainPage" target="_blank">Follow along in KPP <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                        <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Customize KPP dashboard" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Professionalism &amp; Success" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Use Available Support" onClick={() =>sliderNavClicked(3)}>3</a></li>
                    </ol>
                </nav>

                <div className="flex-row" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1'>
                          <h3>Customize your KPP dashboard</h3>
                          <hr/>
                          <p>You can re-order your menu to fit your workflow at anytime by clicking your name
                            in the top right corner, then click "Re-order Menus". You can then hide/show and drag to
                            re-order.</p>
                          <img src={require('../../images/availability-slide-1.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                          <img src={require('../../images/performance-success.jpeg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                      <h3>Use Komatsu's Customer Service and Technical Support</h3>
                        <hr/>
                        <p>You won't always have or know where to find all the answers. When this happens, reach out to
                            the appropriate customer or technical support lines for the commodity your are working with:
                        </p>
                        <div class="content-box" style={{"padding":"0","justifyContent":"center"}}>
                            <h3>Product Support Contacts</h3>
                            <a class="commodity-link"
                                href="/dashboard/commodities/support/batteries/"
                                target="_blank">
                                <img src={require('../../images/batteries-icon.png')} />
                                Batteries</a>
                            <a class="commodity-link"
                                href="/dashboard/commodities/support/filters/" target="_blank">
                                <img src={require('../../images/filters-icon.png')} />
                                Filters</a>
                            <a class="commodity-link"
                                href="/dashboard/commodities/support/hydraulics/"
                                target="_blank">
                                <img src={require('../../images/hydraulics-icon.png')} />
                                Hydraulics</a>
                            <a class="commodity-link"
                                href="/dashboard/commodities/support/lubricants/"
                                target="_blank">
                                <img src={require('../../images/lubricants-icon.png')} />
                                Lubricants</a>
                            <a class="commodity-link"
                                href="/dashboard/commodities/support/reman/" target="_blank">
                                <img src={require('../../images/reman-icon.png')} />
                                Reman</a>
                            <a class="commodity-link"
                                href="/dashboard/commodities/support/undercarriage/"
                                target="_blank">
                                <img src={require('../../images/undercarriage-icon.png')} />
                                Undercarriage</a>
                            <a class="commodity-link"
                                href="/dashboard/commodities/support/sr-drive-system/"
                                target="_blank">
                                <img src={require('../../images/sr-drive-system-icon.png')} />
                                SR Drive System</a>
                        </div>
                        <p class="content-credit"
                            style={{"width":"100%","textAlign":"right","fontSize":"12px","fontStyle":"italic"}}>Thanks to Christine
                            Sideikas at Linder for this great ProTip!</p>
                      </SwiperSlide>
                      
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default ProTips
