import React, { useState } from "react"
import { Form, Row, Col, Radio, Button, Modal, message, Badge } from "antd"

import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useURL } from "../../hooks/url.hooks"

import SuccessModal from "../other/success.modal"
import {
  IS_QUESTION_CORRECT,
  SUBMIT_QUIZ
} from "../../../store/actions/quiz.actions"

const QuestionQuiz = ({ quiz, index, totalQuestions, multiple = false }) => {
  const history = useHistory()
  const q = useSelector((state) => state.Quiz)
  const [state, setState] = useState({})
  const url = useURL()
  const quiz_active = url.query.get("quiz_active")
  const quiz_prompt = url.query.get("quiz_prompt")
  const dispatch = useDispatch()

  const quizId = url.query.get("qid")
  const nextQuestion = () => {
    if (quiz_active !== null && index + 1 !== totalQuestions) {
      history.push(
        `${window.location.pathname}?quiz_prompt=${quiz_prompt}&sub_quiz=${
          q.sub_quizes[index + 1].ez_id
        }&quiz_active=true`
      )
    } else if (quiz_active !== null && index + 1 === totalQuestions) {
      history.push(`${window.location.pathname}?quiz_active=false`)
    } else {
      history.push(
        index + 1 !== totalQuestions
          ? `/dashboard/quiz/${q.sub_quizes[index + 1].ez_id}?qid=${quizId}`
          : "/dashboard/machines"
      )
    }
  }
  const onFinish = (values) => {
    if (!multiple) {
      if (quiz.correct_answers[0][0] === values["radio-group"]) {
        dispatch(IS_QUESTION_CORRECT(true))
        dispatch(SUBMIT_QUIZ(quiz))
        if (index + 1 === totalQuestions) {
          dispatch(SUBMIT_QUIZ(q))
        }
        Modal.success({
          className: "quiz-contain",
          content: (
            <SuccessModal
              lastQuestion={index + 1 === totalQuestions}
              onOk={nextQuestion}
            />
          ),
          onOk: nextQuestion,
          okText: index + 1 === totalQuestions ? "Exit" : "Next Question",
          icon: null
        })
      } else message.error("Incorrect, please try again")
    } else {
      let correct = true
      for (let i = 0; i < Object.values(state).length; i++) {
        const field = Object.values(state)[i]

        if (
          !quiz.correct_answers.includes(field) ||
          quiz.correct_answers.length !== Object.values(state).length
        ) {
          correct = false
          break
        }
      }
      if (correct) {
        dispatch(IS_QUESTION_CORRECT(true))
        dispatch(SUBMIT_QUIZ(quiz))
        if (index + 1 === totalQuestions) {
          dispatch(SUBMIT_QUIZ(q))
        }
        Modal.success({
          className: "quiz-contain",
          content: (
            <SuccessModal
              lastQuestion={index + 1 === totalQuestions}
              onOk={nextQuestion}
            />
          ),
          onOk: nextQuestion,
          okText: index + 1 === totalQuestions ? "Exit" : "Next Question",
          icon: null
        })
      } else {
        setState({})
        message.error("Incorrect, please try again")
      }
    }
  }
  const onChange = (e, index) => {
    setState({
      ...state,
      [index]: e.target.value
    })
  }
  return (
    <div>
      {index + 1}/{totalQuestions}
      <div className="py-3">
        <h5>
          Quiz: <strong>{quiz.question_prompt}</strong>
        </h5>
        {multiple && (
          <Badge
            status="success"
            text={<b style={{ color: "green" }}>Choose all below that apply</b>}
          />
        )}
      </div>
      <div className="my-4">
        <Form
          name="validate_other"
          // {...formItemLayout}
          onFinish={onFinish}>
          {!multiple && (
            <Form.Item name="radio-group">
              <Radio.Group style={{ width: "100%" }}>
                <Row>
                  {quiz.provided_answers.map((answer) => {
                    return (
                      <Col key={answer.keyIndex} span={12}>
                        <div className="p-4 shadow rounded-lg m-4">
                          <Radio value={answer.keyIndex} />
                          <h4>{answer.data}</h4>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </Radio.Group>
            </Form.Item>
          )}
          {multiple && (
            <Row>
              {quiz.provided_answers.map((answer, index) => {
                return (
                  <Col key={answer.keyIndex} span={12}>
                    <Radio.Group
                      style={{ width: "100%" }}
                      defaultValue={null}
                      onChange={(e) => {
                        onChange(e, index)
                      }}
                      value={state[index]}>
                      <div className="p-4 shadow rounded-lg m-4">
                        <Radio
                          onClick={() => {
                            if (typeof state[index] !== answer.keyIndex) {
                              setState({
                                ...state,
                                [index]: false
                              })
                            }
                          }}
                          value={answer.keyIndex}
                        />
                        <h4>{answer.data}</h4>
                      </div>
                    </Radio.Group>
                  </Col>
                )
              })}
            </Row>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
export default QuestionQuiz
