/* eslint-disable */
import React from "react"
import { Row, Col, Divider } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import { commodityHowItWorksLinks } from "../../helpers/quiz.helpers"

import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import EyePopup from "../popups/eye-popups"

const UndercarriageHowItWorks = () => {
  const setModalToInactive = () => {}
  return (
    <div className="page">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="undercarriage-color">
          <b>Undercarriage</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="py-5">
            <center>
              <div
                style={{ fontSize: 26, fontWeight: 600, fontFamily: "Roboto" }}>
                <b>How it Works</b>
              </div>
            </center>
          </div>

          <Divider />

          <p>
            The Undercarriage is the mechanism/system used to provide the
            machine flotation and traction that allows for pushing and carrying
            material. It also allows the machine to move material in demanding
            environments.
          </p>
          <p style={{ fontSize: 26 }}>
            <b>Undercarriage Structure</b>
          </p>
          <center>
            <div style={{ position: "relative", height: 338, width: 538 }}>
              <EyePopup
                type="uc"
                className="undercarriage-border"
                left={-2}
                top={6}
                content={
                  <div style={{ fontSize: 12 }}>
                    The Track Shoe, or also just known as a “Shoe” is designed
                    to provide traction for the machine. Without traction, the
                    machine would not be able to propel itself or efficiently
                    move large loads of rock, dirt, coal, etc. The Shoe also
                    provides much needed floatation over soft ground so the
                    heavy dozer can continue to push heavy loads without sinking
                    into the ground.
                  </div>
                }
                contentSide="left"
              />
              <EyePopup
                type="uc"
                className="undercarriage-border"
                left={35}
                top={3}
                content={
                  <div style={{ fontSize: 12 }}>
                    An idler wheel is used to provide the proper tension on the
                    Track Link Assembly and also will absorb a large amount of
                    shock that the machine will experience when it's moving
                    forward over uneven ground.
                  </div>
                }
                contentSide="left"
              />
              <EyePopup
                type="uc"
                className="undercarriage-border"
                left={62}
                top={8}
                content={
                  <div style={{ fontSize: 12 }}>
                    A Carrier Roller is used to hold the Track Link Assembly up
                    in the air between the Sprocket and the Idler. Without the
                    use of a Carrier Roller, the track would sag unnecessarily
                    and possibly rub on dirt, rock or debris which would cause
                    accelerated wear of the Track Link Assembly.
                  </div>
                }
                contentSide="left"
              />
              <EyePopup
                type="uc"
                className="undercarriage-border"
                left={75}
                top={73}
                content={
                  <div style={{ fontSize: 12 }}>
                    The main purpose of the sprocket, also known as a “Chain”,
                    is to engage with the Track Link Assembly and move the
                    machine forward or backwards. A sprocket on a dozer or
                    excavator is very much like a sprocket on a bicycle, and the
                    overall condition of this component must be closely
                    monitored. When the sprocket is excessively worn it can
                    break or slip and the ability to move the machine in a
                    consistent manner will be negatively affected.
                  </div>
                }
                contentSide="right"
              />

              <EyePopup
                type="uc"
                className="undercarriage-border"
                left={55}
                top={88}
                content={
                  <div style={{ fontSize: 12 }}>
                    A Track Roller, or Bottom Roller, is used to help push the
                    Track Link Assembly and Track Shoes into the ground. Without
                    Track Rollers, the center of the Track Link Assembly can
                    lift itself up and the result of that would be loss of
                    traction. With the loss of traction, there will be a
                    significant loss of overall productivity and excessive track
                    slippage would most likely occur.
                  </div>
                }
                contentSide="right"
              />
              <EyePopup
                type="uc"
                className="undercarriage-border"
                left={8}
                top={88}
                content={
                  <div style={{ fontSize: 12 }}>
                    The Track Link Assembly, also know as a TLA or Chain, is the
                    backbone of an Undercarriage. The TLA provides connection of
                    all of the Undercarriage Components. It acts much like a
                    moveable train track that holds the shoes in place for
                    traction, the propulsion device between the transmission and
                    sprocket. All components of the Undercarriage interact with
                    the TLA to put all the machine horsepower and traction to
                    the ground so the machine can move its payload in a fast and
                    efficient manner
                  </div>
                }
                contentSide="right"
              />
              <img
                className="image-contain"
                height={338}
                width={538}
                src={require("../../assets/images/how-it-works-undercarriage-1.png")}
                alt="undercarriage how it works image"
              />
            </div>
          </center>

          <p style={{ fontSize: 26 }}>
            <b>Undercarrige Structure Detailed View</b>
          </p>
          <center>
            <img
              className="image-contain"
              height={338}
              width={538}
              src={require("../../assets/images/how-it-works-undercarriage-2.png")}
              alt="structured detail of undercarriage"
            />
          </center>
          <Divider />
          <div className="d-flex flex-row align-items-center">
            <div>
              <p style={{ fontSize: 26 }}>
                <b>Flotation</b>
              </p>
              <p>
                Flotation is created by spreading the weight of the machine over
                a large surface area:
              </p>
            </div>
            <center>
              <img
                className="image-contain ml-4"
                height={110}
                width={296}
                src={require("../../assets/images/how-it-works-undercarriage-3.png")}
                alt="illustration of undercarriage flotation"
              />
            </center>
          </div>
          <Divider />
          <div className="d-flex flex-row align-items-center">
            <div>
              <p style={{ fontSize: 26 }}>
                <b>Traction</b>
              </p>
              <p>
                The Track Shoe grousers interlock with the underfoot material to
                provide traction and decrease track slippage.
              </p>
            </div>
            <center>
              <img
                className="image-contain ml-4"
                height={110}
                width={296}
                src={require("../../assets/images/how-it-works-undercarriage-4.png")}
                alt="gif needed"
              />
            </center>
          </div>
        </Col>
      </Row>
      <TestYourKnowledge link={commodityHowItWorksLinks.undercarriage} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default UndercarriageHowItWorks
