/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

//document.documentElement.setAttribute("data-theme", "mydna");

const LearningStyles = () => {
  return (
    <>
      <div className="myDnaPage parts-managers page" data-theme="parts-managers"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header">
                <div className="page-title">
                    <h1>Learning Styles</h1>
                    <p >How to deliver new information so it's understood and remembered</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
            <div className="content-box" style={{"paddingTop":"20px"}}>
                <ul className="breadcrumb">
                    <li><a href="parts-managers">Just for Managers</a></li>
                    <li><a href="adult-learning">How Adults Learn</a></li>
                    <li>Learning Styles</li>
                </ul>
                <p>Everyone processes information differently and belongs primarily to one of three learning
                    styles. Knowing the learning styles of your PCRs can help you tailor their training to fit the way they
                    take in information best, helping them get up to speed even faster.</p>
                <p>The three learning styles are:</p>
                <section className="flex-row learning-styles-descriptions" style={{width:"100%"}}>
                    <div className="flex-item-30 flex-column">
                        <img src={require('../../images/visual-icon@2x.png')} alt="visual learner icon" width="250"/>
                        <p>Visual learners learn best by seeing how it's done. Show them.</p>
                    </div>
                    <div className="flex-item-30 flex-column">
                        <img src={require('../../images/auditory-icon@2x.png')} alt="auditory learner icon" width="250"/>
                        <p>Auditory learners learn best by hearing how it's done. Tell them.</p>
                    </div>
                    <div className="flex-item-30 flex-column">
                        <img src={require('../../images/kinisthetic-icon@2x.png')} alt="kinisthetic learner icon" width="250"/>
                        <p>Kinisthetic learning learn best by doing it themselves. Let them do it with you.</p>
                    </div>
                </section>
                <h4>Watch the short video below to learn more:</h4>
                <div style={{"padding":"56.25% 0 0 0","position":"relative"}}><iframe
                        src="https://player.vimeo.com/video/804420026?h=f8553fdcbc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        style={{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"}}
                        title="Learning Styles Introduction"></iframe></div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        </main>
      </div>
    </>
  )
}

export default LearningStyles
