/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

const ManageAnOrder = () => {
  return (
    <>
      <div className="parent-page page" data-theme="manage-order"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1 style={{
                        color:'var(--look-up) !important'
                    }}>Managing Orders</h1>
                    <p style={{"marginTop":"0px"}}>How to monitor and manage the orders you’ve placed</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
            <div className="flex-row">
                <a className="portal portal-50 manage-order-portal" href="manage-an-order-process">
                    <p>Order Status &amp; Tracking</p>
                    <p className="note">When you need to see if an order has been filled or shipped</p>
                </a>
                <a href="manage-suspended-orders" className="portal portal-50 manage-order-portal">
                    <p>Suspended Orders</p>
                    <p className="note">How to view and manage existing orders that have been suspended</p>
                </a>
                <a href="manage-cancelled-orders" className="portal portal-50 manage-order-portal">
                    <p>Cancel an Order</p>
                    <p className="note">When you need to cancel an order after it's already been placed</p>
                </a>
                <a href="manage-returned-orders" className="portal portal-50 manage-order-portal">
                    <p>Return a Part</p>
                    <p className="note">If you’re responsible for managing part returns at your dealership</p>
                </a>
            </div>
        </main>
      </div>
    </>
  )
}

export default ManageAnOrder
