import React, { useState, useEffect } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { Row, Col, Modal } from "antd"

import { useSelector, useDispatch } from "react-redux"
import { quizSelector } from "../../../store/selectors/selectors"
import { useHistory } from "react-router-dom"
import Drop from "../../dnd/drop.dnd"
import { reorder } from "../../../helpers/Utils.helpers"
import SuccessModal from "../other/success.modal"
import { useURL } from "../../hooks/url.hooks"
import {
  IS_QUESTION_CORRECT,
  SUBMIT_QUIZ
} from "../../../store/actions/quiz.actions"

const WordMatchQuiz = ({ ez_id, index, totalQuestions }) => {
  const history = useHistory()
  const quiz = useSelector(quizSelector(ez_id))
  const q = useSelector((state) => state.Quiz)
  const url = useURL()
  const quiz_active = url.query.get("quiz_active")
  const quiz_prompt = url.query.get("quiz_prompt")
  const quizId = url.query.get("qid")
  const dispatch = useDispatch()

  const initialstate = {
    provided_answers: quiz.provided_answers.filter(
      (item) => item.type !== "image"
    ),
    proposed_answers: [],
    provided_2_answers: quiz.provided_answers.filter(
      (item) => item.type !== "word"
    )
  }

  const nextQuestion = () => {
    if (quiz_active !== null && index + 1 !== totalQuestions) {
      history.push(
        `${window.location.pathname}?quiz_prompt=${quiz_prompt}&sub_quiz=${
          q.sub_quizes[index + 1].ez_id
        }&quiz_active=true`
      )
    } else if (quiz_active !== null && index + 1 === totalQuestions) {
      history.push(`${window.location.pathname}?quiz_active=false`)
    } else {
      history.push(
        index + 1 !== totalQuestions
          ? `/dashboard/quiz/${q.sub_quizes[index + 1].ez_id}?qid=${quizId}`
          : "/dashboard/machines"
      )
    }
  }

  const [state, setState] = useState(initialstate)

  const [isCorrect, setIsCorrect] = useState(false)

  const moveItem = (source, destination) => {
    if (source.droppableId !== destination.droppableId) {
      if (
        destination.droppableId === "proposed_answers" &&
        state.proposed_answers.length === 2
      ) {
        // setState({
        // 	...state,
        // 	[destination.droppableId]: state[destination.droppableId].filter(
        // 		(item) => item.type !== state[source.droppableId][source.index].type
        // 	),
        // })
      } else {
        // TRAVERSES LISTS
        setState({
          ...state,
          [source.droppableId]: state[source.droppableId].filter(
            (item, index) => index !== source.index
          ),
          [destination.droppableId]: state[destination.droppableId].concat(
            state[source.droppableId][source.index]
          )
        })
      }
    } else {
      // REORDERS CURRENT LIST
      setState({
        ...state,
        [source.droppableId]: reorder(
          state[source.droppableId],
          source.index,
          destination.index
        )
      })
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    moveItem(result.source, result.destination)
  }

  const checkAnswer = () => {
    let correctAnswer = false
    const possible = state.proposed_answers
      .map((item) => item.keyIndex)
      .sort()
      .join(",")

    quiz.correct_answers.forEach((correct) => {
      if (correct.sort().join(",") === possible) {
        correctAnswer = true
        return
      }
    })
    if (correctAnswer) {
      setIsCorrect(true)
      setTimeout(() => {
        setState({
          ...state,
          provided_answers: state.provided_answers.concat(
            state.proposed_answers.filter((item) => item.type === "word")[0]
          ),
          proposed_answers: []
        })
      }, 1500)
    } else {
      setIsCorrect(false)
      setTimeout(() => {
        setState({
          ...state,
          proposed_answers: [],
          provided_answers: state.provided_answers.concat(
            ...state.proposed_answers.filter((item) => item.type === "word")
          ),
          provided_2_answers: state.provided_2_answers.concat(
            ...state.proposed_answers.filter(
              (item) => item.type === "image" || item.type === "sentence"
            )
          )
        })
      }, 1500)
    }
  }

  const onAnswer = () => {
    if (state.proposed_answers.length === 2) {
      checkAnswer()
    }

    if (
      state.proposed_answers.length === 0 &&
      // state.provided_2_answers.length === 0 &&
      state.provided_2_answers.length === 0
    ) {
      dispatch(IS_QUESTION_CORRECT(true))
      dispatch(SUBMIT_QUIZ(quiz))
      if (index + 1 === totalQuestions) {
        dispatch(SUBMIT_QUIZ(q))
      }
      Modal.success({
        className: "quiz-contain",
        content: (
          <SuccessModal
            lastQuestion={index + 1 === totalQuestions}
            onOk={nextQuestion}
          />
        ),
        icon: null
      })
    }
  }

  useEffect(onAnswer, [state.proposed_answers])

  return (
    <div>
      {index + 1}/{totalQuestions}
      <div className="py-3">
        <h5>
          Quiz:{" "}
          <strong>
            Drag a machine into the middle box along with its matching word from
            the box on the left. Continue until you've successfully matched each
            machine with it's purpose.
          </strong>
        </h5>
      </div>
      <div className="my-4">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Row gutter={32} justify="space-around" align="middle">
            <Col span={8}>
              <Drop
                items={state.provided_answers}
                droppableId="provided_answers"
              />
            </Col>
            <Col span={8}>
              <Drop
                isCorrect={isCorrect}
                items={state.proposed_answers}
                droppableId="proposed_answers"
              />
            </Col>
            <Col span={8}>
              <Drop
                items={state.provided_2_answers}
                droppableId="provided_2_answers"
              />
            </Col>
          </Row>
        </DragDropContext>
      </div>
    </div>
  )
}

export default WordMatchQuiz
