import React from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import Lottie from "react-lottie"
import Correct from "../../assets/lotties/correct.json"
import Incorrect from "../../assets/lotties/incorrect.json"
import Fade from "react-reveal/Fade"

const lottieOptions = {
  loop: true,
  autoplay: true,

  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}
const Drop = ({
  items,
  droppableId,
  isCorrect,
  type = "multi",
  style = {},
  mode = "",
  itemStyle = {}
}) => {
  const reduceItem = (item, provided) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={`wordmatch-item d-flex align-items-center justify-content-center ${
          mode === "hotspot" ? "hotspot-style" : ""
        }`}
        style={{
          ...provided.draggableProps.style,
          userSelect: "none",
          padding: 0,
          margin: "0"

          // backgroundColor: 'white',
        }}>
        {item.type === "image" && (
          <div>
            <center>
              <div
                style={{ backgroundImage: `url(${item.data})` }}
                className="wordmatch-quiz-image"
              />
            </center>
            {provided.placeholder}
          </div>
        )}
        {item.type === "word" && (
          <center>
            <div className="wordmatch-quiz-text">
              <h4 style={itemStyle}>{item.data.toUpperCase()}</h4>
            </div>
            {provided.placeholder}
          </center>
        )}

        {item.type === "image_answer" && (
          <div>
            <center>
              <div
                style={{
                  backgroundImage: `url(${item.data[1]})`,
                  height: 50,
                  width: 50
                }}
                className="wordmatch-quiz-image"
              />
              <h4>{item.data[0]}</h4>
            </center>
            {provided.placeholder}
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {droppableId === "proposed_answers" && isCorrect && items.length === 2 && (
        <Fade bottom>
          <center>Correct!</center>
          <Lottie
            options={{ ...lottieOptions, animationData: Correct }}
            height={60}
            width={60}
            isStopped={false}
            isPaused={false}
          />
        </Fade>
      )}

      <Droppable type="group" droppableId={droppableId}>
        {(provided_) => (
          <div
            ref={provided_.innerRef}
            {...provided_.droppableProps}
            className={`shadow rounded-lg ${
              type === "multi" ? "wordmatch-droppable" : ""
            } p-4`}
            style={style}>
            {items.map((item, index) => (
              <Draggable
                key={item.keyIndex.toString()}
                draggableId={item.keyIndex.toString()}
                index={index}>
                {(provided) => reduceItem(item, provided)}
              </Draggable>
            ))}
            {provided_.placeholder}
          </div>
        )}
      </Droppable>

      {droppableId === "proposed_answers" && !isCorrect && items.length === 2 && (
        <Fade top>
          <Lottie
            options={{ ...lottieOptions, animationData: Incorrect }}
            height={60}
            width={60}
            isStopped={false}
            isPaused={false}
          />
          <center>Incorrect...</center>
        </Fade>
      )}
    </>
  )
}
export default Drop
