/* eslint-disable */
// import "bootstrap/dist/css/bootstrap.min.css";
import { isMobile } from "react-device-detect"
import React from "react"
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom"
import { PersistGate } from "redux-persist/es/integration/react"
import ProtectedRoute from "./components/routes/Protected.components.routes"
import PublicRoute from "./components/routes/Public.components.routes"
import "./App.css"
import routes from "./data/routes.data"
import { Provider } from "react-redux"
import configureStore from "./store/configurations/configureStore-main"
import * as Sentry from "@sentry/react"
import { AzureAD, AuthenticationState } from "react-aad-msal"
import { LoadingOutlined } from "@ant-design/icons"
import { authProvider } from "./configs/msal.config"

import "bootstrap-css-only"

const { persistor, store } = configureStore()

const RenderRoute = (route) => {
  if (route.protected) {
    return <ProtectedRoute {...route} />
  } else {
    return <PublicRoute {...route} />
  }
}

const App = () => {
  if (isMobile) {
    return (
      <div
        className="flex-column d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}>
        Mobile Coming Soon...
        <br />
        <br />
        Please access on a desktop or larger screen
      </div>
    )
  } else {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occurred"} showDialog>
        <Provider store={store}>
          <PersistGate loading={<h1>loading</h1>} persistor={persistor}>
            <AzureAD provider={authProvider} forceLogin={true}>
              {({ login, authenticationState, error }) => {
                if (!error) {
                  switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                      return (
                        <Router>
                          <Switch>
                            {/* <Redirect exact from="/" to="/myDna" /> */}
                            <Redirect
                              exact
                              from="/dashboard"
                              to="/dashboard/machines"
                            />
                            
                            {routes.map(RenderRoute)}
                          </Switch>
                        </Router>
                      )
                    case AuthenticationState.Unauthenticated:
                      return (
                        <div className="page d-flex flex-column justify-content-center align-items-center">
                          <p>
                            <a
                              onClick={login}
                              className="login-button button--microsoft"
                              style={{ color: "white" }}>
                              Login With Microsoft
                            </a>
                          </p>
                        </div>
                      )
                    case AuthenticationState.InProgress:
                      return (
                        <div className="d-flex flex-column justify-content-center align-items-center page">
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "blue" }}
                            spin
                          />
                          <p style={{ color: "blue" }}>Signing In...</p>
                        </div>
                      )
                  }
                } else {
                  Sentry.captureException(error)
                  return (
                    <div
                      className="d-flex justify-content-center align-items-center "
                      style={{ height: "100vh" }}>
                      <center>
                        <div className="page d-flex flex-column justify-content-center align-items-center">
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "blue" }}
                            spin
                          />
                          <p style={{ color: "blue" }}>Signing In...</p>
                          <p>
                            Taking too long? You can try to sign in again below
                          </p>
                          <p>
                            <a
                              onClick={login}
                              className="login-button button--microsoft"
                              style={{ color: "white" }}>
                              Login With Microsoft
                            </a>
                          </p>
                        </div>
                      </center>
                    </div>
                  )
                }
              }}
            </AzureAD>
          </PersistGate>
        </Provider>
      </Sentry.ErrorBoundary>
    )
  }
}

export default App
