/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import QuizPrompt from "../quizes/prompts/quizprompt.quiz"

const Forestry = () => {
  const setModalToInactive = () => {}
  return (
    <>
      <div className="page " style={{ paddingTop: "18vh" }}>
        <div
          style={{
            position: "absolute",
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <div>
              <h1 style={{ fontWeight: "900", fontSize: "3.5rem" }}>
                <b id="tut-1" style={{ color: "white" }}>
                  Forestry
                </b>
              </h1>
            </div>
          </center>
        </div>
        <Row className="flex-wrap">
        <Col
            id="tut-3"
            className="machine-col mb-sm-5 mb-xs-5 position-relative"
            align="bottom"
            sm={24}
            md={24}
            lg={12}
            xl={12}>
            <Link
              className="link d-flex flex-column justify-content-center align-items-center machine"
              to="/dashboard/forestry/machines/wheeled">
              <img
                style={{
                  height:"30vw",
                  width: "auto"
                }}
                src={`https://cdn.komatsupartsu.com/new-Forestry_Harvester_901XCxparent.png`}
                alt=""
              />
            </Link>
            <h2 className="text-dark-blue machine-name machine-landing-titles">
              <b>Wheeled Machines</b>
            </h2>
          </Col>
          <Col
            id="tut-2"
            className="machine-col mb-sm-5 mb-xs-5 "
            align="bottom"
            sm={24}
            md={24}
            lg={12}
            xl={12}>
            <Link
              className="link d-flex flex-column justify-content-center align-items-center machine"
              to="/dashboard/forestry/machines/track">
              <img
              style={{
                height:"30vw",
                width: "auto"
              }}
                src={`https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png`}
                alt=""
              />
            </Link>
            <h2 className="text-dark-blue machine-name machine-landing-titles">
              <b>Track Machines</b>
            </h2>
          </Col>
        </Row>
      </div>
      <QuizPrompt onExitEarly={setModalToInactive} />
    </>
  )
}

export default Forestry
