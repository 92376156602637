import { useState, useEffect } from "react"
import { message } from "antd"
import { useSelector } from "react-redux"
import { baseUrl } from "../../data/misc.data"
import { APIResponseParser } from "../../helpers/APIResponseParser.helpers"
import { addHeaders } from "../../helpers/Saga.helpers"
import axios from "axios"
import { tokenSelector } from "../../store/selectors/selectors"

export const useNotes = () => {
  const token = useSelector(tokenSelector)
  const [notes, setNotes] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchNotes = async () => {
    setLoading(true)
    try {
      const result = await axios.get(`${baseUrl}/v1/user/notes`, {
        headers: addHeaders(token)
      })
      const response = APIResponseParser(result)
      setNotes(response)
    } catch (error) {
      // console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const createNote = async (body) => {
    setLoading(true)
    message.loading("Creating note...")
    try {
      const result = await axios.post(`${baseUrl}/v1/user/notes`, body, {
        headers: addHeaders(token)
      })
      APIResponseParser(result)
      window.location.reload()
    } catch (error) {
      message.error("Please try again later....")
    } finally {
      setLoading(false)
    }
  }

  const deleteNote = async (noteId) => {
    setLoading(true)
    message.loading("Deleting note...")

    try {
      const result = await axios.delete(`${baseUrl}/v1/user/notes/${noteId}`, {
        headers: addHeaders(token)
      })
      APIResponseParser(result)
      window.location.reload()
    } catch (error) {
      message.error("Please try again later....")
    } finally {
      setLoading(false)
    }
  }

  const initialize = () => {
    fetchNotes()
  }

  useEffect(initialize, [])
  return {
    notes,
    setNotes,
    createNote,
    deleteNote,
    // updateNote,
    loading
  }
}
