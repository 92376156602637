import React, { useEffect, useState } from "react"
import { Layout } from "antd"

import HeaderNav from "../components/common/nav/header.nav"
import Footer from "../components/common/nav/footer.nav"
// import { useAuthentication } from '../components/hooks/authentication.hooks'
// import { useCredentials } from '../components/hooks/user.hooks'
import UserProgress from "../components/common/nav/user-progress.nav"
import GraphicalParallax from "../components/common/parallax/graphical.parallax"

import { useURL } from "../components/hooks/url.hooks"

import { Link, useLocation } from "react-router-dom"

import { useMachineScreen } from "../components/hooks/screen.hooks"
import SiderNav from "../components/common/nav/sider.nav"
//import Tutorial from "../components/hocs/tutorial.hoc"

const { Content, Header } = Layout

const DashboardLayout = ({ children }) => {
  const pathnames = ['/dashboard/my-notes', '/dashboard/about','/dashboard/user/profile','dashboard/commodities/learn/','/dashboard/commodities/'];
  let location = useLocation()
  const machineState = useMachineScreen("", false)
  const url = useURL()
  const [state, setState] = useState({ size: 1000 })
  const resize = () => {
    setState({ size: window.innerWidth })
  }
  const sceneType = url.query.get("scene")

  const onMount = () => {
    window.addEventListener("resize", resize)
    resize()
    return () => {
      window.removeEventListener("resize", resize)
    }
  }

  useEffect(onMount, [])

  if (state.size > 800) {
    return (
        <Layout style={{ overflowX: "hidden" }}>
          {location.pathname.includes("/dashboard/machines/learn") &&
            sceneType !== "commodities" && (
              <GraphicalParallax subtype={machineState.machine.sub_type} />
            )}
          {location.pathname === "/dashboard/machines" && <GraphicalParallax />}
          {location.pathname === "/dashboard/construction" && <GraphicalParallax />}
          {location.pathname === "/dashboard/mining" && <GraphicalParallax />}
          {location.pathname === "/dashboard/forestry" && (
            <GraphicalParallax subtype="Forestry" />
          )}


          {location.pathname === "/dashboard/machines/track" && (
            <GraphicalParallax subtype="Track" />
          )}
          {location.pathname === "/dashboard/machines/wheeled" && (
            <GraphicalParallax subtype="Wheeled" />
          )}
          {location.pathname === "/dashboard/mining/machines/wheeled" && (
            <GraphicalParallax subtype="Wheeled" />
          )}
          {location.pathname === "/dashboard/mining/machines/track" && (
            <GraphicalParallax subtype="Wheeled" />
          )}
          {location.pathname === "/dashboard/machines/forestry" && (
            <GraphicalParallax subtype="Forestry" />
          )}
          {location.pathname === "/dashboard/forestry/machines/wheeled" && (
            <GraphicalParallax subtype="Forestry" />
          )}
          {location.pathname === "/dashboard/forestry/machines/track" && (
            <GraphicalParallax subtype="Forestry" />
          )}


          {sceneType === "commodities" &&
            machineState.machine.sub_type === "Forestry" && (
              <GraphicalParallax subtype="Forestry" />
            )}
          {location.pathname.includes("/dashboard/machines/xray") && (
            <div className="yellow-banner" />
          )}
          {location.pathname === "/dashboard/commodities" && (
            <div className="k-blue-banner" />
          )}

          {/* {location.pathname === '/dashboard/machines/forestry' && (
          <GraphicalParallax subtype='Forestry' />
        )} */}
          {state.size > 775 && (
            <Header
              className={`k-menu-white ${
                (location.pathname.includes("/dashboard/commodities/learn") ||
                  location.pathname.includes("/how-it-works") ||
                  location.pathname.includes(
                    "/dashboard/commodities/technical"
                  ) ||
                  location.pathname.includes(
                    "/dashboard/commodities/offering"
                  ) ||
//                  location.pathname.includes("/wordfinds") ||
                  location.pathname.includes("/quiz") ||
                  location.pathname.includes("/key-terms") ||
                  location.pathname.includes("/safety") ||
                  location.pathname.includes("/mates-and-relates") ||
                  location.pathname.includes("/support") ||
                  location.pathname.includes("/resources") ||
                  location.pathname.includes("/dashboard/user/profile") ||
                  location.pathname.includes("/value") ||
                  location.pathname.includes("/dashboard/about") ||
                  location.pathname.includes("/dashboard/about") ||
                  location.pathname.includes(
                    "/dashboard/commodities/reman/core-return"
                  ) ||
                  location.pathname.includes("/dashboard/my-notes")) &&
                "k-menu-com"
              }`}>
              <div className="float-left">
                <Link to="/">
                  <img
                    style={{ width: 150 }}
                    src={
                      pathnames.some(v => location.pathname.includes(v))
                        ? require("../assets/images/KOMATSU_LOGO__blue_2021.png")
                        : require("../assets/images/KOMATSU_LOGO_White_2021.png")
                    }
                    alt=""
                  />
                </Link>
              </div>
              <HeaderNav />
              <UserProgress />
            </Header>
          )}

          <Layout>
            {state.size < 775 && <SiderNav />}
            <Content>
              <div>{children}</div>
            </Content>
          </Layout>
          <Footer />
        </Layout>
    )
  } else {
    return (
      <div
        className="flex-column d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}>
        <h2>For the best experience, this learning site is intended for use on
        laptop screens or larger.</h2>
      </div>
    )
  }
}

export default DashboardLayout
