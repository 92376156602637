/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { useEffect } from "react"
import { useParrot } from "../hooks/parrot.hooks"

const UndercarriageSafety = () => {
  const { setParrot } = useParrot()
  useEffect(() => {
    setParrot({
      dialog:
        "<b>Safety Tip:</b>  Perform daily pre-start forklift equipment inspections to check for controls and equipment damage. When driving a fork lift, be attentive and drive slowly. Make sure dock edges are clear and safe to support loads. Keep yourself and your teammates safe.",
      display: "TEACHER",
      mode: 1
    })
  }, [])
  return (
    <div className="page undercarriage-terms-bg vh-80">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="undercarriage-color">
          <b>Undercarriage</b>
        </h1>
      </div>
      <center>
        <h1>
          <b>Safety</b>
        </h1>
      </center>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <p>
            When inspecting an Undercarriage, watch for sharp debris that may be
            lodged in items such as the Bottom Rollers. The debris can be sharp
            and could cause hand injuries.
            <br />
            <br />
            When adjusting the track tension, use caution. The grease used to
            expand or contract the Idler is under pressure and could cause a
            hydraulic skin injection.
            <br />
            <br />
            When checking the pitch wear, use extreme caution. When inserting a
            steel pin into the rear sprocket, there is a potential pinch point
            between the sprocket, track link, steel pin, and your hand.
            <br />
            <br />
            Ensure the machine is locked out and tagged out before beginning any
            inspection. When inspecting an Undercarriage, watch for sharp debris
            that may be lodged in items such as the Bottom Rollers. The debris
            can be sharp and could cause hand injuries.
            <br />
            <br />
            When adjusting the track tension, use caution. The grease used to
            expand or contract the Idler is under pressure and could cause a
            hydraulic skin injection.
            <br />
            <br />
            When checking the pitch wear, use extreme caution. When inserting a
            steel pin into the rear sprocket, there is a potential pinch point
            between the sprocket, track link, steel pin, and your hand.
            <br />
            <br />
            Ensure the machine is locked out and tagged out before beginning any
            inspection.
          </p>
        </Col>
      </Row>

      {/* <TestYourKnowledge link={commoditySafetyLinks.undercarriage} />
      <QuizPrompt onExitEarly={setModalToInactive} /> */}
    </div>
  )
}

export default UndercarriageSafety
