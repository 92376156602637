/* eslint-disable */
import { baseUrl } from "./misc.data"

export const Searchable = [
  {
    href: "/dashboard/commodities/learn/batteries",
    title: "Batteries",
    aka: "",
    thumbnail:
      "https://komatsupartsu.com/v1/images/public/Batteries_lightduty_xparent.png",
    name: "Batteries",
    ez_id: "batteries",
    definition:
      "The Battery is the Heart of the Electrical System. A device made of one or multiple cells, through which chemical energy is converted into electrical energy which is stored and used to power a circuit and start the equipment.",
    terms: [
      [
        "Acid",
        "In the lead acid storage battery industry, ''acid'' implies ''sulfuric acid'', and is used to describe the electrolyte or liquid in the cell."
      ],
      [
        "Active Materials",
        "The materials in a battery which react chemically to produce electrical energy. In a lead-acid battery the active materials are lead dioxide (positive) and sponge lead (negative)."
      ],
      [
        "Ampere-Hour",
        "A measure of the volume of electricity, being one ampere for one hour, or 3600 coulombs. It is used to express battery capacity, and is registered by an ampere hour meter, or is obtained by multiplying the current in amperes by the length of time that the current is maintained."
      ],
      [
        "Battery (Storage)",
        " A storage battery is a connected group of two or more storage cells (common usage permits this term to be applied to a single cell used independently). Batteries are sometimes referred to as ''Accumulators'' since electric energy is accumulated by chemical reaction."
      ],
      [
        "Cold Crank Rating",
        "The cold crank rating refers to the number of amperes a lead-acid battery at 0°F (-17.8°C) can deliver for 30 seconds and while maintaining at least 7.2 volts (1.2 volts per cell). This is commonly referred to as CCA (Cold Cranking Amps)."
      ],
      [
        "Discharged",
        "The condition of a storage cell when as the result of delivering current, the plates are sulfated, the electrolyte is exhausted, and there is little or no potential difference between the terminals."
      ],
      [
        "Electrolyte",
        'Any substance which disassociates into two or more ions when dissolved in water. A solution of electrolyte conducts electricity and is decomposed by it. In the battery industry the word "electrolyte" implies a dilute solution of sulfuric acid.'
      ],
      [
        "Grid",
        "A metallic framework employed in a storage cell or battery for conducting the electric current and supporting the active material."
      ],
      [
        "Jumper",
        "A short length of conductor used to connect or cut out part of an electrical circuit."
      ],
      [
        "Lead (Pb)",
        "Chemical element used in lead-acid batteries (with sulfuric acid and other materials)."
      ],
      [
        "Maintenance-Free Battery",
        "Battery which requires no addition of water, no boost charges, etc. This typically requires a non-antimonial or low-antimonial grid alloy, sealed cell design, or low-loss venting."
      ],
      [
        "Negative Terminal",
        "The terminal toward which current flows (as ordinarily conceived) in the external circuit from the positive terminal."
      ],
      [
        "Polarity",
        "An electrical condition determining the direction in which current tends to flow. By common usage the discharge current is said to flow from the positive electrode through the external circuit."
      ],
      [
        "Rated Capacity",
        "The ampere hours of discharge that can be removed from a fully charged secondary cell or battery, at a specific constant discharge rate at a specified discharge temperature and at a specified cut off voltage."
      ],
      [
        "Soda Ash",
        "Sodium Carbonate (Na2C03), used to neutralize effluents containing sulfuric acid, or acid spills."
      ],
      [
        "Sulfuric Acid (H2S04)",
        "The principal acid compound of sulfur. Sulfuric acid of a high purity and in dilute form is the electrolyte of lead-acid storage cells."
      ],
      [
        "Terminals",
        "The terminals of a battery are the points at which the external circuit is connected."
      ],
      [
        "Volt",
        "The practical unit of measurement of electro-motive force or potential difference required to send a current of one ampere through a resistance of one ohm."
      ],
      [
        "Voltage",
        "The difference of potential which exists between the terminals of a cell or battery, or any two points of an electrical circuit."
      ],
      ["Voltmeter", "An instrument for measuring voltage."]
    ],
    safety_prompt: "Here is a safety prompt for batteries",
    value_proposition: [
      "Made in the U.S specificially for heavy equipment under strict quality controls, Komatsu Batteries are designed to deliver high performance under tough operating conditions as well as withstand the vibration and extreme temperatures of major jobsites. Komatsu Batteries are readily available via a wide distribution network with easily accessible product support and core pick-up services. Choosing Komatsu Batteries saves our customers time and money by reducing the possibility of unexpected downtime, while supporting environmentally friendly procedures (i.e. core return program).",
      "Tools that help you be proactive for your customers like cheat sheets, parts application lists and Komtrax easily position you to provide top-notch support for you customer while being assured you are selling them a battery that will perform well and increase their up-time resulting in higher customer satisfaction and return business."
    ],
    tools_and_resources: [
      [
        ["Quick Reference Guide", "", ""],
        [
          "Batteries Flash Card",
          "A quick-reference sales tool for Parts Counter with information on Mates & Relates, Pro-tips and more",
          ""
        ],
        ["Safety & Maintenance Tips Poster", "", ""],
        [
          "Tips on Stacking and Wrapping Batteries",
          "Tips on how to wrap new and used batteries on pallets",
          ""
        ],
        ["Flyers", "", ""],
        ["Countermats", "", ""],
        ["Display Racks", "", ""],
        ["Counter / Floor Stands", "", ""]
      ],
      [
        [
          "PMNs / PPLs with Program Information",
          "",
          "https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/PMN-BatteriesElectrical.aspx"
        ],
        [
          "Core Price Information Per Part Number",
          "",
          "https://globalkomatsu.sharepoint.com/sites/JP00GR01115/Pages/PMN-BatteriesElectrical.aspx"
        ]
      ]
    ],
    help_contacts: [
      {
        name: "Test Person",
        phone: "111-111-1111",
        email: "test@ilumalearning.com"
      }
    ],
    warranty:
      "Standard Parts Warranty  1 year/unlimited hours   Supplier's Support  18-36 months depending upon the part #.",
    questions_to_ask:
      "1. What is the model & serial number of your machine?   2. Have you inspected & tested all the other batteries in the vehicle?   3. Have you tested and verified the correct operation of the vehicle charging system?   4. Will you be needing any battery cables or clamps or charging/testing tools?   5. Did you know that Komatsu offers credits for every battery core you return? (regardless of battery type and brand)",
    product_offering:
      "Heavy Duty 6V/12V  Used for Heavy Equipment  Light Duty 6V/12V  Used for golf carts and trucks",
    sales_tips: ""
  },
  {
    href: "/dashboard/commodities/learn/lubricants",
    title: "Lubricants",
    aka: "",
    thumbnail: "https://komatsupartsu.com/v1/images/public/new-lubricants.png",
    name: "Lubricants",
    ez_id: "lubricants",
    definition:
      "Oil/Grease: The primary purpose of a lubricant is to reduce friction and protect components. Providing: lubrication, power (energy transfer), cooling, sealing and contamination removal. ",
    terms: [
      [
        "API",
        "American Petroleum Institute—the primary trade association for lubricants"
      ],
      ["CK4", "Latest API engine oil specification for Tier 4 Engines"],
      ["Conventional", "Standard mineral oil"],
      ["KGO", "Komatsu Genuine Oil"],
      [
        "KOWA",
        "Komatsu Oil Wear Analysis—a proactive maintenance program created for the purpose of analyzing machine fluids."
      ],
      [
        "Low Ash",
        "A cleaner oil with less emissions (low sulfer) that is better for the machine and the environment"
      ],
      ["Lubricants", "Oil and grease"],
      [
        "Multi-viscosity",
        "Specialty Oil that is formulated to change thickness as the system temperature changes  At cold start the oil will operate at the lower viscosity level (thinner oil). As the system heats up, the properties of the oil will change to operate at a higher viscosity level (thicker oil)."
      ],
      [
        "SDS",
        "Safety Data Sheets (formerly MSDS-material safety data sheets)- different versions exist for each sales terrirotry (US/Canada)."
      ],
      ["Synthetic", "Molecularly engineered oil"],
      ["Viscosity", "Measure of oil thickness"]
    ],
    safety_prompt: "Here is a safety prompt for lubricants",
    value_proposition: [
      " Komatsu Genuine Lubricants and Coolant are uniquely formulated and tested to exceed industry standards for long component life, machine reliability and optimal performance.  Use Komatsu genuine parts and service for reduced downtown and lower operating costs.",
      "Komatsu Genuine Lubricants and Coolant are uniquely formulated and tested to exceed industry standards for long component life, machine reliability and optimal performance. Technical resources and sales tools are available to help identify the proper lubricants for each application. "
    ],
    tools_and_resources: [
      [
        [
          "Quick Reference Guide",
          "Find lubricants and coolant as well as refill capacity for components on your specific machine model",
          "Parts Marketing Home"
        ],
        [
          "Product Data Sheets",
          "Introduction to Fuel Filters—Komatsu Genuine Filters and All-Makes",
          "Parts Marketing Home"
        ],
        [
          "Counter Mat",
          "Highlights the features and benefits of Komatsu Genuine Oil",
          "Parts Marketing Home"
        ],
        [
          "Safety Data Sheets (SDS)",
          "Provides safety and handling instructions for lubricants and coolants",
          ""
        ],
        [
          "Lubricant Part Number Identification",
          "Provides a breakdown of Lubricants, package size and part number",
          ""
        ],
        [
          "Komatsu Genuine Oil Komatsu Ltd. Video",
          "This video contains information about our current oil, coolant and grease product offering along with the primary functions of the lubricants.",
          ""
        ]
      ],
      [
        [
          "Order Process - PPL",
          "Ordering process for bulk vs. package products",
          ""
        ],
        ["Program Overview - PMN", "Program and product offering", ""]
      ]
    ],
    help_contacts: [
      {
        name: "Test Person",
        phone: "111-111-1111",
        email: "test@ilumalearning.com"
      }
    ],
    warranty:
      "Komatsu publishes the approved brand and type of lubricants in the Operation and Maintenance Manual (OMM). The proper lubricants must be used to qualify for  warranty coverage, Komatsu CARE and Genuine CARE support programs.   OMM lists specific oil types, capacity & service intervals (See Quick Reference Guide for the most popular Komatsu machines)",
    questions_to_ask:
      " 1. What is the machine type?  2. What is the machine model number?  3. What is the machine serial number?  4. How many hours are on the machine (SMR)?  5. What system (engine, axle, hydraulic, powertrain, etc.)?  6. What package size is preferred?  7. What temperature/application is the machine operating in?  8. What oil is currently in the machine?  9. When was the oil last replaced?",
    product_offering:
      "Engine Oil  Engine Oil, also known as motor oil or engine lubricant, it used to help reduce friction and wear of the moving parts of the engine as well as clean it.  Hydraulic Oil  Hydraulic oil is a non-compressible fluid that is not only a lubricant but also is used to transfer power within hydraulic machinery and equipment.  Power Train/Transmission Oil  Transmission oil is more refined and light to maintain the heat and high pressure within the system.  Axle Oil  Axle oil is used in axles with built-in brake and limited slip differential (LSD) to protect the brakes from high temperatures, wear and tear and reduces brake noise.  Grease  While grease has a similar use as a lubricant for mechanical parts, its consistency tends to be more semi-solid to solid to hold longer and seal better than conventional oil. ",
    sales_tips: ""
  },
  {
    href: "/dashboard/commodities/learn/hydraulics",
    title: "Hydraulics",
    aka: "",
    thumbnail:
      "https://komatsupartsu.com/v1/images/public/new-Hydraulics_Main_Image.png",
    name: "Hydraulics",
    ez_id: "hydraulics",
    definition:
      "Hydraulics = Fluid Power    Heavy equipment utilizes a hydraulic system for power and motion control, for a range of applications- from operating the work equipment to making the joysticks in the cab easier to control by the operator.    The heart of the hydraulics system is a pump. The hydraulic pump creates a continuous flow of hydraulic fluid (a type of oil) in a closed loop. Movement of the hydraulic fluid in a confined space creates pressure. The pressure creates a multiplication of force which results in a powerful and efficient transfer of energy-which means a perfect option for digging and moving dirt or other materials.    Additionally, hydraulics is a reusable and cost effective form of power (the oil simply moves between components and is retained within the circuit).",
    terms: [
      [
        "Adapter",
        "Adapters-are used to make connections between fittings and other components. Adapters are used when a different connection type is needed or a different angle or orientation."
      ],
      [
        "Cavitation",
        "Occurs when air enters the hydraulic system. Small pockets of air form in the hydraulic oil, and when pressurized can implode causing damage to metal surfaces/other components."
      ],
      [
        "Cylinder",
        "Cylinders are linear actuators (that extend and retract) used in the hydraulic circuit to perform work."
      ],
      [
        "Fitting",
        "Connectors that get attached/crimped to hoses (also known as hose end/coupling/hose shell)."
      ],
      [
        "Flow",
        "The volume of a substance that flows through a defined surface area over a specified period of time"
      ],
      [
        "Hose",
        "Hoses are flexible lines (high strength reinforced rubber hose) used for fluid conveyance (transfer) between components."
      ],
      [
        "Hose Assembly",
        "A finished part that consists of a hose with fittings attached on each end."
      ],
      [
        "Pressure",
        "Critical rating of hydraulic hose (strength), measured in pounds per square inch (PSI) or Mega Pascal (MPa)"
      ],
      [
        "Pump and Motors",
        "A pump converts mechanical energy into hydraulic energy/ a motor does the opposite-converts hydraulic energy into mechanical energy."
      ],
      [
        "Tube",
        "Rigid steel lines used for fluid conveyance (transfer) between components"
      ],
      [
        "Valves",
        "Valves help direct and control where the hydraulic oil is going through the hydraulic system."
      ]
    ],
    safety_prompt: "Here is a safety prompt for hydraulics",
    value_proposition: [
      "Komatsu hydraulics are specifically engineered and tested to work as a complete system, providing long component life for machine reliability and optimal performance.  Use Komatsu genuine parts and service for reduced downtown and lower operating costs.",
      "Komatsu hydraulics are specifically engineered and tested to work as a complete system, providing long component life for machine reliability and optimal performance. Technical resources and sales tools are available for customer support. "
    ],
    tools_and_resources: [
      [
        [
          "Flash Card - Hose",
          "A quick-reference sales tool for Parts Counter with information on Mates & Relates, Pro-tips and more",
          ""
        ],
        [
          "Fitting ID Chart",
          "Quick reference fitting/adapter identification buck-slip (half page)",
          ""
        ],
        [
          "PMN Bulk Hose and Fittings",
          "Program overview for bulk hose and fittings program",
          ""
        ],
        [
          "Fab Guide for Bulk Hose and Fittings",
          "A sales tool that shows the spare parts that make up a Komatsu OEM hose assembly",
          "Parts Marketing Home > Parts Sales Material > Hydraulics "
        ]
      ],
      [
        [
          "SABA Hydraulics Training",
          "Online traning on Hydraulics provided by Komatsu",
          ""
        ]
      ]
    ],
    help_contacts: [
      {
        name: "Test Person",
        phone: "111-111-1111",
        email: "test@ilumalearning.com"
      }
    ],
    warranty:
      "Standard Parts Warranty  1 year/unlimited hours    Service life for critical hoses (work equipment/hoses on exterior of the machine)  2 years or 4,000 hours.",
    questions_to_ask:
      "1. What is the machine type?2. What is the machine model Number?3. What is the machine serial number?4. How many hours are on the machine (SMR)?5. Where was the part on the machine?6. Are you familiar with hydraulic safety?7. Do you have the proper PPE (personal protective equipment)?8. Did you know that you should NEVER search for leaks with your hand?",
    product_offering:
      "Pumps  Converts mechanical energy into hydraulic energy and creates flow.ValvesControls how the system operate: pressure, flow and directional controlMotorsRotary or linear actuators used to perform workCylindersLinear Actuator: rold and barrel—extends and retracts Hydraulic OilThe media under pressure in the hydraulic system HosesSupply and return lines (fully built hose assemblies are listed in the machine parts book and are available through KPP)SealsPrevents leaks (AKA: o-rings, gaskets)FiltersProtects the hydraulic system from contamination ReservoirTank that stores hydraulic oil",
    sales_tips: ""
  },
  {
    href: "/dashboard/commodities/learn/reman",
    title: "Reman",
    aka: {
      TURBOCHARGER: "Turbo. On Tier 4 Vehicles: VGT (Variable Geometry Turbo)",
      INJECTOR: "Nozzle",
      "ROTATING ELECTRICAL": "Alternators & Starters",
      ALTERNATOR: "Small Electricals",
      STARTER: "Small Electricals",
      CONTROLLER:
        "ECM (Engine Control Module) or TCM (Transmission Control Module)",
      TRANSMISSION: "Trans or T/M",
      DIFFERENTIALS: "Diff",
      SUSPENSION: "Front or Rear Suspension (strut in the car industry)",
      "FINAL DRIVES": "Finals",
      HST: "Hydro-Static Traction",
      MONITOR: "Panel (dashboard in cab)"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/Reman-Main-Composite.png",
    name: "Reman",
    ez_id: "reman",
    definition:
      "Our commitment to reducing the owning and operating (O&O) costs of a Komatsu machine by delivering high-quality and competitively priced remanufactured products.  When we reman, we completely disassemble, clean, inspect and restore to factory specifications.",
    terms: [
      [
        "Core",
        "The Core is the worn component to be returned for reman process (Essential Raw Material)."
      ],
      [
        "Installation Guidelines",
        "Instructions on how to properly install and extend the life of a component"
      ],
      [
        "Major Components",
        "Engine, Transmission, Torque Flow, Torque Converter, Piston Pump & Motor, Axles, Final Drives, and Differentials. “Majors”:  These Components cost represents the Major portion of Customer’s Machine Owning & Operating Cost. Also referred to as “Majors”."
      ],
      [
        "Minor Components",
        "Engine Auxiliaries (Water Pump, Turbocharger, Starters, Alternators, Etc.) “Minors”:  These are small repairs and normal wear-&-tear items.  These components are replaced at smaller hour-intervals. Also referred to as “Minors”."
      ],
      [
        "O&O",
        "Owning and Operating cost of a machine (Price of machine + repair & maintenance cost of machine). Also referred to as TCO."
      ],
      [
        "Reclass",
        "Reclass is when a NEW part is taken from inventory and relabeled as Reman."
      ],
      [
        "Remanufacture",
        "To completely disassemble, clean, inspect and restore to factory specifications."
      ],
      [
        "TCO",
        "Total cost of ownership (Price of machine + Running cost of machine). Also referred to as O&O."
      ],
      ["SMR", "Service Meter Reading (Total hours on a machine)."]
    ],
    safety_prompt: "Here is a safety prompt for Reman",
    value_proposition: [
      "Your customers work hard and have to be efficient in a competitive marketplace.  To stay ahead of the competition, they need to have a partner they can count on to keep them moving dirt.   With the Komatsu genuine reman products, they can have the same quality they are accustomed to from their original Komatsu equipment. With off-the-shelf availability, and the ability to plan ahead with firm future orders, you can assure them that Reman will maximize their uptime.Lowest Owning & Operating Cost · Durability · Uptime · Reliability · Warranty & Quality Assurance",
      "Komatsu Reman components with Core Return gives you the ability to offer your customers unsurpassed quality, value and support for these components, increasing customer confidence and trust in your dealership. Taking advantage of Komatsu's Reman support, sales and technical materials will help you become an expert at saving your customers money while increasing their uptime and loyalty to your dealership. "
    ],
    tools_and_resources: [
      [
        ["Reman Price Rule of Thumb", "", ""],
        ["Counter Mat", "", ""]
      ],
      [
        [
          "NPS/KPP - PAL (Parts Application List)",
          "",
          "https://na.parts.komatsu/Home/MainPage"
        ],
        [
          "NPS/KPP - Reman/Change Button",
          "",
          "https://na.parts.komatsu/Home/MainPage"
        ],
        [
          "NPS/KPP Parts Marketing Home: Installation Guidelines,",
          "",
          "https://na.parts.komatsu/Home/MainPage"
        ],
        [
          "NPS/KPP Parts Marketing Home: Core Criteria",
          "",
          "https://globalkomatsu.sharepoint.com/sites/JP00GR01115//Pages/RemanCoreCrit.aspx"
        ],
        ["S-BOM Reman Section", "", ""]
      ]
    ],
    help_contacts: [
      {
        name: "Test Person",
        phone: "111-111-1111",
        email: "test@ilumalearning.com"
      }
    ],
    warranty:
      "Komatsu Genuine Reman has the best coverage in the industry:  1. Standard Reman Warranty: 1 year / unlimited hours • Covers all Minor and Major Reman components  2. Reman Quality Assurance (PPL 1069a): • An extended warranty for these Major components only: complete engines, transmissions, torque, main hydraulic pumps, finals and differentials • Guaranteed component life up to 10,000 hrs/4 years. • Covers catastrophic failures only. Doesn't include labor, freight, taxes or duty.  To stay in warranty, the customer is required to: • Provide oil samples • Conduct PM Clinics performed by Komatsu Distributor • Use Komatsu or Komatsu approved fluids & filters • The PSSR manages this for the customer",
    questions_to_ask:
      "1. What is the Komatsu Model-Type-Serial# ?   2. Do you have an acceptable core for exchange?   3. What is the condition of the core?   Sometimes:   2. Where (on machine) did the part come from, if removed?   3. if applicable, are you aware IF there is a parent component, that the part was removed from (or is inside of)?",
    product_offering:
      "Steering Components  Swing Bearings  Cylinder Heads  Hydraulic Cylinders  Turbochargers  Water Pumps  Oil Coolers  Air Compressors  Brake Components  Spindle, Hub & Brake  Fuel System Components  Rotating Electrical  Electronic Components  EGR Cooler  iMC Components  A/C Compressors  Engine Assemblies  An engine assembly is complete set of various parts that make up an engine, such as - the block, cylinders, pistons, oil pan, valve covers, water pump, intake manifold, and ignition distributor.  Transmissions  A transmission is essentially the gearbox - a part that transfers the engines power into motion. Without it, you’d sit in your machine with the engine running and go nowhere.  Torque Converters  A torque converter is a type of fluid/hydraulic coupling, or a device that transmits rotational mechanical power. Automatic transmission machines use this to disconnect from the drive shaft and transmission; for example: allow the engine to idle without moving. Torque converters replace a clutch in manual machines.  Piston, Pumps & Motors  Gear Pumps  Differentials  Final Drives  Axle Assemblies  Suspensions  Power Modules  I'm not clear what goes in this section yet. Need to talk to Goran. DG 05072020 ",
    sales_tips: ""
  },
  {
    href: "/dashboard/commodities/learn/undercarriage",
    title: "Undercarriage",
    aka: "",
    thumbnail:
      "https://komatsupartsu.com/v1/images/public/new-Undercarriage_mainImage_xparent.png",
    name: "Undercarriage",
    ez_id: "undercarriage",
    definition:
      "The Undercarriage (UC) is a moveable track that provides:  1. traction to move a machine to push or excavate material  2. flotation of the machine over soft underfoot conditions  3. a stable work platform for the machine to do its intended job",
    terms: [
      [
        "Bushing & Pin",
        "A part of the Track Link Assembly along with Track Links and seals. The bushing is engaged by the sprocket to create transactional force for forward and reverse operation."
      ],
      [
        "Carrier Rollers",
        "Carrier Rollers hold the top of the track up so it does not sag."
      ],
      [
        "Flotation",
        "The machine's weight distribution being spread over a large surface area which keeps it from sinking in soft underfoot conditions."
      ],
      [
        "Idler",
        "A round wheel that is used to keep the proper tension on the track."
      ],
      [
        "Idler Assembly",
        "A system of compontents that includes the Idler wheel, tensioner and bracket."
      ],
      [
        "Link",
        "One individual component of the Link Assembly. Also referred to as a chain."
      ],
      [
        "Sprocket/Segments",
        "Attached to final drive, it is a gear-type component which engages links to provide rotary motion."
      ],
      [
        "Track Link Assembly",
        "A complete assembly consisting of track links, track pins, bushings, seals and internal lubrication. The Track Shoes are bolted onto the Track Link Assembly. These come in many different widths and styles, each meant to provide the best traction and floation of the machine in a unique application."
      ],
      [
        "Track Shoe",
        "Component engaging in underfoot material, providing tracktion and flotation."
      ],
      [
        "Track Roller",
        "Rolling component which guides the track links along the bottom of the track frame."
      ],
      [
        "Traction",
        "The machine's ability to engage the ground and propel itself, either forward or in reverse."
      ],
      [
        "Undercarriage Management System",
        "A program used to help customers manage their UC and provide customers with the best value of their UC components. This tool is used to quickly and accurately measure wear on UC components to be logged into the UMS.  This is a tool that is mostly used by a PSSR for measuring the amount of wear on an UC."
      ],
      [
        "Undercarriage Wear",
        "Inspected by component type. The amount of material worn away over time. Caused by contact, load, and motion of components on one another throughout the system."
      ]
    ],
    safety_prompt: "Here is a safety prompt for undercarriage",
    value_proposition: [
      "Komatsu offers a long-lasting, trouble-free Undercarriage built to exacting specifications for your customers' machines. A Komatsu UC keeps them moving so they can complete their jobs on time and under budget at the lowest cost per hour over the life of the UC, backed by the best Assurance in the industry.",
      "Komatsu Undercarriages provide unrivaled value to your customer over the life of the machine. Rely on the deep support offered by your Undercarriage Product Manager and DPSM to help you easily overcome any obstables presented by your customers and expertly assure them that a Komatsu Undercarriage is absolutely the best choice to keep their machine up and running longer and more reliably, increasing their uptime and lowering their overall cost."
    ],
    tools_and_resources: [
      [
        ["Product Design: KUC Manual", "", ""],
        ["Customer-facing Resources: UMS", "", ""]
      ],
      [
        ["KPP", "", ""],
        ["Undercarriage Management", "", ""]
      ]
    ],
    help_contacts: [
      {
        name: "Test Person",
        phone: "111-111-1111",
        email: "test@ilumalearning.com"
      }
    ],
    warranty:
      "Komatsu Undercarriage Assurance is the best in the industry:  1) Covers against leakage and breakage  2) no registration is required  3) backed by Komatsu    Undercarriage Assurance is:  1) PLUS = 5 years or 6,000 hours  2) Conventional, Standard, GC = 4 years or 5,000 hours, any UC sold prior to 3 years or 4,0000 hours.",
    questions_to_ask:
      "1. What is the Komatsu Model-Type-Serial#?  2. What component do you need, and do you know your old part number?  IF ORDERING:  1. Track Links Assembly: Do you know what length you need?  If ordering Conventional or Plus: Have you heard about the GC UC option?  2. Shoe: What width? How many? Do you need nuts & bolts?  3. Sprocket:  Segment? Sprocket Ring? Do you need nuts & bolts?  4. Bottom Roller:  Do you need a Single or Double Flange?",
    product_offering:
      "Plus  Stands for Parallel Link Undercarriage System: This UC is the premium UC and provides the longest wear life and is meant for specific applications that are high wear and low impact conditions and precise finish dozing is required.  Standard  Standard is a robust UC that is intended to be used in the most harsh conditions such as waste, forestry, and ripping. These conditions also include high wear and high impact conditions.  General Construction (GC)  GC = General Construction: This is a link assembly only. This UC is designed and built by Komatsu and is intended to be a value priced link Assembly of UC. It is best suited for machines that have high hours, low useage or may be removed from a fleet that are in need of a new Link Assembly.  GC Plus  GC PLUS = Provides a value priced link assembly (only) for customers that have a PLUS UC on their machine and are looking (something missing here?)  Track Link Assembly  Tracklink assemblies are the combination of the all the track links and shoes. The links form a chain for the track shoes to attach. They provide weight support and a surface for the machine to move on.  Track Shoes  Track shoes are the platform of the machine and help create traction. There are different types of shoes for different terrain.  Carrier Rollers  Carrier rollers are placed at the top of the under carriage and serve a similar purpose to track rollers in providing track alignment and tension while preventing sagging.  Track Rollers  Multiple track rollers are placed at the bottom of the under carriage to evenly distribute weight of the machine, and track assembly tension and alignment.  Idler  Idlers are a guide that holds the track in place and adjust tension for link assemblies. ",
    sales_tips: ""
  },
  {
    href: "/dashboard/commodities/learn/filters",
    title: "Filters",
    aka: ["ELEMENTS", "CARTRIDGES", "STRAINERS"],
    thumbnail:
      "https://komatsupartsu.com/v1/images/public/Filters_Main-Xparent.png",
    name: "Filters",
    ez_id: "filters",
    definition:
      "Filters are designed to protect the various different systems & components that are in every Komatsu machine. They are there to protect them  from contamination, particles, debris, water and other impurities.",
    terms: [
      ["Micron", "Unit of measure: 1/1,000,000 meters"],
      [
        "Micron Rating",
        "Indicates the particle size that the filter media will capture."
      ],
      [
        "Efficiency",
        "identifies how well the filter will capture particles (contamination)  • Absolute Efficiency -> 100% of contaminants will be captured  • Nominal Efficiency ->percentage of contaminants collected at specific rating (i.e.85% @ 10 microns)"
      ],
      ["KCCV", "Komatsu Closed Crankcase Ventilation"],
      ["KOWA", "Komatsu Oil and Wear Analysis"],
      [
        "Filter Media",
        "Internal filter materials used to collect contaminants. Common types of filter media: synthetic blends, pleated paper, micro-glass and fiberglass"
      ],
      ["Fleetguard", "Komatsu All-Makes Filter Offering by Cummins Filtration"]
    ],
    safety_prompt: "Here is a safety prompt for filter",
    value_proposition: [
      "Komatsu Genuine Filters are tested to exceed industry standards for long component life, machine reliability and optimal performance.    Use Komatsu genuine parts and service for reduced downtime and lower operating costs. ",
      "Komatsu Genuine Filters are tested to exceed industry standards for long component life, machine reliability and optimal performance. Technical resources and sales tools are available to help identify the proper filters for each application. "
    ],
    tools_and_resources: [
      [
        [
          "Fuel Filters Sales Tool",
          "Introduction to Fuel Filters—Komatsu Genuine Filters and All-Makes",
          ""
        ],
        ["Program Overview (PMN)", "Program and product offering", ""],
        [
          "Order Process (PPL)",
          "Ordering process for bulk vs. package products",
          ""
        ]
      ],
      [
        [
          "Komatsu Genuine Filters—Komatsu Ltd. Video",
          "Video showing current filter offering",
          ""
        ]
      ]
    ],
    help_contacts: [
      {
        name: "Test Person",
        phone: "111-111-1111",
        email: "test@ilumalearning.com"
      }
    ],
    warranty:
      "Komatsu publishes the service/replacement interval for each filter in the Operation and Maintenance Manual. Approved Filter part numbers can be found in the serial number specific parts book, QRG (Quick Reference Guide) or RPL (Recommended Parts List).  The proper filter must be used to qualify for warranty coverage, Komatsu CARE and Genuine CARE support programs.  OMM lists specific filter types and service intervals",
    questions_to_ask:
      "1.) What is the machine type?  2.) What is the machine model Number?  3.) What is the machine serial number?  4.) How many hours are on the machine (SMR)?  5.) What type of filter? (engine, air, hydraulic, fuel, DEF, KCCV, etc.)?  6.) What temperature/application is the machine operating in?  7.) What type/brand of filter is currently on the machine?  8.) When was the filter last replaced?",
    product_offering:
      "Air Filter  An Air filter is a device that uses fibrous or porous materials to protect the engine from various harmful particles like dust, dirt, pollen etc.  Cabin Filter  Similar to the air filter, a cabin filter keeps the operator's cab dust and pollen free through air conditioning.  Fuel Filters (Primary & Secondary)  A fuel filter is used to screen out contaminants such as dirt, dust, debris and rust particles that may gather in the fuel to protect the engine.  Hydraulic Filter  Hydraulic filters maintain the integrity of the oil by cleaning various debris and upholding pressure within the system.  Lube Filter  Similar to fuel filters, lube filters screen contaminates from the engine. They are abrasive against important moving parts and can cause wear on the engine.  DEF Filter  DEF (Diesel Emissions Fluid) filters ensure it is free of contaminants and urea crystals. Otherwise the SCR (Selective Catalytic Reduction) system dedicated to reduce harmful emissions can malfunction or not work properly.",
    sales_tips: ""
  },
  {
    href: "/dashboard/commodities/support/batteries",
    title: "Batteries Support",
    _id: {
      $oid: "5ebd7d5244f358db36221aaa"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/battery.png",
    name: "Batteries Product Support",
    ez_id: "support",
    commodity: "batteries",
    content: [
      {
        type: "contact",
        data: [
          {
            contact_name: "Dimitra Balafoutis",
            title: "",
            company: "Komatsu America Corp",
            phone: "847-437-0267",
            phone_type: "office",
            email: "dbalafoutis@komatsuna.com",
            contact_for:
              "Pricing Strategy, Quality/Distribution/Availability network issues, Branding/Marketing Support (flash cards, brochures, POP racks, countermats), Komatsu Battery program (annual discount based on volume, seasonal specials), requests for new part # set up, requests for cross reference between Komatsu Japan part #s and the equivalent U.S. made (EastPenn) part #."
          },
          {
            contact_name: "Tanya Sheraden",
            title: "Customer Care Team",
            company: "Komatsu America Corp",
            phone: "731-635-6343",
            phone_type: "office",
            email: "tsheraden@komatsuna.com",
            contact_for: "Order, invoice and shipment status"
          },
          {
            contact_name: "Parts Portal",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: "Pricing review request"
          },
          {
            contact_name: "Local EastPenn Representative",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: "Battery rotation, warranty, core return, core credit"
          },
          {
            contact_name: "Parts Technical Support (Tech Call Center)",
            title: "",
            company: "",
            phone: "731-635-6343",
            phone_type: "office",
            email: "rpopartstechnical@komatsuna.com",
            contact_for: "Parts questions (size, dimensions, weight)"
          },
          {
            contact_name: "Ripley Customer Service",
            title: "",
            company: "",
            phone: "731-635-6343",
            phone_type: "office",
            email: "rposervice@komatsuna.com",
            contact_for: "Invoices, credits"
          }
        ]
      }
    ]
  },
  {
    href: "/dashboard/commodities/support/filters",
    title: "Filters Support",
    _id: {
      $oid: "5ebd7f0544f358db36221aab"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/filters.png",
    name: "Filters Product Support",
    ez_id: "support",
    commodity: "filters",
    content: [
      {
        type: "contact",
        data: [
          {
            contact_name: "Tony Laskero",
            title: "Sr. Product Manager",
            company: "Komatsu America Corp",
            phone: "847-437-0525",
            phone_type: "office",
            email: "tlaskero@komatsuna.com",
            contact_for:
              "Part Set Up, Product Training, Promotions, Marketing Promos"
          },
          {
            contact_name: "Alexis Crawford",
            title: "Parts Marketing Associate",
            company: "Komatsu America Corp",
            phone: "847-437-1808",
            phone_type: "office",
            email: "alcrawford@komatsuna.com",
            contact_for: "Part set up, Promotions, Marketing Promos"
          },
          {
            contact_name: "Tanya Sheraden",
            title: "Customer Care Team",
            company: "Komatsu America Corp",
            phone: "731-635-6343",
            phone_type: "office",
            email: "RPO_Dealer_DirectShip@komatsuna.com",
            contact_for: "Order, invoice and shipment status"
          },
          {
            contact_name: "DPSM (varies by dealer)",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          },
          {
            contact_name: "Local Shell Distributor",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: "Bulk oil delivery/order support"
          },
          {
            contact_name: "Parts Technical Support (Tech Call Center)",
            title: "",
            company: "",
            phone: "731-635-6581",
            phone_type: "",
            email: "rpopartstechnical@komatsuna.com",
            contact_for: "Parts Questions (size, dimensions, weight)"
          },
          {
            contact_name: "Ripley Customer Service",
            title: "Invoices, credits",
            company: "",
            phone: "731-635-6320",
            phone_type: "office",
            email: "rposervice@komatsuna.com",
            contact_for:
              "Contact the Ripley Customer Service Hotline number for shipping information, inventory, current pricing, estimated delivery dates, rebates, credits"
          },
          {
            contact_name: "Komatsu LMS Tutorials",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          },
          {
            contact_name: "Product Support Training",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          }
        ]
      }
    ]
  },
  {
    href: "/dashboard/commodities/support/hydraulics",
    title: "Hydraulics Support",
    _id: {
      $oid: "5ebd801044f358db36221aac"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",
    name: "Hydraulics Product Support",
    ez_id: "support",
    commodity: "hydraulics",
    content: [
      {
        type: "contact",
        data: [
          {
            contact_name: "Tony Laskero",
            title: "",
            company: "Komatsu America Corp",
            phone: "312-215-3688",
            phone_type: "office",
            email: "tlaskero@komatsuna.com",
            contact_for:
              "Part Set Up, Product Training, Promotions, Marketing Promos"
          },
          {
            contact_name: "Tanya Sheraden",
            title: "Customer Care Team",
            company: "Komatsu America Corp",
            phone: "847-437-0525",
            phone_type: "office",
            email: "tsheraden@komatsuna.com",
            contact_for: "Order, invoice and shipment status"
          },
          {
            contact_name: "DPSM",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          },
          {
            contact_name: "Parts Technical Support (Tech Call Center)",
            title: "",
            company: "",
            phone: "731-635-6581",
            phone_type: "",
            email: "rpopartstechnical@komatsuna.com",
            contact_for: "Parts Questions (size, dimensions, weight)"
          },
          {
            contact_name: "Ripley Customer Service",
            title: "Invoices, credits",
            company: "",
            phone: "731-635-6320",
            phone_type: "office",
            email: "rposervice@komatsuna.com",
            contact_for:
              "Contact the Ripley Customer Service Hotline number for shipping information, inventory, current pricing, estimated delivery dates, rebates, credits"
          },
          {
            contact_name: "Komatsu LMS Tutorials",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          },
          {
            contact_name: "Product Support Training",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          }
        ]
      }
    ]
  },
  {
    href: "/dashboard/commodities/support/lubricants",
    title: "Lubricants Support",
    _id: {
      $oid: "5ebd804444f358db36221aad"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",
    name: "Lubricants Product Support",
    ez_id: "support",
    commodity: "lubricants",
    content: [
      {
        type: "contact",
        data: [
          {
            contact_name: "Tony Laskero",
            title: "Sr. Product Manager",
            company: "Komatsu America Corp",
            phone: "312-215-3688",
            phone_type: "office",
            email: "tlaskero@komatsuna.com",
            contact_for:
              "Part Set Up, Product Training, Promotions, Marketing Promos"
          },
          {
            contact_name: "Alexis Crawford",
            title: "Parts Marketing Associate",
            company: "Komatsu America Corp",
            phone: "847-437-1808",
            phone_type: "office",
            email: "alcrawford@komatsuna.com",
            contact_for: "Part set up, Promotions, Marketing Promos"
          },
          {
            contact_name: "Tanya Sheraden",
            title: "Customer Care Team",
            company: "Komatsu America Corp",
            phone: "847-437-0525",
            phone_type: "office",
            email: "RPO_Dealer_DirectShip@komatsuna.com",
            contact_for: "Order, invoice and shipment status"
          },
          {
            contact_name: "DPSM",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          },
          {
            contact_name: "Parts Technical Support (Tech Call Center)",
            title: "",
            company: "",
            phone: "731-635-6581",
            phone_type: "",
            email: "rpopartstechnical@komatsuna.com",
            contact_for: "Parts Questions (size, dimensions, weight)"
          },
          {
            contact_name: "Ripley Customer Service",
            title: "Invoices, credits",
            company: "",
            phone: "731-635-6320",
            phone_type: "office",
            email: "rposervice@komatsuna.com",
            contact_for:
              "Contact the Ripley Customer Service Hotline number for shipping information, inventory, current pricing, estimated delivery dates, rebates, credits"
          },
          {
            contact_name: "Komatsu LMS Tutorials",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          },
          {
            contact_name: "Product Support Training",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          }
        ]
      }
    ]
  },
  {
    href: "/dashboard/commodities/support/reman",
    title: "Reman Support",
    _id: {
      $oid: "5ebd846444f358db36221aae"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",
    name: "Reman Product Support",
    ez_id: "support",
    commodity: "reman",
    content: [
      {
        type: "contact",
        data: [
          {
            contact_name: "Goran Zeravica",
            title: "",
            company: "Komatsu America Corp",
            phone: "847-437-0131",
            phone_type: "office",
            email: "gzeravica@komatsuna.com",
            contact_for:
              "Reman & Overhaul Product Management; Even Product Addition/Set-Up;  Pricing"
          },
          {
            contact_name: "Tammy Rautmann's Team",
            title: "Supply Chain / Customer Care",
            company: "Ripley Operations",
            phone: "731-635-6692",
            phone_type: "office",
            email: "KACpartsmarketingquotes@komatsuna.com",
            contact_for:
              "DB general-support from Ripley. Order processing.  Order inquiries ETA's (estimated time of arrival) of components' shipment. Invoicing issues. Rebatest. Credits."
          },
          {
            contact_name: "DPSM",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for: ""
          },
          {
            contact_name: "Parts Technical Support (Tech Call Center)",
            title: "",
            company: "",
            phone: "731-635-6581",
            phone_type: "",
            email: "rpopartstechnical@komatsuna.com",
            contact_for:
              "Technical / Drawings / Bill of Material (BOM).  Also Part Number Supersessions and cross-references."
          },
          {
            contact_name: "Jamison Gullette for REMAN",
            title: "Materials Team",
            company: "Komatsu America Corp",
            phone: "",
            phone_type: "",
            email: "jbullette@komatsuna.com",
            contact_for: "How soon can I have this part?"
          },
          {
            contact_name: "Teresa King for CORE",
            title: "Materials Team COREs Management",
            company: "Komatsu America Corp",
            phone: "",
            phone_type: "",
            email: "tking@komatsuna.com",
            contact_for: "Do you want this core? How many cores do you have?"
          },
          {
            contact_name: "Bill Hollis",
            title: "Sr. Quality Engineer",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for:
              "Reman Technical Service Requests (TSR), quality issues and warranty claims"
          },
          {
            contact_name: "TBD (was Britt Muller)",
            title: "Customer Service Department Support",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for:
              "Claims approval, warranty support & installation guidelines"
          }
        ]
      }
    ]
  },
  {
    href: "/dashboard/commodities/support/undercarriage",
    title: "Undercarriage Support",
    _id: {
      $oid: "5ebd866344f358db36221aaf"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",
    name: "Undercarriage Product Support",
    ez_id: "support",
    commodity: "undercarriage",
    content: [
      {
        type: "contact",
        data: [
          {
            contact_name: "Jeff Kurtz",
            title: "Sr. Product Manager, Sales & Marketing",
            company: "Komatsu America Corp",
            phone: "815-905-9261",
            phone_type: "office",
            email: "jjkurtz@komatsuna.com",
            contact_for:
              "Sales and Marketing Activities, cusotmer VOC, customer support, advanced training needs, Undercarriage Manaagment system questions"
          },
          {
            contact_name: "Tammy Rauttman",
            title: "Supply Chain/Customer Care",
            company: "Ripley Operations",
            phone: "731-635-6692",
            phone_type: "office",
            email: "KACpartsmarketingquotes@komatsuna.com",
            contact_for:
              "Invoicing, Parts Availability, Order Status. Competitive pricing requests when no promotional pricing exists in Komatsu Parts Portal"
          },
          {
            contact_name: "DPSM",
            title: "",
            company: "",
            phone: "",
            phone_type: "",
            email: "",
            contact_for:
              "Contact your DPSM for any localized pricing support, or to help schedule a visit to the DB or customer by the Sr. Product Manager"
          },
          {
            contact_name: "Parts Technical Support (Tech Call Center)",
            title: "",
            company: "",
            phone: "731-635-6581",
            phone_type: "",
            email: "rpopartstechnical@komatsuna.com",
            contact_for:
              "Contact Ripley Tech Service for drawing information, specifications, tech data, associated parts, torque data, warranty information, UC Assurance information, replacement parts, cancel replaced by parts,"
          },
          {
            contact_name: "Ripley Customer Service",
            title: "Invoices, credits",
            company: "",
            phone: "731-635-6320",
            phone_type: "office",
            email: "rposervice@komatsuna.com",
            contact_for:
              "Contact the Ripley Customer Service Hotline number for shipping information, inventory, current pricing, estimated delivery dates, rebates, credits"
          }
        ]
      }
    ]
  },
  {
    href: "/dashboard/commodities/offering/batteries",
    title: "Batteries Product Offering",
    _id: {
      $oid: "5ec23c61a761481ff2c43e50"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",

    ez_id: "offering",
    commodity: "batteries",
    content: [
      {
        type: "image/text",
        data: {
          link: "",
          title: "Heavy Duty 6V/12V",
          definition: "Used for Heavy Equipment"
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Light Duty 6V/12V",
          definition: "Used for golf carts and trucks"
        }
      }
    ]
  },
  {
    href: "/dashboard/commodities/offering/filters",
    title: "Filters Product Offering",
    _id: {
      $oid: "5ec2a2e5a761481ff2c43e53"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",

    ez_id: "offering",
    commodity: "filters",
    content: [
      {
        type: "image/text",
        data: {
          link:
            "https://komatsupartsu.com/v1/images/public/Filter_Major_Air.jpg",
          title: "Air Filter",
          definition:
            "An Air filter is a device that uses fibrous or porous materials to protect the engine from various harmful particles like dust, dirt, pollen etc."
        }
      },
      {
        type: "image/text",
        data: {
          link:
            "https://komatsupartsu.com/v1/images/public/Filter_Major_Cabin.jpg",
          title: "Cabin Filter",
          definition:
            "Similar to the air filter, a cabin filter keeps the operator's cab dust and pollen free through air conditioning."
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title:
            "Fuel Filters (Diesel Fuel) (Pre-Fuel Filter & Mail Fuel Filter)",
          definition:
            "A fuel filter is used to screen out contaminants such as dirt, dust, debris and rust particles that may gather in the fuel to protect the engine."
        }
      },
      {
        type: "image/text",
        data: {
          link:
            "https://komatsupartsu.com/v1/images/public/hydraulic_filters.jpg",
          title: "Hydraulic Filter",
          definition:
            "Hydraulic filters maintain the integrity of the oil by cleaning various debris and upholding pressure within the system."
        }
      },
      {
        type: "image/text",
        data: {
          link: "https://komatsupartsu.com/v1/images/public/lube_filter.jpg",
          title: "Lube Filter",
          definition:
            "Similar to fuel filters, lube filters screen contaminates from the engine. They are abrasive against important moving parts and can cause wear on the engine."
        }
      },
      {
        type: "image/text",
        data: {
          link:
            "https://komatsupartsu.com/v1/images/public/Filter_KCCV_Xparent.png",
          title: "KCCV Filter",
          definition: ""
        }
      },
      {
        type: "image/text",
        data: {
          link: "https://komatsupartsu.com/v1/images/public/DEF-filter.png",
          title: "DEF Filter",
          definition:
            "DEF (Diesel Emissions Fluid) filters ensure it is free of contaminants and urea crystals. Otherwise the SCR (Selective Catalytic Reduction) system dedicated to reduce harmful emissions can malfunction or not work properly."
        }
      }
    ]
  },
  {
    href: "/dashboard/commodities/offering/hydraulics",
    title: "Hydraulics Product Offering",

    _id: {
      $oid: "5ec2a89da761481ff2c43e54"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",

    ez_id: "offering",
    commodity: "hydraulics",
    content: [
      {
        type: "image/text",
        data: {
          link: "",
          title: "Pumps",
          definition:
            "Converts mechanical energy into hydraulic energy and creates flow."
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Valves",
          definition:
            "Controls how the system operate: pressure, flow and directional control"
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Motors",
          definition: "Rotary or linear actuators used to perform work"
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Cylinders",
          definition: "Linear Actuator: rold and barrel—extends and retracts"
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Hydraulic Oil",
          definition: "The media under pressure in the hydraulic system"
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Hoses",
          definition:
            "Supply and return lines (fully built hose assemblies are listed in the machine parts book and are available through KPP)"
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Seals",
          definition: "Prevents leaks (AKA: o-rings, gaskets)"
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Filters",
          definition: "Protects the hydraulic system from contamination"
        }
      },
      {
        type: "image/text",
        data: {
          link: "",
          title: "Reservoir",
          definition: "Tank that stores hydraulic oil"
        }
      }
    ]
  },
  {
    href: "/dashboard/commodities/offering/lubricants",
    title: "Lubricants Product Offering",

    _id: {
      $oid: "5ec2a89da761481ff2c43e55"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",

    ez_id: "offering",
    commodity: "lubricants",
    content: [
      {
        type: "image/text",
        data: {
          link:
            "https://komatsupartsu.com/v1/images/public/Lubricants_Major_EngineOilPail.jpg",
          title: "Engine Oil",
          definition:
            "Engine Oil, also known as  motor oil or engine lubricant, it used to help reduce friction and wear of the moving parts of the engine as well as clean it."
        }
      },
      {
        type: "image/text",
        data: {
          link:
            "https://komatsupartsu.com/v1/images/public/Lubricants_Major_HydraulicOilDrum.jpg",
          title: "Hydraulic Oil",
          definition:
            "Hydraulic oil is a non-compressible fluid that is not only a lubricant but also is used to transfer power within hydraulic machinery and equipment."
        }
      },
      {
        type: "image/text",
        data: {
          link: "https://komatsupartsu.com/v1/images/public/TO10_pail.png",
          title: "Power Train/Transmission Oil",
          definition:
            "Transmission oil is more refined and light to maintain the heat and high pressure within the system. "
        }
      },
      {
        type: "image/text",
        data: {
          link:
            "https://komatsupartsu.com/v1/images/public/Lubricants_Major_AxelOilPail.jpg",
          title: "Axle Oil",
          definition:
            "Axle oil is used in axles with built-in brake and limited slip differential (LSD) to protect the brakes from high temperatures, wear and tear and reduces brake noise."
        }
      },
      {
        type: "image/text",
        data: {
          link:
            "https://komatsupartsu.com/v1/images/public/Lubricants_Major_GreasePail.jpg",
          title: "Grease",
          definition:
            "While grease has a similar use as a lubricant for mechanical parts, its consistency tends to be more semi-solid to solid to hold longer and seal better than conventional oil."
        }
      },
      {
        title: "Oil Packaging",
        type: "image/text",
        data: [
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Lubricants_Major_EngineOilGallon.jpg",
            title: "Jug (1 gallon)",
            definition: ""
          },
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Lubricants_Major_GreasePail.jpg",
            title: "Pail (5 gallons)",
            definition: ""
          },
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Lubricants_Major_GreaseKeg.jpg",
            title: "Drum (55 gallons)",
            definition: ""
          },
          {
            link: "",
            title: "Tote (257 gallons)",
            definition: ""
          }
        ]
      },
      {
        title: "Grease Packaging",
        type: "image/text",
        data: [
          {
            link: "",
            title: "Tube (.4 Kg)",
            definition: ""
          },
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Lubricants_Major_GreasePail.jpg",
            title: "Pail (18 Kg)",
            definition: ""
          },
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Lubricants_Major_HydraulicOilDrum.jpg",
            title: "KEG (50 Kg)",
            definition: ""
          }
        ]
      }
    ]
  },
  {
    href: "/dashboard/commodities/offering/reman",
    title: "Reman Product Offering",
    _id: {
      $oid: "5ee27203383fe953e69dfe5d"
    },
    thumbnail: "https://komatsupartsu.com/v1/images/public/hydraulics.png",

    ez_id: "offering",
    commodity: "reman",
    content: [
      {
        title: "Major Reman Components",
        type: "image/text",
        data: [
          {
            link: "",
            title: "Engine Assemblies",
            definition:
              "An engine assembly is complete set of various parts that make up an engine, such as - the block, cylinders, pistons, oil pan, valve covers, water pump, intake manifold, and ignition distributor."
          },
          {
            link: "",
            title: "Transmissions",
            definition:
              "A transmission is essentially the gearbox - a part that transfers the engines power into motion. Without it, you’d sit in your machine with the engine running and go nowhere."
          },
          {
            link: "",
            title: "Torque Converters",
            definition:
              "A torque converter is a type of fluid/hydraulic coupling, or a device that transmits rotational mechanical power. Automatic transmission machines use this to disconnect from the drive shaft and transmission; for example: allow the engine to idle without moving. Torque converters replace a clutch in manual machines."
          },
          {
            link: "",
            title: "Piston",
            definition:
              "A piston is a movable disk within in a cylinder. As the disk moves up and down against liquid or gas inside, it transfers heat energy into mechanical work in internal combustion engines and pumps."
          },
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Reman_Major_SwingMotor.jpg",
            title: "Swing Motor",
            definition:
              "A motor is a device that changes a form of energy into mechanical energy to produce motion."
          },
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Reman_Major_TravelMotor.jpg",
            title: "Travel Motor",
            definition:
              "A motor is a device that changes a form of energy into mechanical energy to produce motion."
          },
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Reman_Major_PumpFuelInj.jpg",
            title: "Pump",
            definition:
              "A pump is a mechanical device that moves liquids and air, or compresses gas through suction or pressure."
          },
          {
            link: "",
            title: "Gear Pumps",
            definition:
              "A gear pump uses rotating gears to push fluid from low pressure to high by displacement. They are often used for hydraulic fluid power."
          },
          {
            link: "",
            title: "Differentials",
            definition:
              "Differentials are designed to drive a pair of wheels while allowing them to rotate at different speeds while averaging the two. When turning, the outermost wheel can travel faster and farther than the innermost wheel."
          },
          {
            link: "",
            title: "Final Drives",
            definition:
              "The final drive is primarily the planetary gear system that converts power to torque to move the machine. Sometimes, the hydraulic/travel motor is part of the final drive by other definitions."
          },
          {
            link: "",
            title: "Axle Assemblies",
            definition:
              "An axle assembly links two wheels on opposite sides together and passes on rotational force coming from the transmission to the wheels of the vehicle."
          },
          {
            link: "",
            title: "Suspensions",
            definition:
              "Suspension is the system springs, shock absorbers, and linkages that connects to the wheels and allows relative motion between the two. This systems helps with ride quality, handling and wear when it comes to cushioning the terrain conditions."
          },
          {
            link: "",
            title: "Power Modules",
            definition:
              "Power modules convert AC power to DC power for various components such as motor drives."
          }
        ]
      },
      {
        title: "Minor Reman Components",
        type: "image/text",
        data: [
          {
            link:
              "https://komatsupartsu.com/v1/images/public/Reman_Major_CylinderHead.jpg",
            title: "Cylinder Heads",
            definition:
              "Cylinder heads help control the gases, for intake and exhaust, and the combustion process in an engine."
          },
          {
            link: "",
            title: "Steering Components",
            definition: ""
          },
          {
            link: "",
            title: "Swing Bearings",
            definition:
              "A bearing is a device that is used to enable rotational or linear movement, while reducing friction and handling stress. Swing bearings support heavy but slow turning loads."
          },
          {
            link: "",
            title: "Hydraulic Cylinders",
            definition:
              "Hydraulic cylinders are mechanical device, similar to a linear hydraulic motor, that converts hydraulic pressure and flow from the oil into unidirectional motion."
          },
          {
            link: "",
            title: "Turbochargers",
            definition:
              "The turbocharger is similar to a piston engine. It uses the exhaust gas to drive a turbine to then spin the air compressor that pushes extra air into the cylinders, allowing them to burn more fuel."
          },
          {
            link: "",
            title: "Water Pumps",
            definition:
              "A water pump is a mechanical device that moves coolant through the system to help cool the machine and its parts."
          },
          {
            link: "",
            title: "Oil Coolers",
            definition:
              "An oil cooler can also be called a radiator, which is a heat exchanger. It takes in hot oil just passed through the engine to cool it down."
          },
          {
            link: "",
            title: "Air Compressors",
            definition:
              "An air compressor is basically an air pump. Its main job is to compress and transfer cooling gas and air through the machine's A/C system."
          },
          {
            link: "",
            title: "Brake Components",
            definition:
              "The major brake system components include the brake pedal, brake booster, brake master cylinder, brake lines and hoses, brake calipers and pistons, disc brake pads or brake shoes, disc brake rotors or brake drums, brake fluid, anti-lock brake system (ABS) control module, wheel speed sensors"
          },
          {
            link: "",
            title: "Spindle, Hub & Brake",
            definition: ""
          },
          {
            link: "",
            title: "Fuel System Components",
            definition:
              "The fuel system consists of a fuel pump, fuel tank, fuel lines, fuel filter, fuel injectors, and carburetor."
          },
          {
            link: "",
            title: "Rotating Electrical",
            definition: ""
          },
          {
            link: "",
            title: "Electronic Components",
            definition: ""
          },
          {
            link: "",
            title: "EGR Cooler",
            definition:
              "The EGR cooler is a component used to lower the temperature of the exhaust gases that are recirculated by the EGR system."
          },
          {
            link: "",
            title: "iMC Components",
            definition: ""
          },
          {
            link: "",
            title: "A/C Compressors",
            definition: ""
          }
        ]
      }
    ]
  },
  {
    href: "/dashboard/machines/learn/excavator",
    title: "Excavator Machine",

    _id: {
      $oid: "5e77bacc5c8b1f192b252909"
    },
    sizes: {
      small: {
        image: "https://cdn.komatsupartsu.com/excavator-small.png",
        description: ""
      },
      mid: {
        image: "https://cdn.komatsupartsu.com/excavator.png",
        description: ""
      },
      large: {
        image: "https://cdn.komatsupartsu.com/excavator-large.png",
        description: ""
      },
      mining: {
        image: "https://cdn.komatsupartsu.com/excavator-mining.png",
        description: ""
      }
    },
    xray_images: {
      colored_full:
        "https://cdn.komatsupartsu.com/KAC-PC290LCi-11-Full-Machine-Not-Grey-Lines.png",
      xray_full:
        "https://cdn.komatsupartsu.com/KAC-PC290LCi-11-Full-Machine-Lines.png",
      batteries:
        "https://cdn.komatsupartsu.com/KAC-PC290LCi-11-Batteries-Lines.png",
      filters:
        "https://cdn.komatsupartsu.com/KAC-PC290LCi-11-Filters.png",
      hydraulics:
        "https://cdn.komatsupartsu.com/KAC-PC290LCi-11-Hydraulics.png",
      lubricants:
        "https://cdn.komatsupartsu.com/KAC-PC290LCi-11-Lubricants.png",
      reman:
        "https://cdn.komatsupartsu.com/KAC-PC290LCi-11-Reman-V2.png",
      undercarriage:
        "https://cdn.komatsupartsu.com/KAC-PC290LCi-11-Undercarraige.png"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/new-PC210LC-11_hiRes_xparent.png",
    ez_id: "excavator",
    name: "Excavator",
    description:
      "Dig  Suitable for excavating tightly packed earth and used for a wide range of land excavation such as civil engineering, pipe jacking and mining. Designed particularly for work in confined zones, minimal rear-swing radius and small rear-swing radius models are available for wheel-type hydraulic excavators. Featuring excellent fuel economy and CO2 reduction, hybrid hydraulic excavators are also available.",
    model_number: "",
    machine_type: "Excavator",
    sub_type: "Track",
    commodities: [],
    files: [],
    media: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/seIGoK4J184"
      }
    ],
    popups: [
      {
        x: 65,
        y: 40,
        title: "Operators Cab",
        text:
          "The cab provides an enclosed, environmentally conrolled and safe environment in which the operator can control and monitor the health of the machine. Roll-over protection (ROPS) and falling-object protection (FOPS) can also be incorporated into the cab to further protect the operator in certain working environments.",
        position: "topLeft"
      },
      {
        x: 50,
        y: 50,
        title: "Boom",
        text:
          "The Boom supports the arm and attachments. Through the use of hydraulic cylinders, it facilitates vertical movement of the attachment which can be used to control digging depth and height. The base of the Boom (foot) is connected to the Upper Structure of the Excavator and the top end of the Boom (nose) provides the connection for the Arm.",
        position: "topLeft"
      },
      {
        x: 20,
        y: 20,
        title: "Attachment",
        text:
          "There a numerous Excavator attachments available: bucket, ripper, thumb, hammer, grapple. The Bucket, as shown here, comes in varying sizes and is used to dig and carry material. ",
        position: "left"
      },
      {
        x: 80,
        y: 35,
        title: "Revolving Frame",
        text:
          "The Revolving Frame houses the engine, hydraulic pumps and tanks, fuel, etc. and provides mounting for the Boom and Operator's Cab.",
        position: "right"
      },
      {
        x: 65,
        y: 10,
        title: "Undercarraige",
        text:
          "The Undercarriage is a system of rollers, idlers, track link assemblies and other components which allow the Excavator to move forward and backward. It provides traction, floation and a stable base for machine operation.",
        position: "right"
      },
      {
        x: 65,
        y: 24,
        title: "Swing Machinery",
        text:
          "The Swing Machinery is a circle gear located under the Operator's Cab designed to rotate the Upper Structure of the machine 360 degrees.",
        position: "bottom"
      },
      {
        x: 20,
        y: 55,
        title: "Arm",
        text:
          "The Arm is connected to the Boom and hosts the attachment (bucket, grapple, hammer, etc.). It is available in varying lengths, and through the use of hydraulic cylinders it increases the horizontal reach of the machine and controls the movement of the attachment. It can also be used to position objects that are being hoisted and lowered with the Boom.",
        position: "left"
      }
    ],
    __v: 0,
    createdAt: {
      $date: "2020-03-22T19:21:48.148Z"
    },
    updatedAt: {
      $date: "2020-03-22T19:21:48.148Z"
    }
  },
  {
    href: "/dashboard/machines/learn/dozer",
    title: "Dozer Machine",
    _id: {
      $oid: "5e77bacc5c8b1f192b25290a"
    },
    sizes: {
      small: {
        image: "https://cdn.komatsupartsu.com/small_dozer.png",
        description: ""
      },
      mid: {
        image: "https://cdn.komatsupartsu.com/midsize-dozer.png",
        description: ""
      },
      large: {
        image: "https://cdn.komatsupartsu.com/large_dozer.png",
        description: ""
      },
      mining: {
        image: "https://cdn.komatsupartsu.com/mining_dozer.png",
        description: ""
      }
    },
    xray_images: {
      colored_full:
        "https://cdn.komatsupartsu.com/D51EXi-24_base_color.png",
      xray_full:
        "https://cdn.komatsupartsu.com/D51EXi-24_base_grey.png",
      batteries:
        "https://cdn.komatsupartsu.com/D51EXi-24_batteries.png",
      filters: "https://cdn.komatsupartsu.com/D51EXi-24_filters.png",
      hydraulics: "https://cdn.komatsupartsu.com/D51EXi-24_hyd.png",
      lubricants:
        "https://cdn.komatsupartsu.com/D51EXi-24_lubricants.png",
      reman: "https://cdn.komatsupartsu.com/D51EXi-24_reman.png",
      undercarriage:
        "https://cdn.komatsupartsu.com/D51EXi-24_undercarriage.png"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/Komatsu_Dozer_xparent_hiRes.png",
    ez_id: "dozer",
    name: "Dozer",
    description:
      "Push  Designed for excavating earth, hauling excavated rocks and leveling earth.",
    model_number: "",
    machine_type: "Dozer",
    sub_type: "Track",
    commodities: [],
    files: [],
    media: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/hhRJgm_kLXI"
      }
    ],
    popups: [
      {
        x: 0,
        y: 25,
        title: "Blade",
        text:
          "The Blade is a Ground Engagement Tool (GET) comprised of a cutting edge and moldboard. It moves materials and levels the ground.",
        position: "left"
      },
      {
        x: 80,
        y: 26,
        title: "Undercarriage",
        text:
          "The Undercarriage is a system of rollers, idlers, track link assemblies and other components which allows the Excavator to move forward and backward. It provides traction, floation and a stable base for machine operation.",
        position: "bottom"
      },
      {
        x: 90,
        y: 40,
        title: "Ripper",
        text:
          "The Ripper is a single or multi-shank attachment used to penetrate the ground to break up or loosen hard-packed material to be pushed by the Blade. This helps reduce wear on the Blade GET and related components as well as the undercarriage.",
        position: "topLeft"
      },
      {
        x: 65,
        y: 65,
        title: "Operator's Cab",
        text:
          "The cab provides an enclosed, environmentally conrolled and safe environment in which the operator can control and monitor the health of the machine. Roll-over protection (ROPS) and falling-object protection (FOPS) can also be incorporated into the cab to further protect the operator in certain working environments.",
        position: "topRight"
      }
    ],
    __v: 0,
    createdAt: {
      $date: "2020-03-22T19:21:48.149Z"
    },
    updatedAt: {
      $date: "2020-03-22T19:21:48.149Z"
    }
  },
  {
    href: "/dashboard/machines/learn/wheel-loader",
    title: "Wheel Loader Machine",
    _id: {
      $oid: "5e77bacc5c8b1f192b25290b"
    },
    sizes: {
      small: {
        image: "",
        description: ""
      },
      mid: {
        image: "https://cdn.komatsupartsu.com/midsize_loader.png",
        description: ""
      },
      large: {
        image: "https://cdn.komatsupartsu.com/large_loader.png",
        description: ""
      },
      mining: {
        image: "https://cdn.komatsupartsu.com/mining_loader.png",
        description: ""
      }
    },
    xray_images: {
      colored_full:
        "https://cdn.komatsupartsu.com/WA380-8_base_color.png",
      xray_full: "https://cdn.komatsupartsu.com/WA380-8_base_grey.png",
      batteries: "https://cdn.komatsupartsu.com/WA380-8_batteries.png",
      filters: "https://cdn.komatsupartsu.com/WA380-8_filters.png",
      hydraulics:
        "https://cdn.komatsupartsu.com/WA380-8_hydraulics.png",
      lubricants:
        "https://cdn.komatsupartsu.com/WA380-8_lubricants.png",
      reman: "https://cdn.komatsupartsu.com/WA380-8-reman_updated.png"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/Komatsu_WheelLoader_xparent_Aligned.png",
    ez_id: "wheel-loader",
    name: "Wheel Loader",
    description:
      "LOAD Used for loading stones and sand into the dump truck or carrying with loaded bucket, ranging from small wheel loaders for civil engineering to super-large ones for quarry and mining.",
    model_number: "",
    machine_type: "Wheel Loader",
    sub_type: "Wheeled",
    commodities: [],
    files: [],
    media: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/8bH9ieinei0"
      }
    ],
    popups: [
      {
        x: 35,
        y: 55,
        title: "Operators Cab",
        text:
          "The cab provides and enclosed, environmentall conrolled safe environment in which the operator can control and monitor the health machine.  Roll-over protection (ROPS) and falling-object protection (FOPS) can also be incorporated into the cab to further protect the operator in certain working environments..",
        position: "topRight"
      },
      {
        x: 1,
        y: 45,
        title: "Engine Compartment",
        text:
          "The Engine Compartment is where the engine, pumps, cooling packages and exhaust systems are housed, along with multiple service points for daily maintenance. The engine compartment houses the components that allow the machine to work.",
        position: "topLeft"
      },
      {
        x: 90,
        y: 25,
        title: "Bucket",
        text:
          "The Wheel Loader Bucket is considered to be the main part of the work equipment and is  the primary method of moving material. There are different types available including general purpose, excavation and light material, and the bucket should be chosen based on the customer's material type and density. Bucket capacity is measured in cubic yards and refers to amount of material carried in one scoop.",
        position: "left"
      },
      {
        x: 30,
        y: 40,
        title: "Articulated Joint",
        text:
          "The Articulation Joint allows the Wheel Loader to articulate left and right, providing more flexibility of movement when making a turn.",
        position: "bottom"
      },
      {
        x: 55,
        y: 43,
        title: "Lift Arm",
        text:
          "Wheel Loaders have two Lift Arms with hydraulic cylinders that are used to raise and lower the bucket and is one of the three main structural components of a Wheel Loader. It is what connects the Bucket to the front frame of the Loader. Komatsu Wheel Loaders also have a bucket linkage with one bucket cylinder for curling (tilting) and dumping the bucket.",
        position: "topRight"
      },
      {
        x: 5,
        y: 25,
        title: "Tires",
        text:
          "The tire is the main point of contact between the Wheel Loader and the work surface. The Tire is mounted to the wheel and provides the traction or grip that allows the Wheel Loader to move material. There are different types of tires available for different types of work surfaces such as airless, solid, deep traction and hard surface tires as well as varying widths to match different width wheels for wide or narrow axles.",
        position: "left"
      }
    ],
    __v: 0,
    createdAt: {
      $date: "2020-03-22T19:21:48.150Z"
    },
    updatedAt: {
      $date: "2020-03-22T19:21:48.150Z"
    }
  },
  {
    href: "/dashboard/machines/learn/haul-truck",
    title: "Haul Truck Machine",
    _id: {
      $oid: "5e77bacc5c8b1f192b25290c"
    },
    sizes: {
      small: {
        image: "https://cdn.komatsupartsu.com/haul.png",
        description: ""
      },
      mid: {
        image: "https://cdn.komatsupartsu.com/articulated_truck.png",
        description: ""
      },
      large: {
        image: "https://cdn.komatsupartsu.com/offhighway_truck.png",
        description: ""
      },
      mining: {
        image: "https://cdn.komatsupartsu.com/mining_truck.png",
        description: ""
      }
    },
    xray_images: {
      colored_full:
        "http://localhost:4999/v1/images/public/HM400-5-Full-Machine-Color-Lines.png",
      xray_full:
        "https://cdn.komatsupartsu.com/HM400-5-Full-Machine-Grey-Lines.png",
      batteries: "https://cdn.komatsupartsu.com/HM400-5-Batteries.png",
      filters: "https://cdn.komatsupartsu.com/HM400-5-Filters.png",
      hydraulics:
        "https://cdn.komatsupartsu.com/HM400-5-Hydraulics-Main-Comp.png",
      lubricants:
        "https://cdn.komatsupartsu.com/HM400-5-Lubracants.png",
      reman: "https://cdn.komatsupartsu.com/HM400-5-Reman-V2.png"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/new-Haul-Truck_xparent_1200px.png",
    ez_id: "haul-truck",
    name: "Haul Truck",
    description:
      "CARRY    Rigid frame dump trucks  Off-road dump trucks are durable, stable and designed for hauling excavated earth and rocks at construction sites, and overburden and/or ore at mine sites.   Articulated dump trucks  Articulated dump trucks feature excellent performance on rough and soft grounds with low ground pressure and an all wheel drive system.",
    model_number: "",
    machine_type: "Haul Truck",
    sub_type: "Wheeled",
    commodities: [],
    files: [],
    media: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/ODuob9kK6q4"
      }
    ],
    popups: [
      {
        x: 20,
        y: 65,
        title: "Operators Cab",
        text:
          "The cab provides an enclosed, environmentally conrolled and safe environment in which the operator can control and monitor the health of the machine. Roll-over protection (ROPS) and falling-object protection (FOPS) can also be incorporated into the cab to further protect the operator in certain working environments.",
        position: "topRight"
      },
      {
        x: 95,
        y: 50,
        title: "Body",
        text:
          "The Body is mounted to the rear of a haul truck and is used to hold material while the truck moves it to another location. When the operator lifts the dump lever, the hoist cylinders will raise the front of the body causing the material to be dumped onto the ground from the back of the body.",
        position: "topLeft"
      },
      {
        x: 75,
        y: 25,
        title: "Tires",
        text:
          "A Tire is designed to transfer a vehicle's load from the axle through the wheel to the ground, and provide traction on the surface traveled over. Tires also provide a flexible cushion that absorbs shock as the tire rolls over rough features on the surface. Tires provide a footprint that is designed to match the weight of the vehicle with the bearing strength of the surface that it rolls over by providing a bearing pressure that will not deform the surface excessively.",
        position: "bottom"
      },
      {
        x: 54,
        y: 40,
        title: "Articulation Joint",
        text:
          "The Articulation Joint gives the Haul Truck more flexibility of movement when making a turn.",
        position: "bottom"
      }
    ],
    __v: 0,
    createdAt: {
      $date: "2020-03-22T19:21:48.150Z"
    },
    updatedAt: {
      $date: "2020-03-22T19:21:48.150Z"
    }
  },
  {
    href: "/dashboard/machines/learn/wheeled-harvester",
    title: "Wheeled Harvester Machine",
    _id: {
      $oid: "5e77bacc5c8b1f192b25290d"
    },
    sizes: {
      small: {
        image: "https://cdn.komatsupartsu.com/h-small-harvester.jpg",
        description: "Models 901 and 911"
      },
      mid: {
        image: "https://cdn.komatsupartsu.com/h-medium-harvester.jpg",
        description: "Model 931"
      },
      large: {
        image: "https://cdn.komatsupartsu.com/h-large-harvester.jpg",
        description:
          "Model 951: this is the largest harvester on the market. Because it it already rated at the highest tonnage, there is no need to offer an XC version of this model."
      },
      mining: {
        image: "https://cdn.komatsupartsu.com/h-xl-harvester.jpg",
        description:
          "XC stands for 'Extreme Conditions'. These machines have 8 wheels instead of six and are available in model numbers 901, 991 and 931."
      }
    },
    xray_images: {
      colored_full:
        "https://cdn.komatsupartsu.com/forestry_harvester.jpg",
      xray_full:
        "https://cdn.komatsupartsu.com/forestry_harvester.jpg",
      batteries:
        "https://cdn.komatsupartsu.com/forestry_harvester.jpg",
      filters: "https://cdn.komatsupartsu.com/forestry_harvester.jpg",
      hydraulics:
        "https://cdn.komatsupartsu.com/forestry_harvester.jpg",
      lubricants:
        "https://cdn.komatsupartsu.com/forestry_harvester.jpg",
      reman: "https://cdn.komatsupartsu.com/forestry_harvester.jpg"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/new-Forestry_Harvester_901XCxparent.png",
    ez_id: "wheeled-harvester",
    name: "Wheeled Harvester",
    description:
      "LIMBING (PROCESSING)  Harvesters fell and process standing trees into merchandised products at the tree stump   Distinguishing features between models include weight, crane, capacity, HP and 6 or 8 Wheels.",
    model_number: "",
    machine_type: "Wheeled Harvester",
    sub_type: "Forestry",
    commodities: [
      {
        x: 90,
        y: 40,
        title: "Batteries",
        text:
          "Komatsu Wheeled Harvesters come equipped with two 4DLT size batteries. Because Forestry machines are exposed to high vibrations, temperature extremes and long operating hours, it is important to never sacrifice on quality when it comes to batteries.",
        position: "topRight"
      },
      {
        x: 80,
        y: 40,
        title: "Filters",
        text:
          "Due to the amount of debris generated during harvesting, high quality air filters are needed to ensure the air entering the engine is clear of harmful particulates. Furthermore, since many of the services and repairs on Forestry machines take place in the forest, there is a high risk of contaminants being introduced into the various systems. The engine, hydraulic and fuel filters ensure that those contaminants do not harm the key components of the machines.",
        position: "topLeft"
      },
      {
        x: 25,
        y: 60,
        title: "Hydraulics",
        text:
          "Hydraulics allow harvesters to perform their function of harvesting and processing timber. Hydraulic hoses have to be strong to withstand the demanding enviromental conditions the harvester operates in. Leaking hydraulic hoses will often lead to lower production as the Harvesting Head will not work at its optimal level",
        position: "topLeft"
      },
      {
        x: 50,
        y: 25,
        title: "Lubricants",
        text:
          "Harvesters operate in all weather conditions from frigid cold to sweltering heat. Quality lubrication ensures that components such as the engine, gearbox and differential are protected from premature wear.",
        position: "topRight"
      },
      {
        x: 55,
        y: 50,
        title: "Reman",
        text:
          "Komatsu is committed to reducing the owning and operating (O&O) costs of  Komatsu Forestry machines by delivering high-quality and competitively priced  re-manufactured products.   Every Komatsu re-manufactured component is built to perform as well as new, and is backed by Komatsu’s first in class Reman Warranty: One Year, Unlimited Hours.",
        position: "bottom"
      }
    ],
    files: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/trfKft28ic4?rel=0"
      }
    ],
    media: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/trfKft28ic4?rel=0"
      }
    ],
    popups: [
      {
        x: 70,
        y: 55,
        title: "Operators Cab",
        text:
          "The cab provides the machines operator an enclosed and safe environment to operate the machine. It protects the operator in case the machine is rolled or damaged. Unlike on a tracked machine, the cab on a wheeled harvester can rotate 360 degrees independently of the rest of the body",
        position: "topRight"
      },
      {
        x: 40,
        y: 75,
        title: "Boom",
        text:
          "The Boom connects the arm/attachment to the machine. Its primary function is to allow the lifting and lowering of the arm/attachment.",
        position: "topLeft"
      },
      {
        x: 10,
        y: 20,
        title: "Harvesting Head",
        text:
          "Harvesters come equipped with a harvesting head. The harvesting  head is capable of processing (felling, delimbing, and bucking) a tree right at the stump, eliminating the need for a processor at the landing",
        position: "left"
      },
      {
        x: 75,
        y: 30,
        title: "Bogie",
        text:
          "A bogie is a chassis or framework that is attached to the vehicle and carries a wheelset. Unlike a tracked machine, a harvester bogie uses wheels and rugged tires to provide traction and stability; however, tire chains can be installed to improve traction. Komatsu forwarders come in both a 6 or 8 wheel configuration",
        position: "bottom"
      },
      {
        x: 10,
        y: 65,
        title: "Arm",
        text:
          "The arm is the piece that connects the attachment to the boom. Its primary function is to extend or retract the attachment. The Komastu Harvester has a telescoping arm that can extend in length to increase reach."
      }
    ],
    __v: 0,
    createdAt: {
      $date: "2020-03-22T19:21:48.150Z"
    },
    updatedAt: {
      $date: "2020-03-22T19:21:48.150Z"
    }
  },
  {
    href: "/dashboard/machines/learn/feller-buncher",
    title: "Feller Buncher Machine",

    _id: {
      $oid: "5e77bacc5c8b1f192b25290e"
    },
    sizes: {
      small: {
        image: "https://cdn.komatsupartsu.com/fb-sm.jpg",
        description: "Model 430"
      },
      mid: {
        image: "https://cdn.komatsupartsu.com/fb-md.jpg",
        description: "Model 445"
      },
      large: {
        image: "https://cdn.komatsupartsu.com/fb-lg.jpg",
        description: "Model 465"
      },
      mining: {
        image: "",
        description: ""
      }
    },
    xray_images: {
      colored_full:
        "https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png",
      xray_full:
        "https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png",
      batteries:
        "https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png",
      filters:
        "https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png",
      hydraulics:
        "https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png",
      lubricants:
        "https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png",
      reman:
        "https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/new-Komatsu-Feller-Buncher-XT430-5-xparent.png",
    ez_id: "feller-buncher",
    name: "Feller Buncher",
    description:
      "FELLING & BUNCHING (PROCESSING)  A Tracked Feller Buncher cuts down (Fells) standing trees and positions them into piles (bunches) for another machine to transport to the roadside or landing. Typically a skidder is used for transportation of tree length material to the roadside or landing.    Distinguishing features between models include weight, crane capacity, under carriage and leveling vs non leveling.",
    model_number: "",
    machine_type: "Feller Buncher",
    sub_type: "Forestry",
    commodities: [
      {
        x: 75,
        y: 40,
        title: "Batteries",
        text:
          "Komatsu Feller Bunchers feature two 31P BCI size heavy duty batteries located in the rear of the machine. Because Forestry machines are exposed to high vibrations, temperature extremes and long operating hours, it is important to never sacrifice on quality when it comes to batteries.",
        position: "topRight"
      },
      {
        x: 45,
        y: 50,
        title: "Filters",
        text:
          "Many of the services and repairs on Forestry machines take place in the forest. As a result there is a high risk of contaminants being introduced into the various systems. The engine, hydraulic and fuel filters ensure that those contaminants do not harm the key components of the machines.",
        position: "topLeft"
      },
      {
        x: 30,
        y: 80,
        title: "Hydraulics",
        text:
          "Hydraulics allow feller bunchers to perform their function of felling entire trees.. Hydraulic hoses have to be strong to withstand the demanding environmental conditions the Feller Buncher operates in. Because Feller Bunchers and their attachments need incredible hydraulic pressure to fell tress whole, a malfunctioning hydraulic system will lead to decreased performance and eventually downtime.",
        position: "topLeft"
      },
      {
        x: 75,
        y: 50,
        title: "Lubricants",
        text:
          "Feller Bunchers operate in all weather conditions from frigid cold to sweltering heat. Quality lubrication ensures that components such as the engine, swing machinery and  final drives are protected from premature wear. This is particularly true of Tracked Feller Bunchers as these machines will work long hours, often time at maximum output.",
        position: "topRight"
      },
      {
        x: 55,
        y: 20,
        title: "Undercarriage",
        text:
          "Tracked Feller Bunchers spend all day traveling tree to tree, often over uneven and steep terrain. As a result, the undercarriage on a Tracked Feller Buncher is more robust than that found on the excavator. It features a heavier track chain, high density track rollers and rock guards to prevent damage to undercarriage components.",
        position: "bottom"
      },
      {
        x: 85,
        y: 20,
        title: "Reman",
        text:
          "Komatsu is committed to reducing the owning and operating (O&O) costs of  Komatsu Forestry machines by delivering high-quality and competitively priced  re-manufactured products.   Every Komatsu re-manufactured component is built to perform as well as new, and is backed by Komatsu’s first in class Reman Warranty: One Year, Unlimited Hours.",
        position: "bottom"
      }
    ],
    files: [],
    media: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/-4fjJEP93JM?rel=0"
      }
    ],
    popups: [
      {
        x: 80,
        y: 50,
        title: "Operators Cab",
        text:
          "The cab provides the machines operator an enclosed and safe environment to operate the machine. It protects the operator in case the machine is rolled or damaged. The cab on the XT Feller Buncher features more robust design compared to other tracked machines. This is to prevent falling debris damage.",
        position: "topRight"
      },
      {
        x: 55,
        y: 65,
        title: "Boom",
        text:
          "The Boom connects the arm/attachment to the machine. Its primary function is to allow the lifting and lowering of the arm/attachment.",
        position: "topLeft"
      },
      {
        x: 20,
        y: 20,
        title: "Felling Head",
        text:
          "A felling head, is an attachment used on a Feller Buncher. The felling head is used strictly for cutting, holding, and placing the cut trees on the ground. Unlike harvesting/processing heads, felling heads do not have tree processing capabilities (delimbing and bucking)",
        position: "left"
      },
      {
        x: 85,
        y: 25,
        title: "Undercarriage",
        text:
          "The undercarriage is the base of the machine. It provides stability, traction, and movement to the machine. Because Feller Bunchers need to traverse over uneven terrain and slopes, the Komatsu XT feller bunchers feature more rugged undercarriage components than those found on Excavators.",
        position: "bottom"
      },
      {
        x: 35,
        y: 55,
        title: "Arm",
        text:
          "The arm is the piece that connects the attachment to the boom. Its primary function is to extend or retract the attachment. The XT Feller bunchers feature increased guarding for components to prevent debris damage"
      },
      {
        x: 65,
        y: 35,
        title: "Leveling System",
        text:
          "The leveling system provides simultaneous front/rear/side leveling of the cab when working on slopes. Available on the XT445L-5 and 465L-5 models",
        position: "bottom"
      }
    ],
    __v: 0,
    createdAt: {
      $date: "2020-03-22T19:21:48.150Z"
    },
    updatedAt: {
      $date: "2020-03-22T19:21:48.150Z"
    }
  },
  {
    href: "/dashboard/machines/learn/forwarder",
    title: "Forwarder Machine",

    _id: {
      $oid: "5e77bacc5c8b1f192b25290f"
    },
    sizes: {
      small: {
        image: "https://cdn.komatsupartsu.com/fwd-sm.jpg",
        description: "Model 845"
      },
      mid: {
        image: "https://cdn.komatsupartsu.com/fwd-md.jpg",
        description: "Models 855 and 875"
      },
      large: {
        image: "https://cdn.komatsupartsu.com/fwd-lg.jpg",
        description: "Model 895"
      },
      mining: {
        image: "",
        description: ""
      }
    },
    xray_images: {
      colored_full:
        "https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png",
      xray_full:
        "https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png",
      batteries:
        "https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png",
      filters:
        "https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png",
      hydraulics:
        "https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png",
      lubricants:
        "https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png",
      reman:
        "https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/new-Komatsu-875-Forwarder_xparent.png",
    ez_id: "forwarders",
    name: "Forwarders",
    description:
      "CARRY  A Forwarder is used to follow behind a Harvester (Wheeled or Tracked) and transport the merchandised material to the roadside or landing. At that point the material will be stockpiled or loaded directly onto an truck for transportation to a processing facility.   Distinguishing features between models include weight, crane capacity, HP and payload capacity.",
    model_number: "",
    machine_type: "Forwarders",
    sub_type: "Forestry",
    commodities: [
      {
        x: 25,
        y: 40,
        title: "Batteries",
        text:
          "Komatsu Wheeled Forwarder come equipped with two 4DLT BCI size heavy duty batteries located at the front of the mahine. Because Forestry machines are exposed to high vibrations, temperature extremes and long operating hours, it is important to never sacrifice on quality when it comes to batteries. ",
        position: "topRight"
      },
      {
        x: 35,
        y: 47,
        title: "Filters",
        text:
          "Many of the services and repairs on Forestry machines take place in the forest. As a result there is a high risk of contaminants being introduced into the various systems. The engine, hydraulic and fuel filters ensure that those contaminants do not harm the key components of the machines. ",
        position: "topLeft"
      },
      {
        x: 50,
        y: 70,
        title: "Hydraulics",
        text:
          " Hydraulics allow forwarders to perform their function of loading and unloading processed lumber at the job site. Hydraulic hoses have to be strong to withstand the demanding enviromental conditions the forwarder operates in. Leaking hydraulic hoses will often lead to poor performance in the boom, arm and grapple thus lowering production",
        position: "topLeft"
      },
      {
        x: 50,
        y: 35,
        title: "Lubricants",
        text:
          "Forwarders operate in all weather conditions from frigid cold to sweltering heat. Quality lubrication ensures that components such as the engine, gearbox and differential are protected from premature wear. This is especially true of the bogie differential as the Forwarder has to traverse over uneven, steep terrain often carrying a heavy load.",
        position: "topRight"
      },
      {
        x: 30,
        y: 55,
        title: "Reman",
        text:
          "Komatsu is committed to reducing the owning and operating (O&O) costs of  Komatsu Forestry machines by delivering high-quality and competitively priced  re-manufactured products.   Every Komatsu re-manufactured component is built to perform as well as new, and is backed by Komatsu’s first in class Reman Warranty: One Year, Unlimited Hours.",
        position: "bottom"
      }
    ],
    files: [],
    media: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/tmb-l0Bp5XA?rel=0"
      }
    ],
    popups: [
      {
        x: 20,
        y: 65,
        title: "Operators Cab",
        text:
          "The cab provides an enclosed, environmentally conrolled and safe environment in which the operator can control and monitor the health of the machine. Roll-over protection (ROPS) and falling-object protection (FOPS) can also be incorporated into the cab to further protect the operator in certain working environments.",
        position: "topRight"
      },
      {
        x: 65,
        y: 50,
        title: "Arm",
        text:
          "The arm is the piece that connects the attachment to the boom. Its primary function is to extend or retract the attachment. The Komatsu Forwarder has a telescoping arm that can extend in length to increase reach.",
        position: "topLeft"
      },
      {
        x: 80,
        y: 30,
        title: "Bogie",
        text:
          "A bogie is a chassis or framework that carries a wheelset, attached to a vehicle. Unlike a tracked machine a forwarder bogie uses wheels and rugged tires to provide traction and stability. However tire chains can be installed to improve traction.",
        position: "bottom"
      },
      {
        x: 64,
        y: 40,
        title: "Grapple Attachment",
        text:
          "Forwarders come equipped with a log grapple attachment. The log grapple attachment is used load and unload logs",
        position: "bottom"
      },
      {
        x: 84,
        y: 50,
        title: "Long Bunk",
        text:
          'The log bunk is located at the rear of a forwarder The bunk is used to hold processed material while the forwarder moves it to another location. Unlike the bed of a haul truck, the log bunk is not enclosed on the sides. This allows the grapple to easily handle logs in the bunk. When not in use, the bunk is used to "store" the boom, arm and attachment for ease of transport (pictured)',
        position: "bottom"
      },
      {
        x: 50,
        y: 60,
        title: "Boom",
        text:
          "The Boom connects the arm/attachment to the machine. Its primary function is to allow the lifting and lowering of the arm/attachment.",
        position: "top"
      }
    ],
    __v: 0,
    createdAt: {
      $date: "2020-03-22T19:21:48.150Z"
    },
    updatedAt: {
      $date: "2020-03-22T19:21:48.150Z"
    }
  },
  {
    href: "/dashboard/machines/learn/forestry-excavator",
    title: "Forestry Excavator Machine",

    _id: {
      $oid: "5ec6cb15ba16c906d5fcb879"
    },
    sizes: {
      small: {
        image: "https://cdn.komatsupartsu.com/fe-small.jpg",
        description:
          "Model PC210LL (LL stands for 'Log Loader'). This model is used for processing."
      },
      mid: {
        image: "https://cdn.komatsupartsu.com/fe-medium.jpg",
        description: "Model PC240LL. This model is typically used for loading."
      },
      large: {
        image: "https://cdn.komatsupartsu.com/fe-large.jpg",
        description: "Models 290 and 390"
      },
      mining: {
        image: "",
        description: ""
      }
    },
    xray_images: {
      colored_full:
        "https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png",
      xray_full:
        "https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png",
      batteries:
        "https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png",
      filters:
        "https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png",
      hydraulics:
        "https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png",
      lubricants:
        "https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png",
      reman:
        "https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png"
    },
    thumbnail:
      "https://cdn.komatsupartsu.com/new-Komatsu-Forestry-Excavator-PC210LL-10-1200px_xparent.png",
    ez_id: "forestry-excavator",
    name: "Forestry Excavator",
    description:
      "GRAB  A Forestry Excavator is a purpose built forestry machine used in the following applications:  Log Loader: Equipped with a forestry front, cab riser and live heal grapple. Used for shovel logging, and truck loading.  Processor: Equipped with a processing attachment. Used at the roadside or landing to process tree length material into merchandised products.  Roadbuilder: Equipped with excavator front for building roads in forestry operations. ",
    model_number: "",
    machine_type: "Excavator",
    sub_type: "Forestry",
    commodities: [
      {
        x: 45,
        y: 40,
        title: "Batteries",
        text:
          "Komatsu Forestry Excavators feature two 4D BCI size heavy duty batteries located at the front of the machine. Because Forestry machines are exposed to high vibrations, temperature extremes and long operating hours, it is important to never sacrifice on quality when it comes to batteries.",
        position: "topRight"
      },
      {
        x: 60,
        y: 50,
        title: "Filters",
        text:
          "Many of the services and repairs on Forestry machines take place in the forest or at the landing. As a result there is a high risk of contaminants being introduced into the various systems. The engine, hydraulic and fuel filters ensure that those contaminants do not harm the key components of the machines.",
        position: "topLeft"
      },
      {
        x: 30,
        y: 90,
        title: "Hydraulics",
        text:
          " Hydraulics allow log loaders to perform their various functions, whether it's loading and unloading logs, or using a processing attachment to process lumber at the landing. Hydraulic hoses have to be strong to withstand the demanding enviromental conditions forestry excavator operates in. Because Forestry Excavators and their attachments need incredible hydraulic pressure to perform their functions, a malfunctioning hydraulic system will lead to decreased performance and possibly downtime",
        position: "topLeft"
      },
      {
        x: 80,
        y: 40,
        title: "Lubricants",
        text:
          "Forestry Excavators operate in all weather conditions from frigid cold to sweltering heat. Quality lubrication ensures that components such as the engine, swing machinery final drives are protected from premature wear. ",
        position: "topRight"
      },
      {
        x: 80,
        y: 30,
        title: "Reman",
        text:
          "Komatsu is committed to reducing the owning and operating (O&O) costs of  Komatsu Forestry machines by delivering high-quality and competitively priced  re-manufactured products.   Every Komatsu re-manufactured component is built to perform as well as new, and is backed by Komatsu’s first in class Reman Warranty: One Year, Unlimited Hours.",
        position: "bottom"
      },
      {
        x: 65,
        y: 10,
        title: "Undercarriage",
        text:
          "Despite their similarity to the construction excavators, Forestry Excavators use a purpose built Forestry undercarriage. The rugged undercarriage is designed from the ground up for demanding forestry applications using components from the larger size class excavator for high reliability, stability & durability.",
        position: "bottom"
      }
    ],
    files: [],
    media: [
      {
        type: "videoLink",
        data: "https://www.youtube.com/embed/RlUfvnnLfMI?rel=0"
      }
    ],
    popups: [
      {
        x: 75,
        y: 58,
        title: "Operators Cab",
        text:
          "The cab provides the machines operator an enclosed and safe environment to operate the machine. It protects the operator in case the machine is rolled or damaged. The cab on the Log Loader (LL) machine features more robust design compared to it's construction sibling—this is to prevent falling limbs and logs from penetrating the passenger compartment. The Log Loader variant comes equipped with a cab riser for increased visibility.",
        position: "topRight"
      },
      {
        x: 40,
        y: 70,
        title: "Boom",
        text:
          "The Boom connects the arm/attachment to the machine. Its primary function is to allow the lifting and lowering of the arm/attachment. On the Log Loading application, extra protection can be found on the underside of the boom to prevent damage from logs.",
        position: "right"
      },
      {
        x: 80,
        y: 35,
        title: "Revolving Frame",
        text:
          "The Revolving Frame houses the engine, hydraulic pumps and tanks, fuel, etc. and provides mounting for the Boom and Operator's Cab.",
        position: "left"
      },
      {
        x: 85,
        y: 25,
        title: "Undercarriage",
        text:
          "The undercarriage is the base of the machine. It provides stability and movement to the machine. Unlike their Feller Buncher and Harvester cousins, Forestry Excavators move very little; however, undercarriage is key in providing the machine with a stable base when handling material.",
        position: "bottom"
      },
      {
        x: 55,
        y: 24,
        title: "Swing Machinery",
        text:
          "The Swing Machinery is a circle gear located under the Operator's Cab designed to rotate the Upper Structure of the machine 360 degrees.",
        position: "bottom"
      },
      {
        x: 15,
        y: 65,
        title: "Arm",
        position: "left",
        text:
          "The arm is the piece that connects the attachment to the boom. Its primary function is to extend or retract the attachment."
      },
      {
        x: 30,
        y: 55,
        title: "Live Heel",
        position: "left",
        text:
          "A Log Loader arm will often come equipped with a live heel which assists in handling  of the logs by protecting the boom and controlling the end of the log."
      },
      {
        x: 20,
        y: 15,
        title: "Attachments",
        position: "left",
        text:
          "Forestry Excavators can be equipped with 2 distinct attachments: A log grapple or Processor Log Grapple: Used for loading proccesed wood (logs) onto trucks Processor: Used at the roadside or landing to process tree length material into merchandised products"
      }
    ],
    __v: 0,
    createdAt: {
      $date: "2020-03-22T19:21:48.148Z"
    },
    updatedAt: {
      $date: "2020-03-22T19:21:48.148Z"
    }
  }
]
