import { put, select } from "redux-saga/effects"

function* LocalStorageHandler() {
  const versionL = yield select((state) => state.local.version)
  const versionP = yield select((state) => state._persist.version)
  if (versionL !== versionP) {
    yield put({ type: "UPDATE_LOCAL_VERSION", payload: versionP })
    // localStorage.removeItem("persist:KPARTSU")
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }
}

export default LocalStorageHandler
