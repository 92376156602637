/* eslint-disable */
import React from 'react'

const MachineAction = ({ source }) => {
	return (
		<div className="d-flex justify-content-around align-items-end flex-row">
			{/* <video width="400" controls>
				<source src={source} type="video/mp4" />
			</video> */}
			<iframe
				width="560"
				height="315"
				src={source}
				frameBorder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen></iframe>
		</div>
	)
}

export default MachineAction
