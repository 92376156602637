/* eslint-disable */
import React, { useEffect, useState } from "react"
import { Row, Col, Spin, Modal } from "antd"

import { useParams } from "react-router-dom"
import { useCommodityScreen } from "../hooks/screen.hooks"

import { LoadingOutlined } from "@ant-design/icons"
import { useParrot } from "../hooks/parrot.hooks"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import { valuePropLinks } from "../../helpers/quiz.helpers"


const CommodityTechnical = () => {
  let [modal, setModal] = useState({
    visible: false,
    title: "",
    content: "",
    image: ""
  })
  let { commodity_ez_id } = useParams()
  const { commodity } = useCommodityScreen(commodity_ez_id)
  const { setParrot } = useParrot()

  const commodityState = useCommodityScreen(commodity_ez_id)

  const setModalDealership = () => {
    setModal({
      visible: true,
      title: "Value for the Dealership",
      content: commodityState.commodity.value_proposition[1],
      image: require("../../assets/images/icons/dealership-value-icon@3x.png")
    })
  }

  const setModalCustomer = () => {
    setModal({
      visible: true,
      title: "Value for the Customer",
      content: commodityState.commodity.value_proposition[0],
      image: require("../../assets/images/icons/customer-value-icon@3x.png")
    })
  }

  const closeModal = () => {
    setModal({
      visible: false
    })
  }

  const setModalToInactive = () => {}

  useEffect(() => {
    setParrot({
      dialog:
        "This page is all about why our Komatsu genuine parts are better for your customer and your dealership.  Can you help your customer see the value in buying Komastu genuine parts on their machines?  When you are ready, click the quiz head below to test your selling skill.",
      display: "TALKING",
      mode: 1
    })
    // dispatch({ type: 'RESET_COMMODITY_SCREEN' })
  }, [commodity_ez_id])
  return (
    <>
      <Spin
        spinning={commodityState.commodity.value_proposition.length < 1}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
        <Modal
          className="modal-70vw"
          onOk={closeModal}
          onCancel={closeModal}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
          okText="Close"
          visible={modal.visible}
          title={
            <h3 className="k-blue">
              <b>{modal.title}</b>
            </h3>
          }>
          <div className="d-flex justify-content-center align-items-center flex-row">
            <img
              className="object-fill mr-4"
              height={200}
              width={250}
              src={modal.image}
              alt="image"
            />
            <div dangerouslySetInnerHTML={{ __html: modal.content }} />
          </div>
        </Modal>
        <div className="page py-5">
          <div className="mb-5">
            <center>
              <strong>
                <h1 className={`${commodity_ez_id.toLowerCase()}-color capitalize-title`}>
                  <b>{commodity.name}</b>
                </h1>
              </strong>
              <div
                style={{ fontSize: 24, fontWeight: 600, fontFamily: "Roboto" }}>
                <b>Value Proposition</b>
              </div>
            </center>
          </div>
          <Row gutter={32}>
            <Col span={6} />
            <Col span={6}>
              <div
                className="d-flex justify-content-center align-items-center flex-column pointer-cursor"
                onClick={setModalCustomer}>
                <img
                  className="object-fill"
                  height={200}
                  width={200}
                  src={require("../../assets/images/icons/customer-value-icon@3x.png")}
                  alt=""
                />
                <h4 className="k-blue mt-3">
                  <b>Value for the Customer</b>
                </h4>
              </div>
            </Col>
            <Col span={6}>
              <div
                className="d-flex justify-content-center align-items-center flex-column pointer-cursor"
                onClick={setModalDealership}>
                <img
                  className="object-fill"
                  height={200}
                  width={200}
                  src={require("../../assets/images/icons/dealership-value-icon@3x.png")}
                  alt=""
                />
                <h4 className="k-blue mt-3">
                  <b>Value for the Dealership</b>
                </h4>
              </div>
            </Col>
          </Row>
        </div>

        {typeof valuePropLinks[commodity_ez_id.toLowerCase()] !==
        "undefined" && (
        <div>
          <TestYourKnowledge link={valuePropLinks[commodity_ez_id]} />
      <QuizPrompt onExitEarly={setModalToInactive} />
        </div>
      )}
      </Spin>
    </>
  )
}

export default CommodityTechnical
