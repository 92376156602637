import React from 'react'

export const FrostedGlass = ({
  children,
  shade = 'light',
  className = '',
  containerStyle = {}
}) => {
  return (
    <div
      className={`frost-container frost-container-${shade} p-4 ${className}`}
      style={containerStyle}>
      {children}
    </div>
  )
}
