import React from "react"

const AboutKomatsu = () => {
  return (
    <div className="page custom-p-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          src={require("../assets/images/komatsu-logo.png")}
          height={97}
          width={465}
          alt=""
        />
        <div className="p-2">
          <h4>
            <b>About Komatsu</b>
          </h4>
        </div>
      </div>
      <p>
        Komatsu America Corp. is a U.S. subsidiary of Komatsu Ltd., one of the
        world’s leading manufacturers and suppliers of earth-moving equipment,
        including construction, mining and compact construction equipment.
        Komatsu America also serves forklift and forestry markets. Through its
        distributor network, Komatsu offers a state-of-the-art parts and service
        program to support its equipment. Komatsu has proudly been providing
        high-quality reliable products for nearly a century.
      </p>
      <h5>
        <b>Fast Facts</b>
      </h5>
      <p>
        <ul>
          <li>Born: 1917</li>
          <li>Established: May 13,1921</li>
          <li>Established in America: February 17, 1970</li>
          <li>
            Locations: 151 nations around the world, including Japan, United
            States of America, China, Africa, Oceana, Middle East and Europe.
          </li>
          <li>
            Scale: World’s second largest producer of construction equipment.
          </li>
          <li>
            Advantage: We design and build every piece of our equipment so you
            have harmony from end to end.
          </li>
        </ul>
      </p>
      <h5>
        <b>Komatsu Way</b>
      </h5>
      <p>
        Komatsu began its business in 1921 in Komatsu City, Japan as a company
        providing repair work for European-made mining machinery for use in
        copper mines. Meitaro Takeuchi, the founder, was a man of foresight and
        defined the following guiding principles when he established Komatsu 86
        years ago; “overseas expansion”, “quality first”, “technology
        innovation”, and “human resource development”.Over the course of the
        years, Komatsu’s businesses have grown substantially and now employ some
        34,500 employees from around the world with overseas sales coming close
        to 70% of total sales.
      </p>
      <p>
        The Komatsu Way is made up of seven guiding principles based on
        Komatsu’s strengths built by our founder:
      </p>
      <h5>
        <b>The Seven Guiding Principles</b>
      </h5>
      <p>
        <b>Commitment to Quality and Reliability</b>
        <p>
          Komatsu is well known for the quality of the equipment that we produce
          but in the Komatsu Way, it does not stop at the quality of our
          equipment that we focus on. We expect our employees to constantly
          think continuous improvement and not settle for status quo. We place
          top priority on quality over all matters in our everyday work
          activities and will make no compromise in quality as we move into the
          future.
        </p>

        <b>Customer Oriented</b>
        <p>
          By valuing the opinions of our customers, we continue to manufacture
          the products that customers are proud to own. However, in the Komatsu
          Way we recognize our customers not only as the end users of our
          products but also recognize every employee has a “customer”. That
          customer is defined as the end user of that person’s work output. We
          put great emphasis on meeting the needs of all our customers, whoever
          they may be.
        </p>

        <b>Defining The Root Cause</b>
        <p>
          By clearly defining processes from product planning to follow up on
          operating conditions, including the occurrence of problems, we always
          work to find and fix the root cause of problems early in the process
          in order to prevent the problem from reoccurring.
        </p>

        <b>Workplace Philosophy (Gemba)</b>
        <p>
          Workplaces (Gemba) offer information which should constitute the
          foundation of our policies, strategies, improvement plans, and other
          vital initiatives. Therefore, it is important to understand what is
          going on in the workplace by making information “visible”. This
          information is a tool for us to continuously improve as a company.
        </p>

        <b>Policy Deployment</b>
        <p>
          When top management lays out management policies or goals, they shall
          be clearly communicated. On each level, employees should understand
          their respective roles, make their activity plan, and follow up on the
          implementation of their own initiatives.
        </p>

        <b>Collaborations With Business Partners</b>
        <p>
          When it comes to solving problems, improving operations, or sharing
          “know-how”, we recognize regardless of what department, function,
          location, or subsidiary we belong to, we all have to work together. We
          will continue to work for mutual growth as “One Komatsu”.
        </p>

        <b>Human Resource Development</b>
        <p>
          Employees (human capital) are the most valuable asset at Komatsu. The
          more our employees continue to grow and learn the more the company
          will benefit from their knowledge.
        </p>
      </p>
      <h4>
        <b>History and Heritage</b>
      </h4>
      <h5>
        <b>Our American Roots</b>
      </h5>
      <p>
        Although Komatsu started marketing its products in the 1960’s in the
        United States, our North American operations were established in 1970
        with a fresh vision for the industry. Our goal was to become more than
        just a supplier of superior construction equipment. We made a commitment
        to become a trusted partner that could provide ongoing support to every
        customer. Today, Komatsu America is the second-largest, fully-integrated
        manufacturer and supplier of construction equipment in North America
        with a reputation for reliability that’s second to none. We have built a
        network of manufacturing plants and parts. We manufacture many units in
        the United States to serve this market.
      </p>

      <p>
        Komatsu America Corp. (KAC) manufactures and markets a full line of
        integrated construction equipment from the smallest compact construction
        size to the largest mining size. Our product line includes intelligent
        Machine Control, hydraulic excavators, wheel loaders, crawler dozers,
        off-highway trucks, and motor graders. All KAC groups are supported by
        Komatsu Parts and Service and can be financed through Komatsu Financial.
      </p>
      <h5>
        <b>Our Heritage</b>
      </h5>
      <p>
        Our company was named after the city Komatsu, located in the Ishikawa
        Prefecture of the Hokuriku region in Japan. The actual name "Komatsu"
        translates into English as "little pine tree" and, according to legend,
        dates back to the middle of the Heian period (794-1192). At that time,
        the monk-emperor Kazan planted a pine sapling during a tour of the
        Hokuriku region, and the area where it grew came to be called "sono no
        komatsubara" ("small-pine field with gardens"). This name is said to
        have been shortened to "Komatsu".
      </p>

      <p>
        When "Komatsu Iron Works" separated from "Takeuchi Mining Co." in 1921
        to become "Komatsu Ltd.", the first logo that was chosen to represent
        the new-born company also symbolised this small pine tree. Our logo
        underwent many modifications over the years, but remained faithful to
        its original "little pine tree" look until the late nineteen-nineties,
        when a major change was decided, and the current lettering adopted.
      </p>

      <p>
        Today, the Komatsu logo is still the most basic design representing the
        Komatsu brand. It reflects the Komatsu image of reliability, stability,
        and strength. The well-balanced sophistication of the logo, the unique
        design of the letter "T" and the deep, vivid Gloria Blue colour
        symbolize a bold corporate character and a leader in technological
        innovation.
      </p>
      <a
        href="https://ilumalearning.com"
        rel="noopener noreferrer"
        target="_blank">
        Website designed and built by Iluma Learning
      </a>
    </div>
  )
}

export default AboutKomatsu
