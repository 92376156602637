/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
//document.documentElement.setAttribute("data-theme", "mydna");

const CommunicationWHoWHat = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="communicate"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1>Who to Contact</h1>
                    <p style={{"marginTop":"0px"}}>Who to contact for specific situations and how to best communicate with them</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>

                <div className="flex-row" >
                    <div class="swiper mySwiper">
                        <div class="swiper-wrapper">
                            <figure data-hash="slide1" class="swiper-slide">
                                <h3>Who to contact for what</h3>
                                <hr/>
                                <p>Knowing exactly who to contact for a specific question or issue will get you to a resolution much faster. Use the below reference chart to contact the right person at Ripley next time you have a question about parts pricing or availability, placing or managing an order</p>
                                <p>And remember, there is a contact list on Parts U for each commodity if you have a question that can't be answered by someone at Ripley. Click any commodity below to view it's contact list:</p>
                                <nav class="mini-nav">
                                    <a href="/dashboard/commodities/support/batteries" target="_blank">Batteries</a>
                                    <a href="/dashboard/commodities/support/filters" target="_blank">Filters</a>
                                    <a href="/dashboard/commodities/support/hydraulics" target="_blank">Hydraulics</a>
                                    <a href="/dashboard/commodities/support/lubricants" target="_blank">Lubricants</a>
                                    <a href="/dashboard/commodities/support/reman" target="_blank">Reman</a>
                                    <a href="/dashboard/commodities/support/undercarriage" target="_blank">Undercarriage</a>
                                    <a href="/dashboard/commodities/support/sr-drive-system" target="_blank">SR Drive System</a>
                                    <a href="/dashboard/commodities/support/forestry" target="_blank">Forestry</a>
                                </nav>
                                <h3>Ripley Contact Routes:</h3>
                                <table class="contact-table" style={{color:"black"}}>
                                    <tbody>
                                        <tr>
                                            <td class="ic-code header-row" style={{"borderTopLeftRadius":"5px"}}>Question or Issue</td>
                                            <td class="ic-definition header-row" style={{"borderTopRightRadius":"5px"}}> Who to contact</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">You don't have access to a function</td>
                                            <td class="ic-definition">Ask the KPP admin at your dealership if they can give you access. If they can't, submit a K-Request.</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">A function doesn't work</td>
                                            <td class="ic-definition">Submit a K-Request and provide details on the issue you are experiencing.</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">A function or process is unclear</td>
                                            <td class="ic-definition">
                                                <tr>
                                                    <td class="ic-code">DB dashboard/Work Queue</td>
                                                    <td class="ic-definition">Your assigned customer care rep</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">Communication</td>
                                                    <td class="ic-definition">Your assigned customer care rep</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">Sales Order</td>
                                                    <td class="ic-definition">Your assigned customer care rep</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">Order Status</td>
                                                    <td class="ic-definition">Your assigned customer care rep</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">Availability</td>
                                                    <td class="ic-definition">Your assigned customer care rep </td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">General invoice inquiry</td>
                                                    <td class="ic-definition">Your assigned customer care rep</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">Invoice adjustments</td>
                                                    <td class="ic-definition">Your assigned customer care rep</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">Returns</td>
                                                    <td class="ic-definition">Returns Specialist</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">Depot</td>
                                                    <td class="ic-definition">Brandi Smith</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">RIM</td>
                                                    <td class="ic-definition">RIM Specialist</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">CTS</td>
                                                    <td class="ic-definition">Teresa King </td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code">PAL</td>
                                                    <td class="ic-definition">Parts Technical Specialist</td>
                                                </tr>
                                                <tr>
                                                    <td class="ic-code" style={{"borderBottomLeftRadius":"5px"}}>Price Review</td>
                                                    <td class="ic-definition" style={{"borderBottomRightRadius":"5px"}}>Pricing Specialist </td>
                                                </tr>
                                            </td>
                                        </tr>                                        
                                        <tr>
                                            <td class="ic-code">System Error Message</td>
                                            <td class="ic-definition">Submit a K-Request with what you were trying to do and the error details</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">Changing default carrier codes</td>
                                            <td class="ic-definition">Customer Care</td>
                                        </tr>
                                        <tr></tr>
                                        <tr>
                                            <td class="ic-code">Changing default forwarder codes</td>
                                            <td class="ic-definition">Customer Care</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">Changing sourcing sequence</td>
                                            <td class="ic-definition">Customer Care</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">Customer Master Change</td>
                                            <td class="ic-definition">Customer Care</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">When is my order going to ship</td>
                                            <td class="ic-definition">Customer Care</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">When is backorder going to arrive</td>
                                            <td class="ic-definition">Customer Care</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">I'm missing parts in my package</td>
                                            <td class="ic-definition">Create SIE (Shipped in Error) Claim Request in <a href="https://na.parts.komatsu/Home/MainPage" target="_blank">KPP</a> under Part Return Requests </td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">I received extra parts in my package</td>
                                            <td class="ic-definition">Create SIE Claim Request</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">I recieved the wrong parts</td>
                                            <td class="ic-definition">Create SIE Claim Request</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">Incorrect part price on invoice</td>
                                            <td class="ic-definition">Create Invoice Adjustment in KPP &amp; attach supporting documentation (in KPP under Invoice Adjustment)</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code">Incorrect freight charge</td>
                                            <td class="ic-definition">Creat Invoice Adjustment Request</td>
                                        </tr>
                                        <tr>
                                            <td class="ic-code" style={{"borderBottomLeftRadius":"5px"}}>Refund of ASL Fee / EO Fee</td>
                                            <td class="ic-definition" style={{"borderBottomRightRadius":"5px"}}>Create Invoice Adjustment Request</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </figure>
                        </div>
                    </div>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default CommunicationWHoWHat
