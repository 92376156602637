/* eslint-disable */
import React from "react"
import { Row, Col, Divider } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import { commodityHowItWorksLinks } from "../../helpers/quiz.helpers"

import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
const SrDriveSystemHowItWorks = () => {
  const setModalToInactive = () => {
    let currentUrlParams = new URLSearchParams(window.location.search)
    // currentUrlParams.set('quiz_active', 'false')
  }
  return (
    <div className="page">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="sr-drive-system-color">
          <b>SR Drive System</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="py-5">
            <center>
                    {/* <strong>
                  <h1 className={`${commodity_ez_id.toLowerCase()}-color`}>
                    <b>{commodity_ez_id.toUpperCase()}</b>
                  </h1>
                </strong> */}
              <div
                style={{ fontSize: 26, fontWeight: 600, fontFamily: "Roboto" }}>
                <b>How it Works</b>
              </div>
            </center>
          </div>
          <div className="align-items-center">
            <div>
              <p>
              The engine and generator work to supply the initial power used throughout the system. The power is then sent to the front end converters, across the DC Bus and into the load side converters. From there the power is delivered to the wheel motors where they spin the drivers and cause the wheels to turn.
              </p>
              <p>
              When the machine goes into regenerative braking mode, the momentum of the wheel motors produce electricity. The electricty is sent from the wheel motors back to the converters to power the hydraulic motors. Any additional electricity is sent to the KESS unit to store for a later event or across the braking grid where it is dispersed as heat.
              </p>
            </div>
            <center>
            <img
              className="image-contain mr-4"
              height={"auto"}
              width={"100%"}
              src={`https://cdn.komatsupartsu.com/sr-drive-system-overview.jpg`}
              alt="visual diagram of how the SR Drive System Works"
              style={{
                marginTop: "2vw"
              }}
            />
            </center>
          </div>
          <Divider />
          <p style={{ fontSize: 26 }}>
            <b>Converter Panel</b>
          </p>
          <div className="d-flex align-items-center flex-lg-row flex-sm-column mb-4">
            <img
              className="image-contain mb-3"
              height={392}
              width={411}
              src={`https://cdn.komatsupartsu.com/SR-drive-system-Converter-Panel_.png`}
              alt=""
            />

            <div className="p-4">
            <p>The converter panels can handle bidirectional power, meaning it can put energy on or remove energy from the common bus.</p>
              <p>The three sets of switches turn on and off passing energy through the coils in the electrical rotating device (Motor or Generator) allowing it to generate or consume energy by rotating.</p>
              <p>The 7th switch or “chopper” is connected to a braking resistor (Braking grid) that helps consume excessive common bus energy during large regenerative braking events. These grids are the last device turned on to consume energy since we want to capture energy in the engine / generator or ESD.  Only when both of these are saturated does the system allow current to pass across these resistors.</p>
            </div>
          </div>
          <Divider />
          <p style={{ fontSize: 26 }}>
            <b>Electrical Rotating Devices</b>
          </p>
          <p>Devices that consume or create energy by rotation</p>
          <div className="d-flex align-items-center flex-lg-row flex-sm-column mb-4">
            <img
              className="image-contain mb-3"
              height={235}
              width={175}
              src={`https://cdn.komatsupartsu.com/sr-drive-system-generator.png`}
              alt="SR Drive System Generator"
            />

            <div className="p-4">
            <p><b>Generator</b></p>
              <p>The generator is attached to the engine and is used to maintain a set voltage on the common bus. In propel, it generates power to replace energy that is being removed from the common bus by the wheel motors. When the machine is in regenerative braking, it switches into a motoring mode dissipating the excess energy created by the motors as they slow the vehicle speed. In the motoring mode the generator is turning the diesel engine as well as the hydraulics that are attached. </p>
            </div>
          </div>
          <div className="d-flex align-items-center flex-lg-row flex-sm-column mb-4">
            <img
              className="image-contain mb-3"
              height={192}
              width={175}
              src={`https://cdn.komatsupartsu.com/sr-drive-system-motor.png`}
              alt="SR Drive System Motor"
            />

            <div className="p-4">
            <p><b>Wheel Motor</b></p>
              <p>There is one motor hooked up to a driver or planetary gear in each wheel. The motor provides the power to turn the gears to move the machine. The motors can produce excess power due to inertia and act as a generator.</p>
            </div>
          </div>

          <div className="d-flex align-items-center flex-lg-row flex-sm-column mb-4">
            <img
              className="image-contain mb-3"
              height={192}
              width={175}
              src={`https://cdn.komatsupartsu.com/KESS-Product-Image.png`}
              alt="KESS"
            />

            <div className="p-4">
            <p><b>KESS</b></p>
              <p>(Kinetic Energy Storage System) – KESS is an energy storage device or a deep energy storage system that takes the excess energy produced during the loading cycle and releases it at optimal times to supplement engine power. The KESS stores its power thanks to a flywheel. </p>
            </div>
          </div>
          <Divider />
          <div className="align-items-center">
          <p style={{ fontSize: 26 }}>
            <b>Example of an SR Drive System on WE1850</b>
          </p>
            <center>
            <img
              className="image-contain mr-4"
              height={"auto"}
              width={"100%"}
              src={`https://cdn.komatsupartsu.com/WE1850-Drive-System-Example.jpg`}
              alt="example of an SR Drive System on a WE1850"
              style={{
                marginTop: "2vw",
                marginBottom: "2vw"
              }}
            />
            </center>
          </div>
          
        </Col>
      </Row>
      <TestYourKnowledge link={commodityHowItWorksLinks.srdrivesystem} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default SrDriveSystemHowItWorks
