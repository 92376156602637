/* eslint-disable */
import React from "react"
import { AutoComplete, Select } from "antd"
import Fuse from "fuse.js"
import { Searchable } from "../../data/searchable.data"

import { useState } from "react"
const { Option } = Select

const options = {
  isCaseSensitive: false,
  shouldSort: true,
  includeMatches: true,
  // threshold: 0.1,
  // location: 0,
  // distance: 100,
  // maxPatternLength: 32,
  // minMatchCharLength: 1,
  keys: [
    // "aka",
    { name: "name", weight: 4 },
    "ez_id",
    "definition",
    "safety_prompt",
    "warranty",
    "questions_to_ask",
    "product_offering",
    "content.data.definition",
    "content.data.content_name",
    "content.title",
    "machine_type",
    "commodity"
  ]
}
const fuse = new Fuse(Searchable, options)

const SearchToolbox = ({ context = "header" }) => {
  const [data, setData] = useState([])
  const onSelect = (value) => {
    // console.log()
    window.location.href = data[value].item.href
  }

  const onChange = (value) => {
    const results = fuse.search(value)

    setData(results)
  }

  const datalist = data.map((d, i) => (
    <Option key={d.item.name} value={i}>
      <h6>
        <b>{d.item.title}</b>
      </h6>
      {d.matches
        .map((match, i) => {
          if (i < 3) {
            return (
              <div key={match.value} className="trunc">
                <div style={{ fontSize: 8 }}>
                  <b>{match.value}</b>
                </div>
              </div>
            )
          } else {
            return null
          }
        })
        .reverse()}
    </Option>
  ))

  return (
    <div>
      {context === "header" && (
        <h1>
          <b>Search</b>
        </h1>
      )}
      {context === "parrot" && (
        <h6>
          <p>What can I help you find?</p>
        </h6>
      )}
      <Select
        style={{ width: "100%", zIndex: 1000, position: "relative" }}
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={onChange}
        onChange={onSelect}
        notFoundContent={null}>
        {datalist}
      </Select>
    </div>
  )
}

export default SearchToolbox
