const initialState = {
  ez_id: '',
  name: '',
  definition: '',
  terms: [],
  safety_prompt: '',
  value_proposition: [],
  tools_and_resources: [],
  help_contacts: [],
  related: [],
  warranty: '',
  location: '',
  questions_to_ask: '',
  product_offering: '',
  technical: ''
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RESET_COMMODITY_SCREEN':
      return initialState
    case 'GET_COMMODITY_SUCCESS':
      return payload
    default:
      return state
  }
}
