import React from 'react'
const MachineSizeCard = ({ image, description, index, name, machine }) => {
  return (
    <div className='d-flex flex-column justify-content-end align-top p-2'>
      <center>
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // minHeight: 150,
            // minWidth: 150,
            height: index * 15 + 200,
            width: index * 15 + 200
            // maxHeight: 300,
            // maxWidth: 300
          }}
        />
        <b style={{ fontSize: 16, textTransform: "capitalize" }}>
          {machine.ez_id === 'wheeled-harvester' && name === 'mining'
            ? 'XC'
            : name}
        </b>
        <p
          style={{
            fontSize: 12,
            textAlign: 'center',
            width: 200,
            minHeight: 100
          }}>
            <div
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
        </p>
      </center>
    </div>
  )
}
export default MachineSizeCard
