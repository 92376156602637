/* eslint-disable */
import {
  userSelector,
  isLoggedInSelector
} from "../../store/selectors/selectors"
import { useSelector } from "react-redux"

export const useCredentials = () => {
  const user = useSelector(userSelector)
  // const loggedIn = useSelector(isLoggedInSelector)
  return {
    user
    // loggedIn
  }
}
