import React from "react"
import { Row, Col, Divider } from "antd"
import { useParams } from "react-router-dom"
import TestYourKnowledge from "../components/common/icons/test-your-knowledge"
import QuizPrompt from "../components/quizes/prompts/quizprompt.quiz"
import { useCommodityScreen } from "../components/hooks/screen.hooks"

import { commodityMatesLinks } from "../helpers/quiz.helpers"

const data = {
  batteries: [
    {
      content: [
        "",
        "• battery charger/tester \n • clamps \n • cables \n • terminals \n • safety gloves \n • glasses \n • face mask"
      ],
      name: "Battery"
    }
  ],
  filters: [
    {
      content: ["• Engine Oil", "• Pre-Fuel Filter \n• Cabin Air filter \n • Engine Air Filter"],
      name: "Engine Oil Filter"
    },
    {
      content: ["• Hydraulic oil", "• O-ring \n• Engine Air Filter"],
      name: "Hydraulic Filter"
    },
    {
      content: ["• Pre-Fuel Filter", "• Engine Oil Filter \n• Engine Air Filter"],
      name: "Main Fuel Filter"
    },
    {
      content: ["• Oil", "• O-ring (for transmission strainer) \n• Engine Air Filter"],
      name: "Transmission/HST Filter"
    }
  ],
  hydraulics: [
    {
      content: [
        "• O-rings \n • Hydraulic Oil \n • Filters",
        "• Clamps/Hardware \n • Protective sleeves/Wraps \n • Adapters"
      ],
      name: "Hydraulic Hose"
    }
  ],
  lubricants: [
    {
      content: ["", "• Hydraulic Filters \n • Engine Oil Filters \n • O-rings"],
      name: "Lubricants"
    }
  ],
  reman: [
    {
      content: [
        "• Mounting Gasket \n• O-rings \n• Oil Filter \n• Sealant \n• Bolts \n• Nuts & Studs \n• Oil (30W) \n• Oil supply gaskets/seals \n• Installation Guidelines",
        "• Oil drain hoses (inlet/outlet) \n • Oil drain mounting belts & nuts"
      ],
      name: "Turbocharger"
    },
    {
      content: [
        "• Installation Guidelines",
        "• Main power cable \n • Dielectric grease \n • Anti-corrosion spray \n • New gaskets (if wet clutch) \n • Starter mounting bolts & shims"
      ],
      name: "Starter"
    },
    {
      content: [
        "• Mounting gasket \n • Sealant \n • Installation guidelines",
        "• Coolant hoses \n • Belt \n • Super Coolant \n • Hose Clamps \n • If overheating: thermostat & gasket"
      ],
      name: "Water Pump"
    },
    {
      content: [
        "• Installation Guidelines",
        "• Belt \n • Pulley \n • Anti-seize compound \n • Batteries \n • Corrosion resistance spray \n • Ground strap \n • Connecting cables"
      ],
      name: "Alternator"
    }
  ],
  undercarriage: [
    {
      content: [
        "• Mounting hardware \n • Sprockets",
        "• Carrier Rollers \n • Track Shoes \n • Bottom Rollers \n • Idlers"
      ],
      name: "Undercarriage Link Assembly"
    }
  ],
  "sr-drive-system": [
    {
      content: [
        "• Bearings \n • Cables",
        ""
      ],
      name: "SR Drive System"
    }
  ]
}

const Mates = () => {
  const setModalToInactive = () => {}
  const { commodity_ez_id } = useParams()
  const { commodity } = useCommodityScreen(commodity_ez_id)
  return (
    <>
      <div className="page">
        <div className="py-5">
          <center>
            <strong>
              <h1 className={`${commodity_ez_id.toLowerCase()}-color capitalize-title`}>
                <b>{commodity.name}</b>
              </h1>
            </strong>
            <h4>
              <b>Mates & Relates</b>
            </h4>
          </center>
        </div>
        <Row>
          <Col span={6} />
          <Col span={12}>
            {data[commodity_ez_id].map((dat) => {
              return (
                <div key={dat.name} className="py-4">
                  <Divider />
                  <h5 className={`${commodity_ez_id.toLowerCase()}-color`}>
                    <b>{dat.name}</b>
                  </h5>
                  <p>
                    <i>
                      When a customer orders an {dat.name.toLowerCase()} they:
                    </i>
                  </p>
                  <Row>
                    <Col
                      span={dat.content[1].length > 0 ? 10 : 24}
                      className="mr-4">
                      {dat.content[0].length > 0 && (
                        <div className="mates-chart" style={{ height: 300 }}>
                          <div>
                            <b>Probably Also Need:</b>
                          </div>
                          <div>{dat.content[0]}</div>
                          {commodity_ez_id === "undercarriage" && (
                            <div style={{ fontSize: 14 }} className="px-4">
                              <i>
                                always recommend Sprockets with new UC Link
                                Assemblies to ensure new goes on new
                              </i>
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      span={dat.content[0].length > 0 ? 10 : 24}
                      className="mr-4">
                      {dat.content[1].length > 0 && (
                        <div className="mates-chart" style={{ height: 300 }}>
                          <div>
                            <b>Might Also Need:</b>
                          </div>
                          <div>{dat.content[1]}</div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              )
            })}
          </Col>
        </Row>
      </div>
      <TestYourKnowledge
        link={commodityMatesLinks[commodity_ez_id.toLowerCase()]}
      />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </>
  )
}

export default Mates
