/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
//document.documentElement.setAttribute("data-theme", "mydna");

const PartLookupSbom = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)
    const pagination = {
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
      },
    };
    const slideItems = sbomSlides.map((slide, index) =>
        <SwiperSlide id={'slide'+(index+1)} data-hash={'slide'+(index+1)}>
            <h3>{slide.title}</h3>
            <hr/>
            {slide.description}
            <img src={slide.image} alt="step 1"/>
        </SwiperSlide>
    );

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="lookup"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1 style={{
                        color:'var(--look-up) !important'
                    }}>S-BOM Part Lookup</h1>
                    <p style={{"marginTop":"0px"}}>How to lookup a part number in S-BOM</p>
                </div>
                <a className="platform-link" href="https://sbom.komatsuamerica.com/InService/delivery/" target="_blank">Follow along in S-BOM <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                        <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Begin your search" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Select the model range" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Select correct model" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Select model number" onClick={() =>sliderNavClicked(4)}>4</a></li>
                        <li><a href="#slide5" className={"step-title " + (activeSlide === 5 ? 'active' : '')} data-text="Choose the correct parts list" onClick={() =>sliderNavClicked(5)}>5</a></li>
                        <li><a href="#slide6" className={"step-title " + (activeSlide === 6 ? 'active' : '')} data-text="Select the system" onClick={() =>sliderNavClicked(6)}>6</a></li>
                        <li><a href="#slide7" className={"step-title " + (activeSlide === 7 ? 'active' : '')} data-text="Select the part or parent part" onClick={() =>sliderNavClicked(7)}>7</a></li>
                        <li><a href="#slide8" className={"step-title " + (activeSlide === 8 ? 'active' : '')} data-text="Locate the exact part" onClick={() =>sliderNavClicked(8)}>8</a></li>
                        <li><a href="#slide9" className={"step-title " + (activeSlide === 9 ? 'active' : '')} data-text="Capture your part numbers" onClick={() =>sliderNavClicked(9)}>9</a></li>
                    </ol>
                </nav>

                <div className="flex-row" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1'>
                          <h3>Search by machine model or serial number</h3>
                          <hr/>
                          Type the machine model number or serial number in the search
                            box, or click the "Select Product" dropdown menu and select your customer's machine type.
                          <img src={require('../../images/sbom-slide-1.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                          <h3>Select the model range</h3>
                          <hr/>
                          Choose the correct model range for your customer's machine from the options provided.
                          <img src={require('../../images/sbom-slide-2.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                          <h3>Select correct model</h3>
                          <hr/>
                          Select the your customer's machine model.
                          <img src={require('../../images/sbom-slide-3.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                          <h3>Select correct model number</h3>
                          <hr/>
                          Select your customer's exact model number.
                          <img src={require('../../images/sbom-slide-4.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide5' data-hash='slide5'>
                          <h3>Choose the correct parts list</h3>
                          <hr/>
                          Select the correct parts list. In North America, you will typically choose the Overseas Version.
                          <img src={require('../../images/sbom-slide-5.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide6' data-hash='slide6'>
                          <h3>Select the correct system</h3>
                          <hr/>
                          Select the correct system or category on the machine for the part you are looking up.
                          <img src={require('../../images/sbom-slide-6.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide7' data-hash='slide7'>
                          <h3>Select the part or parent part</h3>
                          <hr/>
                          Select the correct part or parent part to view the part number(s).
                          <img src={require('../../images/sbom-slide-7.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide8' data-hash='slide8'>
                          <h3>Locate specific part you need in list provided</h3>
                          <hr/>
                          <p>Look through the list to locate the exact part you need, as well as any potential Mates
                            &amp; Relates parts.</p>
                          <p class="note">Clicking a part in the list on the right will highlight
                            it in the diagram. This can help you verify that you are making a correct part
                            identification, as well as identify Mates &amp; Relates parts. Also, pay attention to
                            any available notes for the part you need.</p>
                          <img src={require('../../images/sbom-slide-8.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide9' data-hash='slide9'>
                          <h3>Capture your part number(s)</h3>
                          <hr/>
                          <p>Make a note of the part number(s) you need so you have that
                            precise information available
                            when you place the order.</p>
                          <img src={require('../../images/sbom-slide-9.jpg')} alt="step 1"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default PartLookupSbom
