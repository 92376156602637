/* eslint-disable */
import React, { useState } from "react"
import { Menu, Avatar, Layout, Button } from "antd"
import { Link } from "react-router-dom"
import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined
} from "@ant-design/icons"
const { Sider } = Layout

const SiderNav = () => {
  const [collapsed, setcollapsed] = useState(true)

  const toggleCollapsed = () => {
    setcollapsed(!collapsed)
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: 15,
          top: 15,
          color: "white",
          zIndex: 1000
        }}>
        <b>Parts University</b>
      </div>

      <Sider
        collapsedWidth="0"
        collapsible
        mode="inline"
        collapsed={collapsed}
        onCollapse={() => {
          toggleCollapsed()
        }}
        style={{ zIndex: 1000 }}
        theme="light">
        <Button
          type="primary"
          onClick={() => {
            toggleCollapsed()
          }}
          style={{ marginBottom: 16, transform: "translateX(-50px)" }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </Button>
        <Menu
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}>
          <Menu.Item key="5">
            <Link
              onClick={() => {
                toggleCollapsed()
              }}
              to="/dashboard/user/profile">
              <Avatar size={40}>User</Avatar>
            </Link>
          </Menu.Item>
          <Menu.Item key="1">
            <Link
              onClick={() => {
                toggleCollapsed()
              }}
              to="/dashboard/machines">
              <b>HOME</b>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/dashboard/about">
              <b>ABOUT</b>
            </Link>
          </Menu.Item>
          <Menu.SubMenu key="3" title={<b>LEARN</b>}>
            <Menu.SubMenu
              key="/dashboard/machines"
              title={
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/machines">
                  <b>Machines</b>
                </Link>
              }>
              <Menu.Item key="/dashboard/machines/learn/excavator">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/machines/learn/excavator">
                  Excavator
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/machines/learn/dozer">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/machines/learn/dozer">
                  Dozer
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/machines/learn/wheel-loader">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/machines/learn/wheel-loader">
                  Wheel Loader
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/machines/learn/haul-truck">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/machines/learn/haul-truck">
                  Haul Truck
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              key="g2"
              title={
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/commodities">
                  <b>Commodities</b>
                </Link>
              }>
              <Menu.Item key="/dashboard/commodities/learn/batteries">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/commodities/learn/batteries">
                  Batteries
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/filters">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/commodities/learn/filters">
                  Filters
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/hydraulics">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/commodities/learn/hydraulics">
                  Hydraulics
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/lubricants">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/commodities/learn/lubricants">
                  Lubricants
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/reman">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/commodities/learn/reman">
                  Reman
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/commodities/learn/undercarriage">
                <Link
                  onClick={() => {
                    toggleCollapsed()
                  }}
                  to="/dashboard/commodities/learn/undercarriage">
                  Undercarriage
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.SubMenu>
          <Menu.Item key="4">
            <b>TOOLBOX</b>
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  )
}

export default SiderNav
