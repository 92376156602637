/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
const CommodityBox = ({
  clickImage,
  top = 0,
  left = 0,
  isLink = false,
  label = '',
  header,
  content,
  index,
  currentOpened,
  labelColor = 'black',
  onClick = () => {}
}) => {
  const dispatch = useDispatch()
  const [stage, setStage] = useState(0)
  const [lbC, setlbC] = useState('#808285')

  const resetStage = () => {
    if (currentOpened !== index) {
      setlbC('#808285')
      setStage(0)
    }
  }
  const mouseOverStage = () => {
    if (currentOpened !== index) {
      setlbC(labelColor)
      setStage(1)
      dispatch({
        type: 'PARROT_EVENT',
        payload: 'commodity_box'
      })
    }
  }
  const clickStage = () => {
    if (stage !== 2) {
      setlbC(labelColor)
      onClick({
        header: header,
        content,
        index
      })
      setStage(2)
    } else {
      setlbC('#808285')
      onClick({
        header: '',
        content: '',
        index
      })
      setStage(0)
    }
  }
  const determineStageImage = () => {
    if (stage === 0) {
      return require('../../assets/images/Topic_Icons2_ClosedBox.png')
    } else if (stage === 1) {
      if (isLink) {
        return clickImage
      } else {
        return require('../../assets/images/Topic_Icons2_Half OpenBox.png')
      }
    } else if (stage === 2 || currentOpened === index) {
      return clickImage
    }
  }
  useEffect(() => {
    if (currentOpened !== index) {
      setlbC('#808285')
      setStage(0)
    }
  }, [currentOpened])
  return (
    <div
      onMouseLeave={resetStage}
      onMouseOver={mouseOverStage}
      onClick={clickStage}
      className='commodity-shelf-item'
      style={{
        backgroundImage: `url(${determineStageImage()})`,
        top,
        left
      }}>
      <div className='commodity-shelf-item-label' style={{ color: `${lbC}` }}>
        {label}
      </div>
    </div>
  )
}
export default CommodityBox
