/* eslint-disable */
import React, { useEffect } from "react"
import { Editor, EditorState, RichUtils } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import { Button } from "antd"
import { useNotes } from "../hooks/notes.hooks"

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "P", style: "paragraph" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" }
]

const MyEditor = ({ cancel = () => {}, save = () => {} }) => {
  const notesState = useNotes()
  let className = "RichEditor-editor"

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  )

  const editor = React.useRef(null)

  function focusEditor() {
    editor.current.focus()
  }

  const onCancel = () => {
    cancel()
  }

  const onSave = () => {
    save()
    const contentState = editorState.getCurrentContent()
    notesState.createNote({ text: stateToHTML(contentState) })
  }

  const onChange = (editorState) => {
    // const contentState = editorState.getCurrentContent()
    // console.log("content state", stateToHTML(contentState), contentState)
    setEditorState(editorState)
  }

  const _handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      onChange(newState)
      return true
    }
    return false
  }

  const _onTab = (e) => {
    const maxDepth = 4
    onChange(RichUtils.onTab(e, editorState, maxDepth))
  }

  const _toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  const _toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  React.useEffect(() => {
    focusEditor()
  }, [])

  return (
    <>
      <div className="RichEditor-root p-4">
        <BlockStyleControls
          editorState={editorState}
          onToggle={_toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={_toggleInlineStyle}
        />
        <div className={`${className}`} onClick={focusEditor}>
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={(editorState) => setEditorState(editorState)}
          />
        </div>
      </div>
      <div className="py-4">
        <Button onClick={onCancel}>CANCEL</Button>
        <Button className="ml-3" type="primary" onClick={onSave}>
          SAVE
        </Button>
      </div>
    </>
  )
}

const StyleButton = ({ active, style, onToggle, label }) => {
  let className = "RichEditor-styleButton"

  const toggle = (e) => {
    e.preventDefault()
    onToggle(style)
  }

  useEffect(() => {
    if (active) {
      className += " RichEditor-activeButton"
    }
  }, [])

  return (
    <span className={className} onMouseDown={toggle}>
      {label}
    </span>
  )
}

const BlockStyleControls = (props) => {
  const { editorState } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" }
  //   { label: "Monospace", style: "CODE" }
]

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle()
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}
export default MyEditor
