export const roles = [
    {label: 'Parts Counter', value: 'Parts Counter'},
    {label: 'Parts Warehouse', value: 'Parts Warehouse'},
    {label: 'Parts Manager', value: 'Parts Manager'},
    {label: 'PSSR', value: 'PSSR'},
    {label: 'Field Technician', value: 'Field Technician'},
    {label: 'Shop Technician', value: 'Shop Technician'},
    {label: 'Service Management', value: 'Service Management'},
    {label: 'Preventative Maintenance', value: 'Preventative Maintenance'},
    {label: 'Warranty', value: 'Warranty'},
    {label: 'Technical Solutions Expert', value: 'Technical Solutions Expert'},
    {label: 'Operator', value: 'Operator'},
    {label: 'Salesperson', value: 'Salesperson'},
    {label: 'Sales Manager', value: 'Sales Manager'},
    {label: 'Komatsu Corporate', value: 'Komatsu Corporate'},
    {label: 'Administrator', value: 'Administrator'},
    {label: 'Other', value: 'Other'},
];

export const industriesOptions = [
    { label: 'Construction', value: 'Construction' },
    { label: 'Forestry', value: 'Forestry'},
    { label: 'Mining', value: 'Mining' },
];

export const dealership = [
    {
        label: "All Roads Equipment - Bealeton",
        value: "All Roads Equipment - Bealeton"
    },
    {
        label: "All Roads Equipment - Chesapeake",
        value: "All Roads Equipment - Chesapeake"
    },
    {
        label: "All Roads Equipment - Chester",
        value: "All Roads Equipment - Chester"
    },
    {
        label: "All Roads Equipment - Fishersville",
        value: "All Roads Equipment - Fishersville"
    },
    {
        label: "All Roads Equipment - Salem",
        value: "All Roads Equipment - Salem"
    },
    {
        label: "Anderson Equipment Company - Allegany",
        value: "Anderson Equipment Company - Allegany"
    },
    {
        label: "Anderson Equipment Company - Bangor",
        value: "Anderson Equipment Company - Bangor"
    },
    {
        label: "Anderson Equipment Company - Bridgeville",
        value: "Anderson Equipment Company - Bridgeville"
    },
    {
        label: "Anderson Equipment Company - Clearfield",
        value: "Anderson Equipment Company - Clearfield"
    },
    {
        label: "Anderson Equipment Company - East Montpelier",
        value: "Anderson Equipment Company - East Montpelier"
    },
    {
        label: "Anderson Equipment Company - Endicott",
        value: "Anderson Equipment Company - Endicott"
    },
    {
        label: "Anderson Equipment Company - Gorham",
        value: "Anderson Equipment Company - Gorham"
    },
    {
        label: "Anderson Equipment Company - Henrietta",
        value: "Anderson Equipment Company - Henrietta"
    },
    {
        label: "Anderson Equipment Company - Lancaster",
        value: "Anderson Equipment Company - Lancaster"
    },
    {
        label: "Anderson Equipment Company - Latham",
        value: "Anderson Equipment Company - Latham"
    },
    {
        label: "Anderson Equipment Company - Manchester",
        value: "Anderson Equipment Company - Manchester"
    },
    {
        label: "Anderson Equipment Company - Pleasant Valley",
        value: "Anderson Equipment Company - Pleasant Valley"
    },
    {
        label: "Anderson Equipment Company - Shippenville",
        value: "Anderson Equipment Company - Shippenville"
    },
    {
        label: "Anderson Equipment Company - Somerset",
        value: "Anderson Equipment Company - Somerset"
    },
    {
        label: "Anderson Equipment Company - South Charleston",
        value: "Anderson Equipment Company - South Charleston"
    },
    {
        label: "Anderson Equipment Company - Syracuse",
        value: "Anderson Equipment Company - Syracuse"
    },
    {
        label: "Anderson Equipment Company - Tonawanda",
        value: "Anderson Equipment Company - Tonawanda"
    },
    {
        label: "Anderson Equipment Company - Watertown",
        value: "Anderson Equipment Company - Watertown"
    },
    {
        label: "Bacon-Universal Company, Inc. - Hilo",
        value: "Bacon-Universal Company, Inc. - Hilo"
    },
    {
        label: "Bacon-Universal Company, Inc. - Honolulu",
        value: "Bacon-Universal Company, Inc. - Honolulu"
    },
    {
        label: "Bacon-Universal Company, Inc. - Kailua-Kona",
        value: "Bacon-Universal Company, Inc. - Kailua-Kona"
    },
    {
        label: "Bacon-Universal Company, Inc. - Lihue",
        value: "Bacon-Universal Company, Inc. - Lihue"
    },
    {
        label: "Bacon-Universal Company, Inc. - Wailuku Maui",
        value: "Bacon-Universal Company, Inc. - Wailuku Maui"
    },
    {
        label: "Berry Tractor & Equipment Co. - Garden City",
        value: "Berry Tractor & Equipment Co. - Garden City"
    },
    {
        label: "Berry Tractor & Equipment Co. - Springfield",
        value: "Berry Tractor & Equipment Co. - Springfield"
    },
    {
        label: "Berry Tractor & Equipment Co. - Topeka",
        value: "Berry Tractor & Equipment Co. - Topeka"
    },
    {
        label: "Berry Tractor & Equipment Co. - Wichita",
        value: "Berry Tractor & Equipment Co. - Wichita"
    },
    {
        label: "Brandeis Machinery & Supply Company - Corbin",
        value: "Brandeis Machinery & Supply Company - Corbin"
    },
    {
        label: "Brandeis Machinery & Supply Company - Evansville",
        value: "Brandeis Machinery & Supply Company - Evansville"
    },
    {
        label: "Brandeis Machinery & Supply Company - Fort Wayne",
        value: "Brandeis Machinery & Supply Company - Fort Wayne"
    },
    {
        label: "Brandeis Machinery & Supply Company - Indianapolis",
        value: "Brandeis Machinery & Supply Company - Indianapolis"
    },
    {
        label: "Brandeis Machinery & Supply Company - Lexington",
        value: "Brandeis Machinery & Supply Company - Lexington"
    },
    {
        label: "Brandeis Machinery & Supply Company - Louisville",
        value: "Brandeis Machinery & Supply Company - Louisville"
    },
    {
        label: "Brandeis Machinery & Supply Company - Paducah",
        value: "Brandeis Machinery & Supply Company - Paducah"
    },
    {
        label: "Brandeis Machinery & Supply Company - Stanville",
        value: "Brandeis Machinery & Supply Company - Stanville"
    },
    {
        label: "C. N. Wood Co., Inc. - Avon",
        value: "C. N. Wood Co., Inc. - Avon"
    },
    {
        label: "C. N. Wood Co., Inc. - Holmes",
        value: "C. N. Wood Co., Inc. - Holmes"
    },
    {
        label: "C. N. Wood Co., Inc. - I-84",
        value: "C. N. Wood Co., Inc. - I-84"
    },
    {
        label: "C. N. Wood Co., Inc. - Newington",
        value: "C. N. Wood Co., Inc. - Newington"
    },
    {
        label: "C. N. Wood Co., Inc. - Pine Bush",
        value: "C. N. Wood Co., Inc. - Pine Bush"
    },
    {
        label: "C. N. Wood Co., Inc. - Smithfield",
        value: "C. N. Wood Co., Inc. - Smithfield"
    },
    {
        label: "C. N. Wood Co., Inc. - Whately",
        value: "C. N. Wood Co., Inc. - Whately"
    },
    {
        label: "C. N. Wood Co., Inc. - Woburn",
        value: "C. N. Wood Co., Inc. - Woburn"
    },
    {
        label: "Clairemont Equipment Company - Escondido",
        value: "Clairemont Equipment Company - Escondido"
    },
    {
        label: "Clairemont Equipment Company - Fontana",
        value: "Clairemont Equipment Company - Fontana"
    },
    {
        label: "Clairemont Equipment Company - Imperial",
        value: "Clairemont Equipment Company - Imperial"
    },
    {
        label: "Clairemont Equipment Company - Indio",
        value: "Clairemont Equipment Company - Indio"
    },
    {
        label: "Clairemont Equipment Company - San Diego",
        value: "Clairemont Equipment Company - San Diego"
    },
    {
        label: "Columbus Equipment Company - Cadiz",
        value: "Columbus Equipment Company - Cadiz"
    },
    {
        label: "Columbus Equipment Company - Cincinnati",
        value: "Columbus Equipment Company - Cincinnati"
    },
    {
        label: "Columbus Equipment Company - Columbus",
        value: "Columbus Equipment Company - Columbus"
    },
    {
        label: "Columbus Equipment Company - Dayton",
        value: "Columbus Equipment Company - Dayton"
    },
    {
        label: "Columbus Equipment Company - Massillon",
        value: "Columbus Equipment Company - Massillon"
    },
    {
        label: "Columbus Equipment Company - Painesville",
        value: "Columbus Equipment Company - Painesville"
    },
    {
        label: "Columbus Equipment Company - Perrysburg",
        value: "Columbus Equipment Company - Perrysburg"
    },
    {
        label: "Columbus Equipment Company - Piketon",
        value: "Columbus Equipment Company - Piketon"
    },
    {
        label: "Columbus Equipment Company - Richfield",
        value: "Columbus Equipment Company - Richfield"
    },
    {
        label: "Columbus Equipment Company - Zanesville",
        value: "Columbus Equipment Company - Zanesville"
    },
    {
        label: "Continental Distributing Corporation - Bridgeport",
        value: "Continental Distributing Corporation - Bridgeport"
    },
    {
        label: "Continental Distributing Corporation - Grand Rapids",
        value: "Continental Distributing Corporation - Grand Rapids"
    },
    {
        label: "Continental Distributing Corporation - Lansing",
        value: "Continental Distributing Corporation - Lansing"
    },
    {
        label: "Continental Distributing Corporation - Lenox",
        value: "Continental Distributing Corporation - Lenox"
    },
    {
        label: "Continental Distributing Corporation - New Hudson",
        value: "Continental Distributing Corporation - New Hudson"
    },
    {
        label: "Continental Distributing Corporation - Williamsburg",
        value: "Continental Distributing Corporation - Williamsburg"
    },
    {
        label: "Diesel Machinery, Inc - Aberdeen",
        value: "Diesel Machinery, Inc - Aberdeen"
    },
    {
        label: "Diesel Machinery, Inc - Rapid City",
        value: "Diesel Machinery, Inc - Rapid City"
    },
    {
        label: "Diesel Machinery, Inc - Sioux Falls",
        value: "Diesel Machinery, Inc - Sioux Falls"
    },
    {
        label: "Equipment Sales & Service Ltd. - Lively (Sudbury)",
        value: "Equipment Sales & Service Ltd. - Lively (Sudbury)"
    },
    {
        label: "Equipment Sales & Service Ltd. - London",
        value: "Equipment Sales & Service Ltd. - London"
    },
    {
        label: "Equipment Sales & Service Ltd. - Ottawa",
        value: "Equipment Sales & Service Ltd. - Ottawa"
    },
    {
        label: "Equipment Sales & Service Ltd. - Toronto",
        value: "Equipment Sales & Service Ltd. - Toronto"
    },
    {
        label: "General Equipment & Supplies, Inc. - Bismarck",
        value: "General Equipment & Supplies, Inc. - Bismarck"
    },
    {
        label: "General Equipment & Supplies, Inc. - Fargo",
        value: "General Equipment & Supplies, Inc. - Fargo"
    },
    {
        label: "General Equipment & Supplies, Inc. - Minot",
        value: "General Equipment & Supplies, Inc. - Minot"
    },
    {
        label: "General Equipment & Supplies, Inc. - Williston",
        value: "General Equipment & Supplies, Inc. - Williston"
    },
    {
        label: "H & E Equipment Services, Inc. - Alexandria",
        value: "H & E Equipment Services, Inc. - Alexandria"
    },
    {
        label: "H & E Equipment Services, Inc. - Baton Rouge",
        value: "H & E Equipment Services, Inc. - Baton Rouge"
    },
    {
        label: "H & E Equipment Services, Inc. - Bossier City",
        value: "H & E Equipment Services, Inc. - Bossier City"
    },
    {
        label: "H & E Equipment Services, Inc. - Broussard",
        value: "H & E Equipment Services, Inc. - Broussard"
    },
    {
        label: "H & E Equipment Services, Inc. - Kenner",
        value: "H & E Equipment Services, Inc. - Kenner"
    },
    {
        label: "H & E Equipment Services, Inc. - Little Rock",
        value: "H & E Equipment Services, Inc. - Little Rock"
    },
    {
        label: "H & E Equipment Services, Inc. - New Orleans",
        value: "H & E Equipment Services, Inc. - New Orleans"
    },
    {
        label: "H & E Equipment Services, Inc. - Springdale",
        value: "H & E Equipment Services, Inc. - Springdale"
    },
    {
        label: "H & E Equipment Services, Inc. - Sulphur",
        value: "H & E Equipment Services, Inc. - Sulphur"
    },
    {
        label: "Kirby Smith Machinery, Inc - Abilene",
        value: "Kirby Smith Machinery, Inc - Abilene"
    },
    {
        label: "Kirby Smith Machinery, Inc - Amarillo",
        value: "Kirby Smith Machinery, Inc - Amarillo"
    },
    {
        label: "Kirby Smith Machinery, Inc - Dallas",
        value: "Kirby Smith Machinery, Inc - Dallas"
    },
    {
        label: "Kirby Smith Machinery, Inc - DALLAS",
        value: "Kirby Smith Machinery, Inc - DALLAS"
    },
    {
        label: "Kirby Smith Machinery, Inc - Ft. Worth",
        value: "Kirby Smith Machinery, Inc - Ft. Worth"
    },
    {
        label: "KIRBY SMITH MACHINERY, INC - Hewitt",
        value: "KIRBY SMITH MACHINERY, INC - Hewitt"
    },
    {
        label: "Kirby Smith Machinery, Inc - Lubbock",
        value: "Kirby Smith Machinery, Inc - Lubbock"
    },
    {
        label: "Kirby Smith Machinery, Inc - Mcalester",
        value: "Kirby Smith Machinery, Inc - Mcalester"
    },
    {
        label: "Kirby Smith Machinery, Inc - Odessa",
        value: "Kirby Smith Machinery, Inc - Odessa"
    },
    {
        label: "Kirby Smith Machinery, Inc - Oklahoma City",
        value: "Kirby Smith Machinery, Inc - Oklahoma City"
    },
    {
        label: "Kirby Smith Machinery, Inc - Tulsa",
        value: "Kirby Smith Machinery, Inc - Tulsa"
    },
    {
        label: "Komatsu East - Baltimore",
        value: "Komatsu East - Baltimore"
    },
    {
        label: "Komatsu East - Laurel",
        value: "Komatsu East - Laurel"
    },
    {
        label: "Komatsu East - Cedar Brook",
        value: "Komatsu East - Cedar Brook"
    },
    {
        label: "Komatsu East - Hatfield",
        value: "Komatsu East - Hatfield"
    },
    {
        label: "Komatsu East - Holbrook",
        value: "Komatsu East - Holbrook"
    },
    {
        label: "Komatsu East - Yonkers",
        value: "Komatsu East - Yonkers"
    },
       {
        label: "Komatsu East - S. Plainfield",
        value: "Komatsu East - S. Plainfield"
    },
    {
        label: "Komatsu East - Bellefonte",
        value: "Komatsu East - Bellefonte"
    },
    {
        label: "Komatsu East - Frackville",
        value: "Komatsu East - Frackville"
    },
    {
        label: "Komatsu East - Harrisburg",
        value: "Komatsu East - Harrisburg"
    },
    {
        label: "Komatsu East - Wilkes-Barre",
        value: "Komatsu East - Wilkes-Barre"
    },
    {
        label: "Komatsu East - Other",
        value: "Komatsu East - Other"
    },
    {
        label: "Komatsu Employee",
        value: "Komatsu Employee"
    },
    {
        label: "Komatsu West - Elko",
        value: "Komatsu West - Elko"
    },
    {
        label: "Komatsu West - Gillette",
        value: "Komatsu West - Gillette"
    },
    {
        label: "Komatsu West - N. Las Vegas",
        value: "Komatsu West - N. Las Vegas"
    },
    {
        label: "Komatsu West - Rock Springs",
        value: "Komatsu West - Rock Springs"
    },
    {
        label: "Komatsu West - Salt Lake City",
        value: "Komatsu West - Salt Lake City"
    },
    {
        label: "Komatsu West - Sparks",
        value: "Komatsu West - Sparks"
    },
    {
        label: "Komatsu West - St. George",
        value: "Komatsu West - St. George"
    },
    {
        label: "Linder Industrial Machinery Company - Arden",
        value: "Linder Industrial Machinery Company - Arden"
    },
    {
        label: "Linder Industrial Machinery Company - Aurora",
        value: "Linder Industrial Machinery Company - Aurora"
    },
    {
        label: "Linder Industrial Machinery Company - Concord",
        value: "Linder Industrial Machinery Company - Concord"
    },
    {
        label: "Linder Industrial Machinery Company - Ft. Mill",
        value: "Linder Industrial Machinery Company - Ft. Mill"
    },
    {
        label: "Linder Industrial Machinery Company - Ft. Myers",
        value: "Linder Industrial Machinery Company - Ft. Myers"
    },
    {
        label: "Linder Industrial Machinery Company - Greensboro",
        value: "Linder Industrial Machinery Company - Greensboro"
    },
    {
        label: "Linder Industrial Machinery Company - Greenville",
        value: "Linder Industrial Machinery Company - Greenville"
    },
    {
        label: "Linder Industrial Machinery Company - Greer",
        value: "Linder Industrial Machinery Company - Greer"
    },
    {
        label: "Linder Industrial Machinery Company - Jacksonville",
        value: "Linder Industrial Machinery Company - Jacksonville"
    },
    {
        label: "Linder Industrial Machinery Company - Ladson",
        value: "Linder Industrial Machinery Company - Ladson"
    },
    {
        label: "Linder Industrial Machinery Company - Ocala",
        value: "Linder Industrial Machinery Company - Ocala"
    },
    {
        label: "Linder Industrial Machinery Company - Orlando",
        value: "Linder Industrial Machinery Company - Orlando"
    },
    {
        label: "Linder Industrial Machinery Company - Pembroke Pines",
        value: "Linder Industrial Machinery Company - Pembroke Pines"
    },
    {
        label: "Linder Industrial Machinery Company - Plant City",
        value: "Linder Industrial Machinery Company - Plant City"
    },
    {
        label: "Linder Industrial Machinery Company - Raleigh",
        value: "Linder Industrial Machinery Company - Raleigh"
    },
    {
        label: "Linder Industrial Machinery Company - Riviera Beach",
        value: "Linder Industrial Machinery Company - Riviera Beach"
    },
    {
        label: "Linder Industrial Machinery Company - West Columbia",
        value: "Linder Industrial Machinery Company - West Columbia"
    },
    {
        label: "Linder Industrial Machinery Company - Wilmington",
        value: "Linder Industrial Machinery Company - Wilmington"
    },
    {
        label: "Lyle Machinery Co. - Columbus",
        value: "Lyle Machinery Co. - Columbus"
    },
    {
        label: "Lyle Machinery Co. - Hattiesburg",
        value: "Lyle Machinery Co. - Hattiesburg"
    },
    {
        label: "Lyle Machinery Co. - Meridian",
        value: "Lyle Machinery Co. - Meridian"
    },
    {
        label: "Lyle Machinery Co. - Richland",
        value: "Lyle Machinery Co. - Richland"
    },
    {
        label: "Lyle Machinery Co. - Saucier",
        value: "Lyle Machinery Co. - Saucier"
    },
    {
        label: "Lyle Machinery Co. - Summit",
        value: "Lyle Machinery Co. - Summit"
    },
    {
        label: "Modern Machinery Co., Inc. - Billings",
        value: "Modern Machinery Co., Inc. - Billings"
    },
    {
        label: "Modern Machinery Co., Inc. - Boise",
        value: "Modern Machinery Co., Inc. - Boise"
    },
    {
        label: "Modern Machinery Co., Inc. - Eugene",
        value: "Modern Machinery Co., Inc. - Eugene"
    },
    {
        label: "Modern Machinery Co., Inc. - Jerome",
        value: "Modern Machinery Co., Inc. - Jerome"
    },
    {
        label: "Modern Machinery Co., Inc. - Kalispell",
        value: "Modern Machinery Co., Inc. - Kalispell"
    },
    {
        label: "Modern Machinery Co., Inc. - Kent",
        value: "Modern Machinery Co., Inc. - Kent"
    },
    {
        label: "Modern Machinery Co., Inc. - Missoula",
        value: "Modern Machinery Co., Inc. - Missoula"
    },
    {
        label: "Modern Machinery Co., Inc. - Pocatello",
        value: "Modern Machinery Co., Inc. - Pocatello"
    },
    {
        label: "Modern Machinery Co., Inc. - Portland",
        value: "Modern Machinery Co., Inc. - Portland"
    },
    {
        label: "Modern Machinery Co., Inc. - Rochester",
        value: "Modern Machinery Co., Inc. - Rochester"
    },
    {
        label: "Modern Machinery Co., Inc. - Spokane",
        value: "Modern Machinery Co., Inc. - Spokane"
    },
    {
        label: "Power Equipment Company - Chattanooga",
        value: "Power Equipment Company - Chattanooga"
    },
    {
        label: "Power Equipment Company - Kingsport",
        value: "Power Equipment Company - Kingsport"
    },
    {
        label: "Power Equipment Company - Knoxville",
        value: "Power Equipment Company - Knoxville"
    },
    {
        label: "Power Equipment Company - Lavergne",
        value: "Power Equipment Company - Lavergne"
    },
    {
        label: "Power Equipment Company - Little Rock",
        value: "Power Equipment Company - Little Rock"
    },
    {
        label: "Power Equipment Company - Memphis",
        value: "Power Equipment Company - Memphis"
    },
    {
        label: "Power Equipment Company - Saltillo",
        value: "Power Equipment Company - Saltillo"
    },
    {
        label: "Power Equipment Company - Springdale",
        value: "Power Equipment Company - Springdale"
    },
    {
        label: "Power Motive Corporation - Albuquerque",
        value: "Power Motive Corporation - Albuquerque"
    },
    {
        label: "Power Motive Corporation - Carlsbad",
        value: "Power Motive Corporation - Carlsbad"
    },
    {
        label: "Power Motive Corporation - Chandler",
        value: "Power Motive Corporation - Chandler"
    },
    {
        label: "Power Motive Corporation - Cheyenne",
        value: "Power Motive Corporation - Cheyenne"
    },
    {
        label: "Power Motive Corporation - Colorado Springs",
        value: "Power Motive Corporation - Colorado Springs"
    },
    {
        label: "Power Motive Corporation - Denver",
        value: "Power Motive Corporation - Denver"
    },
    {
        label: "Power Motive Corporation - Durango",
        value: "Power Motive Corporation - Durango"
    },
    {
        label: "Power Motive Corporation - El Paso",
        value: "Power Motive Corporation - El Paso"
    },
    {
        label: "Power Motive Corporation - Farmingtion",
        value: "Power Motive Corporation - Farmingtion"
    },
    {
        label: "Power Motive Corporation - Grand Junction",
        value: "Power Motive Corporation - Grand Junction"
    },
    {
        label: "Power Motive Corporation - Milliken",
        value: "Power Motive Corporation - Milliken"
    },
    {
        label: "Rish Equipment Company - Bealeton",
        value: "Rish Equipment Company - Bealeton"
    },
    {
        label: "Rish Equipment Company - Beckley",
        value: "Rish Equipment Company - Beckley"
    },
    {
        label: "Rish Equipment Company - Bridgeport",
        value: "Rish Equipment Company - Bridgeport"
    },
    {
        label: "Rish Equipment Company - Chesapeake",
        value: "Rish Equipment Company - Chesapeake"
    },
    {
        label: "Rish Equipment Company - Chester",
        value: "Rish Equipment Company - Chester"
    },
    {
        label: "Rish Equipment Company - Coeburn",
        value: "Rish Equipment Company - Coeburn"
    },
    {
        label: "Rish Equipment Company - Fishersville",
        value: "Rish Equipment Company - Fishersville"
    },
    {
        label: "Rish Equipment Company - Frostburg",
        value: "Rish Equipment Company - Frostburg"
    },
    {
        label: "Rish Equipment Company - Gainesville",
        value: "Rish Equipment Company - Gainesville"
    },
    {
        label: "Rish Equipment Company - Parkersburg",
        value: "Rish Equipment Company - Parkersburg"
    },
    {
        label: "Rish Equipment Company - Salem",
        value: "Rish Equipment Company - Salem"
    },
    {
        label: "Rish Equipment Company - St. Albans",
        value: "Rish Equipment Company - St. Albans"
    },
    {
        label: "Rish Equipment Company - Wilkinson",
        value: "Rish Equipment Company - Wilkinson"
    },
    {
        label: "Rish Equipment Company - Wise",
        value: "Rish Equipment Company - Wise"
    },
    {
        label: "Road Machinery & Supplies Co. - Bondurant",
        value: "Road Machinery & Supplies Co. - Bondurant"
    },
    {
        label: "Road Machinery & Supplies Co. - Cedar Rapids",
        value: "Road Machinery & Supplies Co. - Cedar Rapids"
    },
    {
        label: "Road Machinery & Supplies Co. - Duluth",
        value: "Road Machinery & Supplies Co. - Duluth"
    },
    {
        label: "Road Machinery & Supplies Co. - East Moline",
        value: "Road Machinery & Supplies Co. - East Moline"
    },
    {
        label: "Road Machinery & Supplies Co. - Naguanee",
        value: "Road Machinery & Supplies Co. - Naguanee"
    },
    {
        label: "Road Machinery & Supplies Co. - Savage",
        value: "Road Machinery & Supplies Co. - Savage"
    },
    {
        label: "Road Machinery & Supplies Co. - Sioux City",
        value: "Road Machinery & Supplies Co. - Sioux City"
    },
    {
        label: "Road Machinery & Supplies Co. - Virginia",
        value: "Road Machinery & Supplies Co. - Virginia"
    },
    {
        label: "Road Machinery, Llc - Bakersfield",
        value: "Road Machinery, Llc - Bakersfield"
    },
    {
        label: "Road Machinery, Llc - Fresno",
        value: "Road Machinery, Llc - Fresno"
    },
    {
        label: "Road Machinery, Llc - Hayward",
        value: "Road Machinery, Llc - Hayward"
    },
    {
        label: "Road Machinery, Llc - Mojave",
        value: "Road Machinery, Llc - Mojave"
    },
    {
        label: "Road Machinery, Llc - Perris",
        value: "Road Machinery, Llc - Perris"
    },
    {
        label: "Road Machinery, Llc - Phoenix",
        value: "Road Machinery, Llc - Phoenix"
    },
    {
        label: "Road Machinery, Llc - Pico Rivera",
        value: "Road Machinery, Llc - Pico Rivera"
    },
    {
        label: "Road Machinery, Llc - Prescott",
        value: "Road Machinery, Llc - Prescott"
    },
    {
        label: "Road Machinery, Llc - Redding",
        value: "Road Machinery, Llc - Redding"
    },
    {
        label: "Road Machinery, Llc - Santa Rosa",
        value: "Road Machinery, Llc - Santa Rosa"
    },
    {
        label: "Road Machinery, Llc - Superior",
        value: "Road Machinery, Llc - Superior"
    },
    {
        label: "Road Machinery, Llc - Tucson",
        value: "Road Machinery, Llc - Tucson"
    },
    {
        label: "Road Machinery, Llc - West Sacramento",
        value: "Road Machinery, Llc - West Sacramento"
    },
    {
        label: "Road Machinery, Llc - Other",
        value: "Road Machinery, Llc - Other"
    },
    {
        label: "Roadbuilders Machinery & Supply Co., Inc. - Grand Island",
        value: "Roadbuilders Machinery & Supply Co., Inc. - Grand Island"
    },
    {
        label: "Roadbuilders Machinery & Supply Co., Inc. - Kansas City",
        value: "Roadbuilders Machinery & Supply Co., Inc. - Kansas City"
    },
    {
        label: "Roadbuilders Machinery & Supply Co., Inc. - Lincoln",
        value: "Roadbuilders Machinery & Supply Co., Inc. - Lincoln"
    },
    {
        label: "Roadbuilders Machinery & Supply Co., Inc. - Norfolk",
        value: "Roadbuilders Machinery & Supply Co., Inc. - Norfolk"
    },
    {
        label: "Roadbuilders Machinery & Supply Co., Inc. - Omaha",
        value: "Roadbuilders Machinery & Supply Co., Inc. - Omaha"
    },
    {
        label: "Roland Machinery Company - Bolingbrook",
        value: "Roland Machinery Company - Bolingbrook"
    },
    {
        label: "Roland Machinery Company - Bridgeton",
        value: "Roland Machinery Company - Bridgeton"
    },
    {
        label: "Roland Machinery Company - Cape Girardeau",
        value: "Roland Machinery Company - Cape Girardeau"
    },
    {
        label: "Roland Machinery Company - Carterville",
        value: "Roland Machinery Company - Carterville"
    },
    {
        label: "Roland Machinery Company - Columbia",
        value: "Roland Machinery Company - Columbia"
    },
    {
        label: "Roland Machinery Company - De Forest",
        value: "Roland Machinery Company - De Forest"
    },
    {
        label: "Roland Machinery Company - Depere",
        value: "Roland Machinery Company - Depere"
    },
    {
        label: "Roland Machinery Company - East Peoria",
        value: "Roland Machinery Company - East Peoria"
    },
    {
        label: "Roland Machinery Company - Eau Claire",
        value: "Roland Machinery Company - Eau Claire"
    },
    {
        label: "Roland Machinery Company - Escanaba",
        value: "Roland Machinery Company - Escanaba"
    },
    {
        label: "Roland Machinery Company - Franksville",
        value: "Roland Machinery Company - Franksville"
    },
    {
        label: "Roland Machinery Company - Marengo",
        value: "Roland Machinery Company - Marengo"
    },
    {
        label: "Roland Machinery Company - Palmyra",
        value: "Roland Machinery Company - Palmyra"
    },
    {
        label: "Roland Machinery Company - Portage",
        value: "Roland Machinery Company - Portage"
    },
    {
        label: "Roland Machinery Company - Schofield",
        value: "Roland Machinery Company - Schofield"
    },
    {
        label: "Roland Machinery Company - Slinger",
        value: "Roland Machinery Company - Slinger"
    },
    {
        label: "Roland Machinery Company - Springfield",
        value: "Roland Machinery Company - Springfield"
    },
    {
        label: "SMS Alaska - Anchorage",
        value: "SMS Alaska - Anchorage"
    },
    {
        label: "SMS East - Baie-Comeau",
        value: "SMS East - Baie-Comeau"
    },
    {
        label: "SMS East - Chicoutimi",
        value: "SMS East - Chicoutimi"
    },
    {
        label: "SMS East - Fredericton",
        value: "SMS East - Fredericton"
    },
    {
        label: "SMS East - Laval",
        value: "SMS East - Laval"
    },
    {
        label: "SMS East - Moncton",
        value: "SMS East - Moncton"
    },
    {
        label: "SMS East - Paradise",
        value: "SMS East - Paradise"
    },
    {
        label: "SMS East - St-Augustin De Desmaures",
        value: "SMS East - St-Augustin De Desmaures"
    },
    {
        label: "SMS East - St-Bruno",
        value: "SMS East - St-Bruno"
    },
    {
        label: "SMS East - Timmins",
        value: "SMS East - Timmins"
    },
    {
        label: "SMS East - Trois Rivieres Oue",
        value: "SMS East - Trois Rivieres Oue"
    },
    {
        label: "SMS East - Val D'Or",
        value: "SMS East - Val D'Or"
    },
    {
        label: "SMS East - Wabush",
        value: "SMS East - Wabush"
    },
    {
        label: "SMS West - Acheson",
        value: "SMS West - Acheson"
    },
    {
        label: "SMS West - Calgary",
        value: "SMS West - Calgary"
    },
    {
        label: "SMS West - Campbell River",
        value: "SMS West - Campbell River"
    },
    {
        label: "SMS West - Chetwynd",
        value: "SMS West - Chetwynd"
    },
    {
        label: "SMS West - Edmonton",
        value: "SMS West - Edmonton"
    },
    {
        label: "SMS West - Elkford",
        value: "SMS West - Elkford"
    },
    {
        label: "SMS West - Fort Frances",
        value: "SMS West - Fort Frances"
    },
    {
        label: "SMS West - Ft Mcmurray",
        value: "SMS West - Ft Mcmurray"
    },
    {
        label: "SMS West - Ft.St.John",
        value: "SMS West - Ft.St.John"
    },
    {
        label: "SMS West - Grand Prairie",
        value: "SMS West - Grand Prairie"
    },
    {
        label: "SMS West - Hinton",
        value: "SMS West - Hinton"
    },
    {
        label: "SMS West - Houston",
        value: "SMS West - Houston"
    },
    {
        label: "SMS West - Kamloops",
        value: "SMS West - Kamloops"
    },
    {
        label: "SMS West - Prince George",
        value: "SMS West - Prince George"
    },
    {
        label: "SMS West - Red Deer",
        value: "SMS West - Red Deer"
    },
    {
        label: "SMS West - Regina",
        value: "SMS West - Regina"
    },
    {
        label: "SMS West - Rosslyn",
        value: "SMS West - Rosslyn"
    },
    {
        label: "SMS West - Saskatoon",
        value: "SMS West - Saskatoon"
    },
    {
        label: "SMS West - Surrey",
        value: "SMS West - Surrey"
    },
    {
        label: "SMS West - Whitehorse",
        value: "SMS West - Whitehorse"
    },
    {
        label: "SMS West - Williams Lake",
        value: "SMS West - Williams Lake"
    },
    {
        label: "SMS West - Winnipeg",
        value: "SMS West - Winnipeg"
    },
    {
        label: "SMS West - Yellowkinfe",
        value: "SMS West - Yellowkinfe"
    },
    {
        label: "Tractor & Equipment Company - Alabaster",
        value: "Tractor & Equipment Company - Alabaster"
    },
    {
        label: "Tractor & Equipment Company - Albany",
        value: "Tractor & Equipment Company - Albany"
    },
    {
        label: "Tractor & Equipment Company - Augusta",
        value: "Tractor & Equipment Company - Augusta"
    },
    {
        label: "Tractor & Equipment Company - Birmingham",
        value: "Tractor & Equipment Company - Birmingham"
    },
    {
        label: "Tractor & Equipment Company - Calhoun",
        value: "Tractor & Equipment Company - Calhoun"
    },
    {
        label: "Tractor & Equipment Company - Columbus",
        value: "Tractor & Equipment Company - Columbus"
    },
    {
        label: "Tractor & Equipment Company - Decatur",
        value: "Tractor & Equipment Company - Decatur"
    },
    {
        label: "Tractor & Equipment Company - Dothan",
        value: "Tractor & Equipment Company - Dothan"
    },
    {
        label: "Tractor & Equipment Company - Forest Park",
        value: "Tractor & Equipment Company - Forest Park"
    },
    {
        label: "Tractor & Equipment Company - Hoschton",
        value: "Tractor & Equipment Company - Hoschton"
    },
    {
        label: "Tractor & Equipment Company - Huntsville",
        value: "Tractor & Equipment Company - Huntsville"
    },
    {
        label: "Tractor & Equipment Company - Kennesaw",
        value: "Tractor & Equipment Company - Kennesaw"
    },
    {
        label: "Tractor & Equipment Company - Macon",
        value: "Tractor & Equipment Company - Macon"
    },
    {
        label: "Tractor & Equipment Company - Mobile",
        value: "Tractor & Equipment Company - Mobile"
    },
    {
        label: "Tractor & Equipment Company - Montgomery",
        value: "Tractor & Equipment Company - Montgomery"
    },
    {
        label: "Tractor & Equipment Company - Oxford",
        value: "Tractor & Equipment Company - Oxford"
    },
    {
        label: "Tractor & Equipment Company - Panama City",
        value: "Tractor & Equipment Company - Panama City"
    },
    {
        label: "Tractor & Equipment Company - Pensacola",
        value: "Tractor & Equipment Company - Pensacola"
    },
    {
        label: "Tractor & Equipment Company - Pooler",
        value: "Tractor & Equipment Company - Pooler"
    },
    {
        label: "Tractor & Equipment Company - Tuscaloosa",
        value: "Tractor & Equipment Company - Tuscaloosa"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Bryan",
        value: "Waukesha-Pearce Industries, Inc. - Bryan"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Buffalo",
        value: "Waukesha-Pearce Industries, Inc. - Buffalo"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Corpus Christi",
        value: "Waukesha-Pearce Industries, Inc. - Corpus Christi"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Edinburg",
        value: "Waukesha-Pearce Industries, Inc. - Edinburg"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Houston",
        value: "Waukesha-Pearce Industries, Inc. - Houston"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Kilgore",
        value: "Waukesha-Pearce Industries, Inc. - Kilgore"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Pflugerville",
        value: "Waukesha-Pearce Industries, Inc. - Pflugerville"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Port Arthur",
        value: "Waukesha-Pearce Industries, Inc. - Port Arthur"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - San Antonio",
        value: "Waukesha-Pearce Industries, Inc. - San Antonio"
    },
    {
        label: "Waukesha-Pearce Industries, Inc. - Tomball",
        value: "Waukesha-Pearce Industries, Inc. - Tomball"
    },
    {
        label: "Wilson Equipment - Dartmouth",
        value: "Wilson Equipment - Dartmouth"
    },
    {
        label: "Wilson Equipment - East Mountain",
        value: "Wilson Equipment - East Mountain"
    }
];