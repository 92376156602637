import Login from "../screens/login"
import AuthLayout from "../layouts/auth.layout"
import DashboardLayout from "../layouts/dashboard.layout"
import Dashboard from "../screens/dashboard"
import NotFound from "../screens/404"
import MyDnaLayout from "../layouts/mydna.layout"
import MyDna from "../screens/mydna"

export default [
  {
    path: "/dashboard/login",
    exact: true,
    layout: AuthLayout,
    component: Login,
    protected: false
  },
  {
    path: "/dashboard",
    exact: false,
    layout: DashboardLayout,
    component: Dashboard,
    protected: true
  },
  {
    path: "/mydna",
    layout: MyDnaLayout,
    component: MyDna,
    protected: true
  },
  {
    path: "/",
    layout: MyDnaLayout,
    component: MyDna,
    protected: true
  },
  {
    path: "*",
    layout: DashboardLayout,
    component: NotFound,
    protected: false
  }
]
