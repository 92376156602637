import { SetEnv } from '../helpers/SetEnv.helpers'

export const headers = {
  'Content-Type': 'application/json',
  accept: 'application/json',
  'Cache-Control': 'no-cache'
}

export const baseUrl = SetEnv(
  `${window.location.origin}`,
  'http://localhost:4999'
)
