/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import { commoditySafetyLinks } from "../../helpers/quiz.helpers"
import { useEffect } from "react"
import { useParrot } from "../hooks/parrot.hooks"

const HydraulicsSafety = () => {
  const setModalToInactive = () => {}
  const { setParrot } = useParrot()
  useEffect(() => {
    setParrot({
      dialog:
        "<b>Safety Tip:</b> Know where your branch’s first-aid kits and supplies are kept. Your safety and well being matters.",
      display: "TEACHER",
      mode: 1,
      next_bird_actions: []
    })
  }, [])
  return (
    <div className="page vh-80">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="hydraulics-color">
          <b>Hydraulics</b>
        </h1>
      </div>
      <center>
        <h1>
          <b>Safety</b>
        </h1>
      </center>
      <br />
      <br />

      <Row>
        <Col span={4} />
        <Col span={16}>
          <Row>
            <Col span={12}>
              <div>
                <center>
                  <img
                    className="image-contain "
                    height={191}
                    width={390}
                    src={require("../../assets/images/safety-hydraulics-1.png")}
                    alt=""
                  />
                </center>
              </div>
            </Col>
            <Col span={12}>
              <p>
                • Wear appropriate safety gear
                <br />
                • This is a high-pressure system up to 6,000 PSI
                <br />
                • It is also a high-temperature system
                <br />
                • Use the correct components for the application:
                manufacturer/pressure/temperature/types/sizes
                <br />
                • Use the proper installation techniques and tools
                <br />
                • Do not exceed the maximum bend radius
                <br />• Do not twist hoses
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div className="py-5">
                <p className="hydraulics-color" style={{ fontSize: 26 }}>
                  <b>ALERT! Hydraulic Injection Injury</b>
                </p>
                <p>
                  Do not check for leaks with your hands! Hydraulic fluid
                  injection injury causes extreme tissue damage and often leads
                  to amputation. This is a very real risk when working with
                  hydraulics!
                </p>
              </div>
            </Col>
            <Col span={12}>
              <div className="py-5">
                <center>
                  <img
                    className="image-contain "
                    height={266}
                    width={450}
                    src={require("../../assets/images/safety-hydraulics-2.png")}
                    alt=""
                  />
                </center>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <TestYourKnowledge link={commoditySafetyLinks.hydraulics} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default HydraulicsSafety
