import React from "react"
import { Descriptions } from "antd"
import { useCredentials } from "../hooks/user.hooks"
import ProgressTrack from "../progress/track.progress"

const UserProfile = () => {
  const profile = useCredentials()

  return (
    <div className="page">
      <div className="p-4">
        <Descriptions>
          <Descriptions.Item>
            <h2>
              <b>{profile.user.credentials.name}</b>
            </h2>
          </Descriptions.Item>
        </Descriptions>
        <center>
          <h3 className="profileProgress">Your Progress</h3>
        
        <a className="myNotes" target="_blank" rel="noopener noreferrer" href="/dashboard/my-notes">
          <img
            width={30}
            src={require("../../assets/images/my-notes-icon@3x.png")}
            alt=""
          />
          <p>My Notes</p>
        </a>
        </center>
        <ProgressTrack />
      </div>
    </div>
  )
}

export default UserProfile
