import React from "react"
import { Row, Col, Modal } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import { commodityHowItWorksLinks } from "../../helpers/quiz.helpers"

import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import EyePopup from "../popups/eye-popups"
import { useState } from "react"
import { Link } from "react-router-dom"

const BatteriesHowItWorks = () => {
  const [modal, setModal] = useState(false)
  const setModalToInactive = () => {}
  const showModal = () => {
    setModal(!modal)
  }
  const close = () => {
    setModal(false)
  }
  return (
    <div className="page">
      <Modal
        className="modal-80vw"
        visible={modal}
        onOk={close}
        onCancel={close}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
        okText="Close">
        <Row justify="center" align="middle">
          <Col sm={24} md={12}>
            <img
              width={460}
              src={require("../../assets/images/Battery-electrical-system-diagram.png")}
              alt=""
            />
          </Col>
          <Col sm={24} md={12}>
            <div className="pl-5 ">
              The Battery acts as a storage tank for voltage.
              <br />
              <br />
              Everything in the system is powered by the Alternator. The Battery
              backs up the Alternator.
              <br />
              <br />
              <br />
              <div className="batteries-color">
                <b>Pro Tips:</b>
              </div>
              If the Battery keeps failing but is still serviceable, it's time
              to check the Alternator.
              <br />
              <br />
              If the Alternator is not functioning properly, all electrical
              components in the machine—including the Battery—will suffer. If
              the Alternator begins to fail, everything else in the sytem will
              rely on the Battery which will then begin to lose its charge
              causing the system to ultimately fail.
            </div>
          </Col>
        </Row>
      </Modal>
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="batteries-color">
          <b>Batteries</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="py-5" style={{ paddingTop: "0px !important" }}>
            <center>
              <div
                style={{ fontSize: 26, fontWeight: 600, fontFamily: "Roboto" }}>
                <b>How It Works</b>
              </div>
              <h4 className="batteries-color">Anatomy of a Battery</h4>
            </center>
          </div>

          <center>
            <div style={{ position: "relative", width: 500 }}>
              <EyePopup
                left={0}
                top={10}
                content={
                  <div style={{ fontSize: 12 }}>
                    <div className="batteries-color">
                      <b>The Flush Top Cover</b>
                    </div>
                    <p>
                      prevents water and dirt from entering the battery when the
                      machine is cleaned or sprayed down with water. The
                      specially designed flush screw-in caps eliminate the
                      possiblity of lost caps and make the battery
                      maintenance-free but not maintenance-impossible.
                    </p>
                  </div>
                }
                contentSide="left"
              />
              <EyePopup
                left={0}
                top={40}
                content={
                  <div style={{ fontSize: 12 }}>
                    <div className="batteries-color">
                      <b>The Reinforced Case</b>
                    </div>
                    <p>
                      is made with battery-grade polypropylene plastic with
                      ribbed casing and a reinforced bottom to endure the heavy
                      vibration and harsh environments within the machines.
                    </p>
                  </div>
                }
                contentSide="left"
              />
              <EyePopup
                left={18}
                top={80}
                content={
                  <Row align="middle">
                    <Col span={12}>
                      <div className="float-left" style={{ fontSize: 12 }}>
                        <div className="batteries-color">
                          <b>Anchor Bonding</b>
                        </div>
                        <p>
                          is a hot glue like material that is placed in the
                          bottom of the case in a heated liquid form. The
                          plates/cells are placed in the case while the Anchor
                          Bonding is still in liquid form.
                          <br />
                          <br />
                          As the bonding agent cools, it hardens and secures all
                          the plates/cells within the battery.This enhances
                          durability in high vibration environments.
                        </p>
                      </div>
                    </Col>
                    <Col span={12}>
                      <img
                        src={require("../../assets/images/anchor-bonding.png")}
                        alt=""
                      />
                    </Col>
                  </Row>
                }
                contentSide="left"
              />
              <EyePopup
                left={95}
                top={10}
                content={
                  <div style={{ fontSize: 12 }}>
                    <div className="batteries-color">
                      <b>Stainless Steel Terminal Studs</b>
                    </div>
                    <p>resist corrosion for longer service life.</p>
                  </div>
                }
                contentSide="right"
              />

              <EyePopup
                left={95}
                top={40}
                content={
                  <div style={{ fontSize: 12 }}>
                    <div className="batteries-color">
                      <b>Full-Frame Positive & Negative Grids</b>
                    </div>
                    <p>
                      direct all available cranking power to the terminals
                      resulting in more CCA output. as a cost-saving measure,
                      competitors may use expanded metal which will expand over
                      time breaching the separator causing a short which will
                      prematurely end the battery's life. Komatsu's Full-Frame
                      Grids will not expand due to the completion of all four
                      sides.
                    </p>
                    <div className="row">
                      <div style={{ width: "40%" }}>
                        <img
                          className="float-left"
                          src={require("../../assets/images/grid-full.png")}
                          alt=""
                        />
                        <br />
                        <div className="batteries-color">
                          <b>Komatsu Full Frame Grid</b>
                        </div>
                      </div>
                      <div style={{ width: "40%" }}>
                        <img
                          className="float-left"
                          src={require("../../assets/images/grid-metal.png")}
                          alt=""
                        />
                        <br />
                        <div className="batteries-color">
                          <b>Competitor Expanded Metal Grid</b>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                contentSide="right"
              />
              <EyePopup
                left={95}
                top={60}
                content={
                  <div>
                    <div className="float-left" style={{ fontSize: 12 }}>
                      <div className="batteries-color">
                        <b>Deep Pocket Envelope Separators</b>
                      </div>
                      <p>
                        resist corrosion and lock active material to the plates,
                        extending battery life.
                      </p>
                    </div>
                    <img
                      className="float-left"
                      src={require("../../assets/images/deep-pocket.png")}
                      alt=""
                    />
                  </div>
                }
                contentSide="right"
              />
              <img
                className="image-contain mb-3"
                height={250}
                width={500}
                src={require("../../assets/images/Anatomy-of-Battery-New.png")}
                alt=""
              />
            </div>
          </center>

          <div
            onClick={showModal}
            className="py-4 d-flex justify-content-center align-items-center flex-row pointer-cursor">
            <img
              className="image-contain mb-3 mr-3"
              height={19}
              width={29}
              src={require("../../assets/images/icons/eye-icon-reman-blue@3x.png")}
              alt=""
            />
            <p className="reman-color">
              see how the battery is connected to the rest of the system.
            </p>
          </div>
          <div className="p-2 d-flex justify-content-start align-items-center flex-row">
            <img
              className="image-contain mb-3 mr-3"
              height={25}
              width={25}
              src={require("../../assets/images/icons/exclamation-circle-green@3x.png")}
              alt=""
            />
            <h5 className="batteries-color">
              <b>Important Tips!</b>
            </h5>
          </div>
          <div className="p-2 d-flex justify-content-center align-items-center flex-row">
            <ul>
              <li>
                Low voltage from the battery can destroy the starting motor
              </li>
              <li>
                PM (preventative maintenance) should include cleaning and
                inspecting the battery, terminal connections and battery state
                of charge.
              </li>
              <li>
                Batteries will eventually self-discharge due to their internal
                reactions. By following strict First In First Out (FIFO)
                practices, you can help reduce the chance of having batteries
                fully discharge while sittting on the shelf. Learn more about
                FIFO on the
                <Link
                  to="/dashboard/commodities/batteries/safety"
                  className="batteries-color p-2">
                  <b>Safety</b>
                </Link>
                page.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <TestYourKnowledge link={commodityHowItWorksLinks.batteries} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default BatteriesHowItWorks
