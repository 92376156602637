/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
//document.documentElement.setAttribute("data-theme", "mydna");

const CommunicatingWithRipley = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="communicate"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1>Communicating with Ripley</h1>
                    <p style={{"marginTop":"0px"}}>How communication with Ripley about your orders</p>
                </div>
                <a className="platform-link" href="https://na.parts.komatsu/Home/MainPage" target="_blank">Follow along in KPP <i className="fa-sharp fa-solid fa-arrow-up-right-from-square"></i></a>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                        <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="ProTips" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="New Call Registration" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Completing the Call Registration" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Customer Care Responsibilities" onClick={() =>sliderNavClicked(4)}>4</a></li>
                    </ol>
                </nav>

                <div className="flex-row" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      {/* 
                        Leaving for future reference in case we want to use dynamic information
                        {slideItems} 
                      */}
                      <SwiperSlide id='slide1' data-hash='slide1'>
                            <h3>ProTips for communicating with Ripley</h3>
                            <hr/>
                            <p>How you communicate with Ripley is very important in managing your orders. Us the right
                                method for the situation at hand: </p>
                            <section className="slider-content">
                                <div className="flex-item-50">
                                    <img src={require('../../images/ripley-comm-thread-graphic.png')} alt="start communication thread in Ripley" style={{maxWidth:"450px"}} />
                                    <ol>
                                        <li>Lookup your order</li>
                                        <li>Find the part in question</li>
                                        <li>Click the chat bubble at the far right of the part's line to begin a
                                            communication thread</li>
                                    </ol>
                                    <p className="note" style={{paddingLeft:"20px"}}>See <a href="communicate-with-ripley#slide3" onClick={() =>sliderNavClicked(3)}>slide 3</a> for details on how to complete your new communication request</p>
                                </div>
                                <div className="flex-item-50">
                                    <img src={require('../../images/ripley-call-rep-graphic.png')} alt="call your assigned rep" style={{"width":"85%","maxWidth":"375px"}}/>
                                    <p>Responses to your communication threads usually take at least 24 hours. <strong>If you need an answer more quickly, pick up the phone and call your assigned rep!</strong>
                                    </p>
                                    <p>And if an answer you receive is unclear (or you're not getting an answer back in KPP), call your rep!</p>
                                </div>
                                <h4 className="alert center">Always check your communication threads in Order Status Inquiry
                                    and Suspended orders in KPP.<br/>Often the answer you're looking for is already there.
                                </h4>
                            </section>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                      <h3>New Call Registration</h3>
                        <hr/>
                        <p>To initiate a new communication thread with Ripley, click Communication in the menu on the
                            left. Then click Call Registration in the
                            submenu provided.</p>
                        <p>If you are opening a thread about a specific part/order, it is easier to look up your order,
                            find the part you need to ask about and click the chat bubble on that part to initiate the
                            thread from there.</p>
                          <img src={require('../../images/ripley-communication-slide-1.jpg')} alt="new call registration step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                      <h3>How to complete the new Call Registration form</h3>
                        <hr/>
                        <p>Select a Request Type and then pick a more specific area of inquiry if needed, then
                            complete all required fields and all other information you can provide.</p>
                        <p>FYI: Ripley prefers that you not assign the communication. They will get it to the most
                            appropriate department/person.</p>
                          <img src={require('../../images/ripley-communication-slide-2.jpg')} alt="Ripley call registration step 2"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                      <h3>Ripley Customer Care Responsibilities Chart</h3>
                        <hr/>
                        <p>Select a Request Type and then pick a more specific area of inquiry if needed, then
                            complete all required fields and all other information you can provide.</p>
                        <p>FYI: Ripley prefers that you not assign the communication. They will get it to the most
                            appropriate department/person.</p>
                          <img src={require('../../images/ripley-customer-care-responsibilities-chart.jpeg')} alt="Ripley call registration step 2"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default CommunicatingWithRipley
