import React from 'react'
import { Row, Col } from 'antd'
import CommodityCard from '../commodities/commodity-card.landing'
import { allCommodities } from '../../data/json.data'

const Commodities = () => {
  const RenderCommodity = ({ name, thumbnail, link }) => {
    return (
      <Col className='commodity-card-column' span={8}>
        <CommodityCard link={link} thumbnail={thumbnail} title={name} />
      </Col>
    )
  }
  return (
    <div className='page'>
      <center>
        <Row className='commodities-row'>
          {allCommodities.map(RenderCommodity)}
        </Row>
      </center>
    </div>
  )
}

export default Commodities
