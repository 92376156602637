/* eslint-disable */
import React, { useState } from "react"
import { List, Button, Spin, Modal, Popconfirm } from "antd"
import { LoadingOutlined, ProfileOutlined } from "@ant-design/icons"
import { useNotes } from "../components/hooks/notes.hooks"
import MyEditor from "../components/notes/editor.notes"
import moment from "moment"
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const MyNotes = () => {
  const [visible, toggleModal] = useState(false)
  const noteState = useNotes()
  const [content, setContent] = useState("editor")
  const [innerHTML, setInnerHTML] = useState("")

  const openNoteEditor = () => {
    toggleModal(!visible)
  }

  const closeModal = () => {
    toggleModal(!visible)
    setTimeout(() => {
      setContent("editor")
    }, 200)
  }

  const deleteNote = (noteId) => {
    noteState.deleteNote(noteId)
  }

  return (
    <Spin
      title="New Note"
      indicator={antIcon}
      spinning={noteState.loading}
      tip="Loading Notes...">
      <Modal
        visible={visible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}>
        {content === "editor" && (
          <>
            <div className="mt-5"></div>
            <MyEditor cancel={closeModal} />
          </>
        )}
        {content === "note" && (
          <>
            <h5>NOTE</h5>
            <hr />
            <div
              className="mt-3"
              dangerouslySetInnerHTML={{ __html: innerHTML }}></div>
          </>
        )}
      </Modal>

      <div className="page p-5">
        <h1>My Notes</h1>
        <br />
        <br />
        <Button type="primary" onClick={openNoteEditor}>
          CREATE NOTE
        </Button>
        <br />
        <br />
        <div className=" p-4">
          <List
            locale={{
              emptyText: "No Notes..."
            }}
            itemLayout="horizontal"
            dataSource={noteState.notes}
            renderItem={(note) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <a
                      onClick={() => {
                        setContent("note")
                        openNoteEditor()
                        setInnerHTML(note.text)
                      }}>
                      <div className="trunc">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: note.text.split("</")[0]
                          }}
                        />
                      </div>
                    </a>
                  }
                  description={
                    <div className="d-flex align-items-center">
                      <ProfileOutlined />
                      <div className="mx-2">
                        Note <i>{moment(note.createdAt).format("M/D/YYYY")}</i>
                      </div>
                      <Popconfirm
                        placement="right"
                        title="Are you sure delete this note?"
                        // onConfirm={confirm}
                        onCancel={() => deleteNote(note._id)}
                        okText="No"
                        cancelText="Yes">
                        <Button type="danger" size="small">
                          Delete note
                        </Button>
                      </Popconfirm>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Spin>
  )
}

export default MyNotes
