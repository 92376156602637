/* eslint-disable */
import React, { useEffect } from "react"
import { Link, useParams } from "react-router-dom"

import { useURL } from "../hooks/url.hooks"

const LeftMenu = ({ onCommodityHover, commodityString }) => {
  const url = useURL()
  const menuType = url.query.get("menuLeft")
  let { machine_ez_id } = useParams()

  // const onBatteryHover = () => {
  //   onCommodityHover('batteries')
  // }
  // const onFiltersHover = () => {
  //   onCommodityHover('filters')
  // }
  // const onHydraulicsHover = () => {
  //   onCommodityHover('hydraulics')
  // }
  // const onLubricantsHover = () => {
  //   onCommodityHover('lubricants')
  // }
  // const onRemanHover = () => {
  //   onCommodityHover('reman')
  // }
  // const onUndercarraigeHover = () => {
  //   onCommodityHover('undercarriage')
  // }

  // const setActiveColor = () => {
  //   switch (commodityString) {
  //     case "batteries":
  //       return "#9acf72"
  //     case "filters":
  //       return "#f17f3a"
  //     case "hydraulics":
  //       return "#d63c2f"
  //     case "lubricants":
  //       return "#fdf851"
  //     case "reman":
  //       return "#40a8e1"
  //     case "undercarriage":
  //       return "#863399"
  //     default:
  //       break
  //   }
  // }

  const handleOnChange = (value) => {
    onCommodityHover(value)
  }
  useEffect(() => {}, [menuType])
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-around left-learning-menu">
        <h4 id="commodities-title">
          <b>
            Learn About a
            <br />
            Commodity
          </b>
        </h4>
        <div className="link-list">
          <Link
            onMouseLeave={() => {
              if (
                machine_ez_id !== "wheeled-harvester" &&
                machine_ez_id !== "feller-buncher" &&
                machine_ez_id !== "forwarders" &&
                machine_ez_id !== "forestry-excavator"
              ) {
                handleOnChange("")
              }
            }}
            onMouseOver={() => {
              handleOnChange("batteries")
            }}
            className={`left-menu-link ${
              commodityString === "batteries" && commodityString
            }-still-color`}
            to="/dashboard/commodities/learn/batteries">
            <div className="ml-com-icon">
              <img
                height={75}
                width={75}
                src={require("../../assets/images/icons/BATTERIES White@3x.png")}
                alt="image"
              />
            </div>
            <h6>
              <b>Batteries</b>
            </h6>
          </Link>
          <Link
            onMouseLeave={() => {
              if (
                machine_ez_id !== "wheeled-harvester" &&
                machine_ez_id !== "feller-buncher" &&
                machine_ez_id !== "forwarders" &&
                machine_ez_id !== "forestry-excavator"
              ) {
                handleOnChange("")
              }
            }}
            onMouseOver={() => {
              handleOnChange("filters")
            }}
            className={`left-menu-link ${
              commodityString === "filters" && commodityString
            }-still-color`}
            to="/dashboard/commodities/learn/filters">
            <div className="ml-com-icon">
              <img
                height={75}
                width={75}
                src={require("../../assets/images/icons/FILTERS White@3x.png")}
                alt=""
              />
            </div>
            <h6>
              <b>Filters</b>
            </h6>
          </Link>
          <Link
            onMouseLeave={() => {
              if (
                machine_ez_id !== "wheeled-harvester" &&
                machine_ez_id !== "feller-buncher" &&
                machine_ez_id !== "forwarders" &&
                machine_ez_id !== "forestry-excavator"
              ) {
                handleOnChange("")
              }
            }}
            onMouseOver={() => {
              handleOnChange("hydraulics")
            }}
            className={`left-menu-link ${
              commodityString === "hydraulics" && commodityString
            }-still-color`}
            to="/dashboard/commodities/learn/hydraulics">
            <div className="ml-com-icon">
              <img
                height={75}
                width={75}
                src={require("../../assets/images/icons/HYDRAULICS White@3x.png")}
                alt=""
              />
            </div>
            <h6>
              <b>Hydraulics</b>
            </h6>
          </Link>
          {(url.pathname !== "/dashboard/machines/xray/hybrid-wheel-loader") && (
          <Link
            onMouseLeave={() => {
              if (
                machine_ez_id !== "wheeled-harvester" &&
                machine_ez_id !== "feller-buncher" &&
                machine_ez_id !== "forwarders" &&
                machine_ez_id !== "forestry-excavator"
              ) {
                handleOnChange("")
              }
            }}
            onMouseOver={() => {
              handleOnChange("lubricants")
            }}
            className={`left-menu-link ${
              commodityString === "lubricants" && commodityString
            }-still-color`}
            to="/dashboard/commodities/learn/lubricants">
            <div className="ml-com-icon">
              <img
                height={75}
                width={75}
                src={require("../../assets/images/icons/LUBRICANTS White@3x.png")}
                alt=""
              />
            </div>
            <h6>
              <b>Lubricants</b>
            </h6>
          </Link>
          )}
          <Link
            onMouseLeave={() => {
              if (
                machine_ez_id !== "wheeled-harvester" &&
                machine_ez_id !== "feller-buncher" &&
                machine_ez_id !== "forwarders" &&
                machine_ez_id !== "forestry-excavator"
              ) {
                handleOnChange("")
              }
            }}
            onMouseOver={() => {
              handleOnChange("reman")
            }}
            className={`left-menu-link ${
              commodityString === "reman" && commodityString
            }-still-color`}
            to="/dashboard/commodities/learn/reman">
            <div className="ml-com-icon">
              <img
                height={75}
                width={75}
                src={require("../../assets/images/icons/REMAN White@3x.png")}
                alt=""
              />
            </div>
            <h6>
              <b>Reman</b>
            </h6>
          </Link>
          {(url.pathname === "/dashboard/machines/xray/excavator" ||
            url.pathname === "/dashboard/machines/xray/dozer" ||
            url.pathname === "/dashboard/machines/xray/feller-buncher" ||
            url.pathname === "/dashboard/machines/xray/forestry-excavator") && (
            <Link
              onMouseLeave={() => {
                if (
                  machine_ez_id !== "wheeled-harvester" &&
                  machine_ez_id !== "feller-buncher" &&
                  machine_ez_id !== "forwarders" &&
                  machine_ez_id !== "forestry-excavator"
                ) {
                  handleOnChange("")
                }
              }}
              onMouseOver={() => {
                handleOnChange("undercarriage")
              }}
              className="left-menu-link under-color"
              to="/dashboard/commodities/learn/undercarriage">
              <div className="ml-com-icon">
                <img
                  height={75}
                  width={75}
                  src={require("../../assets/images/icons/undercarriage-icon.png")}
                  alt=""
                />
              </div>
              <h6>
                <b>Undercarriage</b>
              </h6>
            </Link>
          )}
            {(url.pathname === "/dashboard/machines/xray/hybrid-wheel-loader") && (
            <Link
              onMouseLeave={() => {
                if (
                  machine_ez_id !== "wheeled-harvester" &&
                  machine_ez_id !== "feller-buncher" &&
                  machine_ez_id !== "forwarders" &&
                  machine_ez_id !== "forestry-excavator"
                ) {
                  handleOnChange("")
                }
              }}
              onMouseOver={() => {
                handleOnChange("sr-drive-system")
              }}
              className="left-menu-link sr-drive-system-color"
              to="/dashboard/commodities/learn/sr-drive-system">
              <div className="ml-com-icon">
                {<img
                  height={75}
                  width={75}
                  src={"https://cdn.komatsupartsu.com/sr-drive-system-icon@2x.png"}
                  alt=""
              />}
              </div>
              <h6>
                <b>SR Drive System</b>
              </h6>
            </Link>
          )}
        </div>
      </div>
      {/* </Fade> */}
    </div>
  )
}
export default LeftMenu
