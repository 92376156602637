import React from "react"
import { FrostedGlass } from "../common/frosted/glass.frosted"

const EyePopup = ({
  left = 0,
  top = 0,
  content,
  contentSide = "left",
  className = "",
  type = "bat"
}) => {
  return (
    <div
      className="pointer-cursor eye-contain"
      style={{
        position: "absolute",
        left: `${left}%`,
        top: `${top}%`,
        height: 19,
        width: 25
      }}>
      <img
        className={`eyehover image-contain mb-3 mr-3 ${
          type === "uc" && "eyeuc"
        }`}
        height={19}
        width={25}
        src={require("../../assets/images/eye.png")}
        alt=""
      />

      <div
        className="eyepop"
        style={{
          position: "absolute",
          left: contentSide === "left" && -375,
          right: contentSide === "right" && -375,
          top: -50
        }}>
        <div
          className={`pointer-cursor batteries-border ${className}`}
          style={{ width: 350, textAlign: "left", borderRadius: 10 }}>
          <FrostedGlass
            containerStyle={{ borderRadius: 10, backgroundColor: "white" }}>
            {content}
          </FrostedGlass>
        </div>
      </div>
    </div>
  )
}

export default EyePopup
