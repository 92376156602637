import React, { useState } from "react"
import Lottie from "react-lottie"
import Fade from "react-reveal/Fade"
import { FrostedGlass } from "../common/frosted/glass.frosted"
import { Link } from "react-router-dom"

const ForestryPopupLearning = ({ machine }) => {
  const [popupText, setPopupText] = useState({ text: "", title: "" })

  return (
    <div>
      {popupText.text.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 400,
            left: -300
          }}>
          <FrostedGlass
            containerStyle={{ backgroundColor: "white" }}
            className={`${popupText.title.toLowerCase()}-border`}>
            <div  className={`capitalize-title`}>
              <b>{popupText.title.toLowerCase()}</b>
            </div>
            <div dangerouslySetInnerHTML={{ __html: popupText.text }} />
            <Link
              to={`/dashboard/commodities/learn/${popupText.title.toLowerCase()}`}>
              <div
                className={`${popupText.title.toLowerCase()}-color text-right mt-2 capitalize-title`}>
                <img
                  style={{ width: 20, marginRight: 5 }}
                  src={require(`../../assets/images/forestry-${popupText.title.toLowerCase()}-circle-arrow@2x.png`)}
                  alt=""
                />
                Learn More About {popupText.title}
              </div>
            </Link>
          </FrostedGlass>
        </div>
      )}
      {machine.commodities.map((popup) => {
        const lottieOptions = {
          loop: true,
          autoplay: true,
          animationData:
            popup.title.length > 0
              ? require(`../../assets/lotties/${popup.title.toLowerCase()}-ripple.json`)
              : "",
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }
        return (
          <div key={popup.x + popup.y}>
            <div
              onMouseEnter={() => setPopupText(popup)}
              // onMouseLeave={() => setPopupText({ text: '', title: '' })}
              style={{
                zIndex: 100,
                position: "absolute",
                left: `${popup.x}%`,
                bottom: `${popup.y}%`
              }}>
              <Fade bottom>
                <div
                  className="shadow pointer-cursor"
                  style={{
                    backgroundColor: "white",
                    borderRadius: 15
                  }}>
                  <Lottie
                    options={lottieOptions}
                    height={30}
                    width={30}
                    isStopped={false}
                    isPaused={false}
                  />
                </div>
              </Fade>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ForestryPopupLearning
