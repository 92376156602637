/* eslint-disable */
import React from "react"
// import { AnimatedRoute } from 'react-router-transition'
import { Route } from "react-router-dom"
import { mapSubPageStyles, maintheme } from "../../styles/maintheme.styles"
import WordMatchQuiz from "./wordmatch/wordmatch.quiz"
import WordMatchQuizAlt from "./wordmatch/wordmatch-2.quiz"
import { useHistory, useParams } from "react-router-dom"
import QuestionQuiz from "./question/question.quiz"
import { Button } from "antd"
// import WordFindQuiz from "./wordfind/wordfind.quiz"
import HotSpotQuiz from "./hotspot/hotspot.quiz"
import WordMatchQuizNext from "./wordmatch/wordmatch-3.quiz"
import { useURL } from "../hooks/url.hooks"
import LogicQuiz from "./logic/main.quiz"
import StackingQuiz from "./stacking/main.stacking"
import { Menu, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const QuizCompilator = ({ quiz, mode = "normal" }) => {
  const history = useHistory()
  let { ez_id } = useParams()
  const url = useURL()
  const sub_quiz = url.query.get("sub_quiz")
  const quiz_prompt = url.query.get("quiz_prompt")
  // const qid = url.query.get("qid")

  const DetermineComponent = (quizz, ez_id, index) => {
    if (quizz.quiz_type === 0) {
      return (
        <WordMatchQuiz
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
        />
      )
    } else if (quizz.quiz_type === 2) {
      return (
        <QuestionQuiz
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
        />
      )
    } else if (quizz.quiz_type === 3) {
      return (
        <WordMatchQuizAlt
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
        />
      )
//    } else if (quizz.quiz_type === 4) {
//      return (
//        <WordFindQuiz
//          totalQuestions={quiz.sub_quizes.length}
//          index={index}
//          quiz={quizz}
//         ez_id={ez_id}
//        />
//      )
    } else if (quizz.quiz_type === 5) {
      return (
        <HotSpotQuiz
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
        />
      )
    } else if (quizz.quiz_type === 6) {
      return (
        <WordMatchQuizNext
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
          imge={require("../../assets/images/quiz-question-bubble.png")}
        />
      )
    } else if (quizz.quiz_type === 7) {
      return (
        <QuestionQuiz
          multiple
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
        />
      )
    } else if (quizz.quiz_type === 8) {
      return (
        <WordMatchQuizNext
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
          imge={require("../../assets/images/Topic_Icons2_OpenBox.png")}
        />
      )
    } else if (quizz.quiz_type === 9) {
      return (
        <LogicQuiz
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
        />
      )
    } else if (quizz.quiz_type === 10) {
      return (
        <StackingQuiz
          totalQuestions={quiz.sub_quizes.length}
          index={index}
          quiz={quizz}
          ez_id={ez_id}
        />
      )
    }
  }

  const RenderRoutes = (quiz, index) => (
    <Route
      key={quiz._id}
      className="switch-wrapper"
      component={() => DetermineComponent(quiz, ez_id, index)}
      exact={true}
      path={`/dashboard/quiz/${quiz.ez_id}`}
      atEnter={maintheme.subPageEnter}
      atLeave={maintheme.subPageLeave}
      atActive={maintheme.subPageActive}
      mapStyles={mapSubPageStyles}
    />
  )

  const RenderModalRoutes = (quiz, index) => {
    if (typeof sub_quiz !== "undefined" && sub_quiz === quiz.ez_id) {
      return <div>{DetermineComponent(quiz, sub_quiz, index)}</div>
    }
  }

  const navigate = () => {
    if (mode !== "modal") {
      history.push(`/dashboard/quiz/${quiz.sub_quizes[0].ez_id}`)
    } else {
      history.push(
        `${window.location.pathname}?quiz_prompt=${quiz_prompt}&sub_quiz=${quiz.sub_quizes[0].ez_id}&quiz_active=true`
      )
    }
  }

  // const menu = (
  //   <Menu>
  //     {quiz.sub_quizes.map((quiz) => {
  //       return (
  //         <Menu.Item key={quiz.ez_id}>
  //           <a
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             href={`/dashboard/quiz/${quiz.ez_id}?qid=${qid}`}>
  //             {quiz.ez_id}
  //             <br />
  //             quiz id:{quiz._id}
  //           </a>
  //         </Menu.Item>
  //       )
  //     })}
  //   </Menu>
  // )

  return (
    <Spin
      spinning={quiz.sub_quizes.length == 0}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <div className="page p-4 head-bg">
        <center>
          <h2>
            <b>Test Your Knowledge</b>
          </h2>
          {sub_quiz === null && (
            <div>
              <h2>{quiz.name}</h2>
              <h4>{quiz.sub_quizes.length} questions</h4>
              <Button onClick={navigate}>Start</Button>
            </div>
          )}
        </center>
        {mode === "normal" && quiz.sub_quizes.map(RenderRoutes)}
        {mode === "modal" && quiz.sub_quizes.map(RenderModalRoutes)}
      </div>
    </Spin>
  )
}
export default QuizCompilator
