import React from "react"
import { Row, Col } from "antd"
import { useParams } from "react-router-dom"
import { useCommodityScreen } from "../hooks/screen.hooks"

const ToolsResources = () => {
  const { commodity_ez_id } = useParams()
  const { commodity } = useCommodityScreen(commodity_ez_id)
  return (
    <div className="page">
      <center>
        <h1 className={`${commodity_ez_id}-color capitalize-title`}>
          <b>{commodity.name}</b>
        </h1>
        <h4>
          <b>Resources & Tools</b>
        </h4>
      </center>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="py-5">
            <div className="mates-chart">
              <div>Printed Resources</div>

              {commodity.tools_and_resources.length > 0 && (
                <div>
                  {commodity.tools_and_resources[0]?.map((resource) => {
                    return (
                      <div className="mates-chart-alt" key={resource[0]}>
                        <div>{resource[0]}</div>
                        {resource[1].length > 0 && (
                          <div>
                            <b>What it is:</b> <br />
                            <p>{resource[1]}</p>
                          </div>
                        )}
                        {/* <Divider /> */}
                        {resource[2].length > 0 && (
                          <div>
                            <b>Where to get it:</b> <br />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: resource[2]
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
            <div className="mates-chart mt-4">
              <div>Online Resources</div>
              {commodity.tools_and_resources.length > 0 && (
                <div>
                  {commodity.tools_and_resources[1]?.map((resource) => {
                    return (
                      <div className="mates-chart-alt" key={resource[0]}>
                        <div>{resource[0]}</div>
                        {resource[1].length > 0 && (
                          <div>
                            <b>What it is:</b> <br />
                            <p>{resource[1]}</p>
                          </div>
                        )}

                        {resource[2].length > 0 && (
                          <div>
                            <b>Where to get it:</b> <br />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: resource[2]
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ToolsResources
