/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import 'swiper/swiper.min.css'

const PartLookupProTips = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="lookup"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1 style={{color:'var(--look-up)'}}>Parts Lookup ProTips</h1>
                    <p style={{"marginTop":"0px"}}>Next-level information to help you move faster and get it right everytime</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                        <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="Parts that come with parts" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Part number seem wrong?" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="Engine parts book in S-BOM" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Engine parts book in CSS" onClick={() =>sliderNavClicked(4)}>4</a></li>
                        <li><a href="#slide5" className={"step-title " + (activeSlide === 5 ? 'active' : '')} data-text="IC Codes and what they mean" onClick={() =>sliderNavClicked(5)}>5</a></li>
                        <li><a href="#slide6" className={"step-title " + (activeSlide === 6 ? 'active' : '')} data-text="Machine type model numbers" onClick={() =>sliderNavClicked(6)}>6</a></li>
                        <li><a href="#slide7" className={"step-title " + (activeSlide === 7 ? 'active' : '')} data-text="Part number quick lookup" onClick={() =>sliderNavClicked(7)}>7</a></li>
                    </ol>
                </nav>

                <div className="flex-row" style={{color:"black"}}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                      <SwiperSlide id='slide1' data-hash='slide1'>
                      <h3>Parts that automatically come with additional parts</h3>
                        <hr/>
                        <p>In the CSS Parts Book or in S-BOM, when you see a part followed by a list of parts
                            with a black dot next to the part numbers, this means that when you place the order, all the
                            parts with this black dot will be
                            included when you order the top parent part (Part #4 in this example).</p>
                          <img src={require('../../images/protip-slide-1.jpg')} alt="step 1"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                      <h3>Does a part number seem wrong?</h3>
                        <hr/>
                        <p>Pay close attention to the breaks in the serial number and make sure you have the
                            correct one for your machine. If you locate a seemingly correct part number that comes up as
                            something else in KPP, quadruple check your own information and cross-reference with as many
                            resources as possible. If it looks like it really is a mistake in KPP, contact your DPSM.
                        </p>
                          <img src={require('../../images/sbom-slide-9.jpg')} alt="step 3"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                      <h3>Separate engine parts book in S-BOM</h3>
                        <hr/>
                        <p>For some machine models, the engine has its own separate parts book. In S-BOM, you
                            can identify that is the case when you see additional parts books like this under the main
                            machine parts book listings. Notice the difference in the last break in the serial
                            number&mdash;make sure you select the correct one for your customer's machine. See ProTip 4
                            for the corresponding view in CSS.</p>
                          <img src={require('../../images/protip-slide-2.jpg')} alt="step 3"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                      <h3>Separate engine parts book in CSS</h3>
                        <hr/>
                        <p>For some machine models, the engine has its own separate parts book. In CSS, you can
                            identify that is the case when you see an additional section above the engine listing.
                            Select
                            this parts book to view all engine parts for this machine. See ProTip 3 for the
                            corresponding view in S-BOM.</p>
                          <img src={require('../../images/protip-slide-3.jpg')} alt="step 4"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide5' data-hash='slide5'>
                      <h3>IC Code Definitions</h3>
                        <hr/>
                        <p>You will encounter IC codes when looking up parts. Reference the list below to learn what the
                            codes mean so you understand the actions you need to take to make sure you get the correct
                            part for your customer.</p>
                        <table className="ic-table">
                            <tbody>
                                <tr>
                                    <td className="ic-code header-row" style={{"borderTopLeftRadius":"5px"}} >IC Code</td>
                                    <td className="ic-definition header-row" style={{"borderTopRightRadius":"5px"}}>
                                        Definition</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">Blank</td>
                                    <td className="ic-definition">Normal part that is available for allocating from free on
                                        hand and/or free on order. Any remaining quantity will go to suspend.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">010</td>
                                    <td className="ic-definition">DB order line is cancelled or not accepted and there must
                                        be comments advising the DB why the part is not available and what steps, if
                                        any, are to be taken to replace the part number.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">014</td>
                                    <td className="ic-definition">Part has quality issue and cannot be sold.</td>
                                </tr>
                                <tr>
                                <td className="ic-code">015</td>
                                <td className="ic-definition">Part is only sold as a component of a kit. Kit DO's will
                                    be allocated from free on hand and/or free on order. If Kit DO and no free on
                                    hand or free on order exists, line goes to suspend. For all other DB DO's, lines
                                    are not accepted and should be cancelled.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">016</td>
                                    <td className="ic-definition">Part has been replaced by another part. Any existing free
                                        on hand and/or free on order will be allocated. The remaining quantity will move
                                        to the replacement part to either be allocated if free on hand or free on order
                                        exists OR to be placed to suspend.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">017</td>
                                    <td className="ic-definition">Part is replaced by multiple parts. If free on hand and/or
                                        free on order exists, line will allocate. Remainder will go to suspend as no
                                        stock.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">018</td>
                                    <td className="ic-definition">Part is fully interchangeable with one or more part
                                        numbers. We call this a part family. Order line should be allocated using any
                                        free stock on hand and/or free stock on order for any of the parts in the family
                                        beginning with the oldest 018 part in the family. When no other free on hand or
                                        free on order exists in the family, the line should go to suspend.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">019</td>
                                    <td className="ic-definition">Part will be replaced in the future when all on hand and
                                        on order is exhausted. There must be a future replacement date. If free on hand
                                        or free on order exists, line can be allocated. Otherwise, line goes to suspend.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ic-code">020</td>
                                    <td className="ic-definition">Part is no longer supplied. If free on hand and/or free on
                                        order exists, line will allocate. Remainder will go to suspend as no stock.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">023</td>
                                    <td className="ic-definition">Part is supplied but price and lead time must be verified.
                                        If free on hand or free on order exists, line can be allocated.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">024</td>
                                    <td className="ic-definition">Part publication or parts book error. Order not accepted.
                                        Lines must be cancelled.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">027</td>
                                    <td className="ic-definition">Part will be discontinued. If free on hand and/or free on
                                        order exists, line will allocate. Remainder will go to suspend as no stock.</td>
                                </tr>
                                <tr>
                                    <td className="ic-code">041</td>
                                    <td className="ic-definition">Part is supplied by direct shipment from the supplier
                                        only. Lines go to suspend.</td>
                                </tr>

                                <tr>
                                    <td className="ic-code">099</td>
                                    <td className="ic-definition">Part has been discontinued. DB order line is cancelled.
                                    </td>
                                </tr>

                                <tr>
                                    <td className="ic-code" style={{"borderTopLeftRadius":"5px"}}>088</td>
                                    <td className="ic-definition" style={{"borderTopRightRadius":"5px"}}>Part is ordered
                                        from the supplier for direct shipment.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                      </SwiperSlide>
                      <SwiperSlide id='slide6' data-hash='slide6'>
                            <img src={'https://cdn.komatsupartsu.com/protip-slide-5.jpg'} alt="step 6"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide7' data-hash='slide7'>
                      <h3>Use Bin Trip Analysis in <a href="https://na.parts.komatsu/Home/MainPage" target="_blank">KPP <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a> for quick part number lookup</h3>
                        <hr/>
                        <p>To quickly lookup a part number, you can use the Bin Trip Analysis and search by machine
                            model:</p>
                        <ol>
                            <li>Search for and select the machine model number for your part</li>
                            <li>Click the search button</li>
                            <li>After the full part list for this machine populates, you can quickly search for any part
                                by typing it into the search box</li>
                            <li>Locate the part in question from the resulting list and capture the part number.</li>
                        </ol>
                            <img src={require('../../images/bin-trip-analysis-slide.jpg')} alt="step 7"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default PartLookupProTips
