import { call, put, select } from "redux-saga/effects"
import axios from "axios"
import { GET_MACHINE_SUCCESS, GET_MACHINE_FAIL  } from "../actions/machine.actions"
import {
  APIResponseParser,
  ErrorHandler
} from "../../helpers/APIResponseParser.helpers"
import { addHeaders } from "../../helpers/Saga.helpers"
import { baseUrl } from "../../data/misc.data"
import { tokenSelector } from "../selectors/selectors"
const MachineGETAPI = async (payload, token) => {
  const result = await axios.get(`${baseUrl}/v1/machine/${payload.ez_id}`, {
    headers: addHeaders(token)
  })
  return APIResponseParser(result)
}

function* MachineGETSaga(action) {
  try {
    const token = yield select(tokenSelector)

    const data = yield call(MachineGETAPI, action.payload, token)
    yield put(GET_MACHINE_SUCCESS(data))
  } catch (e) {
    ErrorHandler(e)
    localStorage.clear()
    yield put(GET_MACHINE_FAIL(e))
  }
}
export default MachineGETSaga
