/* eslint-disable */
import React from "react"
import { Modal, Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import UserInfoForm from "../user-info/user.info"
import { useState,useEffect } from "react"
import { useSelector } from "react-redux"

//document.documentElement.setAttribute("data-theme", "mydna");

const Index = ({
    dataTheme = "",
}) => {
    const user = useSelector((state) => state.User)
    const [state, setstate] = useState({
        modal: false,
        content: "tools"
    })

    useEffect(() => {
        const userDataModal = JSON.parse(localStorage.getItem('userDataModal'));
        if ((userDataModal === false) && (userDataModal !== null) ) {
          userInformation();
        }
      }, [user]);

      const userInformation = () => {
        setstate({
          modal: true,
          content: "userInfo",
        })
      }
      const close = () => {
        setstate({
          modal: false,
          content: ""
        })
      }

  return (
    <>
        <div className="myDnaTopLanding myDnaPage main-landing page" data-theme="mydna"> 
            <main style={{display: "block", minHeight:"100vh", textAlign:"center"}} className="main-landing">
                <h1 className="page-title">Komatsu Parts Counter</h1>
                <h3 className="sub-title">Commodity and Tooling Learning Center</h3>
                <section className="flex-row ">
                    <div className="parts-u-portal">
                        <h2>Parts U</h2>
                        <div className="portal-wrapper">
                            <svg className="border-container" height="400" width="400" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="180" cy="180" r="175" className="shape" height="360" width="360" fillOpacity="0">
                                </circle>
                            </svg>
                            <a href="/dashboard/machines"></a>
                        </div>
                        <p>Learn all about Komatsu machines and commodities</p>
                    </div>
                    <div className="mydna-portal">
                        <h2>MyDNA</h2>
                        <div className="portal-wrapper">
                            <svg className="border-container" height="400" width="400" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="180" cy="180" r="175" className="shape" height="360" width="360" fillOpacity="0">
                                </circle>
                            </svg>
                            <a href="mydna"></a>
                        </div>
                        <p>Learn the fundamentals of the parts counter position and the tools used to get the job done</p>
                    </div>
                </section>
            </main>
        </div>
        <Modal
            visible={state.modal}
            onOk={close} 
            onCancel={close}
            footer={null}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
            okText="Close">
            {state.content === "userInfo" && (
                <UserInfoForm
                    onComplete={() => {
                    localStorage.setItem('userDataModal', true);
                    setstate({
                        modal: false,
                        content: ""
                    })
                    }}
                />
            )}        
        </Modal>
    </>
  )
}

export default Index
