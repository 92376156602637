import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CALL_QUIZ_API, RESET_QUIZ } from "../../store/actions/quiz.actions"

export const useQuiz = (quizId) => {
  const dispatch = useDispatch()
  const quiz = useSelector((state) => state.Quiz)

  const submitAnswers = () => {}

  const onEffect = () => {
    dispatch(
      CALL_QUIZ_API({
        quizId,
        method: "get"
      })
    )
    return () => {
      dispatch(RESET_QUIZ())
    }
  }

  useEffect(onEffect, [quizId])

  return {
    quiz,
    submitAnswers
  }
}
