export const commoditySalesTips = {
  batteries:
    "<b>How to determine the right battery for the machine:</b> <br/>• Read the equipment manual provided by your OEM to identify the right battery size <br/>• Measure the length, width, height (from bottom of case to top of terminals)<br/><br/><b>Identify:</b><br/>• Battery group size of the product at hand <br/>• Post locations (positive and negative terminals) <br/>• CCA requirement for the application<br/><br/>It would also be helpful to measure the battery box to make sure that the battery will fit in it correctly.",
  filters:
    "Not all filters are created equal. Komatsu Genuine filters are specifically designed to meet Komatsu’s high standards, approved tolerances and machine operating specifications. Using a third-party filter can affect machine performance and may also damage the machine or other components. Even if the filter has the same micron rating, the filter efficiency may not meet critical specifications, which can affect overall performance and component life.",
  lubricants:
    '<b>2 Key Product: Engine Oil 15W40 LA CK4 and Hydraulic Oil HO56</b><br/><b>HO56-HE:</b></br> Energy efficient fluid for use in the hydraulic systems of most Komatsu equipment under all ambient temperature conditions, including excavators with hybrid drives. Also for use in "all makes" (unless TO-4 is required) for customers looking for a hydraulic fluid that can potentially lower their fuel bill<br/><b>Engine Oil 15W40 LA CK4:</b></br> Outperforms conventional oil types, meets Tier IV standard and has CK4 API Certification. It is a semi-synthetic base oil for greater heat tolerance and shear stability and has "all makes" compatibility. It is the best option for heavy-duty off-road diesel engine applications',
  reman:
    '<b>How to Quote to Your Customer:</b> <br/>A) Quote the Reman price outright:  "Your price is $25,000"<br/>B) With the exchange of a good core, your price is $15,000<br/><br/><div class="reman-color">*B is the correct way to quote. Always give your customer the price with core return.</div><br/><br/><b>Make sure your customer knows:</b>Komatsu Genuine Reman [component] is a direct-fit for your machine, and assures high-quality performance and reliability at the lowest cost with the best Warranty in the industry (1 year)'
}

export const batteriesImagesProps = [
  {
    image: require("../assets/images/Batteries_Heavyduty_xparent.png"),
    title: "Heavy Duty 12V",
    description: "Heavy Duty 12V batteries are used for heavy equipment."
  },
  {
    image: require("../assets/images/Batteries _lightduty_xparent.png"),
    title: "Light Duty 6V/12V",
    description:
      "Light Duty 6V/12V batteries are used for golf carts and trucks"
  }
]

export const hydraulicsImagesProps = [
  {
    image: require("../assets/images/Hydraulics_Main_Image.png"),
    title: "Pumps",
    description:
      "A pump converts mechanical energy into hydraulic energy and creates flow."
  },
  {
    image: require("../assets/images/Reman_Major_SwingMotor_xparent.png"),
    title: "Motors",
    description: "Motors are rotary or linear actuators used to perform work"
  },
  {
    image: require("../assets/images/Hydraulics_HydraulicCylinder_Xparent.png"),
    title: "Cylinders",
    description: "Linear Actuator: rod and barrel—extends and retracts"
  },
  {
    image:
      "https://komatsupartsu.com/v1/images/public/Hydraulic_Major_HoseXparents.png",
    title: "Hoses",
    description:
      "Supply and return lines (fully built hose assemblies are listed in the machine parts book and are available through KPP)"
  },
  {
    image: require("../assets/images/Lubricants_Hydraulic_Oil_xparent_1000px.png"),
    title: "Hydraulic Oil",
    description: "The media under pressure in the hydraulic system."
  },
  {
    image: require("../assets/images/Hydraulics_Major_Filter_Xparent.png"),
    title: "Filters",
    description: "Protects the hydraulic system from contamination"
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Valves",
    description:
      "A Valve controls how the system operates: pressure, flow and directional control."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Reservior",
    description: "The Reservoir is a tank that stores hydraulic oil."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Seals",
    description: "Seals prevent leaks (AKA: o-rings, gaskets)."
  }
]

export const filtersImagesProps = [
  {
    image: require("../assets/images/Filter_Major_Air_xparent.png"),
    title: "Air Filter",
    description:
      "An Air filter is a device that uses fibrous or porous materials to protect the engine from various harmful particles like dust, dirt, pollen etc."
  },
  {
    image: require("../assets/images/Filter_Major_Cabin_xparent.png"),
    title: "Cabin Filter",
    description:
      "Similar to the air filter, a cabin filter keeps the operator's cab dust and pollen free through air conditioning."
  },
  {
    image: require("../assets/images/Fuel Filter.png"),
    title: "Pre-Fuel Filter (Diesel)",
    description:
      "A fuel filter is used to screen out contaminants such as dirt, dust, debris and rust particles that may gather in the fuel to protect the engine."
  },
  {
    image: require("../assets/images/Filters_MainFuelFilter_Xparent.png"),
    title: "Main Fuel Filter (Diesel)",
    description:
      "A fuel filter is used to screen out contaminants such as dirt, dust, debris and rust particles that may gather in the fuel to protect the engine."
  },
  {
    image: require("../assets/images/Filter_Major_LubeFilter_Xparent.png"),
    title: "Lube/Engine Oil Filter",
    description:
      "Similar to fuel filters, lube filters screen contaminates from the engine. They are abrasive against important moving parts and can cause wear on the engine."
  },
  {
    image: require("../assets/images/Hydraulics_Major_Filter_Xparent.png"),
    title: "Hydraulic Oil Filter",
    description:
      "Hydraulic filters maintain the integrity of the oil by cleaning various debris and upholding pressure within the system."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/Filter_KCCV_Xparent.png",
    title: "KCCV Filter",
    description:
      "Crankcase emissions (blow-by gas) are passed through a CCV filter. The oil mist trapped in the filter is returned back to the crankcase while the filtered gas is returned to the air intake."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/DEF-filter.png",
    title: "DEF Filter",
    description:
      "DEF (Diesel Emissions Fluid) filters ensure the system is free of contaminants and urea crystals; otherwise, the SCR (Selective Catalytic Reduction) system dedicated to reducing harmful emissions can malfunction or not work properly."
  }
]

export const remanImagesProps1 = [
  {
    image: require("../assets/images/po-reman-1.png"),
    title: "Cylinder Head",
    description:
      "Cylinder heads help control the gases, for intake and exhaust, and the combustion process in an engine."
  },
  {
    image: require("../assets/images/Reman_Minor_HydraulicCylinder_Xparent.png"),
    title: "Hydraulic Cylinders",
    description:
      "A hydraulic cylinder is a mechanical device, similar to a linear hydraulic motor, that converts hydraulic pressure and flow from the oil into unidirectional motion."
  },
  {
    image: require("../assets/images/po-reman-2.png"),
    title: "Turbocharger",
    description:
      "A turbocharger is an exhaust driven air pump. It uses the exhaust gas to drive a turbine to then spin the air compressor that pushes extra air into the cylinders, allowing them to burn more fuel."
  },
  {
    image: require("../assets/images/po-reman-3.png"),
    title: "Water Pump",
    description:
      "A water pump is a mechanical device that moves coolant through the engine block to cool and help regulate the temperature of the engine."
  },
  {
    image: require("../assets/images/po-reman-4.png"),
    title: "Air Compressor",
    description:
      "An air compressor is basically a small piston engine.  It compresses air to be stored and used to power and control air actuated devices on the equipment."
  },
  {
    image: require("../assets/images/po-reman-5.png"),
    title: "Brake Components",
    description:
      "The major brake system components include the brake pedal, brake booster, brake master cylinder, brake lines and hoses, brake calipers and pistons, disc brake pads or brake shoes, disc brake rotors or brake drums, brake fluid, anti-lock brake system (ABS)"
  },
  {
    image: require("../assets/images/po-reman-6.png"),
    title: "Starter",
    description:
      "The Starter rotates the engine until the engine runs on its own."
  },
  {
    image: require("../assets/images/po-reman-7.png"),
    title: "iMC Components",
    description:
      "<b>X31—Intelligent Excavator Machine Control Monitor:</b> shows operator where the cuts and the fills are, as well as other features on the jobsite.<br/><br/><b>GX55—Intelligent Dozer Machine Control Monitor:</b>  shows operator where the cuts and the fills are, as well as other features on the jobsite.<br/><br/><b>MCR3—GNSS Receiver:</b> provides GPS/GNSS position for the machine so it knows where it is in the world and on the jobsite.<br/><br/>(GPS: Global Positioning Satellite)  (GNSS: Global Navigation Satellite System)"
  },

  {
    image: require("../assets/images/po-reman-electronic-card.png"),
    title: "Electronic Component",
    description:
      "Machine control cards, panels and modules, mainly for Electric Drive Trucks (EDTs)."
  },
  {
    image: require("../assets/images/po-EGR-Cooler.png"),
    title: "EGR Cooler",
    description:
      "The EGR cooler is a component used to lower the temperature of the exhaust gases that are recirculated by the EGR system."
  },
  {
    image: require("../assets/images/po-KDPF-Filter.png"),
    title: "KDPF",
    description:
      "Komatsu Diesel Particulate Filter: Handles particulate matter accumulation (soot). Operates on a time-based active regeneration set at 48 hours to proactively clean SCR system components."
  },
  {
    image: require("../assets/images/po-Reman_Minor_Injectior_Xparent.png"),
    title: "Fuel System Components",
    description:
      "The fuel system consists of fuel injection pump, fuel injectors, fuel lines, and fuel filters."
  },
  {
    image: require("../assets/images/po-Reman_Minor_Pistons_Xparent.png"),
    title: "Piston",
    description:
      "A piston is a movable disk within a cylinder. As the disk moves up and down against liquid or gas inside, it transfers heat energy into mechanical work in internal combustion engines and pumps."
  },
  {
    image: require("../assets/images/po-Reman_Major_PumpFuelInj.png"),
    title: "Fuel Pump",
    description:
      "The fuel pump is the device that provides Diesel Fuel into the cylinders of the diesel engine. It meters and times the injection of the fuel  into the cylinders to maximize the power delivered by the engine."
  },
  {
    image: require("../assets/images/po-control-valve.png"),
    title: "Control Valve",
    description: ""
  },
  {
    image: require("../assets/images/po-Alternator.png"),
    title: "Alternator",
    description:
      "An alternator is a device that converts mechanical energy into electrical energy.  This provides power to all of the electrical devices on the machine."
  },

  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Swing Bearing",
    description:
      "A bearing is a device that is used to enable rotational or linear movement, while reducing friction and handling stress. Swing bearings support heavy but slow turning loads."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Oil Cooler",
    description:
      "An oil cooler can also be called a radiator, which is a heat exchanger. It takes in hot oil just passed through the engine to cool it down."
  },
  // {
  //   image: 'https://komatsupartsu.com/v1/images/public/placeholder.png',
  //   title: 'Steering Components',
  //   description: ''
  // },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "A/C Compressors",
    description:
      "An A/C compressor is a pump which compresses refrigerant gas vapor and pumps it through the machines air conditioning system."
  }
]

export const remanImagesProps2 = [
  {
    image: require("../assets/images/po-reman-2-1.png"),
    title: "Torque Converter",
    description:
      "A torque converter is a type of fluid/hydraulic coupling, or a device that transmits rotational mechanical power. Automatic transmission machines use this to disconnect from the drive shaft and transmission; for example: allow the engine to idle without moving. Torque converters replace a clutch in manual machines."
  },
  // {
  //   image: require('../assets/images/po-reman-2-2.png'),
  //   title: 'Pump',
  //   description:
  //     'The fuel pump is the device that provides Diesel Fuel into the cylinders of the diesel engine, it meters and times the injection of the fuel  into the cylinders to maximize the power delivered by the engine.'
  // },
  {
    image: require("../assets/images/po-reman-2-3.png"),
    title: "Swing Motor",
    description:
      "A motor is a device that changes a form of energy into mechanical energy to produce motion."
  },
  {
    image: require("../assets/images/po-reman-2-4.png"),
    title: "Travel Motor",
    description:
      "A motor is a device that changes a form of energy into mechanical energy to produce motion."
  },
  {
    image: require("../assets/images/po-reman-2-5.png"),
    title: "Axle Assemblies",
    description:
      "An axle assembly links two wheels on opposite sides together and passes on rotational force coming from the transmission to the wheels of the vehicle."
  },

  {
    image: require("../assets/images/po-Reman_Major_Pump_Hyd_Piston_Xparent.png"),
    title: "Hydraulic Pump",
    description:
      "A pump is a mechanical device that moves liquids and air, or compresses gas through suction or pressure."
  },
  {
    image: require("../assets/images/Reman_Major_Power Module_Xparent.png"),
    title: "Power Modules",
    description:
      "Power modules convert AC power to DC power for various components such as motor drives."
  },

  {
    image: "https://komatsupartsu.com/v1/images/public/engine-assembly.png",
    title: "Engine Assemblies",
    description:
      "A Diesel engine assembly is a complete set of various parts that make up a diesel engine, such as – the engine block, cylinder liners, crankshaft, connecting rods, pistons, cylinder heads, and fuel injection system"
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Transmissions",
    description:
      "A transmission is essentially the gearbox - a part that transfers the engines power into motion. Without it, you’d sit in your machine with the engine running and go nowhere."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Gear Pump",
    description:
      "A gear pump uses rotating gears to push fluid from low pressure to high by displacement. They are often used for hydraulic fluid power."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Suspensions",
    description:
      "The Suspension is the system springs, shock absorbers, and linkages that connect to the wheels and allow relative motion between the two. This system helps with ride quality, handling and wear when it comes to cushioning the impact of terrain conditions."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/placeholder.png",
    title: "Differentials",
    description:
      "Differentials are designed to drive a pair of wheels while allowing them to rotate at different speeds while averaging the two. When turning, the outermost wheel can travel faster and farther than the innermost wheel."
  },
  {
    image:
      "https://komatsupartsu.com/v1/images/public/Reman_Minor_FinalDrive_Xparent.png",
    title: "Final Drives",
    description:
      "The final drive is primarily the planetary gear system that converts power to torque to move the machine. Sometimes, the hydraulic/travel motor is part of the final drive by other definitions."
  }
]

export const undercarriageImagesProps1 = [
  //   {
  //     image: require('../assets/images/po-undercarriage-1.png'),
  //     title: 'Torque Converter',
  //     description:
  //       'A torque converter is a type of fluid/hydraulic coupling, or a device that transmits rotational mechanical power. Automatic transmission machines use this to disconnect from the drive shaft and transmission; for example: allow the engine to idle without mo'
  //   },
  //   {
  //     image: require('../assets/images/po-undercarriage-2.png'),
  //     title: 'Pump',
  //     description:
  //       'A pump is a mechanical device that moves liquids and air, or compresses gas through suction or pressure.'
  //   },
  //   {
  //     image: require('../assets/images/po-undercarriage-3.png'),
  //     title: 'Swing Motor',
  //     description:
  //       'A motor is a device that changes a form of energy into mechanical energy to produce motion.'
  //   },
  //   {
  //     image: require('../assets/images/po-undercarriage-4.png'),
  //     title: 'Travel Motor',
  //     description:
  //       'A motor is a device that changes a form of energy into mechanical energy to produce motion.'
  //   }
]

export const undercarriageImagesProps2 = [
  {
    image: require("../assets/images/po-undercarriage-1.png"),
    title: "Track Link Assembly",
    description:
      "Track link assemblies includes links, pins and bushings. The links form a chain for the track shoes to attach. They provide weight support and a surface for the machine to move on."
  },
  {
    image: require("../assets/images/po-undercarriage-2.png"),
    title: "Track Shoe",
    description:
      "Track shoes are the platform of the machine where machine weight is supported and include a grouser or grousers which transfer the traction to the ground. There are different types of shoes for different terrain."
  },
  {
    image: require("../assets/images/po-undercarriage-3.png"),
    title: "Carrier Roller",
    description:
      "Carrier rollers are placed at the top of the undercarriage roller frames. They support the weight of the track and guide it between the sprocket and idler."
  },
  {
    image: require("../assets/images/po-undercarriage-4.png"),
    title: "Track Roller",
    description:
      "Track rollers are placed at the bottom of the undercarriage roller frame to guide the tracks and support the weight of the machine."
  },
  {
    image: require("../assets/images/po-undercarriage-5.png"),
    title: "Idler",
    description:
      "Idlers are installed at the front of the track frame and is used to guide the track link assembly. Idler is equipped with track link tension adjustment mechanism and cushion mechanism."
  },
  {
    image: require("../assets/images/po-undercarriage-6.png"),
    title: "Sprocket",
    description:
      "The main purpose of the sprocket is to engage with the Track Link Assembly and move the machine forward or backwards. A sprocket on a dozer or excavator is very much like a sprocket on a bicycle, and the overall condition of this component must be closely monitored. When the sprocket is excessively worn it can break or slip and the ability to move the machine in a consistent manner will be negatively affected."
  },
  {
    image: require("../assets/images/po-undercarriage-7.png"),
    title: "Link",
    description:
      "One individual component of the Link Assembly. The Track Shoes are bolted to the Links."
  },
  {
    image: require("../assets/images/po-undercarriage-8.png"),
    title: "Bushing & Pin",
    description:
      "A part of the Track Link Assembly along with Track Links and seals. The bushing is engaged by the sprocket to create transactional force for forward and reverse operation."
  }
]

export const lubricantsImagesProps1 = [
  {
    image: require("../assets/images/po-lub-1.png"),
    title: "Engine Oil",
    description:
      "Engine Oil, also known as motor oil or engine lubricant, is used to help reduce friction and wear of the moving parts of the engine as well as clean it."
  },
  {
    image: require("../assets/images/po-lub-2.png"),
    title: "Hydraulic Oil",
    description:
      "Hydraulic oil is a non-compressible fluid that is not only a lubricant but also is used to transfer power within hydraulic machinery and equipment."
  },
  {
    image: require("../assets/images/po-lub-3.png"),
    title: "Power Train Transmission Oil",
    description:
      "Transmission oil is more refined and light to maintain the heat and high pressure within the system. "
  },
  {
    image: require("../assets/images/po-lub-4.png"),
    title: "Axle Oil",
    description:
      "Axle oil is used in axles with built-in brake and limited slip differential (LSD) to protect the brakes from high temperatures, wear and tear and reduce brake noise."
  },
  {
    image: require("../assets/images/po-lub-5.png"),
    title: "Grease",
    description:
      "While grease has a similar use as a lubricant for mechanical parts, its consistency tends to be more semi-solid to solid to hold longer and seal better than conventional oil."
  }
]

export const srDriveSystemImagesProps = [
  {
    image: "https://cdn.komatsupartsu.com/sr-drive-system-motor.png",
    title: "Motors",
    description: "There is one motor hooked up to a driver or planetary gear in each wheel. The motor provides the power to turn the gears to move the machine. The motors can produce excess power due to inertia and act as a generator."
  },
  {
    image: "https://cdn.komatsupartsu.com/sr-drive-system-generator.png",
    title: "Generators",
    description:
      "The generator is attached to the engine and is used to maintain a set voltage on the common buss. In propel it generates to replace energy that is being removed from the common buss by the wheel motors. When the machine is in regenerative braking it switches into a motoring mode dissipating the excess energy created by the motors as they slow the vehicle speed. (This is commonly referred to a regenerative braking) In the motoring mode the generator is turning the diesel engine as well as the hydraulics that are attached."
  },
  {
    image: "https://cdn.komatsupartsu.com/sr-drive-system-driver.png",
    title: "Drivers",
    description: "Sometimes called a Planetary Gear, this is the final drive for the system, taking the electrical energy from the wheel motors and converting it into mechanical energy. There is a driver in each wheel."
  },
  {
    image: "https://cdn.komatsupartsu.com/KESS-Product-Image.png",
    title: "KESS",
    description:
      "(Kinetic Energy Storage System) – KESS is an energy storage device or a deep energy storage system that takes the excess energy produced during the loading cycle and releases it at optimal times to supplement engine power. The KESS stores its power thanks to a flywheel."
  },
  {
    image: "https://cdn.komatsupartsu.com/SR-drive-system-Converter-Panel_.png",
    title: "SR Converter Panel",
    description:
      "The converter panels can handle bidirectional power, meaning it can put energy on or remove energy from the common buss. The three sets of switches turn on and off, passing energy through the coils in the electrical rotating device (Motor or Generator) allowing it to generate or consume energy by rotating. The 7th switch or “chopper” is connected to a braking resistor (Braking grid) that helps consume excessive common buss energy during large regenerative braking events. These grids are the last device turned on to consume energy since we want to capture energy in the engine / generator or ESD.  Only when both of these are saturated does the system allow current to pass across these resistors."
  }
]

export const undercarriageUCProps = [
  {
    image: "https://komatsupartsu.com/v1/images/public/UC-Standard@3x.png",
    title: "Standard",
    description:
      "Standard is a robust UC that is intended to be used in the most harsh conditions such as waste, forestry, and ripping.  These conditions also include high wear and high impact conditions."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/UC-Plus@3x.png",
    title: "Plus",
    description:
      "PLUS-Stands for Parallel Link Undercarriage System: This UC is the premium UC and provides the longest wear life and can be used for most applications, especially those that are high wear and low impact. Ideal when precise finish dozing is required. Not recommended for waste applications."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/UC-GC@3x.png",
    title: "General Construction (GC)",
    description:
      "GC = General Construction: This is a link assembly and roller offering for specific models. This UC is designed and built by Komatsu and is intended to be a value priced option. It is best suited for machines working in a general construction application. Not intended for quarry or logging."
  },
  {
    image: "https://komatsupartsu.com/v1/images/public/UC-GCPlus@3x.png",
    title: "GC Plus",
    description:
      "GC PLUS provides a value priced link assembly (only) for customers that have a PLUS UC on their machine who are looking to replace their Track Link Assembly (TLA) but still have life left on their track shoes and do not need the full life that PLUS provides."
  }
]

export const lubricantsOilProps = [
  {
    image: require("../assets/images/pail-1.png"),
    height: 150,
    width: 150,
    title: "Jug",
    caption: "1 Gallon"
  },
  {
    image: require("../assets/images/pail-2.png"),
    height: 250,
    width: 250,
    title: "Pail",
    caption: "5 Gallons"
  },
  {
    image: require("../assets/images/pail-3.png"),
    height: 383,
    width: 383,
    title: "Drum",
    caption: "55 Gallons"
  },

  {
    image: require("../assets/images/lubricants-tote-cropped.png"),
    height: 300,
    width: 300,
    title: "Tote",
    caption: "257 Gallons"
  },

  {
    image: require("../assets/images/bulk-oil-icon@3x.png"),
    height: 285,
    width: 396,
    title: "Bulk",
    caption: "Bulk tanks are filled from totes 160 gallons"
  }
]

export const lubricantsGreaseProps = [
  {
    image: "https://komatsupartsu.com/v1/images/public/G2-LI-tube.png",
    height: 160,
    width: 160,
    title: "Tube",
    caption: ".4 keg"
  },
  {
    image: require("../assets/images/grease-2.png"),
    height: 251,
    width: 251,
    title: "Pail",
    caption: "18 Kg"
  },
  {
    image: require("../assets/images/grease-3.png"),
    height: 383,
    width: 383,
    title: "Keg",
    caption: "50 Kg"
  }
]
