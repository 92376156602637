/* eslint-disable */
export const sbomSlides = [
  {
    title:'Search by machine model or serial number',
    description:`Type the machine model number or serial number in the search box, or click the "Select Product" dropdown menu and select your customer's machine type.`,
    image:require('../images/sbom-slide-1.jpg'),
    class:""
  },
  {
    title:'Select the model range',
    description:`Choose the correct model range for your customer's machine from the options provided.`,
    image:require('../images/sbom-slide-2.jpg'),
    class:""
  },
  {
    title:'Select correct model',
    description:`Select the your customer's machine model.`,
    image:require("../images/sbom-slide-3.jpg"),
    class:""
  },
  {
    title:'Select correct model number',
    description:`Select your customer's exact model number.`,
    image:require("../images/sbom-slide-4.jpg"),
    class:""
  },
  {
    title:'Choose the correct parts list',
    description:`Select the correct parts list. In North America, you will typically choose the Overseas Version.`,
    image:require("../images/sbom-slide-5.jpg"),
    class:""
  },
  {
    title:'Select the correct system',
    description:`Select the correct system or category on the machine for the part you are looking up.`,
    image:require("../images/sbom-slide-6.jpg"),
    class:""
  },
  {
    title:'Select the part or parent part',
    description:`Select the correct part or parent part to view the part number(s).`,
    image:require("../images/sbom-slide-7.jpg"),
    class:""
  },
  {
    title:'Locate specific part you need in list provided',
    description:`&lt;p&gtLook through the list to locate the exact part you need, as well as any potential Mates
    &amp; Relates parts.&lt;/p&gt
&lt;p className="note"&gtClicking a part in the list on the right will highlight
    it in the diagram. This can help you verify that you are making a correct part
    identification, as well as identify Mates &amp; Relates parts. Also, pay attention to
    any available notes for the part you need.&lt;/p&gt`,
    image:require("../images/sbom-slide-8.jpg"),
    class:"note"
  },
  {
    title:'Capture your part number(s)',
    description:`Make a note of the part number(s) you need so you have that
    precise information available
    when you place the order.`,
    image:require("../images/sbom-slide-9.jpg"),
    class:"note"
  },
]
