import React from "react"
import { Layout } from "antd"
import ImageIcon from "../icons/image.icon"
import { useURL } from "../../hooks/url.hooks"
import { useLocation } from "react-router-dom"
import _ from "lodash"
import moment from "moment"
const { Footer } = Layout
const FooterNav = () => {
  let location = useLocation()
  const url = useURL()

  const sceneType = url.query.get("scene")

  const goback = () => {
    if (sceneType === "machine") {
      window.location.href = window.location.pathname
    } else {
      window.history.back()
    }
  }
  return (
    <Footer
      className={`footer footer-sub-${sceneType}  ${_.initial(
        location.pathname.split("/")
      )
        .join("")
        .replace(/[/]/g, "")} footer-${location.pathname.split("/").pop()}`}>
      <div className="float-left pointer-cursor" onClick={goback}>
        <ImageIcon
          src={require("../../../assets/images/icons/back-arrow-circle-white@3x.png")}
        />
      </div>
      <span className="copyright" style={{ fontSize: 14, color: "black" }}>
        © {moment().format("YYYY")} Komatsu America Corp
      </span>
      {/* <div
        className="d-flex justify-content-center align-items-center px-4"
        style={{
          backgroundColor: "white",
          position: "absolute",
          right: 0,
          top: 0,
          height: 88,
          borderRadiusTopLeft: 15
        }}>
        <div>
          <img
            style={{ width: 200 }}
            src={require("../../../images/Komatsu_Genuine_Parts-Footer.png")}
            alt=""
          />
          <br />
          
        </div>
      </div> */}
    </Footer>
  )
}

export default FooterNav
