import { call, put, select } from "redux-saga/effects"
import axios from "axios"
import { GET_USER_PROGRESS_SUCCESS, CALL_QUIZ_API_FAIL } from "../actions/quiz.actions"
import { APIResponseParser } from "../../helpers/APIResponseParser.helpers"
import { addHeaders } from "../../helpers/Saga.helpers"
import { baseUrl } from "../../data/misc.data"
import { tokenSelector } from "../selectors/selectors"

const QuizAPI = async (payload, token) => {
  let data;

  try {
    if (payload.type === "SUBMIT_QUIZ") {
     data = await PostQuiz(payload, token)
    } else 
    data = await axios.get(`${baseUrl}/v1/user/progress`, {
      headers: addHeaders(token)
    })
    return APIResponseParser(data)
  } catch (error) {
    console.log(error)
  }

}

const PostQuiz = async (payload, token) => {
  try {
    const result = await axios.post(`${baseUrl}/v1/user/quiz`, payload.payload, {
      headers: addHeaders(token)
    })
    return APIResponseParser(result)
  } catch (error) {
    console.log(error)
  }

}

function* QuizProgressSaga(action) {
  try {
    let data;
    const token = yield select(tokenSelector)

      data = yield call(QuizAPI, action, token)
     // data = yield call(PostQuiz, action, token)

    yield put(GET_USER_PROGRESS_SUCCESS(data))

  } catch (e) {
    // yield put({ type: "GET_USER_PROGRESS_FAIL", payload: e.message })
    yield put(CALL_QUIZ_API_FAIL(e))
  }
}
export default QuizProgressSaga
