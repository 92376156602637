import React, { useEffect } from 'react'
import { ObjToArr } from '../../helpers/Utils.helpers'
import MachineSizeCard from './sizes-card.machine'

const MachineSizes = ({ machine }) => {
  const sizes = ObjToArr(machine.sizes)

  useEffect(() => {}, [machine])

  return (
    <div className='d-flex justify-content-around align-items-start flex-row flex-wrap'>
      {sizes
        .filter((size) => {
          return size.image.length > 0
        })
        .map((size, index) => {
          return (
            <MachineSizeCard
              machine={machine}
              key={size.key}
              name={size.key}
              index={index}
              {...size}
            />
          )
        })}
    </div>
  )
}

export default MachineSizes
