/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

//document.documentElement.setAttribute("data-theme", "mydna");

const Sodoto = () => {
  return (
    <>
      <div className="myDnaPage parts-managers page" data-theme="parts-managers"> 
        <main style={{display: "block", maxWidth: "1250px"}}>
            <div className="content-box" style={{paddingTop:"20px"}}>
                <ul className="breadcrumb">
                    <li><a href="parts-managers">Just for Managers</a></li>
                    <li><a href="adult-learning">How Adults Learn</a></li>
                    <li>SODOTO</li>
                </ul>
                <img src={require('../../images/sodoto-page-title@3x.png')} className="img-page-title"/>
                <h3 style={{"textAlign":"center"}}>How to deliver new information so it's understood and remembered</h3>
                <p><span className="bold">SODOTO</span> is an acronym for ‘see one, do one, teach one’ and is a method of
                    teaching and learning skills through direct observation of a task, hands-on practical experience doing
                    the task, and then teaching the task to someone else. It is especially effective for teaching technical
                    skills and incorporates the full cycle of knowledge: experiencing, reflecting, thinking, and acting.
                </p>
                <p>Try using the SODOTO method next time someone on your team needs to learn a new task or skill.</p>
                <h4 className="header4">Watch the short video below to learn more:</h4>
                <div style={{"padding":"56.25% 0 0 0","position":"relative"}}><iframe
                        src="https://player.vimeo.com/video/804425202?h=b55f0f30d5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        style={{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"}} title="SODOTO-parts-u"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>

                <div className="highlight-box">
                    <h4>See One</h4>
                    <p>Have your PCR watch someone do the task or procedure they need to learn. They can watch someone do it
                        in person,
                        on a video or on a Teams call&mdash;all are equally effective if done well.</p>
                    <h4>Do One</h4>
                    <p>After they watch someone do the procedure, have them do it. They might make mistakes and have
                        to do it again. That's okay&mdash;it actually helps them learn. This step helps reinforce what they
                        saw
                        someone else do.</p>
                    <h4>Teach One</h4>
                    <p>Now have your PCR show someone else how to do it. This step requires deeper knowledge than the Do One
                        step,
                        because teaching a skill or procedure to others can be quite different from doing a task yourself.
                        The questions they get from people they are teaching will help them refine their knowledge.</p>
                    <p>When you teach a new skill or process to someone else you have to be well-versed on the details and
                        organized in your presentation. This is what cements the new knowledge in your own mind.</p>
                </div>

            </div>
        </main>
      </div>
    </>
  )
}

export default Sodoto
