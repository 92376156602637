/* eslint-disable */
import { SetEnv } from '../helpers/SetEnv.helpers'
export const allCommodities = [
  {
    thumbnail: `https://cdn.komatsupartsu.com/new-Batteries_lightduty_xparent.png`,
    link: '/dashboard/commodities/learn/batteries',
    name: 'Batteries'
  },
  {
    thumbnail: `https://cdn.komatsupartsu.com/new-Filter_Major_Air_xparent.png`,
    link: '/dashboard/commodities/learn/filters',
    name: 'Filters'
  },
  {
    thumbnail: `https://cdn.komatsupartsu.com/new-Hydraulics_Main_Image.png`,
    link: '/dashboard/commodities/learn/hydraulics',
    name: 'Hydraulics'
  },
  {
    thumbnail: `https://cdn.komatsupartsu.com/new-lubricants.png`,
    link: '/dashboard/commodities/learn/lubricants',
    name: 'Lubricants'
  },
  {
    thumbnail: `https://cdn.komatsupartsu.com/new-Reman_Major_Torqueflow.png`,
    link: '/dashboard/commodities/learn/reman',
    name: 'Reman'
  },
  {
    thumbnail: `https://cdn.komatsupartsu.com/new-Undercarriage_mainImage_xparent.png`,
    link: '/dashboard/commodities/learn/undercarriage',
    name: 'Undercarriage'
  },
   {
    thumbnail: `https://cdn.komatsupartsu.com/SR-drive-system-Converter-Panel_.png`,
    link: '/dashboard/commodities/learn/sr-drive-system',
    name: 'SR Drive System'
  } 
]
