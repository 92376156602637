/* eslint-disable */
import React, { useState }  from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sbomSlides } from '../../data/part-lookup-sbom.data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
//document.documentElement.setAttribute("data-theme", "mydna");

const PartAvailabilityDetails = () => {
    const btnGroupClasses = true;
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderNavClicked = (number) => {
      setActiveSlide(number)
    }
    
  return (
    <>
      <div className="child-page page" data-theme="place-order"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                    <h1>Check Availability of a Part</h1>
                    <p style={{"marginTop":"0px"}}>How to lookup part availability in KPP before placing an order</p>
                </div>
                <a className="platform-link" href="https://na.parts.komatsu/Home/MainPage" target="_blank">Follow along in KPP <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
                <nav style={{paddingBottom:"20px"}}>
                    <ol className="navigation">
                    <li><a href="#slide1" className={"step-title " + (activeSlide === 1 ? 'active' : '')} data-text="The part details pane" onClick={() =>sliderNavClicked(1)} >1</a></li>
                        <li><a href="#slide2" className={"step-title " + (activeSlide === 2 ? 'active' : '')} data-text="Standard Lead Time" onClick={() =>sliderNavClicked(2)}>2</a></li>
                        <li><a href="#slide3" className={"step-title " + (activeSlide === 3 ? 'active' : '')} data-text="KAC &amp; Network Stock" onClick={() =>sliderNavClicked(3)}>3</a></li>
                        <li><a href="#slide4" className={"step-title " + (activeSlide === 4 ? 'active' : '')} data-text="Service Bulletin" onClick={() =>sliderNavClicked(4)}>4</a></li>
                        <li><a href="#slide5" className={"step-title " + (activeSlide === 5 ? 'active' : '')} data-text="Remarks" onClick={() =>sliderNavClicked(5)}>5</a></li>
                        <li><a href="#slide6" className={"step-title " + (activeSlide === 6 ? 'active' : '')} data-text="Short Supply Indicator" onClick={() =>sliderNavClicked(6)}>6</a></li>
                        <li><a href="#slide7" className={"step-title " + (activeSlide === 7 ? 'active' : '')} data-text="Weight &amp; Dimensions" onClick={() =>sliderNavClicked(7)}>7</a></li>
                        <li><a href="#slide8" className={"step-title " + (activeSlide === 8 ? 'active' : '')} data-text="Dangerous Goods" onClick={() =>sliderNavClicked(8)}>8</a></li>
                    </ol>
                </nav>

                <div className="flex-row" >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onRealIndexChange={(element)=>setActiveSlide(element.activeIndex+1)}
                    >
                    <SwiperSlide id='slide1' data-hash='slide1'>
                        <h3>Digging into the part details pane</h3>
                        <hr/>
                        <p>Knowing how to interpret the information provided in the product detail pane can
                            make the process easier and smoother for you and
                            helps you give more accurate and knowlegable service to your customer. Take a quick tour
                            here to
                            learn why <strong>this information is important to getting your orders right the first
                                time.</strong></p>
                          <img src={require('../../images/availability-slide-9.jpg')} alt="step 1"/>
                    </SwiperSlide>
                      <SwiperSlide id='slide2' data-hash='slide2'>
                      <h3>Standard Lead Time</h3>
                        <hr/>
                        <p class="bold"><span style={{color:"var(--pro-tips)"}}>The Standard Lead Time shown is when you
                                will receive the part if you do nothing
                                more than simply place the order.</span></p>
                        <p>You will learn how to decrease this lead
                            time in the
                            Place an Order section. There are a number of different actions you can take to change this
                            outcome
                            for your customer.</p>
                          <img src={require('../../images/part-detail-slide-1.jpg')} alt="step 2"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide3' data-hash='slide3'>
                      <h3>KAC &amp; Network Stock</h3>
                        <hr/>
                        <p>KAC Stock shows the quantity currently available at Ripley. You can click into this
                            number to see more information about the stock (always a good idea).</p>
                        <p>If a part shows zero
                            KAC Stock, check the Network Stock column. This is the
                            quanitity that is available at all other dealerships. You can contact another
                            dealership and ask if they will sell you the part your customer needs. Click into the
                            Network Stock number, then click the Distributor Inventory number to see a paginated list of
                            dealerships and their current inventory on this part.</p>
                        <p>You can also call
                            your assigned rep and customer care team to ask if there is availability overseas.
                            <strong>Don't stop at zero!</strong>
                        </p>
                          <img src={require('../../images/part-detail-slide-2.jpg')} alt="step 3"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide4' data-hash='slide4'>
                      <h3>Service Bulletin</h3>
                        <hr/>
                        <p>Service Bulletins let you and your customer know if there are special parts
                            available for service procedures, and provide the additional part numbers your customer
                            needs to complete their service or repair. It is also sometimes the only place you can find
                            a service related part (for example, flushing filters for hydraulic systems).</p>
                          <img src={require('../../images/part-detail-slide-3.jpg')} alt="step 4"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide5' data-hash='slide5'>
                      <h3>Remarks</h3>
                        <hr/>
                        <p>Remarks can contain critical information. For example:</p>
                        <ol>
                            <li>If your customer wants a GC Undercarriage part, the GC part number will be shown here
                                after searching for the
                                Standard or Plus UC part number.</li>
                            <li>If you encounter an IC code 20, that means the
                                part is no longer available and there will be no information provided when you click
                                Chain/Reman/New. The remarks will give you the information you need to proceed.</li>
                            <li>If you are looking up a full assembly, the remarks can provide part numbers for the
                                individual
                                parts within the assembly, or it will tell you if a part is not sold individually
                                (NSS).</li>
                        </ol>
                        <p>These are just a few examples of the important and helpful information provide
                            here. <strong>Always check the Remarks!</strong></p>
                          <img src={require('../../images/part-detail-slide-4.jpg')} alt="step 5"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide6' data-hash='slide6'>
                      <h3>Short Supply Indicator</h3>
                        <hr/>
                        <p>If you see a "Call Customer Service" link next to Short Supply Indicator, this means
                            you need to call your assigned rep for assistance in ordering this part for your customer,
                            even if there is available KAC Stock.</p>
                          <img src={require('../../images/part-detail-slide-5.jpg')} alt="step 6"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide7' data-hash='slide7'>
                      <h3>Weight &amp; Dimensions</h3>
                        <hr/>
                        <p>Use the weight and dimensions provided here to get a shipping quote for your
                            customer.</p>
                          <img src={require('../../images/part-detail-slide-6.jpg')} alt="step 7"/>
                      </SwiperSlide>
                      <SwiperSlide id='slide8' data-hash='slide8'>
                      <h3>Dangerous Goods</h3>
                        <hr/>
                        <p>You will see a Dangerous Goods remark for parts such as batteries, accumulators, gas
                            shock and struts. These types of parts often cannot be shipped via next-day air and must be
                            shipped via LTL or parcel.</p>
                          <img src={require('../../images/part-detail-slide-7.jpg')} alt="step 8"/>
                      </SwiperSlide>
                    </Swiper>
                </div> 
            </section>
        </main>
      </div>
    </>
  )
}

export default PartAvailabilityDetails
