import React from "react";
import { Steps } from "antd";
import { ProgressTracks } from "../../configs/progression.configs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const { Step } = Steps
const ProgressTrack = () => {
  const Progress = useSelector((state) => state.Ui.completed_quizes)
  return (
    <div className="custom-p-5 m-5">
      {Object.entries(ProgressTracks).map((track) => {
        return (
          <div className="my-5" key={track[0]}>
            <div className="d-flex flex-row justify-content-between py-3">
              <div
                className={`${
                  track[0] !== "Machine Knowledge" &&
                  track[0] !== "Forestry Machine Knowledge"
                    ? track[0].toLowerCase()
                    : "machines"
                }-color capitalize-title`}>
                {track[0]}
              </div>
              <div
                className={`${
                  track[0] !== "Machine Knowledge" &&
                  track[0] !== "Forestry Machine Knowledge"
                    ? track[0].toLowerCase()
                    : "machines"
                }-color`}>
                {/* {}% percent */}
              </div>
            </div>
            {track[0] !== "Construction Machine Knowledge" &&
             track[0] !== "Forestry Machine Knowledge" &&
             track[0] !== "Mining Machine Knowledge" && (  
              <div
                className={`${
                track[0] !== "Construction Machine Knowledge" &&
                track[0] !== "Forestry Machine Knowledge" &&
                track[0] !== "Mining Machine Knowledge"
                  ? track[0].toLowerCase()
                  : "machines"
              }-border p-5`}>
              <Steps current={-1}>
                {track[1].map((quiz) => (
                  <Step
                    status={Progress.includes(quiz.ez_id) && "finish"}
                    key={quiz.name}
                    // title='Finished'
                    description={<Link to={`/dashboard/commodities/learn/${track[0]}?quiz_prompt=${quiz.ez_id}&quiz_active=true`}>{quiz.name}</Link>}
                    className="white-space-steps"
                  />
                ))}
              </Steps>
            </div>
            )}
            {(track[0] === "Construction Machine Knowledge" ||
             track[0] === "Forestry Machine Knowledge" ||
             track[0] === "Mining Machine Knowledge") && (  
              <div
                className={`${
                track[0] !== "Construction Machine Knowledge" &&
                track[0] !== "Forestry Machine Knowledge" &&
                track[0] !== "Mining Machine Knowledge"
                  ? track[0].toLowerCase()
                  : "machines"
              }-border p-5`}>
              <Steps current={-1}>
                {track[1].map((quiz) => (
                  <Step
                    status={Progress.includes(quiz.ez_id) && "finish"}
                    key={quiz.name}
                    // title='Finished'
                    description={<Link to={`/dashboard/${quiz.url}`}>{quiz.name}</Link>}
                    className="white-space-steps"
                  />
                ))}
              </Steps>
            </div>
            )}
          </div>
        )
      })}
    </div>
    
  )
}

export default ProgressTrack
