/* eslint-disable */
import Index from "../components/mydna/index.mydna"
import MyDna from "../components/mydna/main.mydna"
import PartManager from "../components/mydna/part-manager.mydna"
import PartLookup from "../components/mydna/part-lookup.mydna"
import PartLookupSbom from "../components/mydna/part-lookup-sbom.mydna"
import PartLookupKgp from "../components/mydna/part-lookup-kgp.mydna"
import PartLookupProTips from "../components/mydna/part-lookup-protips.mydna"
import PlaceOrder from "../components/mydna/place-order.mydna"
import CheckPartAvailability from "../components/mydna/check-part-availability.mydna"
import PlaceAndOrderProcess from "../components/mydna/place-an-order-process.mydna"
import PartAvailabilityDetails from "../components/mydna/part-availability-details.mydna"
import ManageAnOrder from "../components/mydna/manage-an-order.mydna"
import ManageAnOrderProcess from "../components/mydna/manage-an-order-process.mydna"
import ManageSuspendedOrders from "../components/mydna/manage-suspended-orders.mydna"
import ManageCancelledOrders from "../components/mydna/manage-cancelled-orders.mydna"
import ManageReturnedOrders from "../components/mydna/manage-returned-orders.mydna"
import Communicate from "../components/mydna/communicate.mydna"
import CommunicationWHoWHat from "../components/mydna/communication-who-what.mydna"
import CommunicatingWithRipley from "../components/mydna/communicating-with-ripley.mydna"
import CommunicateWithCustomers from "../components/mydna/communicate-with-customers.mydna"
import ProTips from "../components/mydna/pro-tips.mydna"
import SalesTips from "../components/mydna/sales-tips.mydna"
import SalesMatesRelatesTips from "../components/mydna/sales-mates-and-relates-tips.mydna"
import AdultLearning from "../components/mydna/adult-learning.mydna"
import Onboarding from "../components/mydna/onboarding.mydna"
import Sodoto from "../components/mydna/sodoto.mydna"
import LearningStyles from "../components/mydna/learning-styles.mydna"

export default [
  {
    exact: true,
    path: "/",
    component: Index,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna",
    component: MyDna,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/part-lookup",
    component: PartLookup,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/parts-managers",
    component: PartManager,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/part-lookup-sbom",
    component: PartLookupSbom,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/part-lookup-kgp",
    component: PartLookupKgp,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/part-lookup-protips",
    component: PartLookupProTips,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/parts-counter-pro-tips",
    component: ProTips,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/place-an-order",
    component: PlaceOrder,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/check-part-availability",
    component: CheckPartAvailability,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/place-an-order-process",
    component: PlaceAndOrderProcess,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/part-availability-details",
    component: PartAvailabilityDetails,
    extraProps: {},
    protected: true
  },    
  {
    exact: true,
    path: "/mydna/sales-tips",
    component: SalesTips,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/sales-mates-and-relates-tips",
    component: SalesMatesRelatesTips,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/manage-an-order",
    component: ManageAnOrder,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/manage-an-order-process",
    component: ManageAnOrderProcess,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/manage-suspended-orders",
    component: ManageSuspendedOrders,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/manage-cancelled-orders",
    component: ManageCancelledOrders,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/manage-returned-orders",
    component: ManageReturnedOrders,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/communicate",
    component: Communicate,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/communicate-who-what",
    component: CommunicationWHoWHat,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/communicate-with-ripley",
    component: CommunicatingWithRipley,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/communicate-with-customers",
    component: CommunicateWithCustomers,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/adult-learning",
    component: AdultLearning,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/onboarding",
    component: Onboarding,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/sodoto",
    component: Sodoto,
    extraProps: {},
    protected: true
  },
  {
    exact: true,
    path: "/mydna/learning-styles",
    component: LearningStyles,
    extraProps: {},
    protected: true
  }
  
]

