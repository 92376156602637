const initialState = {
  version: 0
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "UPDATE_LOCAL_VERSION":
      return {
        version: payload
      }

    default:
      return state
  }
}
