import { call, put, select } from "redux-saga/effects"
import axios from "axios"
import { headers } from "../../data/misc.data"
import { LOGIN_SUCCESS, LOGIN_FAIL } from "../actions/login.actions"
import { message } from "antd"
import { APIResponseParser } from "../../helpers/APIResponseParser.helpers"
import { baseUrl } from "../../data/misc.data"
import { tokenSelector } from "../selectors/selectors"
const LoginAPI = async (data, token) => {
  const result = await axios.post(`${baseUrl}/v1/login`, data, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  })
  return APIResponseParser(result)
}

function* LoginSaga(action) {
  try {
    const token = yield select(tokenSelector)
    const data = yield call(LoginAPI, action.payload, token)
    yield put(LOGIN_SUCCESS(data))
    if((data.credentials.userData.role !== '') && (data.credentials.userData.role !== null)){
      localStorage.setItem('userDataModal', true);
    }else{
      localStorage.setItem('userDataModal', false);
    }
    message.destroy()
  } catch (e) {
    message.destroy()
    yield put(LOGIN_FAIL(e.data))
    localStorage.clear()
  }
}
export default LoginSaga
