/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { useEffect } from "react"
import { useParrot } from "../hooks/parrot.hooks"
// import TestYourKnowledge from "../common/icons/test-your-knowledge"
// import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
// import { commoditySafetyLinks } from "../../helpers/quiz.helpers"

const RemanSafety = () => {
  // const setModalToInactive = () => {
  //   let currentUrlParams = new URLSearchParams(window.location.search)
  //   currentUrlParams.set("quiz_active", "false")
  //   window.location.href =
  //     window.location.pathname + "?" + currentUrlParams.toString()
  // }
  const { setParrot } = useParrot()
  useEffect(() => {
    setParrot({
      dialog:
        "<b>Safety Tip:</b> Never attempt to operate any machinery without the proper training.  Always keep your safety and your team’s safety your top priority.",
      display: "TEACHER",
      mode: 1,
      next_bird_actions: []
    })
  }, [])
  return (
    <div className="page vh-80">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="reman-color">
          <b>Reman</b>
        </h1>
      </div>
      <center>
        <h1>
          <b>Safety</b>
        </h1>
      </center>
      <br />
      <br />
      <Row>
        <Col span={4} />
        <Col span={16}>
          <p>
            For any Komatsu piece of equipment to operate safely it needs to be
            functioning properly. If a customer chooses to not purchase and use
            Genuine Komatsu REMAN components in their equipment, they take a
            risk that the component may not be functioning to the factory
            specifications that Komatsu designed it to operate at. This can
            cause issues, for example: If a Fuel injection pump or injector is
            not remanufactured to specifications, it can over-fuel the engine
            and cause the engine to fail prematurely, or it could cause an under
            power situation where the unit will not function properly—this puts
            the equipment in a situation that can cause unsafe operation. The
            same is true for Transmissions, Torque Converters, Power Modules
            etc. If the equipment is not functioning at its full potential, the
            safety of the operation is compromised.
            <br />
            <br />
            For the SAFETY of operation of all Komatsu equipment it is important
            to use Genuine Komatsu Reman components when service or repairs are
            needed.
          </p>
          <div style={{ fontSize: 26 }}>
            <b>Core Return</b>
          </div>

          <p>
            When discussing a core return with a customer, it is important to
            remind them to drain fluids and secure the core properly before
            transporting or shipping to ensure its viability.
          </p>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <img
                className="image-contain "
                height={239}
                width={367}
                src={require("../../assets/images/safety-reman-1.png")}
                alt=""
              />
              <p>Improperly Secured Core Return</p>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img
                className="image-contain "
                height={239}
                width={367}
                src={require("../../assets/images/safety-reman-2.png")}
                alt=""
              />
              <p>Improperly Secured Core Return</p>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img
                className="image-contain "
                height={239}
                width={367}
                src={require("../../assets/images/safety-reman-3.png")}
                alt=""
              />
              <p>Core Return—Oil Not Drained</p>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <img
                className="image-contain "
                height={239}
                width={367}
                src={require("../../assets/images/safety-reman-4.png")}
                alt=""
              />
              <p>Properly Secured Core Return</p>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <TestYourKnowledge link={commoditySafetyLinks.reman} />
      <QuizPrompt onExitEarly={setModalToInactive} /> */}
    </div>
  )
}

export default RemanSafety
