/* eslint-disable */
import React, { useState, useEffect }  from "react"
import { baseUrl, headers } from "../../data/misc.data"
import './styles/mydna.components.css';
import axios from "axios";
import { useSelector } from "react-redux"
import SlidesSection from "./slides.mydna"


const CommunicatingWithCustomers = () => {
    const [contentData, setContentData] = useState({ title: "", text: "" })
    const [slides, setSlides] =  useState([]);
    const token = useSelector((state) => state.User.accessToken)
    useEffect(() => {
      getFeedback();
    }, []);

    const getFeedback = async () =>
    await axios.get(`${baseUrl}/v1/my-dna/slides/communicate-with-customers`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
    })
    .then((response) => {
      setContentData({ title: response.data.data.title, text: response.data.data.text })
      setSlides(response.data.data.slides)
    })
    .catch((error) => {
      console.log(`We have a server error`, error);
    });
    
  return (
    <>
      <div className="child-page page" data-theme="communicate"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header ">
                <div className="page-title">
                  <h1>{contentData.title}</h1>
                  <p style={{"marginTop":"0px"}}>{contentData.text}</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block"}}>
            <section id="slides" style={{paddingBottom:"40px", paddingTop:"30px"}}>
              <SlidesSection
                slides={slides}
              />
            </section>
        </main>
      </div>
    </>
  )
}

export default CommunicatingWithCustomers
