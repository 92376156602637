/* eslint-disable */
import React from "react"
import { Link, useParams } from "react-router-dom"

import { useURL } from "../hooks/url.hooks"
import { Col } from "antd"
import { baseUrl } from "../../data/misc.data"

const BottomActions = ({ machine }) => {
  const url = useURL()

  const sceneType = url.query.get("scene")
  let { machine_ez_id } = useParams()

  return (
    <Col span={24} style={{ backgroundColor: "#c4c6c8" }} className="pt-4">
      <div>
        <center>
          {sceneType !== "commodities" && (
            <center>
              <h1 className="text-dark-blue machine-name capitalize-title">
                <b>{machine.name}</b>
              </h1>
            </center>
          )}
          {sceneType === null && ((machine_ez_id !== "electric-drive-truck") &&  (machine_ez_id !== "shovel") && (machine_ez_id !== "drill")) && (
            <div
              style={{ height: 150 }}
              className="row d-flex justify-content-center align-items-center">
              <Link
                className="route-link link p-4 learn-about-wrap"
                to={`${url.pathname}?&scene=machine`}>
                <img
                  height={90}
                  width={90}
                  className={`${machine.name
                    .toLowerCase()
                    .split(" ")
                    .join("")}-hover-img`}
                  src={`https://cdn.komatsupartsu.com/icon-${machine.name.toLowerCase()}.png`}
                  alt=""
                />
                <p>Learn about the machine</p>
              </Link>
              <Link
                className="route-link link p-4 text-dark-blue learn-about-wrap"
                to={
                  machine.sub_type.toLowerCase() === "forestry"
                    ? `${url.pathname}?scene=commodities`
                    : `/dashboard/machines/xray/${machine_ez_id}`
                }>
                <img
                  height={60}
                  width={60}
                  className={`shelf-hover-img`}
                  src={require("../../assets/images/commodities-xray-icon@3x.png")}
                  alt=""
                />
                <p>
                  X-ray: see where the commodities are found on this machine.
                </p>
              </Link>
            </div>
          )}
          {sceneType === null && ((machine_ez_id == "electric-drive-truck") ||  (machine_ez_id == "shovel") || (machine_ez_id == "drill")) && (
            <div
              style={{ height: 150 }}
              className="row d-flex justify-content-center align-items-center">
              <Link
                className="route-link link p-4 learn-about-wrap"
                to={`${url.pathname}?&scene=machine`}>
                <img
                  height={90}
                  width={90}
                  className={`${machine.name
                    .toLowerCase()
                    .split(" ")
                    .join("")}-hover-img`}
                  src={`https://cdn.komatsupartsu.com/icon-${machine.name.toLowerCase()}.png`}
                  alt=""
                />
                <p>Learn about the machine</p>
              </Link>
            </div>
          )}
          {sceneType === "machine" && (
            <div style={{ height: 100 }}>
              <Link
                className="route-link link p-4 text-dark-blue learn-about-wrap"
                to={`${url.pathname}?scene=machine&modal_content=machine_sizes`}>
                <img
                  height={30}
                  width={70}
                  className="size-icon-hover"
                  src={require("../../assets/images/sizes-icon@2x.png")}
                  alt=""
                />
                <p>Available sizes</p>
              </Link>
              <Link
                className="route-link link p-4 text-dark-blue learn-about-wrap"
                to={`${url.pathname}?scene=machine&modal_content=machine_action`}>
                <img
                  height={40}
                  width={60}
                  className="sit-icon-hover"
                  src={require("../../assets/images/see-it-in-action-icon@3x.png")}
                  alt=""
                />
                <p>See it in action</p>
              </Link>
            </div>
          )}
        </center>
      </div>
    </Col>
  )
}
export default BottomActions
