/* eslint-disable */
import React, { useState } from "react";
import { Row, Col, Divider, Modal, Spin } from "antd";
import { Link } from "react-router-dom";
import { FrostedGlass } from "../common/frosted/glass.frosted";
import TestYourKnowledge from "../common/icons/test-your-knowledge";
import QuizPrompt from "../quizes/prompts/quizprompt.quiz";
import { commodityProductOfferingLinks } from "../../helpers/quiz.helpers";
import { useDispatch, useSelector } from "react-redux";
import { GET_CONTENT } from "../../store/actions/content.actions";
import { LoadingOutlined } from "@ant-design/icons";

import { useParrot } from "../hooks/parrot.hooks";
import { useEffect } from "react";
const imageMap =
  (title, onHover = () => {}) =>
  (data) => {
    return (
      <Col span={4}>
        <div
          onMouseOver={onHover}
          className={`pointer-cursor offering-contain offering-${title.toLowerCase()}-border`}
        >
          <FrostedGlass>
            <div>
              <img alt="?" className="offering-img" src={data.image} />
              <br />
              <b className={`${title.toLowerCase()}-color`}>{data.title}</b>
              <br />
            </div>
            <p>{data.description}</p>
          </FrostedGlass>
        </div>
      </Col>
    );
  };

const LubricantsCommodityOffering = ({
  title = "",
  subtitle,
  secondSubtitle,
  sales_tips,
}) => {
  const [modal, setModal] = useState(false);
  const { setParrot } = useParrot();
  const [hoverCount, setHoverCount] = useState(0);
  const contentScreen = useSelector((state) => state.Content);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setModal(!modal);
  };

  const close = () => {
    setModal(false);
  };

  const onHover = () => {
    if (hoverCount + 1 === contentScreen?.content.length) {
      setTimeout(() => {
        setParrot({
          dialog: `You've now learned about all the products in ${title.toLowerCase()}. Ready to test your knowledge?`,
          display: "TEACHER",
          mode: 1,
          next_bird_actions: [
            commodityProductOfferingLinks[title.toLowerCase()],
          ],
        });
      }, 2500);
    }
    setHoverCount(hoverCount + 1);
  };

  const setModalToInactive = () => {};

  useEffect(() => {
    setParrot({
      dialog:
        "This is a Commodity Product Offering page. Hover over each product to learn more about it, then click the ''Sales Tip'' at the bottom. Once you've learned all the products and any available Sales Tips, click the quiz head at the bottom right to test your knowledge!",
      display: "TALKING",
      mode: 1,
    });
  }, []);

  useEffect(() => {
    if (title !== "") {
      dispatch(
        GET_CONTENT({ ez_id: "offering", suffix: `/${title.toLowerCase()}` })
      );
    }

    return () => {
      dispatch({
        type: "RESET_CONTENT_SCREEN",
      });
    };
  }, [title]);

  return (
    <Spin
      spinning={contentScreen.loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <div className="page">
        <Modal
          className="modal-70vw"
          onOk={close}
          onCancel={close}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
          okText="Close"
          visible={modal}
        >
          <div
            className="d-flex flex-row align-items-center"
            onClick={toggleModal}
          >
            <img
              height={57}
              width={63}
              src={require("../../assets/images/icons/sales-tip-icon@3x.png")}
              alt=""
            />
            <h2 className="k-blue  ml-2">
              <b>Sales Tips</b>
            </h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: sales_tips }}></div>
        </Modal>
        <div className="py-5">
          <center>
            <h1>
              <b className={`${title.toLowerCase()}-color capitalize-title`}>
                {title}
              </b>
            </h1>
            <div>Product offering</div>
          </center>
        </div>
        <center>
          <h5 className={`${title.toLowerCase()}-color`}>
            <b>{subtitle}</b>
          </h5>
        </center>
        <Row>
          <Col span={24}>
            <Row gutter={32} align="middle" justify="center">
              {contentScreen?.content.length > 0 &&
                contentScreen?.content.map(imageMap(title, onHover))}
            </Row>
          </Col>
        </Row>

        {contentScreen?.secondary_content.length > 0 && (
          <>
            <Divider />
            <center>
              <h5 className={`${title.toLowerCase()}-color`}>
                <b>{secondSubtitle}</b>
              </h5>
            </center>
            <Row>
              <Col span={3} />
              <Col span={18}>
                <Row gutter={32}>
                  {contentScreen?.secondary_content.map(
                    imageMap(title, onHover)
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}

        <div className="d-flex justify-content-center align-items-center pb-5 mt-4">
          <Link to="/dashboard/commodities/offering/lubricants/sizes/oil">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                height={20}
                width={40}
                src={require("../../assets/images/sizes-icon-yellow.png")}
                alt=""
              />
              <div className={`${title.toLowerCase()}-color  ml-2`}>
                <b>Oil Package Sizes</b>
              </div>
            </div>
          </Link>
          <Link to="/dashboard/commodities/offering/lubricants/sizes/grease">
            <div className="d-flex flex-row justify-content-center align-items-center ml-5">
              <img
                height={20}
                width={40}
                src={require("../../assets/images/sizes-icon-yellow.png")}
                alt=""
              />
              <div className={`${title.toLowerCase()}-color ml-2'`}>
                <b>Grease Package Sizes</b>
              </div>
            </div>
          </Link>
        </div>

        <div className="d-flex justify-content-center align-items-center pb-5 mt-4 pointer-cursor">
          <div
            className="d-flex flex-row justify-content-center align-items-center"
            onClick={toggleModal}
          >
            <img
              height={57}
              width={63}
              src={require("../../assets/images/icons/sales-tip-icon@3x.png")}
              alt=""
            />
            <div className="k-blue  ml-2">
              <b>Sales Tips</b>
            </div>
          </div>

          <Link to={`/dashboard/commodities/lubricants/mates-and-relates`}>
            <div className="d-flex flex-row justify-content-center align-items-center ml-5 pointer-cursor">
              <img
                height={55}
                width={73}
                src={require("../../assets/images/icons/mates-and-relates-icon@3x.png")}
                alt=""
              />
              <div className="k-blue ml-2">
                <b>Mates & Relates</b>
              </div>
            </div>
          </Link>
        </div>
        <TestYourKnowledge link={commodityProductOfferingLinks.lubricants} />
        <QuizPrompt onExitEarly={setModalToInactive} />
      </div>
    </Spin>
  );
};

export default LubricantsCommodityOffering;
