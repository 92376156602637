/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

//document.documentElement.setAttribute("data-theme", "mydna");

const PartManager = () => {
  return (
    <>
      <div className="myDnaPage parts-managers page" data-theme="parts-managers"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header">
                <div className="page-title">
                    <h1>Just for Parts Managers</h1>
                    <p >How to get the most out of Parts U & MyDNA for you and your Parts People</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
          <div className="content-box">
              <p>With employee development being one of Komatsu’s founding principles, this guide employs best practices
                  for facilitating the onboarding of new PCRs and for working with current PCRs who may need additional
                  support with learning.</p>
              <p>Below you'll find a few proven training methods you can easily use to get your PCRs get up to speed more
                  quickly, along with suggestions on how to use Parts U and MyDNA to help onboard new and upskill existing
                  PCRs at your dealership. These are brief but rich topics that will help make your onboarding and
                  day-to-day training much smoother, quicker and more rewarding.</p>
              <section className="flex-row" style={{position: "relative"}}>
                  <div className="adult-learning">
                      <h3>How Adults Learn</h3>
                      <a href="/mydna/adult-learning">
                          <div className="mgr-portal"></div>
                      </a>
                      <p>Methods and tips you can use to get your PCRs up to speed more quickly</p>
                  </div>
                  <div className="onboarding">
                      <h3>Onboarding New PCRs</h3>
                      <a href="/mydna/onboarding">
                          <div className="mgr-portal"></div>
                      </a>
                      <p>How to use Parts U and MyDNA to onboard or level-up your PCRs</p>
                  </div>
              </section>
          </div>
        </main>
      </div>
    </>
  )
}

export default PartManager
