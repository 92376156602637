import React from "react"
import { Row, Col } from "antd"

// import { useSelector } from 'react-redux'

const Login = () => {
  //   const msal = useAuthentication()
  //   const loading = useSelector((state) => state.Ui.loading.global)
  // const microsoftLogin = async () => {
  // 	await msal.auth.loginRedirect(msal.scopes)
  // }
  // const mount = () => {
  // 	microsoftLogin()
  // }
  //   useEffect(mount, [loading])
  return (
    <Row>
      <Col span={4} />
    </Row>
  )
}

export default Login
