/* eslint-disable */
import React from "react"
import { Row, Col, Divider } from "antd"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import { commoditySafetyLinks } from "../../helpers/quiz.helpers"
import { useParrot } from "../hooks/parrot.hooks"
import { useEffect } from "react"

const BatteriesSafety = () => {
  const setModalToInactive = () => {}
  const { setParrot } = useParrot()
  useEffect(() => {
    setParrot({
      dialog:
        "<b>Safety Tip:</b> Perform daily pre-start forklift equipment inspections to check for controls and equipment damage. When driving a fork lift, be attentive and drive slowly. Make sure dock edges are clear and safe to support loads. Keep yourself and your teammates safe.",
      display: "TEACHER",
      mode: 1,
      next_bird_actions: []
    })
  }, [])
  return (
    <div className="page vh-80">
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className="batteries-color">
          <b>Batteries</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="py-5">
            <center>
              {/* <strong>
            <h1 className={`${commodity_ez_id.toLowerCase()}-color`}>
              <b>{commodity_ez_id.toUpperCase()}</b>
            </h1>
          </strong> */}
              <h1>
                <b>Safety</b>
              </h1>
            </center>
          </div>
          <div>
            <h4 className="batteries-color">Safety Precautions</h4>
          </div>
          <p>
            <b>
              WARNING: FOLLOW ALL SAFETY INSTRUCTIONS WHEN HANDLING BATTERIES!
              ALWAYS WEAR SAFETY GLASSES AND A FACE SHIELD WHEN WORKING ON OR
              NEAR BATTERIES.
            </b>
          </p>
          <p>
            All batteries generate explosive hydrogen gas.
            <ul>
              <li>
                Keep sparks, flames and cigarettes away from batteries at all
                times.
              </li>
              <li> Do not connect or disconnect “live” circuits.</li>
              <li>
                To avoid creating sparks, always turn charging and testing
                equipment off before attaching or removing clamps
              </li>
            </ul>
          </p>
          <p>
            <b>
              ALWAYS DISCONNECT GROUNDED CABLE FIRST AND CONNECT IT LAST TO
              PREVENT DANGEROUS SPARKS.
            </b>
          </p>
          <p>
            Perform all work in a well ventilated area. Never lean directly over
            a battery while boosting, testing or charging it.
          </p>
          <p style={{ color: " #140A9A" }}>
            <b>PROTECT YOUR EYES! WEAR PROPER EYE PROTECTION!</b>
          </p>
          <p>
            Batteries contain corrosive sulfuric acid that can destroy clothing
            and burn the skin. Neutralize acid spills with a paste made of
            baking soda and water or large quantities of water. Be careful!
          </p>
          <div>
            <center>
              <img
                className="image-contain mb-3"
                height={266}
                width={941}
                src={require("../../assets/images/safety-batteries-1.png")}
                alt=""
              />
            </center>
          </div>
          <Divider />
          <h4 className="batteries-color">Safety Precautions</h4>
          <p>
            Batteries should be stored in a cool, dry area in an upright
            position. Never stack batteries directly on top of each other unless
            they’re in cartons. Do not stack more than 3 high (2 high if battery
            type is heavy commercial).
          </p>
          <p>
            Test wet batteries every 4–6 months and recharge if necessary.
            Always test and charge if necessary before installation.
          </p>
          <Divider />
          <h4 className="batteries-color">Rotate Battery Stock</h4>
          <Row>
            <Col span={16}>
              <p>
                Use oldest batteries first. Batteries require periodic stock
                rotation and routine charging. Always rotate stock using the
                FIFO (First In, First Out) method…Not FISH (First In, Still
                Here).
              </p>
              <p>
                <b>Date Code Example:</b> <br />
                (refer to chart at right) <br /> 11-17 – Battery shipped
                November 2017
              </p>
              <b> Always use oldest batteries first.</b>
              <p className="py-5" style={{ color: " #140A9A" }}>
                <b>Remember... Wet or dry, always rotate your stock!</b>
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://komatsupartsu.com/v1/images/public/Batteries_Safety_Maintenance-Tips-Poster.pdf">
                <div className="d-flex align-items-center pointer-cursor">
                  <img
                    className="image-contain mr-3"
                    height={26}
                    width={40}
                    src={require("../../assets/images/icons/eye-icon-green@3x.png")}
                    alt=""
                  />
                  <h5 className="batteries-color py-5">
                    View the full Komatsu Battery Care & Maintenance Poster
                  </h5>
                </div>
              </a>
            </Col>
            <Col span={8}>
              <center>
                <img
                  className="image-contain mb-3"
                  height={377}
                  width={324}
                  src={require("../../assets/images/safety-batteries-2.png")}
                  alt=""
                />
              </center>
            </Col>
          </Row>
        </Col>
      </Row>
      <TestYourKnowledge link={commoditySafetyLinks.batteries} />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default BatteriesSafety
