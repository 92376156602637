/* eslint-disable */
import React from "react"
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { baseUrl } from "../../data/misc.data"
import './styles/mydna.components.css';

//document.documentElement.setAttribute("data-theme", "mydna");

const Onboarding = () => {
  return (
    <>
      <div className="myDnaPage parts-managers page" data-theme="parts-managers"> 
        <div
          style={{
            zIndex: 10000,
            top: "5vh",
            left: 0,
            width: "100%"
          }}>
          <center>
            <section>
            <div className="sub-header">
                <div className="page-title">
                    <h1>Onboarding New PCRs</h1>
                    <p >How to use Parts U and MyDNA to onboard or level-up your PCRs</p>
                </div>
            </div>
            </section>
          </center>
        </div>
        <main style={{display: "block", maxWidth: "1250px"}}>
            <div className="content-box" style={{paddingTop:"20px"}}>
                <ul className="breadcrumb">
                    <li><a href="parts-managers">Just for Managers</a></li>
                    <li>Onboarding</li>
                </ul>
                <h3 style={{margin:"0"}}>How to use Parts U and MyDNA to onboard new and support existing employees</h3>
            <p>Parts U and MyDNA contain much of the core information a new PCRs needs to learn, from understanding the
                different commodities and how they work on the machines to how to
                look up parts, accurately determine lead times and manage existing orders. <span
                    style={{"fontWeight":"900","color":"var(--blue-mydna)"}}> You can lean on these sites
                    for the initial baseline training
                    your new PCRs need in their first days and weeks.</span></p>
                    <p>We've provided onboarding training checklists below to give you and your PCRs an easy way to move through
                the topics available in Parts U and MyDNA in a logical, sequential way and ensure they have
                learned the information necessary to do their jobs accurately and improve their levels of customer
                service and performance.</p>
                <section className=" flex-row">
                    <div className="checklist-thumb">
                        <h3>MyDNA Learning Checklist</h3>
                        <a href="https://cdn.komatsupartsu.com/mydna_onboarding-checklist.pdf" target="_blank"  rel="noopener noreferrer">
                            <div className="mydna-checklist"></div>
                        </a>
                    </div>
                    <div className="checklist-thumb">
                        <h3>Parts U Learning Checklist</h3>
                        <a href="https://cdn.komatsupartsu.com/parts-u_onboarding-checklist.pdf" target="_blank" rel="noopener noreferrer">
                            <div className="partsu-checklist"></div>
                        </a>
                    </div>
                </section>
            </div>
        </main>
      </div>
    </>
  )
}

export default Onboarding
