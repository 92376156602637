import React from "react"
import { Modal } from "antd"
import { useURL } from "../../hooks/url.hooks"
import { useQuiz } from "../../hooks/quiz.hooks"
import QuizCompilator from "../compilation.quiz"

const QuizPrompt = ({ onExitEarly = () => {} }) => {
  const url = useURL()

  const quizId = url.query.get("quiz_prompt")
  const quizActive = url.query.get("quiz_active")
  const sceneType = url.query.get("scene")

  const q = useQuiz(quizId)

  const onClose = () => {
    const urls = new URL(window.location.href)
    let currentUrlParams = new URLSearchParams(urls)

    currentUrlParams.delete("quiz_prompt")
    currentUrlParams.delete("quiz_active")
    window.location.replace(
      `${window.location.pathname}${
        sceneType !== null ? `?scene=${sceneType}` : ""
      }`
    )

    onExitEarly()
  }

  return (
    <Modal
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { backgroundColor: "#140A9A" } }}
      okText="Close"
      wrapClassName="quiz-modal"
      visible={typeof quizId !== "undefined" && quizActive === "true"}
      footer={null}
      onOk={onClose}
      onCancel={onClose}>
      <QuizCompilator mode="modal" quiz={q.quiz} />
    </Modal>
  )
}

export default QuizPrompt
