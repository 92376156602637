/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Row, Col, Modal, message } from 'antd'

import { useSelector } from 'react-redux'
import { quizSelector } from '../../../store/selectors/selectors'
import { useHistory } from 'react-router-dom'
import Drop from '../../dnd/drop.dnd'
import { reorder } from '../../../helpers/Utils.helpers'
import SuccessModal from '../other/success.modal'
import { useURL } from '../../hooks/url.hooks'

const StackingQuiz = ({ ez_id, index, totalQuestions }) => {
  const history = useHistory()
  const quiz = useSelector(quizSelector(ez_id))
  const q = useSelector((state) => state.Quiz)
  const url = useURL()
  const quiz_active = url.query.get('quiz_active')
  const quiz_prompt = url.query.get('quiz_prompt')
  const quizId = url.query.get('qid')

  const initialstate = {
    provided_answers: quiz.provided_answers.filter(
      (item) => item.type !== 'image'
    ),
    proposed_answers: [],
    provided_2_answers: quiz.provided_answers.filter(
      (item) => item.type !== 'word'
    ),
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: []
  }

  const nextQuestion = () => {
    if (quiz_active !== null && index + 1 !== totalQuestions) {
      history.push(
        `${window.location.pathname}?quiz_prompt=${quiz_prompt}&sub_quiz=${
          q.sub_quizes[index + 1].ez_id
        }&quiz_active=true`
      )
    } else if (quiz_active !== null && index + 1 === totalQuestions) {
      history.push(`${window.location.pathname}?quiz_active=false`)
    } else {
      history.push(
        index + 1 !== totalQuestions
          ? `/dashboard/quiz/${q.sub_quizes[index + 1].ez_id}?qid=${quizId}`
          : '/dashboard/machines'
      )
    }
  }

  const [state, setState] = useState(initialstate)

  const [isCorrect, setIsCorrect] = useState(false)

  const moveItem = (source, destination) => {
    if (source.droppableId !== destination.droppableId) {
      if (
        destination.droppableId === 'proposed_answers' &&
        state.proposed_answers.length === 2
      ) {
        // setState({
        // 	...state,
        // 	[destination.droppableId]: state[destination.droppableId].filter(
        // 		(item) => item.type !== state[source.droppableId][source.index].type
        // 	),
        // })
      } else {
        // TRAVERSES LISTS
        setState({
          ...state,
          [source.droppableId]: state[source.droppableId].filter(
            (item, index) => index !== source.index
          ),
          [destination.droppableId]: state[destination.droppableId].concat(
            state[source.droppableId][source.index]
          )
        })
      }
    } else {
      // REORDERS CURRENT LIST
      setState({
        ...state,
        [source.droppableId]: reorder(
          state[source.droppableId],
          source.index,
          destination.index
        )
      })
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    moveItem(result.source, result.destination)
    checkAnswer(result.source, result.destination)
  }
  const checkAnswer = (source, destination) => {
    if (
      quiz.correct_answers[destination.droppableId].includes(
        state.provided_answers[source.index].keyIndex
      )
    ) {
      message.success('Correct!')
    } else {
      message.error(
        'Incorrect, 6V batteries must not be stacked more than 3 high, 12V batteries no more than 2 high If they stack either type of battery higher than that in any stack, or mix battery types, they get a try again.'
      )
    }
  }

  return (
    <div>
      {index + 1}/{totalQuestions}
      <div className='py-3'>
        <h5>
          Quiz: <strong>{quiz.question_prompt}</strong>
        </h5>
      </div>
      <div className='my-4'>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Drop items={state.provided_answers} droppableId='provided_answers' />
          <Row justify='space-around' align='middle'>
            {quiz.correct_answers.map((answer, index) => {
              return (
                <Drop
                  style={{ width: '15%' }}
                  key={index}
                  items={state[index]}
                  droppableId={index}
                />
              )
            })}
          </Row>
        </DragDropContext>
      </div>
    </div>
  )
}

export default StackingQuiz
