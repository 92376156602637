/* eslint-disable */
import React from "react"
import { Route } from "react-router-dom"
import myDnaRoutesData from "../data/mydna.routes.data"

const MyDna = () => {
  const RenderRoutes = ({ component: Component, ...route }) => (
    <Route
      key={route.path}
      render={(props) => <Component {...route.extraProps} {...props} />}
      exact={route.exact}
      path={route.path}
    />
  )
  return (
    <>
      {myDnaRoutesData.map(RenderRoutes)}
    </>
  )
}

export default MyDna
