const initialState = {
  accessToken: "",
  credentials: {
    avatar: "",
    username: "",
    external_accounts: [],
    xp: 0,
    quizes: [],
    _id: "",
    name: "",
    email: "",
    azure_id: "",
    createdAt: "2020-03-19T19:33:03.373Z",
    updatedAt: "2020-03-19T19:33:03.373Z",
    __v: 0
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SIGNUP_SUCCESS":
    case "LOGIN_SUCCESS":
      return { ...state, credentials: payload.credentials }
    case "LOGIN":
      return {
        ...state,
        accessToken: payload
      }
    case "LOGOUT":
      return {
        ...initialState
      }

    default:
      return state
  }
}
