/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Row, Col } from "antd"
import { useParams, Link } from "react-router-dom"
import { machineTypes } from "../../data/machine-types.data"
import { useParrot } from "../hooks/parrot.hooks"

const RenderMachine = ({ name, thumbnail, description, ez_id, sub_type, height, width }) => {
  let { machine } = useParams()

  const [state, setState] = useState({ size: 0 })
  const resize = () => {
    setState({ size: window.innerWidth })
  }

  const onMount = () => {
    window.addEventListener("resize", resize)
    resize()
    return () => {
      window.removeEventListener("resize", resize)
    }
  }

  useEffect(onMount, [])

  return (
    <Col
      className="machine-col mb-sm-5 mb-xs-5"
      align="bottom"
      xs={24}
      sm={24}
      md={24}
      lg={
        24 /
        machineTypes.filter(
          (machinesub) =>
            machinesub.sub_type.toUpperCase() == machine.toUpperCase()
        ).length
      }>
      <Link
        className="link d-flex flex-column justify-content-center align-items-center machine"
        to={`/dashboard/machines/learn/${ez_id.toLowerCase()}`}>
        {/* <div
          className='machine-img'
          style={{
            backgroundImage: `url(${thumbnail})`
          }}
        /> */}
        <img
                    style={{
                      height: height,
                      width: width,
                    }}
          src={thumbnail}
          alt=""
        />
        <div className="machine-hover">{description}</div>
      </Link>
      <h2
        className="text-dark-blue machine-name machine-landing-titles capitalize-title"
        style={{
          fontSize: sub_type === "Forestry" ? "1.5rem" : "auto"
        }}>
        <b>{name}</b>
      </h2>
    </Col>
  )
}

const TrackMachines = () => {
  let { machine } = useParams()
  const { setParrot } = useParrot()
  useEffect(() => {
    let dialog = ""
    switch (machine.toUpperCase()) {
      case "TRACK":
        dialog =
          "There are two types of Track Machines: Excavators and Dozers. Choose one to learn about the most important parts of that machine."
        break
      case "WHEELED":
        dialog =
          "There are two types of Wheeled Machines: Haul Trucks and Wheel Loaders. Choose one to learn more."
        break
      case "FORESTRY":
        dialog =
          "There are four types of Forestry Machines. Choose one to begin learning the differences between the machines and what each one does."
        break
      default:
        break
    }
    setParrot({
      dialog,
      display: "TEACHER",
      mode: 1
    })
  }, [])
  return (
    <div className="page custom-p-5">
      <Row style={{ position: "relative" }}>
        <Col span={24}>
          <h1 style={{ fontWeight: "900", fontSize: "3.5rem" }}>
            <div
              style={{
                marginTop: "4vh",
                zIndex: 1,
                color: "white",
                textAlign: "center",
                textTransform: "capitalize",
                // transform: "translateY(5px)"
              }}>
              <b>{machine} Machines</b>
            </div>
          </h1>
        </Col>
      </Row>
      <Row
        align="bottom"
        className="flex-wrap"
        style={{ position: "relative", paddingTop: "15vh" }}>
        {machineTypes
          .filter(
            (machinesub) =>
              machinesub.sub_type.toUpperCase() == machine.toUpperCase()
          )
          .map(RenderMachine)}
      </Row>
    </div>
  )
}

export default TrackMachines
