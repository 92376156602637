/* eslint-disable */
import React, { useEffect } from "react"
import { Row, Col, Card, Avatar, Spin } from "antd"
import { useParams } from "react-router-dom"
import { UserOutlined, LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { GET_CONTENT } from "../../store/actions/content.actions"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import { commoditySupportLinks } from "../../helpers/quiz.helpers"
import { useParrot } from "../hooks/parrot.hooks"
import { isUrl } from "../../helpers/Utils.helpers"
import { useCommodityScreen } from "../hooks/screen.hooks"

const CommoditiesSupport = () => {
  const { setParrot } = useParrot()
  const setModalToInactive = () => {}
  let { commodity_ez_id } = useParams()
  const { commodity } = useCommodityScreen(commodity_ez_id)
  const contentScreen = useSelector((state) => state.Content)
  const dispatch = useDispatch()
  useEffect(() => {
    if (
      typeof commoditySupportLinks[commodity_ez_id.toLowerCase()] !==
      "undefined"
    ) {
      setParrot({
        dialog:
          "Learn all of your Product Support contacts, then click the quiz head at the bottom to test your knowledge.",
        display: "TALKING",
        mode: 1
      })
    }
    dispatch(GET_CONTENT({ ez_id: "support", suffix: `/${commodity_ez_id}` }))

    return () => {
      dispatch({
        type: "RESET_CONTENT_SCREEN"
      })
    }
  }, [commodity_ez_id])
  return (
    <Spin
      spinning={contentScreen.loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <div className="page">
        <div className="py-5">
          <center>
            <strong>
              <h1 className={`${commodity_ez_id.toLowerCase()}-color capitalize-title`}>
                <b>{commodity.name}</b>
              </h1>
              <h5>
                <b>Product Support</b>
              </h5>
            </strong>
          </center>
        </div>

        <div className="p-4">
          <Row gutter={32}>
            {contentScreen?.content[0]?.data.map((contact) => {
              return (
                <Col key={contact.email} sm={24} xs={24} md={12}>
                  <Card style={{ height: 290, marginTop: 15 }}>
                    <div className="d-flex flex-row align-items-center">
                      <div className="p-2">
                        <Avatar size={64} icon={<UserOutlined />} />
                      </div>
                      <div
                        className="p-2"
                        style={{ wrap: "no-wrap", textOverflow: "hidden" }}>
                        <div>
                          <b>{contact.contact_name}</b>
                        </div>
                        <div>
                          {contact.title.length > 0 && `${contact.title}, `}{" "}
                          {contact.company}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: contact.phone
                          }}
                        />
                        <div>{contact.email}</div>
                        {contact.contact_for.length > 0 && (
                          <div style={{ fontSize: 12 }}>
                            <b>Contact for:</b>
                            <i> {contact.contact_for}</i>
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
      {typeof commoditySupportLinks[commodity_ez_id.toLowerCase()] !==
        "undefined" && (
        <div>
          <TestYourKnowledge
            link={commoditySupportLinks[commodity_ez_id.toLowerCase()]}
          />
          <QuizPrompt onExitEarly={setModalToInactive} />
        </div>
      )}
    </Spin>
  )
}
export default CommoditiesSupport
