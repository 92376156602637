/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "antd"
import { useParams } from "react-router-dom"
import { useCommodityScreen } from "../hooks/screen.hooks"

import QuizPrompt from "../quizes/prompts/quizprompt.quiz"
import TestYourKnowledge from "../common/icons/test-your-knowledge"
import { commodityKeyTermsLinks } from "../../helpers/quiz.helpers"
import { useParrot } from "../hooks/parrot.hooks"

const KeyTerms = () => {
  const { setParrot } = useParrot()

  const { commodity_ez_id } = useParams()
  const { commodity } = useCommodityScreen(commodity_ez_id)
  const state = useCommodityScreen(commodity_ez_id)
  const [modal, setModal] = useState(false)
  const setModalToInactive = () => {}
  const close = () => {
    setModal(!modal)
  }

  useEffect(() => {
    setParrot({
      dialog:
        "  Take your time to really study these keywords. Visit this page regularly! Once you feel like you've got them locked into your brain, click the quiz head at the bottom of the page to test your knowledge!",
      display: "TEACHER",
      mode: 1
    })
  }, [])
  return (
    <div className={`page ${commodity_ez_id}-terms-bg`}>
      <div className="d-flex justify-content-center align-items-center px-5 p-2 pt-5">
        <h1 className={`${commodity_ez_id}-color capitalize-title`}>
          <b>{commodity.name}</b>
        </h1>
      </div>
      <Row>
        <Col span={4} />
        <Col span={16}>
          <div className="py-5">
            <center>
              <h1>
                <b>Key Terms</b>
              </h1>
              <h5 className={`reman-color`}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://komatsupartsu.com/v1/images/public/${commodity_ez_id.toUpperCase()}-Key-Terms.pdf`}>
                  Download Printable Version
                </a>
              </h5>
            </center>
          </div>
          <i>
            Read each key term, memorize it and use it in a sentence with a
            customer or teammate.
          </i>
          <br />
          <br />
          {state.commodity.terms.map((term) => {
            return (
              <Row key={term[0]} className="mb-2">
                <Col span={23}>
                  <div>
                    <h5>
                      <b>{term[0]}</b>
                    </h5>
                    <div className={term[0] === "Micron" && "d-flex flex-row"}>
                      {term[1].split("\\n").map((text) => (
                        <div key={text}>{text}</div>
                      ))}
                      {term[0] === "Micron" && (
                        <div
                          className="d-flex flex-row ml-4 pointer-cursor"
                          onClick={() => {
                            setModal(!modal)
                          }}>
                          <img
                            height={20}
                            width={30}
                            src={require("../../assets/images/icons/eye-icon-komatsu-blue@3x.png")}
                            alt=""
                          />
                          <div className="k-blue ml-2">
                            View real life comparisons
                          </div>
                          <Modal
                            className="modal-70vw"
                            visible={modal}
                            onOk={close}
                            onCancel={close}
                            cancelButtonProps={{ style: { display: "none" } }}
                            okButtonProps={{
                              style: { backgroundColor: "#140A9A" }
                            }}
                            okText="Close">
                            <h3>Micron Rating: Real-life Comparisons</h3>
                            <img
                              width="100%"
                              src={require("../../assets/images/comp.png")}
                              alt=""
                            />
                          </Modal>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            )
          })}
        </Col>
      </Row>
      <TestYourKnowledge
        link={commodityKeyTermsLinks[commodity_ez_id.toLowerCase()]}
      />
      <QuizPrompt onExitEarly={setModalToInactive} />
    </div>
  )
}

export default KeyTerms
