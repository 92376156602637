import { createSelector } from "reselect"

// export const isLoggedInSelector = createSelector(
// 	[(state) => state.User.authToken],
// 	(token) => {
// 		return token.length > 0
// 	}
// )

export const tokenSelector = createSelector(
  [(state) => state.User.accessToken],
  (token) => {
    return token
  }
)

export const loggedInSelector = createSelector(
  [(state) => state.User.accessToken],
  (token) => {
    return token.length > 0
  }
)

export const userSelector = createSelector([(state) => state.User], (user) => {
  return user
})

export const quizSelector = (quiz_ez_id) => {
  return createSelector([(state) => state.Quiz], (quiz) => {
    if (quiz.sub_quizes.length > 0) {
      for (let i = 0; i < quiz.sub_quizes.length; i++) {
        if (quiz.sub_quizes[i].ez_id === quiz_ez_id) {
          return quiz.sub_quizes[i]
        }
      }
    } else {
      return quiz
    }
  })
}
