const initialState = {
  step: 0
}

export default (state = initialState, { type }) => {
  switch (type) {
    case "UPDATE_LOCAL_VERSION":
      return initialState
    case "START_TUTORIAL":
      return { ...state }
    case "WATCHED_WELCOME_MODAL":
    case "INCREMENT_TUTORIAL":
      return {
        ...state,
        step: state.step + 1
      }
    default:
      return state
  }
}
