const initialState = {
	ez_id: '',
	xray_images: {
		colored_full: '',
		xray_full: '',
		batteries: '',
		filters: '',
		hyrdaulics: '',
		lubricants: '',
		reman: '',
		undercarriage: ''
	},
	sizes: {
		small: {
			image: '',
			description: ''
		},
		mid: {
			image: '',
			description: ''
		},
		large: {
			image: '',
			description: ''
		},
		mining: {
			image: '',
			description: ''
		}
	},
	thumbnail: '',
	name: '',
	model_number: '',
	machine_type: '',
	sub_type: '',
	commodities: [],
	files: [],
	media: [],
	popups: [],
	description: ''
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'RESET_MACHINE_SCREEN':
			return initialState
		case 'GET_MACHINE_SUCCESS':
			return payload
		default:
			return state
	}
}
